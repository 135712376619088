export const map_constants = {
    address_components_names:{
        streetNumber: { 
            typeName: 'street_number',
            addressAttribute: 'number',
        },
        street: {
            typeName: 'route',
            addressAttribute: 'street',
        },
        city: {
            typeName: 'locality',
            addressAttribute: 'city',
        },
        state: {
            typeName: 'administrative_area_level_1',
            addressAttribute: 'locality',
        },
        country: {
            typeName: 'country',
            addressAttribute: 'country',
        },
        postalCode: {
            typeName: 'postal_code',
            addressAttribute: 'postal_code',
        },
    },
    defaultGeometry: {
        latitude: 29.7600771,
        longitude: -95.37011079999999,
    }
}

export const mergeAddress = (place, address) => {

    let newAddress = {...address};

    if( place && place.address_components != null ){
        
        place.address_components.forEach( (addressComponent) => {

            if( addressComponent.types ){
                addressComponent.types.forEach( (addressComponentType) => {
                        
                        const addressComponentNamesProperties = Object.getOwnPropertyNames(map_constants.address_components_names);

                        addressComponentNamesProperties.forEach( (propertyName) => {

                            if( addressComponentType === map_constants.address_components_names[propertyName].typeName ){
                                const addressAttribute = map_constants.address_components_names[propertyName].addressAttribute;
                                newAddress[addressAttribute] = addressComponent.long_name;
                            }

                        });
                    
                });
            }

        });

    }

    if( place.geometry.location ){

        newAddress.latitude = place.geometry.location.lat();
        newAddress.longitude = place.geometry.location.lng();
    }

    return newAddress;
} 