import React from "react";
import { Navigate, useLocation } from "react-router";
import { getUserData, validateUserPrivileges } from "../../../service/tenant/SecurityService";
import constants from "../util/Constants";

function ValidatePrivileges( {privileges, children} ){

    const userData = getUserData();
    const location = useLocation();

    //console.debug('[requireAuth] userData: ', userData);
    //console.debug('[requireAuth] privileges: ', privileges);

    
    if( !userData ){
        return <></>
    }

    const validatePrivileges = validateUserPrivileges(privileges);

    
    if( userData && privileges && !validatePrivileges ){
        return <></>
    }

    
    
    return children;
}

export default ValidatePrivileges;