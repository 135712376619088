import { FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import constants from "./Constants";
import { MuiTelInput } from "mui-tel-input";


function AttributeInputComponent(props){

    const [validationError, setValidationError] = useState(false);


    useEffect(() => {
        if( props.required && (props.fieldValue === null || props.fieldValue === '') ){
            setValidationError(true);
        } else {
            setValidationError(false);
        }
    }, [props.fieldValue])


    const handleChangeValue = (e) => {
        props.handleChangeField( props.attributeName, props.index, e.target.value );

        if ( e.target.validity.valid) {
            setValidationError(false);
        } else {
            setValidationError(true);
        }
    }

    

    return (
        <>
            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                <Typography sx={constants.styles.textNormal} >
                    {props.fieldName} :
                </Typography>
            </Grid>
            <Grid item sx={constants.styles.sectionInput} xs={8} md={8}>
                
                <FormControl variant="standard">
                    <TextField
                        id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                        required={props.required}
                        value={props.fieldValue}
                        onChange={ handleChangeValue }
                        sx={ constants.styles.textFieldNormal }
                        error={validationError}
                        helperText={ validationError ? 
                            props.emailValidation ?
                                constants.errors.formEmailValidationError
                                : constants.errors.formValidationError 
                            : ''}
                        size="small"
                        inputProps={ props.emailValidation ? {
                            type: "email",
                        }:{}}
                    />
                </FormControl>
            </Grid>
        </>
    );


}


export default AttributeInputComponent;