import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, Search } from "@mui/icons-material";
import { logicalDeleteRequest, searchRequestsByAnyAttribute } from "../../../service/tenant/RequestService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { getAllActiveClients } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import { getRequestStatusName } from "../../../service/tenant/StatusService";
import { getClientLocalStorage } from "../../../service/tenant/SecurityService";



function ViewRequestsUsers(props){
    

    const [tableRows, setTableRows] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [requestToDelete, setRequestToDelete] = useState(null);
    const [activeProjects, setActiveProjects] = useState([]);
    const [project, setProject] = useState(null);
    

    const tenant = getTenantLocalStorage();
    const client = getClientLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {
        console.log('[ViewRequests][useEffect] client: ', client);
        
        if( client ){
            const clientId = client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

        }
    }, []);

    const handleSearchRequests = () => {
        const tenantId = tenant.id;
        
        if( (!searchFilter || searchFilter === '') && client == null ){
            setErrorMessage(constants.errors.searchFilterEmpty);
            return;
        }

        setErrorMessage('');
        setTableRows([]);

        setIsLoading(true);

        let clientId = '';
        let projectId = '';
        if( client ){
            clientId = client.id;
            if( project ){
                projectId = project.id;
            }
        }

        searchRequestsByAnyAttribute(searchFilter, clientId, projectId, tenantId).then( requestList => {

            if( requestList !== null ){
                console.debug('[ViewRequests][handleSearchRequests] requestList: ', requestList);

                if( requestList.length > 0 ){
                    setTableRows(requestList);
                } else {
                    setErrorMessage(constants.errors.emptyResult);
                }
            } else {
                setErrorMessage(constants.errors.searchRequestError);
            }

            setIsLoading(false);
            
        });
    }

    const handleEditRequest = (requestId) => {
        console.debug('[ViewRequests][handleEditRequest] requestId: ', requestId);
        navigate(constants.routes.editRequestUsers, {
            state: {
                requestId: requestId
            }
        });
    }


    const handleNewRequest = () => {
        navigate(constants.routes.createRequestUsers, {
            state: {
                requestId: ''
            }
        });
    }

    const handleDeleteRequest = (request) => {
        console.debug('[ViewRequests][handleDeleteRequest] request: ', request);
        setRequestToDelete(request);
        setOpenDeleteAlert(true);
    }

    const handleConfirmDeleteRequest = () => {
        console.debug('[ViewRequests][handleConfirmDeleteRequest] ');

        const tenantId = tenant.id;

        setOpenDeleteAlert(false);
        setIsLoading(true);

        logicalDeleteRequest(requestToDelete, tenantId).then( (deleteResponse) => {
            console.debug('[ViewRequests][handleConfirmDeleteRequest] deleteResponse: ', deleteResponse);

            if( deleteResponse != null ){
                setSuccessMessage(constants.errors.deleteRequestSuccess);

                handleSearchRequests();

            } else {
                setErrorMessage(constants.errors.deleteRequestError);
            }

            setIsLoading(false);
            setRequestToDelete(null);
        })

        
    }

    const handleCloseDeleteDialog = () => {
        setRequestToDelete(null);
        setOpenDeleteAlert(false);
    }


    const handleChangeProject = (attributeName, newProject) => {
        console.debug('[ViewRequests][handleChangeClient] newProject: ', newProject)
        setProject(newProject);
    }


    return (
        <PrincipalLayout section={constants.menu.admin_requests_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Requests &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                            <Typography sx={constants.styles.textNormal}>
                                Search for request:
                            </Typography>
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={9} md={9}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-user-search" sx={constants.styles.textNormal}>
                                    Type request information
                                </InputLabel>
                                <Input
                                    id="input-user-search"
                                    value={searchFilter}
                                    sx={constants.styles.inputSearchNormal}
                                    onChange={ (e) => setSearchFilter(e.target.value) }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={handleSearchRequests}
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    
                                />
                            </FormControl>
                        </Grid>


                        <AutocompleteComponent
                            fieldId={'input-request-project'}
                            fieldName={'Project'} 
                            fieldValue={project}
                            attributeName={'project'}
                            optionList={activeProjects}
                            optionAttributeLabel={'name'}
                            isClient={false}
                            handleChangeField={handleChangeProject}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Title</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Service type</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Status</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {tableRows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditRequest(row.id)}
                                                >
                                                    {row.title}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{row.project_type ? row.project_type.name : ''}</TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ getRequestStatusName( row.status ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit request"
                                                    onClick={() => handleEditRequest(row.id)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete request"
                                                    onClick={() => handleDeleteRequest(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewRequest}
                                > 
                                        New Request 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this request?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            Request name: <strong>{ requestToDelete ? requestToDelete.title : ''}</strong>
                            <br/>If you delete this request will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteRequest} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default ViewRequestsUsers;