import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import constants from "../util/Constants";
import AppRoutes from "./AppRoutes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StompSessionProvider } from "react-stomp-hooks";

function Principal(props){


    return (
        <StompSessionProvider
            url={constants.notifications.stompUrl}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <Box  sx={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: constants.colors.bodyBackground,
                            
                        }}
                >
                    <Grid container 
                        rowSpacing={0} 
                        justifyContent={'center'}
                        alignContent={'center'}
                        alignItems={'center'}
                        >
                            <AppRoutes />
                    </Grid>
                </Box>      

            </LocalizationProvider>
        </StompSessionProvider>
    );
}

export default Principal;