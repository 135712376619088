import { AccountCircle, CircleNotifications } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { clearUserData, getUserData } from "../../../service/tenant/SecurityService";
import constants from "../util/Constants";
import { useSubscription } from "react-stomp-hooks";
import NotificationIcon from "../notifications/NotificationIcon";


function ToolbarMenuIcons(props){

    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        console.debug('[ToolbarMenuIcons][handleLogout]');
        setAnchorEl(null);
        clearUserData();
        navigate(constants.routes.home);
    };

    const handleProfile = () => {
        console.debug('[ToolbarMenuIcons][handleProfile]');
        setAnchorEl(null);
        navigate(constants.routes.userProfile);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    
    
    return (

        <Box
            sx={{
                    width: {
                        xs: 100, 
                        sm: 100, 
                        md: 100, 
                        lg: 100, 
                        xl: 100, 
                    },
                    marginLeft: '10px'
                }}
        >
        
            <NotificationIcon 
                notificationNumber={props.notificationNumber}
                setNotificationNumber={props.setNotificationNumber}
            />

            <IconButton
                size="medium"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleProfile} sx={constants.styles.textNormal}>Profile</MenuItem>
                <MenuItem onClick={handleLogout} sx={constants.styles.textNormal}>Logout</MenuItem>
            </Menu>

            
        </Box>

    );

}

export default ToolbarMenuIcons;