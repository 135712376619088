import React, { useEffect, useState } from "react";
import constants from "../util/Constants";
import { getPublicItemUrl } from "../../../service/tenant/ItemService";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import { QRCodeCanvas } from "qrcode.react";

function ItemQRCode(props){

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;

    const [imgDataURL, setImgDataURL] = useState(undefined);

    useEffect(() => {

        const canvas = document.getElementById(constants.styles.qrCodeDefaultIdPrefix + props.index);
        if( canvas ){
            const pngUrl = canvas.toDataURL("image/png")
                            .replace("image/png", "image/octet-stream");
            setImgDataURL(pngUrl);

        }

    }, [props.item]);


    return (
        <div>
            { props.item && props.item.id &&
                (
                    <>
                    <div style={{display: 'none'}}>

                        <QRCodeCanvas 
                            id={constants.styles.qrCodeDefaultIdPrefix + props.index}
                            value={ getPublicItemUrl(props.item.id, tenantId) } 
                            size={constants.styles.qrCodeDefaultSizeInt} 

                        />
                    </div>
                    <img src={imgDataURL} height={constants.styles.qrCodeDefaultSizeInt} width={constants.styles.qrCodeDefaultSizeInt} />
                    </>
                )
            }
          
        </div>
    )

} 

export default ItemQRCode;