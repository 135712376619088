import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import constants from "./Constants";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import { discountTypesCatalog, getAllDiscountTypes } from "../../../service/tenant/QuoteService";

function DiscountDialog(props){

    const [discountTypes, setDiscountTypes] = useState([]);

    useEffect(() => {

        getAllDiscountTypes().then( discountTypesResponse => {
            if( discountTypesResponse ){
                setDiscountTypes(discountTypesResponse);
            }
        })

    },[]);

    const handleCloseDiscountDialog = () => {
        props.handleChangeDiscountField(props.discountAttributeName, 0.0);
        props.setOpenDiscountDialog(false);
    }

    const handleConfirmAction = () => {
        props.setOpenDiscountDialog(false);
    }

    const handleCancelAction = () => {
        props.handleChangeDiscountField(props.discountAttributeName, 0.0);
        props.setOpenDiscountDialog(false);
    }

    return (
        <Dialog
            open={props.openDiscountDialog}
            onClose={handleCloseDiscountDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={constants.styles.textNormal}
                id="alert-dialog-title"
            >
                <strong>{props.title}</strong>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={constants.styles.textNormal}
                    id="alert-dialog-description"
                >
                    <Grid container >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                            >

                                <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                    &nbsp;
                                </Grid>

                                <SelectComponent
                                    fieldName={ props.fieldDiscountTypeName } 
                                    fieldValue={ props.fieldDiscountTypeValue } 
                                    fieldId={props.fieldDiscountTypeId ? props.fieldDiscountTypeId : 'id_' + props.fieldDiscountTypeName} 
                                    attributeName={props.fieldDiscountTypeAttributeName} 
                                    optionList={discountTypes}
                                    optionAttributeName={'name'}
                                    handleChangeField={props.handleChangeDiscountTypeField}
                                    
                                    sx={constants.styles.selectTextMedium}

                                    hideLabel={true}

                                    labelSx={1}
                                    labelMd={1}

                                    inputSx={4}
                                    inputMd={4}
                                />

                                <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                    &nbsp;
                                </Grid>

                                <InputComponent 
                                    fieldName={props.fieldDiscountName} 
                                    fieldValue={ props.fieldDiscountValue } 
                                    fieldId={props.fieldDiscountId ? props.fieldDiscountId : 'id_' + props.fieldDiscountName} 
                                    attributeName={props.discountAttributeName} 
                                    handleChangeField={props.handleChangeDiscountField}
                                    required={props.discountRequired} 
                                    sx={constants.styles.textFieldMedium}
                                    labelSx={1}
                                    labelMd={1}

                                    inputSx={5}
                                    inputMd={5}

                                    hideLabel={true}

                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            {
                                                props.fieldDiscountTypeValue === discountTypesCatalog.percentageDiscount.id ?
                                                '%'
                                                :
                                                '$'

                                            }
                                        </InputAdornment>,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                            min: 0,
                                        }
                                    }}
                                    numberValidation={true}
                                />

                                


                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined" 
                    size="small"
                    sx={constants.styles.buttonNormal}
                    onClick={handleCancelAction}
                >
                    Cancel
                </Button>
                <Button 
                    variant="outlined" 
                    size="small"
                    sx={constants.styles.buttonNormal}
                    onClick={handleConfirmAction} 
                    autoFocus
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default DiscountDialog;