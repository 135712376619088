import React from "react";
import constants from "./Constants";
import { Chip } from "@mui/material";

function SuccessMessageChip(props) {

    const handleDelete = () => {
        props.setMessage('')
    }

    return (
        <>
            { props.message !== null && props.message !== ''  ?
                <Chip label={props.message} 
                    sx={constants.styles.textNormalSuccess} 
                    color="success"
                    onDelete={handleDelete}
                />
                :
                <></>
            }
        </>
    );

}

export default SuccessMessageChip;