import emailjs from '@emailjs/browser';
import { calculatDiscount, calculateSubTotal, calculatGrandTotal, calculatTaxes, calculatTotal, sendQuoteEmailPost } from './QuoteService';
import { getSingleFileName } from './StorageService';
import { calculatDiscountInvoice, calculateSubTotalInvoice, calculatGrandTotalInvoice, calculatTaxesInvoice, calculatTotalInvoice } from './InvoiceService';
import { getTenantLocalStorage } from './TenantService';

const SERVICE_ID = "mailjs_jobsmanager";
const QUOTE_TEMPLATE_ID = "quote_template_jobtube";
const INVOICE_TEMPLATE_ID = "invoice_template_jobtube";
const PUBLIC_KEY = "oembbCuNHgspSBzeI";
const FROM_NAME= "contact@job-tube.com";
const MAX_NUMBER_IMAGES = 3;


export const sendInvoiceEmail = (invoice, pdfBlob) => {


    if( invoice.client && invoice.client.user ){

        const clientEmail = invoice.client.user.email;
        const clientName = invoice.client.user.first_name + ' ' + invoice.client.user.last_name;
        var itemsMessage = "\nProduct/Service &nbsp;&nbsp;&nbsp;&nbsp; Qty. &nbsp;&nbsp; Unit price &nbsp;&nbsp; Total\n\n";

        console.log('[EmailService][sendInvoiceEmail]: clientEmail: ', clientEmail);
        console.log('[EmailService][sendInvoiceEmail]: itemsMessage: ', itemsMessage);

        if( invoice.invoice_item_list ){
            invoice.invoice_item_list.forEach( invoiceItem => {
                itemsMessage += invoiceItem.name + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + invoiceItem.quantity  + "&nbsp;&nbsp;&nbsp;&nbsp;$" + invoiceItem.unit_price + "&nbsp;&nbsp;&nbsp;&nbsp;$" + calculateSubTotalInvoice(invoiceItem) + "\n\n";
            });
        }


        const subtotal_items = "$" + calculatTotalInvoice(invoice);
        const discount = "$" + calculatDiscountInvoice(invoice);
        const taxes_items = "$" + calculatTaxesInvoice(invoice);
        const total_items = "$" + calculatGrandTotalInvoice(invoice);

        var pdfFileReader = new FileReader();
        
        pdfFileReader.readAsDataURL(pdfBlob);

        

        pdfFileReader.onload = async (e) => {
            console.debug('[EmailService][sendInvoiceEmail] pdf file: ', pdfFileReader.result);

            var templateParams = {
                client_name: clientName,
                invoice_number: invoice.invoice_number,
                from_name: FROM_NAME,
                to_name: clientEmail,
                message: invoice.notes,
                items: itemsMessage,
                subtotal_items: subtotal_items,
                taxes_items: taxes_items,
                total_items: total_items,
                discount: discount,
                reply_to: FROM_NAME,
                files_header: '',
                pdf_files_header: '',
                pdf_file: pdfFileReader.result,
            };

            if( invoice.invoice_image_list && invoice.invoice_image_list.length > 0 ){
                templateParams.files_header = "\nAttached images:";

                var imagesCount = 0;
                var pdfCount = 0;
                invoice.invoice_image_list.forEach( (invoice_image, index) => {
                    const fileName = getSingleFileName("invoice", invoice_image.name);

                    if( fileName.indexOf(".pdf") <= 0 ){
                        imagesCount++;
                        templateParams['file_url_'+imagesCount] = invoice_image.url;
                        templateParams['min-height_'+imagesCount] = '500px';
                    } else {
                        pdfCount++;
                        templateParams['pdf_url_'+pdfCount] = invoice_image.url;
                        templateParams['pdf_name_'+pdfCount] = fileName;
                    }
                    
                });

                if( pdfCount > 0 ){
                    templateParams.pdf_files_header = "\nPDF files:";
                }

                if( imagesCount < MAX_NUMBER_IMAGES ){
                    imagesCount++;
                    for( var i=imagesCount ; i <= MAX_NUMBER_IMAGES; i++ ){
                        templateParams['file_url_'+i] = '';
                        templateParams['min-height_'+i] = '0px';
                    }
                }
            }
        
            sendEmail(templateParams, INVOICE_TEMPLATE_ID);
        }
    }

}

export const sendQuoteEmail = (quote, pdfBlob) => {

    if( quote.client && quote.client.user ){

        const tenant = getTenantLocalStorage();
        const tenantId = tenant.id;
        const clientEmail = quote.client.user.email;
        const clientName = quote.client.user.first_name + ' ' + quote.client.user.last_name;
        var itemsMessage = "\nProduct/Service &nbsp;&nbsp;&nbsp;&nbsp; Qty. &nbsp;&nbsp; Unit price &nbsp;&nbsp; Total\n\n";

        console.log('[EmailService][sendQuoteEmail]: clientEmail: ', clientEmail);
        console.log('[EmailService][sendQuoteEmail]: itemsMessage: ', itemsMessage);

        if( quote.quote_item_list ){
            quote.quote_item_list.forEach( quoteItem => {
                itemsMessage += quoteItem.name + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + quoteItem.quantity  + "&nbsp;&nbsp;&nbsp;&nbsp;$" + quoteItem.unit_price + "&nbsp;&nbsp;&nbsp;&nbsp;$" + calculateSubTotal(quoteItem) + "\n\n";
            });
        }


        const subtotal_items = "$" + calculatTotal(quote);
        const discount = "$" + calculatDiscount(quote);
        const taxes_items = "$" + calculatTaxes(quote);
        const total_items = "$" + calculatGrandTotal(quote);

        var pdfFileReader = new FileReader();
        
        pdfFileReader.readAsDataURL(pdfBlob);

        

        pdfFileReader.onload = async (e) => {
            console.debug('[EmailService][sendQuoteEmail] pdf file: ', pdfFileReader.result);

            sendQuoteEmailPost(pdfBlob, quote.id, tenantId);

            /*
            var templateParams = {
                client_name: clientName,
                quote_title: quote.title,
                from_name: FROM_NAME,
                to_name: clientEmail,
                message: quote.notes,
                assessment: quote.assess_notes,
                items: itemsMessage,
                subtotal_items: subtotal_items,
                taxes_items: taxes_items,
                total_items: total_items,
                discount: discount,
                reply_to: FROM_NAME,
                files_header: '',
                pdf_files_header: '',
                pdf_file: pdfFileReader.result,
            };
    
            if( quote.quote_image_list && quote.quote_image_list.length > 0 ){
                templateParams.files_header = "\nAttached images:";
    
                var imagesCount = 0;
                var pdfCount = 0;
                quote.quote_image_list.forEach( (quote_image, index) => {
                    const fileName = getSingleFileName("quote", quote_image.name);
                    console.log('[EmailService][sendQuoteEmail]: fileName: ', fileName);

                    if( fileName.indexOf(".pdf") <= 0 ){    
                        imagesCount++;
                        templateParams['file_url_'+imagesCount] = quote_image.url;
                        templateParams['min-height_'+imagesCount] = '500px';
                        console.log('[EmailService][sendQuoteEmail]: imagesCount: ', imagesCount);
                    } else {
                        pdfCount++;
                        templateParams['pdf_url_'+pdfCount] = quote_image.url;
                        templateParams['pdf_name_'+pdfCount] = fileName;
                    }
                });

                if( pdfCount > 0 ){
                    templateParams.pdf_files_header = "\nPDF files:";
                }

                if( imagesCount < MAX_NUMBER_IMAGES ){
                    imagesCount++;
                    for( var i=imagesCount ; i <= MAX_NUMBER_IMAGES; i++ ){
                        templateParams['file_url_'+i] = '';
                        templateParams['min-height_'+i] = '0px';
                    }
                }
            }
        
            sendEmail(templateParams, QUOTE_TEMPLATE_ID);
            */
        }

        
        
    }

}


export const sendEmail = (templateParams, templateId) => {

    emailjs.send(SERVICE_ID, templateId, templateParams, {
        publicKey: PUBLIC_KEY
    })
    .then( (emailResponse) => {
        console.debug('[EmailService][sendEmail] Email response: ', emailResponse);
    },
    (error) => {
        console.debug('[EmailService][sendEmail] Send email error: ', error);
    });
    
    
}