import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home"
import Login from "../security/Login";
import Logout from "../security/Logout";
import RequireAuth from "../security/RequireAuth";
import ViewClients from "../clients/ViewClients";
import constants from "../util/Constants";
import EditClient from "../clients/EditClient";
import ViewProjects from "../projects/ViewProjects";
import EditProject from "../projects/EditProject";
import { getUserData, security_constants } from "../../../service/tenant/SecurityService";
import ViewRequests from "../requests/ViewRequests";
import EditRequest from "../requests/EditRequest";
import ViewQuotes from "../quotes/ViewQuotes";
import EditQuote from "../quotes/EditQuote";
import ViewJobs from "../jobs/ViewJobs";
import EditJob from "../jobs/EditJob";
import ViewWorkOrders from "../workorders/ViewWorkOrders";
import EditWorkOrder from "../workorders/EditWorkOrder";
import ViewRequestsUsers from "../requests/ViewRequestsUsers";
import EditRequestUsers from "../requests/EditRequestUsers";
import ViewQuotesUsers from "../quotes/ViewQuotesUsers";
import EditQuoteUsers from "../quotes/EditQuoteUsers";
import ViewWorkOrdersUsers from "../workorders/ViewWorkOrdersUsers";
import EditWorkOrderUsers from "../workorders/EditWorkOrderUsers";
import Users from "../administration/Users";
import Teams from "../administration/Teams";
import ServiceTypes from "../administration/ServiceTypes";
import Items from "../administration/Items";
import Item from "../item/Item";
import ViewInvoices from "../invoice/ViewInvoices";
import EditInvoice from "../invoice/EditInvoice";
import ViewInvoicesUser from "../invoice/ViewInvoicesUser";
import EditInvoiceUsers from "../invoice/EditInvoiceUsers";
import ViewNotifications from "../notifications/ViewNotifications";
import UserProfile from "../clients/UserProfile";

function AppRoutes(props){

    const [notificationNumber, setNotificationNumber] = useState(0);

    return (
        <Routes>
            <Route path={constants.routes.login} element={ <Login /> } />
            <Route path={constants.routes.logout} element={ <Logout /> } />
            <Route path={constants.routes.home}
                element={
                    <RequireAuth>
                        <Home 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.userProfile}
                element={
                    <RequireAuth>
                        <UserProfile 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewClients}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <ViewClients 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editClient}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <EditClient 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createClient}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <EditClient 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewProjects}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <ViewProjects 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editProject}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <EditProject 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createProject}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <EditProject 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewRequests}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_requests_all_access]}>
                        <ViewRequests 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewRequestsUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_requests_all_access]}>
                        <ViewRequestsUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createRequest}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_requests_all_access]}>
                        <EditRequest 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createRequestUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_requests_all_access]}>
                        <EditRequestUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editRequest}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_requests_all_access]}>
                        <EditRequest 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editRequestUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_requests_all_access]}>
                        <EditRequestUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewQuotes}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_quotes_all_access]}>
                        <ViewQuotes 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewQuotesUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_quotes_all_access]}>
                        <ViewQuotesUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createQuote}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_quotes_all_access]}>
                        <EditQuote 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editQuote}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_quotes_all_access]}>
                        <EditQuote 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createQuoteUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_quotes_all_access]}>
                        <EditQuoteUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber} 
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editQuoteUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_quotes_all_access]}>
                        <EditQuoteUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewJobs}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_jobs_all_access]}>
                        <ViewJobs 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createJob}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_jobs_all_access]}>
                        <EditJob 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editJob}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_jobs_all_access]}>
                        <EditJob 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewWorkOrders}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_jobs_all_access, 
                                                security_constants.privileges.admin_workorders_all_access]}>
                        <ViewWorkOrders 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewWorkOrdersUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_jobs_all_access]}>
                        <ViewWorkOrdersUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createWorkOrder}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_jobs_all_access, 
                                                security_constants.privileges.admin_workorders_all_access]}>
                        <EditWorkOrder 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editWorkOrder}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_jobs_all_access, 
                                                security_constants.privileges.admin_workorders_all_access]}>
                        <EditWorkOrder 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewInvoices}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_invoices_all_access]}>
                        <ViewInvoices 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewInvoicesUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_invoices_all_access]}>
                        <ViewInvoicesUser 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.createInvoice}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_invoices_all_access]}>
                        <EditInvoice 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editInvoice}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_invoices_all_access]}>
                        <EditInvoice 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editInvoiceUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_invoices_all_access]}>
                        <EditInvoiceUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.editWorkOrderUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.user_jobs_all_access]}>
                        <EditWorkOrderUsers 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.adminUsers}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <Users 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.adminTeams}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <Teams 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.adminItems}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <Items 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.adminServiceTypes}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_clients_all_access]}>
                        <ServiceTypes 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
            <Route path={constants.routes.viewPublicItem}
                element={
                        <Item />
                }
            />

            <Route path={constants.routes.viewNotifications}
                element={
                    <RequireAuth privileges={[security_constants.privileges.admin_calendar_all_access,
                                                security_constants.privileges.user_calendar_all_access
                                            ]}
                    >
                        <ViewNotifications 
                            notificationNumber={notificationNumber} 
                            setNotificationNumber={setNotificationNumber}
                        />
                    </RequireAuth>
                }
            />
        </Routes>
        
    );

}

export default AppRoutes;