import { EditNote } from "@mui/icons-material";
import { Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import constants from "../util/Constants";
import { convertDateStrToString } from "../../../service/tenant/ValidationService";
import { getWorkOrderStatusName } from "../../../service/tenant/StatusService";
import { useNavigate } from "react-router";

function WorkOrdersTable(props){

    const navigate = useNavigate();

    const handleEditWorkOrder = (workOrderId) => {
        console.debug('[ViewWorkOrdersTable][handleEditWorkOrder] workOrderId: ', workOrderId);
        navigate(constants.routes.editWorkOrder, {
            state: {
                workOrderId: workOrderId
            }
        });
    }

    const handleEditClient = (clientId) => {
        if( clientId != null ) { 
            console.debug('[ViewWorkOrdersTable][handleEditClient] clientId: ', clientId);
            navigate(constants.routes.editClient, {
                state: {
                    clientId: clientId
                }
            });
        }
    }

    return (
    <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
        <TableContainer component={Paper} variant="outlined">
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={constants.styles.tableHeader} >Work order title</TableCell>
                        { props.showClient && 
                            (
                                <TableCell sx={constants.styles.tableHeader} >Client</TableCell>
                            )
                        }
                        <TableCell sx={constants.styles.tableHeader} >Created</TableCell>
                        <TableCell sx={constants.styles.tableHeader} >Status</TableCell>
                        <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {props.workOrderList.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                            <Link 
                                component="button"
                                onClick={() => handleEditWorkOrder(row.id)}
                            >
                                {row.title}
                            </Link>
                        </TableCell>
                        { props.showClient && 
                            (
                                <TableCell sx={constants.styles.tableCell}>
                                    <Link 
                                        component="button"
                                        onClick={() => handleEditClient( row.client ? row.client.id : null)}
                                    >
                                        {row.client && row.client.user ? row.client.user.first_name + ' ' + row.client.user.last_name : '' }
                                    </Link>
                                </TableCell>
                            )
                        }
                        <TableCell sx={constants.styles.tableCell}>{ convertDateStrToString( row.creation_date ) }</TableCell>
                        <TableCell sx={constants.styles.tableCell}>{ getWorkOrderStatusName( row.status ) }</TableCell>
                        <TableCell sx={constants.styles.tableCell} align="center">
                            <IconButton 
                                size="small" 
                                title="Edit workOrder"
                                onClick={() => handleEditWorkOrder(row.id)}
                            >
                                <EditNote/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
    );

}

export default WorkOrdersTable;