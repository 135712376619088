import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, Search } from "@mui/icons-material";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import { getWorkOrderStatusName } from "../../../service/tenant/StatusService";
import { searchJobsByAnyAttribute } from "../../../service/tenant/JobService";
import {  searchWorkOrdersByAnyAttributeForClient } from "../../../service/tenant/WorkOrderService";
import { getClientLocalStorage } from "../../../service/tenant/SecurityService";
import { convertDateStrToString } from "../../../service/tenant/ValidationService";



function ViewWorkOrdersUsers(props){
    

    const [tableRows, setTableRows] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [activeProjects, setActiveProjects] = useState([]);
    const [activeJobs, setActiveJobs] = useState([]);
    const [project, setProject] = useState(null);
    const [job, setJob] = useState(null);
    

    const tenant = getTenantLocalStorage();
    const client = getClientLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {
        console.log('[ViewWorkOrders][useEffect] client: ', client);
        
        if( client ){
            const clientId = client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

            setIsLoading(true);
            searchJobsByAnyAttribute('', clientId, '', tenantId).then( activeJobsResponse => {
                if( activeJobsResponse != null ){
                    setActiveJobs(activeJobsResponse);
                }
                setIsLoading(false);
            });

        }

    }, []);


    const handleSearchWorkOrders = () => {
        const tenantId = tenant.id;
        
        if( (!searchFilter || searchFilter === '') && client == null ){
            setErrorMessage(constants.errors.searchFilterEmpty);
            return;
        }

        setErrorMessage('');
        setTableRows([]);

        setIsLoading(true);

        let clientId = '';
        let projectId = '';
        let jobId = '';
        if( client ){
            clientId = client.id;
            if( project ){
                projectId = project.id;
            }
            if( job ){
                jobId = job.id;
            }
        }

        searchWorkOrdersByAnyAttributeForClient(searchFilter, clientId, projectId, jobId, tenantId).then( workOrderList => {

            if( workOrderList !== null ){
                console.debug('[ViewWorkOrders][handleSearchWorkOrders] workOrderList: ', workOrderList);

                if( workOrderList.length > 0 ){
                    setTableRows(workOrderList);
                } else {
                    setErrorMessage(constants.errors.emptyResult);
                }
            } else {
                setErrorMessage(constants.errors.searchWorkOrderError);
            }

            setIsLoading(false);
            
        });
    }

    const handleEditWorkOrder = (workOrderId) => {
        console.debug('[ViewWorkOrders][handleEditWorkOrder] workOrderId: ', workOrderId);
        navigate(constants.routes.editWorkOrderUsers, {
            state: {
                workOrderId: workOrderId
            }
        });
    }

    
    const handleChangeProject = (attributeName, newProject) => {
        console.debug('[ViewWorkOrders][handleChangeClient] newProject: ', newProject)
        setProject(newProject);
    }

    const handleChangeJob = (attributeName, newJob) => {
        console.debug('[ViewWorkOrders][handleChangeClient] newJob: ', newJob)
        setJob(newJob);
    }



    return (
        <PrincipalLayout section={constants.menu.admin_workorders_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Work Orders &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                            <Typography sx={constants.styles.textNormal}>
                                Search for work order:
                            </Typography>
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={9} md={9}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-user-search" sx={constants.styles.textNormal}>
                                    Type work order info
                                </InputLabel>
                                <Input
                                    id="input-user-search"
                                    value={searchFilter}
                                    sx={constants.styles.inputSearchNormal}
                                    onChange={ (e) => setSearchFilter(e.target.value) }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={handleSearchWorkOrders}
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    
                                />
                            </FormControl>
                        </Grid>

                        <AutocompleteComponent
                            fieldId={'input-workOrder-project'}
                            fieldName={'Project'} 
                            fieldValue={project}
                            attributeName={'project'}
                            optionList={activeProjects}
                            optionAttributeLabel={'name'}
                            isClient={false}
                            handleChangeField={handleChangeProject}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />

                        <AutocompleteComponent
                            fieldId={'input-workOrder-job'}
                            fieldName={'Job'} 
                            fieldValue={job}
                            attributeName={'job'}
                            optionList={activeJobs}
                            optionAttributeLabel={'title'}
                            isClient={false}
                            handleChangeField={handleChangeJob}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Work order title</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Created</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Status</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {tableRows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditWorkOrder(row.id)}
                                                >
                                                    {row.title}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ convertDateStrToString( row.creation_date ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ getWorkOrderStatusName( row.status ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit workOrder"
                                                    onClick={() => handleEditWorkOrder(row.id)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>
                    
                </Paper>

                
                
        </PrincipalLayout>
    );
}

export default ViewWorkOrdersUsers;