import { FormControl, Grid, Switch, Typography } from "@mui/material";
import constants from "./Constants";

function SwitchComponent(props){

    const handleChangeValue = (e) => {
        props.handleChangeField( props.attributeName, e.target.checked );
    }

    return (
        <>
            <Grid item sx={constants.styles.sectionInput} xs={ props.labelSx ? props.labelSx : 3 } md={ props.labelMd ? props.labelMd : 3 }>
                <Typography sx={constants.styles.textNormal} >
                    {props.fieldName} :
                </Typography>
            </Grid>
            <Grid item sx={constants.styles.sectionInput} xs={ props.inputSx ? props.inputSx : 8 } md={ props.inputMd ? props.inputMd : 8 }>
                <FormControl variant="standard">
                    <Switch 
                        id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                        checked={ props.fieldValue }
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                        onChange={ handleChangeValue }
                        disabled={props.disabled}
                    />
                </FormControl>
            </Grid>
        </>
    );


}


export default SwitchComponent;