import React from 'react';
import { clearUserData, getUserData, setUserData } from '../../../service/tenant/SecurityService';
import { useNavigate } from 'react-router';
import constants from '../util/Constants';

const Logout = () => {

    const userData = getUserData();
    const navigate = useNavigate();

    const handleLogout = () => {
        console.debug('[Logout][handleLogout] userData: ', userData);
        clearUserData();
        navigate(constants.routes.home);
    }

    return (
        <div>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
}

export default Logout;