//Constants for shop application

const constants = {
    styles: {
        textHeader: {fontSize: {xs: 16, sm: 20, md: 28, lg: 28, xl: 28} },
        sectionTitle: {marginLeft: '0px', marginTop: '5px', marginBottom: '10px'},
        sectionTable: {marginLeft: '5px', marginRight: '5px', marginTop: '5px', marginBottom: '10px'},
        sectionButtons: {marginLeft: '10px', marginTop: '40px', marginBottom: '20px', marginRight: '10px'},
        sectionInput: {marginLeft: '10px', marginTop: '5px'},
        sectionQR: {marginLeft: '10px', marginTop: '20px'},
        sectionDivider: {marginTop: '10px', marginLeft: '5px', marginRight: '5px'},
        sectionError: {marginLeft: '10px'},
        textTitle: {marginLeft: '10px', fontSize: {xs: 13, sm: 17, md: 17, lg: 22, xl: 22}, fontWeight: 'bold'},
        textTitleUser: {fontSize: {xs: 12, sm: 16, md: 16, lg: 21, xl: 21}, textTransform: 'none'},
        textSubtitle: {fontSize: {xs: 13, sm: 17, md: 17, lg: 22, xl: 22}, textTransform: 'none'},
        textNormal: {fontSize: {xs: 12, sm: 16, md: 16, lg: 21, xl: 21}, textTransform: 'none'},
        textSmall: {fontSize: {xs: 8, sm: 10, md: 10, lg: 15, xl: 15}, textTransform: 'none'},
        selectTextNormal: {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 240, sm: 240, md: 260, lg: 350, xl: 350}},
        selectTextMedium: {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 100, sm: 100, md: 120, lg: 180, xl: 250}},
        selectTextSmall: {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 40, sm: 40, md: 60, lg: 70, xl: 70}},
        selectTextNormalDialog: {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 200, sm: 280, md: 320, lg: 380, xl: 400}},
        buttonNormal: {fontSize: {xs: 12, sm: 16, md: 16, lg: 21, xl: 21}, textTransform: 'none', marginRight: '10px'},
        textMenu: {fontSize: {xs: 12, sm: 16, md: 16, lg: 21, xl: 21}},
        textSubmenu: {fontSize: {xs: 10, sm: 14, md: 14, lg: 18, xl: 18}},
        textNormalError: {fontSize: {xs: 11, sm: 15, md: 15, lg: 20, xl: 20}, color: '#dd2d16'},
        textNormalSuccess: {fontSize: {xs: 11, sm: 15, md: 15, lg: 20, xl: 20}, color: 'success' },
        inputTextNormal: {fontSize: {xs: 12, sm: 16, md: 16, lg: 21, xl: 21}},
        iconSize: {fontSize: {xs: 17, sm: 24, md: 26, lg: 31, xl: 31}},
        avatarIconSize: {fontSize: {xs: 17, sm: 20, md: 20, lg: 22, xl: 22}},
        textFieldSmall: { "& .MuiOutlinedInput-root": {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 38, sm: 60, md: 80, lg: 80, xl: 80} } },
        textFieldMedium: { "& .MuiOutlinedInput-root": {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 74, sm: 110, md: 150, lg: 160, xl: 160} } },
        textFieldMediumAlignRight: { "& .MuiOutlinedInput-root": { textAlign: 'right', fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 140, sm: 140, md: 150, lg: 160, xl: 160} } },
        textFieldNormal: { "& .MuiOutlinedInput-root": {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 240, sm: 240, md: 260, lg: 350, xl: 350} } },
        textFieldLarge: { "& .MuiOutlinedInput-root": {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 300, sm: 320, md: 400, lg: 500, xl: 500} } },
        textFieldNormalDialog: { "& .MuiOutlinedInput-root": {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: 200, sm: 280, md: 320, lg: 380, xl: 400} } },
        textFieldLineItem: { "& .MuiOutlinedInput-root": {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: '32vw', sm: '24vw', md: '25vw', lg: '28vw', xl: '28vw'} } },
        textFieldNormalFullWidth: { "& .MuiOutlinedInput-root": {fontSize: {xs: 10, sm: 14, md: 16, lg: 20, xl: 20}, width:{xs: '80vw', sm: '60vw', md: '75vw', lg: '80vw', xl: '90vw'} } },
        inputSearchNormal: {fontSize: {xs: 12, sm: 16, md: 16, lg: 21, xl: 21}, marginBottom: '15px'},
        tableHeader: {fontSize: {xs: 11, sm: 15, md: 15, lg: 20, xl: 20}, fontWeight: 'bold', textTransform: 'none'},
        tableCell: {fontSize: {xs: 11, sm: 15, md: 15, lg: 20, xl: 20}},
        ipnputSearch: {width: {xs:'50px', sm:'80px', md:'150px', lg:'150px', xl:'200px' }, fontSize: {xs: 12, sm: 16, md: 16, lg: 21, xl: 21}},
        paperContainer: {marginTop: '10px', width:'100%', marginLeft: '0px'},
        qrCodeDefaultSize: '100px',
        qrCodeMiniSize: '50px',
        qrCodeDefaultSizeInt: 80,
        qrCodeDefaultIdPrefix: 'item_qrcode_',
    },
    
    colors: {
        navBackground: '#1271b5',
        navLightBackground: '#5d19704',
        bodyBackground: '#f7f7f7',
        menuBackground: '#1271b5',
        menuColor: '#1271b5',
        bottonTextColor: '#1271b5',
        secondaryTextColor: '#0013ab',
        buttonBorderColor: '#1271b5',
        iconColor: '#1271b5',
        alertColor: '#dd2d16',
        calendarUnnasigned: '#8498ab',
        tableCellHeaderBorder: '#00519C',
        tableCellBorder: '#3778C2',
        tableHeaderBackground: '#00519C',
        tableHeaderColor: '#fff',
        tableBorder: '#3778C2',
    },
    routes: {
        login: '/login',
        logout: '/logout',
        home: '/',
        userProfile: '/userProfile',
        viewClients: '/viewClients',
        editClient: '/editClient',
        createClient: '/createClient',
        viewProjects: '/viewProjects',
        editProject: '/editProject',
        createProject: '/createProject',
        viewRequests: '/viewRequests',
        editRequest: '/editRequest',
        createRequest: '/createRequest',
        viewQuotes: '/viewQuotes',
        editQuote: '/editQuote',
        createQuote: '/createQuote',
        viewJobs: '/viewJobs',
        editJob: '/editJob',
        createJob: '/createJob',
        viewWorkOrders: '/viewWorkOrders',
        editWorkOrder: '/editWorkOrder',
        createWorkOrder: '/createWorkOrder',

        viewInvoices: '/viewInvoices',
        editInvoice: '/editInvoice',
        createInvoice: '/createInvoice',

        viewRequestsUsers: '/viewRequestsUsers',
        editRequestUsers: '/editRequestUsers',
        createRequestUsers: '/createRequestUsers',
        viewQuotesUsers: '/viewQuotesUsers',
        editQuoteUsers: '/editQuoteUsers',
        createQuoteUsers: '/createQuoteUsers',
        viewJobsUsers: '/viewJobsUsers',
        editJobUsers: '/editJobUsers',
        createJobUsers: '/createJobUsers',
        viewWorkOrdersUsers: '/viewWorkOrdersUsers',
        editWorkOrderUsers: '/editWorkOrdeUsers',
        createWorkOrderUsers: '/createWorkOrderUsers',

        viewInvoicesUsers: '/viewInvoicesUsers',
        editInvoiceUsers: '/editInvoiceUsers',
        createInvoiceUsers: '/createInvoiceUsers',

        viewNotifications: '/viewNotifications',

        adminUsers: '/adminUsers',
        adminTeams: '/adminTeams',
        adminServiceTypes: '/adminServiceTypes',
        adminItems: '/adminItems',
        viewPublicItem: '/item',
    },
    menu: {
        admin_clients_section: 'admin.client.section',
        admin_requests_section: 'admin.requests.section',
        admin_quotes_section: 'admin.quotes.section',
        admin_workorders_section: 'admin.workorders.section',
        admin_invoices_section: 'admin.invoices.section',
        admin_admin_section: 'admin.admin.section',
        admin_admin_catalogs_section: 'admin.admin.catalogs.section',
        admin_calendar_section: 'admin.calendar.section'
    },
    api: {
        defaultHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        },
        defaultMultipartHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data'
        },
        baseUrlUI: 'https://jobs-manager-ui-a2165021851d.herokuapp.com/',
        baseUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com',
        categoryApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/category/v1',
        itemApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/item/v1',
        tenantApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/tenant/v1',
        userApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/user/v1',
        roleApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/role/v1',
        teamApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/team/v1',
        clientApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/client/v1',
        projectApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/project/v1',
        projectTypeApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/project_type/v1',
        requestApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/request/v1',
        quoteApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/quote/v1',
        jobApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/job/v1',
        workOrderApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/work_order/v1',
        invoiceApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/invoice/v1',
        notificationApiUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/notification/v1',
        successMessage: 'Succeeded'
    },
    notifications: {
        stompUrl: 'https://jobs-manager-api-8078916dfbf0.herokuapp.com/job-manager-ws',
        broadcastTopic: '/job-manager-notifications/broadcast-',
        broadcastRoleTopic: '/job-manager-notifications/broadcast_role-',
        userMessageTopicPrefix: '/job-manager-notifications/user-message-',
        broadcastReply: '/job-manager-topic/reply-',
        userMessageQueueReplyPrefix: '/job-manager-queue/reply-',
        broadcastRoleReply: '/job-manager-topic/reply_role-',
        
        requestAssessmentCompleted: 'Assessment completed for request',
        quoteSentToClient: 'A quote is waiting for your approval',
        workOrderSentToClient: 'A work order is waiting for your approval',
        quoteApproved: 'A quote was approved',
        workOrderApproved: 'A work order schedule was approved',
        workOrderOnGoing: 'A work order is on going',
        workOrderLineItemApproved: 'A line item was approved',
        workOrderLineItemCompleted: 'A line item was completed',
        workOrderCompleted: 'A work order was completed',
        invoiceSentToClient: 'An invoice is waiting for your approval',
        invoiceApproved: 'An invoice was approved',
        invoiceDepositSent: 'An invoice deposit is waiting for your approval',
        invoiceDepositApproved: 'An invoice deposit was approved',
        invoiceDepositRejected: 'An invoice deposit was rejected',
        invoicePaid: 'An invoice was complete paid',
        quoteRequiresAssessment: 'A quote requires assessment',
        requestRequiresAssessment: 'A request requires assessment',
    },
    storage: {
        tenant: 'tenant',
        userData: 'user_data',
        client: 'client',
        userTitles: 'user_titles',
        scheduleTypes: 'schedule_types',
        billingTypes: 'billing_types',
        reminderTypes: 'reminder_types',
        paymentMethods: 'payment_methods',
        discountTypes: 'discount_types',
        notificationPermissions: 'notification_permissions',
    },
    errors: {
        authenticationError: 'Authentication failed: Your user id or password are wrong',
        searchFilterEmpty: 'You must specify a search filter',
        emptyResult: 'The search doesn\'t return any result',
        searchClientError: 'An error occurred searching clients',
        createClientError: 'An error occurred creating the client',
        createClientSuccess: 'Client created successfully',
        editClientError: 'An error occurred updating the client',
        editClientSuccess: 'Client updated successfully',
        deleteClientError: 'An error occurred deleting the client',
        deleteClientSuccess: 'Client deleted successfully',

        searchProjectError: 'An error occurred searching projects',
        createProjectError: 'An error occurred creating the project',
        createProjectSuccess: 'Project created successfully',
        editProjectError: 'An error occurred updating the project',
        editProjectSuccess: 'Project updated successfully',
        deleteProjectError: 'An error occurred deleting the project',
        deleteProjectSuccess: 'Project deleted successfully',

        createRequestSuccess: 'Request created successfully',
        createRequestError: 'An error occurred creating the request',
        editRequestSuccess: 'Request updated successfully',
        editRequestError: 'An error occurred updating the request',
        deleteRequestError: 'An error occurred deleting the request',
        deleteRequestSuccess: 'Request deleted successfully',
        searchRequestError: 'An error occurred searching requests',

        createQuoteSuccess: 'Quote created successfully',
        createQuoteError: 'An error occurred creating the quote',
        editQuoteSuccess: 'Quote updated successfully',
        editQuoteError: 'An error occurred updating the quote',
        deleteQuoteError: 'An error occurred deleting the quote',
        deleteQuoteSuccess: 'Quote deleted successfully',
        searchQuoteError: 'An error occurred searching quotes',

        createJobSuccess: 'Job created successfully',
        createJobError: 'An error occurred creating the job',
        editJobSuccess: 'Job updated successfully',
        editJobError: 'An error occurred updating the job',
        deleteJobError: 'An error occurred deleting the job',
        deleteJobSuccess: 'Job deleted successfully',
        searchJobError: 'An error occurred searching jobs',

        createWorkOrderSuccess: 'Work order created successfully',
        createWorkOrderError: 'An error occurred creating the work order',
        editWorkOrderSuccess: 'Work order updated successfully',
        editWorkOrderError: 'An error occurred updating the work order',
        deleteWorkOrderError: 'An error occurred deleting the work order',
        deleteWorkOrderSuccess: 'Work order deleted successfully',
        searchWorkOrderError: 'An error occurred searching work orders',

        createInvoiceSuccess: 'Invoice created successfully',
        createInvoiceError: 'An error occurred creating the invoice',
        editInvoiceSuccess: 'Invoice updated successfully',
        editInvoiceError: 'An error occurred updating the invoice',
        deleteInvoiceError: 'An error occurred deleting the invoice',
        deleteInvoiceSuccess: 'Invoice deleted successfully',
        searchInvoiceError: 'An error occurred searching invoices',

        formValidationError: 'This field is required!',
        formEmailValidationError: 'Please enter a valid email!',
        validationErrorRequiredFields: 'You must capture all mandatory fields',
        fileUploadNotSupportedTypeError: 'Only images or pdf files are allowed to upload',
        fileUploadedSuccessfully: 'The file was successfully uploaded',
        fileDeletedSuccessfully: 'The file was successfully deleted',
        fileUploadError: 'An error ocurred uploading the file',
        fileDeletedError: 'An error ocurred deleting the file',

        createUserSuccess: 'User created successfully',
        createUserError: 'An error occurred creating the user',
        editUserSuccess: 'User updated successfully',
        editUserError: 'An error occurred updating the user',
        userValidationErrorNoTeam: 'Team and rol are mandatory for user',
        deleteUserError: 'An error occurred deleting the user',
        deleteUserSuccess: 'User deleted successfully',

        createTeamSuccess: 'Team created successfully',
        createTeamError: 'An error occurred creating the team',
        editTeamSuccess: 'Team updated successfully',
        editTeamError: 'An error occurred updating the team',
        deleteTeamError: 'An error occurred deleting the team',
        deleteTeamSuccess: 'Team deleted successfully',

        createServiceTypeSuccess: 'Service type created successfully',
        createServiceTypeError: 'An error occurred creating the service type',
        editServiceTypeSuccess: 'Service type updated successfully',
        editServiceTypeError: 'An error occurred updating the service type',
        deleteServiceTypeError: 'An error occurred deleting the service type',
        deleteServiceTypeSuccess: 'Service type deleted successfully',

        createItemSuccess: 'Item created successfully',
        createItemError: 'An error occurred creating the item',
        editItemSuccess: 'Item updated successfully',
        editItemError: 'An error occurred updating the item',
        deleteItemError: 'An error occurred deleting the item',
        deleteItemSuccess: 'Item deleted successfully',
        itemValidationErrorNoCategory: 'Category/Price is mandatory for user',

        editNotificationSuccess: 'Notification updated successfully',
        editNotificationError: 'An error occurred updating the notification',
        searchNotificationsError: 'An error occurred searching for notifications',
        viewNotificationsNoNotifications: 'You don\'t have any notification yet ',
        
    },
    messages: {
        defaultQuoteMessage: 'Dear customer, thank you for contacting us. \n'+
                            'This quote includes all the materials, installation, maintenance and removal. You own the lights (you storage them)\n'+
                            'We give you 3 years of guarantee in our products.\n'+
                            'We attached a photo with the design for your house.\n'+
                            'You can pay via Zelle, Venmo, cash app, online invoice (fee 4% of the total amount to pay online), check and cash.\n'+
                            'We request 50% at installation and 50% at removal.\n'+
                            '\n'+
                            'Warm regards.',
        defaultInvoiceMessage: 'Please find attached our invoice with the details about the included items.',
    
    },
    
    allowedUploadFileTypes: [
        'image/jpeg',
        'image/png',
        'application/pdf'
    ],

    defaultSubCompanyName: 'Christs+ Lights',
    defaultCompanyName: 'Job-Tube'
}



export default constants;