//Request Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { getUserData } from "./SecurityService";
import {status} from "./StatusService";


export const searchRequestsByAnyAttribute = async(anyInfo, clientId, projectId, tenanId) => {
    var response = await axios.get( constants.api.requestApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getRequestById = async(requestId, tenanId) => {

    var response = await axios.get( constants.api.requestApiUrl + '/' + tenanId + '/' + requestId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateRequest = async(request, tenanId) => {

    const user = getUserData();
    request.user_id = user.uuid;

    console.debug('[RequestService][updateRequest] request: ', request);

    var response = await axios.put( constants.api.requestApiUrl + '/' + tenanId + '/', request, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteRequest = async(request, tenanId) => {

    const user = getUserData();
    request.user_id = user.uuid;
    request.status = status.requestStatus.cancelled.status;

    console.debug('[RequestService][logicalDeleteRequest] request: ', request);

    var response = await axios.put( constants.api.requestApiUrl + '/' + tenanId + '/', request, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createRequest = async(request, tenanId) => {

    const user = getUserData();
    request.user_id = user.uuid;

    console.debug('[RequestService][createRequest] request: ', request);

    var response = await axios.post( constants.api.requestApiUrl + '/' + tenanId + '/', request, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}



