import { Backdrop, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getItemById } from "../../../service/tenant/ItemService";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import { useSearchParams } from "react-router-dom";
import { getSignedUrlToFilePath } from "../../../service/tenant/StorageService";

function Item(props){

    const [searchParams, setSearchParams] = useSearchParams();

    const tenant = getTenantLocalStorage();

    const [item, setItem] = useState(null);
    const [itemImageUrl, setItemImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect( () => {

        const tenantId = searchParams.get('tenant_id');
        const itemId = searchParams.get('item_id');

        setIsLoading(true);
        getItemById(tenantId, itemId).then( itemResponse => {

            if( itemResponse != null ){
                setItem(itemResponse);

                if( itemResponse.image_list && itemResponse.image_list.length > 0  ){

                    const itemImage = itemResponse.image_list[0];
                    getSignedUrlToFilePath(itemImage.name).then( newSignedUrlResponse => {
                        if( newSignedUrlResponse != null ){
                            console.log('[Item] newSignedUrlResponse: ', newSignedUrlResponse);
                            setItemImageUrl(newSignedUrlResponse);
                        }
                        setIsLoading(false);
                    });
                } else {
                    setIsLoading(false);
                }
                

            }


        });

    }, []);


    const handleCloseBackdrop = () => {
        setIsLoading(false);
    }

    return (

        <Grid
            container
            direction="column" 
            spacing={0}
            justifyContent={'right'}
            alignContent={'center'}
            style={{marginLeft: 10, marginRight: 10, marginTop: 40, marginBottom: 20}}
        >
            <Backdrop
                key={'public_item_backdrop'}
                sx={{color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1, "position": "absolute"}}
                open={isLoading}
                onClick={() => handleCloseBackdrop()}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            { item && 
                (
                    <Grid 
                        item
                        xs={10} sm={8} md={5} lg={5} key={item.id}
                        rowSpacing={1}
                        style={{marginLeft: 0, marginRight: 5, marginTop: 0, marginBottom: 0}}
                    >
                        <Card 
                            style={{ maxWidth: '100%', minHeight: '100%', marginBottom: 0, marginRight: 0, alignContent: 'flex-start', alignItems: 'flex-start' }}
                            key={ item.id }
                        >
                            <CardActionArea
                                style={{ alignContent: "flex-start", alignItems: 'flex-start' }}
                                
                            >
                                <CardMedia 
                                    sx={{ maxHeight: 250, minHeight: 250 }}
                                    image={ itemImageUrl != null? 
                                            itemImageUrl
                                            :
                                            '/images/no_image.jpg'}
                                    alt={item.name}
                                />
                                <CardContent
                                    sx={{ minHeight: 200, maxHeight: 200 }}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                    >
                                        { item.name }
                                    </Typography>
                                    
                                    <Typography
                                        variant="body1" 
                                        color="text.secondary"
                                    >
                                        { item.description != null ? item.description.substring(0, 80) : '' }
                                    </Typography>
                                    <Typography
                                        variant="body1" 
                                        color="text.secondary"
                                    >
                                        SKU: { item.sku }
                                    </Typography>
                                    
                                    {
                                        item.price_unit_list != null ?

                                        item.price_unit_list.map( (price_unit) => (
                                            <Typography
                                                variant="overline" 
                                                color="text.secondary"
                                                style={{ alignItems: 'flex-end', alignSelf: 'flex-end', marginTop: 20, textAlign: 'end', fontWeight: 'bold'}}
                                            >
                                                { "$" + price_unit.price + " / " + (price_unit.unit != null ? price_unit.unit.name : '') }
                                            </Typography>
                                        ))

                                        :
                                        null
                                    }

                                </CardContent>
                            </CardActionArea>
                            
                        </Card>
                    </Grid>
                )
            }
        </Grid>
 
    );

}

export default Item;