import { FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import constants from "./Constants";
import { MuiTelInput } from "mui-tel-input";
import { validateEmail } from "../../../service/tenant/ValidationService";


function InputComponent(props){

    const [validationError, setValidationError] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState('');


    useEffect(() => {
        if( props.required && (props.fieldValue === null || props.fieldValue === '') ){
            setValidationError(true);
            if( props.phoneValidation ){
                setValidationErrorMessage(constants.errors.formValidationError); 
            }
        } else if( !props.emailValidation ) {
            setValidationError(false);
            setValidationErrorMessage('');
        } else if( props.emailValidation && validateEmail(props.fieldValue) ) {
            setValidationError(false);
            setValidationErrorMessage('');
        }
    }, [props.fieldValue])


    const handleChangeValue = (e) => {

        if( props.fieldIndex != null ){
            props.handleChangeField(props.fieldIndex, props.attributeName, e.target.value);
        } else {
            props.handleChangeField( props.attributeName, e.target.value );
        }
       

        if ( e.target.validity.valid) {
            setValidationError(false);
        } else {
            setValidationError(true);
        }
    }

    const handleChangePhoneValue = (newPhoneValue) => {
        
        props.handleChangeField( props.attributeName, newPhoneValue );

        if( props.required && newPhoneValue === '' ){
            setValidationErrorMessage(constants.errors.formValidationError);
            setValidationError(true);
        } else {
            setValidationErrorMessage('');
            setValidationError(false);
        }
    }

    const buildValidationErrorMessage = () => {
        if( props.emailValidation ){
            return constants.errors.formEmailValidationError;
        } else if ( props.validationErrorMessage != null ){
            return props.validationErrorMessage;
        } else {
            return constants.errors.formValidationError;
        }

    }

    const getInputType = () => {
        if( props.passwordValidation ){
            return 'password';
        } else if ( props.numberValidation ){
            return 'number';
        } else if ( props.colorValidation ){
            return 'color';
        }else {
            return 'text';
        }

    }

    return (
        <>
            { !props.hideLabel &&  
                (
                <Grid item sx={constants.styles.sectionInput} xs={ props.labelSx ? props.labelSx : 3} md={ props.labelMd ? props.labelMd : 3 }>
                    <Typography sx={constants.styles.textNormal} >
                        {props.fieldName} :
                    </Typography>
                    { props.fieldIcon &&
                        (
                            <>
                                {props.fieldIcon}
                            </>
                        )
                    }
                </Grid>
                )
            }   
            <Grid item sx={constants.styles.sectionInput} xs={ props.inputSx ? props.inputSx : 8 } md={ props.inputMd ? props.inputMd : 8 }>
                
                <FormControl variant="standard">
                    { !props.phoneValidation && !props.emailValidation ?
                        
                        <TextField
                            id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                            key={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                            required={props.required}
                            value={props.fieldValue}
                            onChange={ handleChangeValue }
                            sx={ props.sx != null ? 
                                    props.sx : 
                                    props.fullWidth ? 
                                        constants.styles.textFieldNormalFullWidth : 
                                        constants.styles.textFieldNormal 
                                }
                            error={validationError}
                            type={ getInputType() }
                            helperText={ validationError ? 
                                buildValidationErrorMessage()
                                :
                                ''
                            }
                            size="small"
                            
                            inputProps={ props.emailValidation ? {
                                type: "email",
                            }:{}}

                            multiline={props.multiline}
                            fullWidth={props.fullWidth}
                            
                            disabled={props.disabled}

                            InputProps={props.InputProps ? props.InputProps: {}}

                            
                        />
                        
                        :

                        props.emailValidation ? 


                        <TextField
                            id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                            required={props.required}
                            value={props.fieldValue}
                            onChange={ handleChangeValue }
                            sx={ constants.styles.textFieldNormal }
                            error={validationError}
                            helperText={ validationError ? 
                                    constants.errors.formEmailValidationError
                                    : ''
                            }
                            inputProps={{
                                type: 'email'
                            }}
                            size="small"
                        />

                        :

                        <MuiTelInput
                            id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                            required={props.required}
                            value={props.fieldValue}
                            onChange={ handleChangePhoneValue }
                            sx={ props.sx != null ? 
                                props.sx : 
                                props.fullWidth ? 
                                    constants.styles.textFieldNormalFullWidth : 
                                    constants.styles.textFieldNormal 
                            }
                            error={validationError}
                            helperText={validationErrorMessage}
                            size="small"
                            defaultCountry="US"
                            onlyCountries={["US", "MX", "CA"]}
                            
                        />

                    }
                </FormControl>
            </Grid>
        </>
    );


}


export default InputComponent;