//Project Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { getUserData } from "./SecurityService";
import {status} from "./StatusService";


export const getAllProjectTypes = async(tenanId) => {
    var response = await axios.get( constants.api.projectTypeApiUrl + '/' + tenanId + '/', { 
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const searchProjectsByAnyAttribute = async(anyInfo, clientId, tenanId) => {
    var response = await axios.get( constants.api.projectApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getProjectById = async(projectId, tenanId) => {

    var response = await axios.get( constants.api.projectApiUrl + '/' + tenanId + '/' + projectId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateProject = async(project, tenanId) => {

    const user = getUserData();
    project.user_id = user.uuid;

    console.debug('[ProjectService][updateProject] project: ', project);

    var response = await axios.put( constants.api.projectApiUrl + '/' + tenanId + '/', project, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteProject = async(project, tenanId) => {

    const user = getUserData();
    project.user_id = user.uuid;
    project.status = status.projectStatus.inactive;

    console.debug('[ProjectService][logicalDeleteProject] project: ', project);

    var response = await axios.put( constants.api.projectApiUrl + '/' + tenanId + '/', project, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createProject = async(project, tenanId) => {

    const user = getUserData();
    project.user_id = user.uuid;

    console.debug('[ProjectService][createProject] project: ', project);

    var response = await axios.post( constants.api.projectApiUrl + '/' + tenanId + '/', project, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const getAllActiveProjects = async( tenanId ) => {

    var response = await axios.get( constants.api.projectApiUrl + '/' + tenanId + '/shorten/filter', { 
        params: {
            status: status.projectStatus.active
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getAllActiveProjectsByClientId = async( clientId, tenanId ) => {

    var response = await axios.get( constants.api.projectApiUrl + '/' + tenanId + '/shorten/filter', { 
        params: {
            status: status.projectStatus.active,
            client_id: clientId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}





export const getAllActiveServiceTypes = async( tenanId ) => {

    var response = await axios.get( constants.api.projectTypeApiUrl + '/' + tenanId + '/', { 
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const getServiceTypeById = async(tenanId, serviceTypeId) => {

    var response = await axios.get( constants.api.projectTypeApiUrl + '/' + tenanId + '/' + serviceTypeId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateServiceType = async(serviceType, tenanId) => {

    const user = getUserData();
    serviceType.user_id = user.uuid;

    console.debug('[ProjectService][updateServiceType] serviceType: ', serviceType);

    var response = await axios.put( constants.api.projectTypeApiUrl + '/' + tenanId + '/', serviceType, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteServiceType = async(serviceType, tenanId) => {

    const user = getUserData();
    serviceType.user_id = user.uuid;
    serviceType.status = status.serviceTypeStatus.inactive;

    console.debug('[ProjectService][logicalDeleteServiceType] serviceType: ', serviceType);

    var response = await axios.put( constants.api.projectTypeApiUrl + '/' + tenanId + '/', serviceType, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createServiceType = async(serviceType, tenanId) => {

    const user = getUserData();
    serviceType.user_id = user.uuid;

    console.debug('[ProjectService][createServiceType] serviceType: ', serviceType);

    var response = await axios.post( constants.api.projectTypeApiUrl + '/' + tenanId + '/', serviceType, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

