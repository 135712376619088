import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, Search } from "@mui/icons-material";
import { logicalDeleteQuote, searchQuotesByAnyAttributeAndStatus } from "../../../service/tenant/QuoteService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { getAllActiveClients } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import { getQuoteStatusName, getQuoteStatusValues } from "../../../service/tenant/StatusService";
import { convertDateStrISOString } from "../../../service/tenant/ValidationService";
import SelectComponent from "../util/SelectComponent";



function ViewQuotes(props){
    

    const [tableRows, setTableRows] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [quoteToDelete, setQuoteToDelete] = useState(null);
    const [client, setClient] = useState(null);
    const [activeClients, setActiveClients] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [project, setProject] = useState(null);
    const [visibleStatus, setVisibleStatus] = useState([]);
    const [status, setStatus] = useState(null);

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {
        setIsLoading(true);
        getAllActiveClients( tenantId ).then( activeClientsResponse => {
            if( activeClientsResponse != null ){
                setActiveClients(activeClientsResponse);
            }
            setIsLoading(false);
        });

        const visibleStatusAux = getQuoteStatusValues();
        setVisibleStatus(visibleStatusAux);

    }, []);

    useEffect( () => {
        console.log('[ViewQuotes][useEffect] client: ', client);
        
        if( client ){
            const clientId = client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

        }
    }, [client]);

    const handleSearchQuotes = () => {
        const tenantId = tenant.id;
        
        if( (!searchFilter || searchFilter === '') && client == null && status === null){
            setErrorMessage(constants.errors.searchFilterEmpty);
            return;
        }

        setErrorMessage('');
        setTableRows([]);

        setIsLoading(true);

        let clientId = '';
        let projectId = '';
        if( client ){
            clientId = client.id;
            if( project ){
                projectId = project.id;
            }
        }

        searchQuotesByAnyAttributeAndStatus(searchFilter, clientId, projectId, status, tenantId).then( quoteList => {

            if( quoteList !== null ){
                console.debug('[ViewQuotes][handleSearchQuotes] quoteList: ', quoteList);

                if( quoteList.length > 0 ){
                    setTableRows(quoteList);
                } else {
                    setErrorMessage(constants.errors.emptyResult);
                }
            } else {
                setErrorMessage(constants.errors.searchQuoteError);
            }

            setIsLoading(false);
            
        });
    }

    const handleEditQuote = (quoteId) => {
        console.debug('[ViewQuotes][handleEditQuote] quoteId: ', quoteId);
        navigate(constants.routes.editQuote, {
            state: {
                quoteId: quoteId
            }
        });
    }

    const handleEditClient = (clientId) => {
        if( clientId != null ) { 
            console.debug('[ViewClients][handleEditClient] clientId: ', clientId);
            navigate(constants.routes.editClient, {
                state: {
                    clientId: clientId
                }
            });
        }
    }

    const handleNewQuote = () => {
        navigate(constants.routes.createQuote, {
            state: {
                quoteId: ''
            }
        });
    }

    const handleDeleteQuote = (quote) => {
        console.debug('[ViewQuotes][handleDeleteQuote] quote: ', quote);
        setQuoteToDelete(quote);
        setOpenDeleteAlert(true);
    }

    const handleConfirmDeleteQuote = () => {
        console.debug('[ViewQuotes][handleConfirmDeleteQuote] ');

        const tenantId = tenant.id;

        setOpenDeleteAlert(false);
        setIsLoading(true);

        logicalDeleteQuote(quoteToDelete, tenantId).then( (deleteResponse) => {
            console.debug('[ViewQuotes][handleConfirmDeleteQuote] deleteResponse: ', deleteResponse);

            if( deleteResponse != null ){
                setSuccessMessage(constants.errors.deleteQuoteSuccess);

                handleSearchQuotes();

            } else {
                setErrorMessage(constants.errors.deleteQuoteError);
            }

            setIsLoading(false);
            setQuoteToDelete(null);
        })

        
    }

    const handleCloseDeleteDialog = () => {
        setQuoteToDelete(null);
        setOpenDeleteAlert(false);
    }

    const handleChangeClient = (attributeName, newClient) => {
        console.debug('[ViewQuotes][handleChangeClient] newClient: ', newClient)
        setClient(newClient);
        setActiveProjects([]);
        setProject(null);
    }

    const handleChangeProject = (attributeName, newProject) => {
        console.debug('[ViewQuotes][handleChangeClient] newProject: ', newProject)
        setProject(newProject);
    }

    const handleChangeStatus = (attributeName, newStatus) => {
        console.debug('[ViewQuotes][handleChangeStatus] newStatus: ', newStatus);
        setStatus(newStatus);
    } 


    return (
        <PrincipalLayout section={constants.menu.admin_quotes_section} isLoading={isLoading} 
            notificationNumber={props.notificationNumber} 
            setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Quotes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                            <Typography sx={constants.styles.textNormal}>
                                Search for quote:
                            </Typography>
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={9} md={9}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-user-search" sx={constants.styles.textNormal}>
                                    Type quote information
                                </InputLabel>
                                <Input
                                    id="input-user-search"
                                    value={searchFilter}
                                    sx={constants.styles.inputSearchNormal}
                                    onChange={ (e) => setSearchFilter(e.target.value) }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={handleSearchQuotes}
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    
                                />
                            </FormControl>
                        </Grid>

                        <AutocompleteComponent
                            fieldId={'input-quote-client'}
                            fieldName={'Client'} 
                            fieldValue={client}
                            attributeName={'client'}
                            optionList={activeClients}
                            isClient={true}
                            handleChangeField={handleChangeClient}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />

                        <AutocompleteComponent
                            fieldId={'input-quote-project'}
                            fieldName={'Project'} 
                            fieldValue={project}
                            attributeName={'project'}
                            optionList={activeProjects}
                            optionAttributeLabel={'name'}
                            isClient={false}
                            handleChangeField={handleChangeProject}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />

                        <SelectComponent
                            fieldName={"Status"} 
                            fieldValue={ status } 
                            fieldId={'input-workOrder-status'} 
                            attributeName={'status'} 
                            optionList={visibleStatus}
                            optionAttributeName={'name'}
                            handleChangeField={handleChangeStatus}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Quote title</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Client</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Created</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Status</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {tableRows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditQuote(row.id)}
                                                >
                                                    {row.title}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditClient( row.client ? row.client.id : null)}
                                                >
                                                    {row.client && row.client.user ? row.client.user.first_name + ' ' + row.client.user.last_name : '' }
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ convertDateStrISOString( row.creation_date ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ getQuoteStatusName( row.status ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit quote"
                                                    onClick={() => handleEditQuote(row.id)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete quote"
                                                    onClick={() => handleDeleteQuote(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewQuote}
                                > 
                                        New Quote 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this quote?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            Quote name: <strong>{ quoteToDelete ? quoteToDelete.title : ''}</strong>
                            <br/>If you delete this quote will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteQuote} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default ViewQuotes;