//WorkOrder Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { getUserData } from "./SecurityService";
import {status} from "./StatusService";
import { getTaxRateByClient } from "./ClientService";
import { parseToFloatAmount } from "./ValidationService";
import { discountTypesCatalog } from "./QuoteService";


export const searchWorkOrdersByAnyAttribute = async(anyInfo, clientId, projectId, jobId, tenanId) => {
    var response = await axios.get( constants.api.workOrderApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            job_id: jobId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const searchWorkOrdersByAnyAttributeAndStatus = async(anyInfo, clientId, projectId, jobId, status, tenanId) => {
    var response = await axios.get( constants.api.workOrderApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            job_id: jobId,
            status: status
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const searchActiveWorkOrdersByAnyAttribute = async(anyInfo, clientId, projectId, jobId, tenanId) => {
    var response = await axios.get( constants.api.workOrderApiUrl + '/' + tenanId + '/shorten/active_filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            job_id: jobId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const searchWorkOrdersByAnyAttributeForClient = async(anyInfo, clientId, projectId, jobId, tenanId) => {
    var response = await axios.get( constants.api.workOrderApiUrl + '/' + tenanId + '/shorten/client_filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            job_id: jobId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getWorkOrderById = async(workOrderId, tenanId) => {

    var response = await axios.get( constants.api.workOrderApiUrl + '/' + tenanId + '/' + workOrderId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateWorkOrder = async(workOrder, tenanId) => {

    const user = getUserData();
    workOrder.user_id = user.uuid;

    console.debug('[WorkOrderService][updateWorkOrder] workOrder: ', workOrder);

    var response = await axios.put( constants.api.workOrderApiUrl + '/' + tenanId + '/', workOrder, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteWorkOrder = async(workOrder, tenanId) => {

    const user = getUserData();
    workOrder.user_id = user.uuid;
    workOrder.status = status.workOrderStatus.cancelled.status;

    console.debug('[WorkOrderService][logicalDeleteWorkOrder] workOrder: ', workOrder);

    var response = await axios.delete( constants.api.workOrderApiUrl + '/' + tenanId + '/logical_delete/' + workOrder.id , {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createWorkOrder = async(workOrder, tenanId) => {

    const user = getUserData();
    workOrder.user_id = user.uuid;

    console.debug('[WorkOrderService][createWorkOrder] workOrder: ', workOrder);

    var response = await axios.post( constants.api.workOrderApiUrl + '/' + tenanId + '/', workOrder, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const setReminderTypes = (reminderTypes) => {
    console.debug('[WorkOrderService][setReminderTypes] reminderTypes: ', reminderTypes);
    if( reminderTypes != null  ){
        localStorage.setItem(constants.storage.reminderTypes, JSON.stringify(reminderTypes));
    }
}

export const getReminderTypes = () => {
    const reminderTypesStr = localStorage.getItem(constants.storage.reminderTypes);
    if( reminderTypesStr != null ){
        const reminderTypes = JSON.parse(reminderTypesStr);
        console.debug('[WorkOrderService][getReminderTypes] reminderTypes: ', reminderTypes);
        return reminderTypes;
    }
    return null;
}


export const getAllReminderTypes = async () => {
    const reminderTypes = getReminderTypes();

    if( reminderTypes != null ){
        return reminderTypes;
    } else {
        var response = await axios.get( constants.api.workOrderApiUrl + '/reminder_types', {
            headers: constants.api.defaultHeaders 
        } )
        .catch( error => {
            console.error('[ERROR] error: ', error);
        });
        const reminderTypesArray = getDataRespose(response);
        setReminderTypes(reminderTypesArray);
        return reminderTypesArray;
    }

}

export const calculateSubTotal = ( workOrderItem ) => {
    if( workOrderItem && workOrderItem.line_item && workOrderItem.line_item.unit_price && workOrderItem.line_item.quantity ){
        const subTotal = workOrderItem.line_item.unit_price * workOrderItem.line_item.quantity;
        return parseToFloatAmount(subTotal);
    }
    return 0;
}

export const calculateTotal = (workOrder) => {
    let total = 0;
    if( workOrder && workOrder.work_order_item_list ){
        workOrder.work_order_item_list.forEach( workOrderItem => {
            const subTotal = parseFloat(calculateSubTotal(workOrderItem));
            total = total + subTotal;
        });
    }
    return parseToFloatAmount(total);
}


export const calculateDiscount = (workOrder) => {
    let totalDiscount = 0;
    const total = parseFloat(calculateTotal(workOrder));
    if( workOrder && workOrder.discount ){
        if( discountTypesCatalog.percentageDiscount.id === workOrder.discount_type ){
            totalDiscount = total * (workOrder.discount/100);
        } else {
            totalDiscount = workOrder.discount;
        }
    }
    return parseToFloatAmount(totalDiscount);
}


export const calculateTaxes = (workOrder) => {
    const total = parseFloat(calculateTotal(workOrder));
    const taxRate = getTaxRateByClient();
    let taxes = 0.0;
    if( workOrder && workOrder.include_taxes === true ){
        taxes = total * taxRate;
    }
    return parseToFloatAmount(taxes);
}

export const calculateGrandTotal = (workOrder) => {
    const taxRate = getTaxRateByClient();
    const total = parseFloat(calculateTotal(workOrder));
    let taxes = 0.0;
    if( workOrder && workOrder.include_taxes === true ){
        taxes = total * taxRate;
    }

    const totalDiscount = calculateDiscount(workOrder);
    const grandTotal = total + taxes - totalDiscount;

    

    return parseToFloatAmount(grandTotal);
}
