import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import pdfConstants from "../util/PDFConstants";
import { useEffect, useState } from "react";
import constants from "../util/Constants";
import PDFHeader from "../util/PDFHeader";


function ItemsPDF(props) {

    const [qrCodesURIList, setQrCodesURIList] = useState([]);

    useEffect(() => {

        if( props.itemList ){

            var qrCodesURIListAux = [];

            props.itemList.forEach( (item, index) => {
                const canvas = document.getElementById(constants.styles.qrCodeDefaultIdPrefix + index);
                if( canvas ){
                    const pngUrl = canvas.toDataURL("image/png")
                                    .replace("image/png", "image/octet-stream");
                    
                    qrCodesURIListAux.push(pngUrl);
                }
            });

            setQrCodesURIList(qrCodesURIListAux);
        }

        

    }, [props.itemList]);

    return (
        <Document>
            <Page size="A4" style={pdfConstants.pdfStyles.page}>

                <PDFHeader
                    title={'Product/Services Catalog'}
                />
                
                <View style={pdfConstants.pdfStyles.tableContainer}>

                    <View style={pdfConstants.pdfStyles.container}>
                        <Text style={pdfConstants.pdfStyles.mediumColumnHeader}>Item</Text>
                        <Text style={pdfConstants.pdfStyles.mediumColumnHeader}>SKU</Text>
                        <Text style={pdfConstants.pdfStyles.smallColumnHeader}>Price</Text>
                        <Text style={pdfConstants.pdfStyles.bigColumnHeader}>Description</Text>
                        <Text style={pdfConstants.pdfStyles.endColumnHeader}>QR Code</Text>
                    </View>

                    {   props.itemList && qrCodesURIList.length > 0 && props.itemList.map( (item, index) => (
                        
                            <View style={pdfConstants.pdfStyles.row} key={index}>
                                <Text style={pdfConstants.pdfStyles.mediumColumn}>{item.name }</Text>
                                <Text style={pdfConstants.pdfStyles.mediumColumn}>{item.sku}</Text>
                                <Text style={pdfConstants.pdfStyles.smallColumnRight}>
                                    ${item.price_unit_list && (item.price_unit_list.length > 0) ? item.price_unit_list[0].price : ''}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.bigColumn}>{item.description}</Text>
                                <Text style={pdfConstants.pdfStyles.endColumnCenter}>
                                    <Image
                                        style={pdfConstants.pdfStyles.qrCodeImage}
                                        src={ qrCodesURIList[index] }
                                    />
                                </Text>
                            </View>

                        ))
                    }

                </View>

            </Page>
        </Document>

    );

}

export default ItemsPDF;