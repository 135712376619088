import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import constants from "./Constants";

function AutocompleteComponent(props) {

    const [inputValue, setInputValue] = useState('');

    return (
        <>
            { !props.hideLabel &&  
                (
                <Grid item sx={constants.styles.sectionInput} xs={ props.labelSx ? props.labelSx : 3 } md={ props.labelMd ? props.labelMd : 3 }>
                    <Typography sx={constants.styles.textNormal} >
                        {props.fieldName}:
                    </Typography>
                </Grid>
                )
            }
            <Grid item sx={constants.styles.sectionInput} xs={ props.inputSx ? props.inputSx : 8 } md={ props.inputMd ? props.inputMd : 8 }>
                <Autocomplete
                    key={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                    id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }

                    value={props.fieldValue}
                    
                    onChange={(e, newValue) => {
                        console.debug('[AutocompleteComponent][onChange] props.fieldIndex: ', props.fieldIndex);
                        if( props.fieldIndex != null ){
                            props.handleChangeField(props.fieldIndex, props.attributeName, newValue);
                        } else {
                            props.handleChangeField(props.attributeName, newValue);
                        }
                        console.debug('[AutocompleteComponent][onChange] newValue: ', newValue);
                    }}

                    inputValue={inputValue}

                    onInputChange={(e, newInputValue) => {
                        setInputValue(newInputValue);

                        if( props.setInputValue ){
                            props.setInputValue( newInputValue );
                        }
                        console.debug('[AutocompleteComponent][onInputChange] newInputValue: ', newInputValue);
                    }}

                    options={ props.optionList }
                    
                    getOptionLabel={ (option) => 
                        props.isClient ? 
                            option.user.first_name + ' ' + option.user.last_name
                            :
                            props.isUser ?
                                option.first_name + ' ' + option.last_name
                                :
                                option[props.optionAttributeLabel]
                    }

                    renderInput={
                        (params) => 
                            <TextField 
                                {...params} 
                                sx={ constants.styles.textSubmenu }
                            />
                    }

                    sx={ props.sx != null ? 
                            props.sx : 
                            constants.styles.textFieldNormal 
                    }
                    size="small"

                    disabled={props.disabled}
                />

                
            </Grid>

        </>
    );

}

export default AutocompleteComponent;