import React, { useEffect, useState } from "react";
import { Button, Divider, FormControl, Grid, IconButton, InputAdornment, Paper} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { calculatDiscount, calculatGrandTotal, calculatTaxes, calculatTotal, createQuote, discountTypesCatalog, getQuoteById, updateQuote } from "../../../service/tenant/QuoteService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation } from "react-router";
import InputComponent from "../util/InputComponent";
import { getAllActiveStaffUsers, getClientLocalStorage, getUserData } from "../../../service/tenant/SecurityService";
import SwitchComponent from "../util/SwitchComponent";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createEmptyQuote, createEmptyQuoteItem, parseToFloatAmount, validateQuote } from "../../../service/tenant/ValidationService";
import { getAllActiveClients, getShortenClientById, getTaxRateByClient } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import RatingComponent from "../util/RatingComponent";
import { getAllActiveItems } from "../../../service/tenant/ItemService";
import { CloudUploadOutlined, Delete, EditNote } from "@mui/icons-material";
import QuoteItemDetails from "./QuoteItemDetails";
import UploaderComponent from "../util/UploaderComponent";
import ConfirmDialog from "../util/ConfirmDialog";
import { getQuoteStatusName, isQuoteEditable, isQuoteInDraft, isQuotePendingApproval, status } from "../../../service/tenant/StatusService";
import { useStompClient } from "react-stomp-hooks";
import { sendBroadcastRoleAdministratorApprovedQuoteNotification, sendBroadcastRoleAdministratorAssessmentQuoteNotification } from "../../../service/tenant/NotificationsService";


function EditQuoteUsers( props ){
    
    const location = useLocation();
    const tenant = getTenantLocalStorage();
    const client = getClientLocalStorage();
    const userData = getUserData();
    const userId = userData.id;
    var quoteId = location.state.quoteId;
    const tenantId = tenant.id;

    const [quote, setQuote] = useState(null);
    const [clientAddress, setClientAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeProjects, setActiveProjects] = useState([]);
    const [activeTeamUsers, setactiveTeamUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeItems, setActiveItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [saveEvent, setSaveEvent] = useState(false);

    const stompClient = useStompClient();
    

    useEffect(() => {
        
        console.debug('[EditQuote] quoteId: ', quoteId);

        setIsLoading(true);

        if( quoteId != null && quoteId != '' ){
            getQuoteById(quoteId, tenantId).then( quote => {

                if( quote != null ){
                    console.debug('[EditQuote][useEffect] quote: ', quote);
                    setQuote(quote);
                }
                setIsLoading(false);
            });
        } else {

            const newQuote = createEmptyQuote(userData.uuid);
            newQuote.client = client;
            newQuote.notes = constants.messages.defaultQuoteMessage;
            console.debug('[EditQuote][useEffect] newQuote: ', newQuote);
            setQuote(newQuote);
            setIsLoading(false);
        }

        setIsLoading(true);

        getAllActiveStaffUsers( tenantId ).then( activeStaffUsersResponse => {
            if( activeStaffUsersResponse != null ){
                setactiveTeamUsers(activeStaffUsersResponse);
            }
        });

        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse ){
                setActiveItems(activeItemsResponse);
            }

            setIsLoading(false);
        })

        if( client && client.id ){

            console.log('[EditQuotes][useEffect] client.id: ', client.id);
            const clientId = client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

            getShortenClientById(clientId, tenantId).then( clientResponse => {
                if( clientResponse != null ){
                    setClientAddress(clientResponse.client_address);
                }
            });

        }

    }, [location.state.quoteId]);
    


    useEffect( () => {
        if( quote ){

            console.log('[EditQuote][useEffect] saveEvent: ');
            
            handleClickSave();

        }
    }, [saveEvent]);


    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        let isValidQuote = validateQuote(quote);
        isValidQuote = isValidQuote && ( quote.client != null );

        console.debug('[EditQuote][handleClickSave] quote: ', quote);
        console.debug('[EditQuote][handleClickSave] quoteId: ', quoteId);

        if( isValidQuote && quote.id != null && quote.id != ''  ){
            handleUpdateQuote();
        } else if( isValidQuote && ( quote.id == null || quote.id == '' ) ){
            handleCreateQuote();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateQuote = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateQuote(quote, tenantId).then( quoteResponse => {

            if( quoteResponse != null ){
                console.debug('[EditQuote][handleUpdateQuote] quoteResponse: ', quoteResponse);
                setQuote(quoteResponse);
                setSuccessMessage(constants.errors.editQuoteSuccess);
            } else {
                setErrorMessage(constants.errors.editQuoteError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateQuote = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createQuote(quote, tenantId).then( quoteResponse => {

            if( quoteResponse != null ){
                console.debug('[EditQuote][handleCreateQuote] quoteResponse: ', quoteResponse);
                setQuote(quoteResponse);
                quoteId = quoteResponse.id;
                console.debug('[EditQuote][handleCreateQuote] quoteId: ', quoteId);
                setSuccessMessage(constants.errors.createQuoteSuccess);
            } else {
                setErrorMessage(constants.errors.createQuoteError)
            }

            setIsLoading(false);
        });

    }


    const handleChangeQuoteAttribute = (attributeName, attributeValue) => {
        if( quote ){
            let quoteAux = { ...quote };
            quoteAux[attributeName] = attributeValue;

            if( attributeName === 'client' ){
                quoteAux['project'] = null; 

                setActiveProjects([]);
            }else if(  attributeName === 'predefined_message'){

                if( attributeValue == true ){
                    let clientGreeting = '';
                    if( quoteAux.client && quoteAux.client.user ){
                        clientGreeting = 'Dear ' + quoteAux.client.user.first_name + ' ' +quoteAux.client.user.last_name + ',  \n';
                    }
                    
                    quoteAux.notes = clientGreeting + constants.messages.defaultQuoteMessage;
                } else {
                    quoteAux.notes = '';
                }
                
            }

            console.debug('[EditQuote][handleChangeQuoteAttribute] quoteAux: ', quoteAux);
            setQuote(quoteAux);
        }
    }


    const handleChangeQuoteItemAttribute = (index, attributeName, attributeValue) => {
        if( quote && quote.quote_item_list ){
            let quoteAux = { ...quote };
            console.debug('[EditQuote][handleChangeQuoteItemAttribute] attributeName: ', attributeName);

            let quoteItemListAux = quoteAux[attributeName];

            quoteItemListAux[index].item = attributeValue;
            quoteItemListAux[index].unit_price = getQuoteItemPrice(attributeValue);
            quoteItemListAux[index].details = attributeValue.description;
            quoteItemListAux[index].name = attributeValue.name;
            console.debug('[EditQuote][handleChangeQuoteItemAttribute] quoteItemListAux: ', quoteItemListAux);

            setQuote(quoteAux);
        }
    }


    

    const getQuoteItemPrice = ( item ) => {
        if(  item && item.price_unit_list && item.price_unit_list.length > 0 ){
            return parseToFloatAmount(item.price_unit_list[0].price);
        }
        return 0;
    }

    const handleChangeQuoteItemQuantity = (index, attributeName, attributeValue) => {
        if( quote && quote.quote_item_list ){
            let quoteAux = { ...quote };

            let quoteItemListAux = quoteAux[attributeName];

            quoteItemListAux[index].quantity = attributeValue;
            setQuote(quoteAux);
        }
    }

    const handleAddQuoteItem = () => {
        const quoteItem = createEmptyQuoteItem();
        let quoteAux = { ...quote };
        quoteAux.quote_item_list.push(quoteItem);
        setQuote(quoteAux);
    }

    const handleDeleteQuoteItem = (index) => {
        let quoteAux = { ...quote };
        quoteAux.quote_item_list.splice(index, 1);
        setQuote(quoteAux);
    }

    const calculateSubTotal = ( quoteItem ) => {
        if( quoteItem && quoteItem.unit_price && quoteItem.quantity ){
            const subTotal = quoteItem.unit_price * quoteItem.quantity;
            return parseToFloatAmount(subTotal);
        }
        return 0;
    }



    const buildCompleteClientAddress = () => {
        if( clientAddress ){
            return clientAddress.number + ' ' + clientAddress.street + ', ' + clientAddress.city + ', ' + clientAddress.locality;
        }
        return '';
    }


    const handleShowItemDetails = (item) => {
        console.debug('[EditQuote][handleShowItemDetails] item: ', item);
        setSelectedItem(item);
        setOpenDetailsDialog(true);
    }

    const handleCloseDetailsDialog = () => {
        setSelectedItem(null);
        setOpenDetailsDialog(false);
    }

    const handleApproveQuote = () => {
        setOpenConfirmDialog(true);
    }

    const handleConfirmApproveQuote = () => {
        let quoteAux = {...quote};
        quoteAux.status = status.quoteStatus.approved.status;
        setQuote(quoteAux);

        sendBroadcastRoleAdministratorApprovedQuoteNotification(userId, quoteAux, tenantId, stompClient);

        setSaveEvent(!saveEvent);
    }

    const handleClickSaveAndSend = () => {
        let quoteAux = {...quote};
        quoteAux.status = status.quoteStatus.pendingApproval.status;
        setQuote(quoteAux);


        sendBroadcastRoleAdministratorAssessmentQuoteNotification(userId, quoteAux, tenantId, stompClient);

        setSaveEvent(!saveEvent);
    }

    return (
        
        <PrincipalLayout section={constants.menu.admin_quotes_section} isLoading={isLoading}
            notificationNumber={props.notificationNumber} 
            setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Quote: {quote && quote.id ? ' #' + quote.id + ' - ' : ''} {quote ? quote.title : ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Quote title"} 
                                    fieldValue={ quote ? quote.title: '' } 
                                    fieldId={'input-quote-title'} 
                                    attributeName={'title'} 
                                    handleChangeField={handleChangeQuoteAttribute}
                                    required={true} 
                                    disabled={!isQuoteEditable(quote ? quote.status : '')}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-quote-project'}
                                    fieldName={'Project'} 
                                    fieldValue={quote ? quote.project : null}
                                    attributeName={'project'}
                                    optionList={activeProjects}
                                    optionAttributeLabel={'name'}
                                    isClient={false}
                                    handleChangeField={handleChangeQuoteAttribute}
                                    disabled={!isQuoteEditable(quote ? quote.status : '')}
                                />


                               
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <InputComponent 
                                    fieldName={"Status"} 
                                    fieldValue={ quote ? getQuoteStatusName( quote.status ): '' } 
                                    fieldId={'input-quote-status'} 
                                    attributeName={'status'} 
                                    required={false}
                                    disabled={true} 
                                />

                                <InputComponent 
                                    fieldName={"Address"} 
                                    fieldValue={ quote && quote.client ? buildCompleteClientAddress() : '' } 
                                    fieldId={'input-quote-client-address'} 
                                    attributeName={'address'} 
                                    handleChangeField={handleChangeQuoteAttribute}
                                    required={false} 
                                    disabled={true}

                                />

                                <AutocompleteComponent
                                        fieldId={'input-quote-user'}
                                        fieldName={'Sales person'} 
                                        fieldValue={quote ? quote.user : null}
                                        attributeName={'user'}
                                        optionList={activeTeamUsers}
                                        optionAttributeLabel={'first_name'}
                                        isUser={true}
                                        handleChangeField={handleChangeQuoteAttribute}
                                        disabled={true}
                                />


                            </Grid>

                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                        
                            <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                            {  quote && quote.quote_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Product/Service
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Qty.
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Unit price
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {  quote && quote.quote_item_list &&

                                quote.quote_item_list.map( (quoteItem, index) => (
                                    <>
                                    
                                    <AutocompleteComponent
                                        fieldId={'input-quote_item_list-'+index}
                                        fieldName={'Item'} 
                                        fieldValue={quoteItem.item}
                                        fieldIndex={index}
                                        attributeName={'quote_item_list'}
                                        optionList={activeItems}
                                        optionAttributeLabel={'name'}
                                        hideLabel={true}
                                        handleChangeField={handleChangeQuoteItemAttribute}
                                        sx={ constants.styles.textFieldLineItem }
                                        inputSx={4}
                                        inputMd={4}
                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                    />

                                    <InputComponent 
                                        fieldName={'Quantity'} 
                                        fieldValue={quoteItem.quantity} 
                                        fieldIndex={index}
                                        fieldId={'input-quote_item_qty-'+index} 
                                        attributeName={'quote_item_list'} 
                                        handleChangeField={handleChangeQuoteItemQuantity}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldSmall }
                                        inputSx={1}
                                        inputMd={1}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                    />

                                    <InputComponent 
                                        fieldName={'Unit price'} 
                                        fieldValue={quoteItem.unit_price} 
                                        fieldIndex={index}
                                        fieldId={'input-quote_item_price-'+index} 
                                        attributeName={'quote_item_list'} 
                                        required={true} 
                                        hideLabel={true}
                                        disabled={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                    />

                                    <InputComponent 
                                        fieldName={'Total'} 
                                        fieldValue={quoteItem.unit_price ? calculateSubTotal(quoteItem) : 0} 
                                        fieldIndex={index}
                                        fieldId={'input-quote_item_total-'+index} 
                                        attributeName={'quote_item_list'} 
                                        required={false} 
                                        hideLabel={true}
                                        disabled={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            }
                                        }}
                                    />

                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <IconButton 
                                            sx={{ p: 0 }} 
                                            title="Edit item"
                                            onClick={()=> handleShowItemDetails(quoteItem)}
                                        >
                                            <EditNote sx={constants.styles.iconSize}/>
                                        </IconButton>
                                        { quote && isQuoteEditable( quote.status ) &&
                                        <>
                                            <IconButton 
                                                sx={{ p: 0 }} 
                                                title="Delete item"
                                                onClick={() => handleDeleteQuoteItem(index) }
                                            >
                                                <Delete sx={constants.styles.iconSize}/>
                                            </IconButton>
                                        </>
                                        }
                                    </Grid>
                                    
                                    </>
                                ))

                            }

                            {  quote && quote.quote_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={5} md={4}>
                                        <Grid container direction="row" spacing={0}
                                            justifyContent={'left'}
                                            alignContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Subtotal:
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatTotal(quote) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Discount:
                                                </Typography>
                                                {quote && quote.discount_type && quote.discount_type === discountTypesCatalog.percentageDiscount.id &&
                                                    (
                                                        <Typography sx={constants.styles.textSmall} align="right">
                                                            ({quote ? quote.discount : 0.0}%)
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatDiscount(quote) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Taxes:
                                                </Typography>
                                                
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatTaxes(quote) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong> Total:  </strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong>${ calculatGrandTotal(quote) }</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            } 

                            </Grid>

                            
                        </Grid>

                        { quote && isQuoteEditable( quote.status ) &&
                        <>
                            <Grid item  
                                    xs={12} md={12} 
                                    sx={constants.styles.sectionButtons}
                                >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleAddQuoteItem}
                                    > 
                                        Add item 
                                </Button>
                            </Grid>
                        </>
                        }

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <InputComponent 
                                    fieldName={"Quote notes"} 
                                    fieldValue={ quote ? quote.notes: '' } 
                                    fieldId={'input-quote-notes'} 
                                    attributeName={'notes'} 
                                    handleChangeField={handleChangeQuoteAttribute}
                                    required={true} 
                                    multiline={true}
                                    rows={6}

                                    labelSx={12}
                                    labelMd={12}

                                    inputSx={12}
                                    inputMd={12}

                                    sx={constants.styles.textFieldLarge}

                                    disabled={true}
                                />


                            </Grid>
                        </Grid>


                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <UploaderComponent
                                object={quote}
                                prefix={'quote'}
                                folder={'quotes'}
                                imageListAttributeName={'quote_image_list'}
                                setObject={setQuote}
                                saveObject={handleClickSave}
                                disabled={true}
                            />

                        </Grid>

                        { quote && quote.id && !isQuoteInDraft( quote.status ) &&
                        <>
                            <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>

                                <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                                    <InputComponent 
                                        fieldName={"Assessment"} 
                                        fieldValue={ quote ? quote.assess_notes: '' } 
                                        fieldId={'input-quote-assess_notes'} 
                                        attributeName={'assess_notes'} 
                                        handleChangeField={handleChangeQuoteAttribute}
                                        required={true} 
                                        multiline={true}
                                        rows={6}

                                        labelSx={12}
                                        labelMd={12}

                                        inputSx={12}
                                        inputMd={12}

                                        fullWidth={true}
                                        disabled={true}
                                    />

                                </Grid>
                            </Grid>
                        </>
                        }


                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                { quote && quote.id && isQuoteInDraft( quote.status ) &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleClickSaveAndSend}
                                        > 
                                        Save ans send to assessment
                                        </Button>
                                    </>
                                }
                                { quote && isQuotePendingApproval( quote.status ) &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleApproveQuote}
                                        > 
                                        Approve quote
                                        </Button>
                                    </>
                                }
                                
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
                <QuoteItemDetails
                    openDetailsDialog={openDetailsDialog}
                    handleCloseDetailsDialog={handleCloseDetailsDialog}
                    item={selectedItem}
                />

                <ConfirmDialog 
                    key={'confirm_approve_quote'}
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                    handleConfirmAction={handleConfirmApproveQuote}
                    title={'Do you approve this quote?'}
                    message={quote ? 'Quote assessment: ' + quote.assess_notes : ''}
                />

        </PrincipalLayout>

        
    );
}

export default EditQuoteUsers;