import logo from './logo.svg';
import './App.css';
import Principal from './components/jobs-manager/home/Principal';

function App() {

  return (
    <>
      <Principal></Principal>
    </>
  );
}


export default App;
