import { Document, Font, Page, Text, View } from "@react-pdf/renderer";
import pdfConstants from "../util/PDFConstants";
import PDFHeader from "../util/PDFHeader";
import { calculatDiscount, calculateSubTotal, calculatGrandTotal, calculatTaxes, calculatTotal } from "../../../service/tenant/QuoteService";
import PDFClientHeader from "../util/PDFClientHeader";


function QuotePDF(props) {


    return (
        <Document>
            <Page size="A4" style={pdfConstants.pdfStyles.page}>

                <PDFHeader
                    title={"Quote: " + 
                        (props.quote && props.quote.id ? ' #' + props.quote.id + ' - ' : '') +
                        (props.quote ? props.quote.title : '')
                    }
                />

                <PDFClientHeader
                    client={props.client}
                    clientAddress={props.clientAddress}
                />
                
                <View style={pdfConstants.pdfStyles.tableContainer}>

                    <View style={pdfConstants.pdfStyles.container}>
                        <Text style={pdfConstants.pdfStyles.bigColumnHeader50}>Product/Service</Text>
                        <Text style={pdfConstants.pdfStyles.smallColumnHeader}>Qty.</Text>
                        <Text style={pdfConstants.pdfStyles.mediumColumnHeader}>Unit price</Text>
                        <Text style={pdfConstants.pdfStyles.mediumColumnHeader}>Total</Text>
                        
                    </View>

                    {  props.quote && props.quote.quote_item_list && props.quote.quote_item_list.map( (item, index) => (
                        
                            <View style={pdfConstants.pdfStyles.row30} key={index}>
                                <Text style={pdfConstants.pdfStyles.bigColumn50}>
                                    {item.name}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.smallColumnCenter}>
                                    {item.quantity}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.mediumColumnRight}>
                                    ${item.unit_price}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.mediumColumnRight}>
                                    ${ calculateSubTotal(item) }
                                </Text>
                               
                            </View>

                        ))
                    }

                </View>


                {  props.quote && 
                    (
                        <View style={pdfConstants.pdfStyles.quoteFooterContainer}>

                            <View style={pdfConstants.pdfStyles.row120NoBorder}>
                                <Text style={pdfConstants.pdfStyles.bigColumn60}>
                                    {props.quote.notes}
                                </Text>
                                <View style={pdfConstants.pdfStyles.quoteFooterTotals}>

                                    <View style={pdfConstants.pdfStyles.row30NoBorder}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Subtotal
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatTotal(props.quote) }
                                        </Text>
                                    </View>
                                    <View style={pdfConstants.pdfStyles.row30NoBorder}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Discount
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatDiscount(props.quote) }
                                        </Text>
                                    </View>
                                    <View style={pdfConstants.pdfStyles.row30NoBorder}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Taxes
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatTaxes(props.quote) }
                                        </Text>
                                    </View>
                                    <View style={pdfConstants.pdfStyles.row30}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Total
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatGrandTotal(props.quote) }
                                        </Text>
                                    </View>

                                </View>
                                
                                
                            </View>

                        </View>
                    )
                }

            </Page>
        </Document>

    );

}

export default QuotePDF;