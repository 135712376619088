import React from "react";
import constants from "../util/Constants";
import { Delete, EditNote } from "@mui/icons-material";
import { Button, Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

function ProjectsTable(props){

    return (
        
        <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

            <Grid item 
                xs={12} md={12}
                sx={constants.styles.sectionTable}
            >
                <TableContainer component={Paper} variant="outlined">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={constants.styles.tableHeader} >Project name</TableCell>
                                <TableCell sx={constants.styles.tableHeader} >Pending jobs</TableCell>
                                <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { props.projectList != null && props.projectList.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                                    <Link 
                                        component="button"
                                        onClick={() => props.handleEditProject(row.id)}
                                    >
                                        {row.name}
                                    </Link>
                                </TableCell>
                                <TableCell sx={constants.styles.tableCell} align="center">
                                    { row.job_list ? row.job_list.length : 0 }
                                </TableCell>
                                <TableCell sx={constants.styles.tableCell} align="center">
                                    <IconButton 
                                        size="small" 
                                        title="Edit project"
                                        onClick={() => props.handleEditProject(row.id)}
                                    >
                                        <EditNote/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            
            <Grid 
                container
                alignItems={'flex-end'}
                alignContent={'flex-end'}
                flexDirection={'column'}
            >

                <Grid item  
                    xs={12} md={12} 
                    sx={constants.styles.sectionInput}
                >
                    <Button 
                        variant="outlined" 
                        size="small" 
                        sx={constants.styles.buttonNormal}
                        onClick={props.handleAddProject}
                        > 
                            Add project 
                    </Button>

                </Grid>

            </Grid>

        </Grid>
    );
}

export default ProjectsTable;