import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, Grid, Icon, IconButton, Link, Paper, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convertDateTimeStrToString } from "../../../service/tenant/ValidationService";
import { Delete, MarkEmailRead, Warning } from "@mui/icons-material";
import constants from "../util/Constants";
import PrincipalLayout from "../menu/PrincipalLayout";
import ErrorMessageChip from "../util/ErrorMessageChip";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { useNavigate } from "react-router";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import { getClientLocalStorage, getUserData } from "../../../service/tenant/SecurityService";
import { searchNotificationsByAnyAttribute, updateNotification } from "../../../service/tenant/NotificationsService";
import { blue, green, red, yellow } from "@mui/material/colors";
import { getObjectTypeName, getStatusNameByType, objectTypes, status } from "../../../service/tenant/StatusService";


function ViewNotifications(props){

    const navigate = useNavigate();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    const client = getClientLocalStorage();

    let userId = '';
    let tenantId = '';
    let roleCode = '';
    userId = userData.id;

    const roleList = userData.role_list;
    if( roleList && roleList.length > 0 ){
        const primaryRole = roleList[0];
        roleCode = primaryRole.code;
    }
    tenantId = tenant.id;

    const [notificationList, setNotificationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');

    useEffect(() => {

        searchAllNotificationForUserAndRole();

    }, [props.notificationNumber]);


    const searchAllNotificationForUserAndRole = () => {
        setIsLoading(true);
        setInfoMessage("");
        searchNotificationsByAnyAttribute('', userId, roleCode, tenantId).then( notificationListResponse => {
            if( notificationListResponse != null ){
                setNotificationList(notificationListResponse);

                if( notificationListResponse.length === 0 ){
                    setInfoMessage(constants.errors.viewNotificationsNoNotifications);
                }

            } else {
                setErrorMessage(constants.errors.searchNotificationsError);
            }
            setIsLoading(false);
        });
    }

    const handleUpdateNotification = (notification, updatePendingNotifications) => {

        setSuccessMessage('');
        setErrorMessage('');

        setIsLoading(true);

        updateNotification(notification, tenantId).then( notificationResponse => {

            if( notificationResponse != null ){
                setSuccessMessage(constants.errors.editNotificationSuccess);
            } else {
                setErrorMessage(constants.errors.editNotificationError);
            }
            
            if( updatePendingNotifications === true ){
                props.setNotificationNumber( props.notificationNumber - 1 );
            } else {
                searchAllNotificationForUserAndRole();
            }
            

            setIsLoading(false);

        });

    }

    const handleMarkNotificationAsReaded = ( notification ) => {
        let notificationAux = {...notification};
        notificationAux.status = status.notification.read.status;

        handleUpdateNotification(notificationAux, true);
    }

    const handleMarkNotificationAsDeleted = ( notification ) => {
        let notificationAux = {...notification};
        const previuosStatus = notification.status;
        notificationAux.status = status.notification.deleted.status;
        const updatePendingNotifications = previuosStatus === status.notification.active.status;
        handleUpdateNotification(notificationAux, updatePendingNotifications);
    }


    const handleClickOpenObject = (notification) => {
        console.debug('[ViewNotifications][handleClickOpenObject] notification: ', notification);

        handleMarkNotificationAsReaded(notification);

        const objectType = notification.object_type;

        if( objectType === objectTypes.request.type ){

            let requestRoute = constants.routes.editRequest;
            if( client != null ){
                requestRoute = constants.routes.editRequestUsers;
            }
            navigate(requestRoute, {
                state: {
                    requestId: notification.object_id
                }
            });

        } else if( objectType === objectTypes.quote.type ){
            
            let quoteRoute = constants.routes.editQuote;
            if( client != null ){
                quoteRoute = constants.routes.editQuoteUsers;
            }
            navigate(quoteRoute, {
                state: {
                    quoteId: notification.object_id
                }
            });

        } else if( objectType === objectTypes.work_order.type ){
            
            let editWorkOrderRoute = constants.routes.editWorkOrder;
            if( client != null ){
                editWorkOrderRoute = constants.routes.editWorkOrderUsers;
            }
            navigate(editWorkOrderRoute, {
                state: {
                    workOrderId: notification.object_id
                }
            });

        } else if( objectType === objectTypes.job.type ){
            
            navigate(constants.routes.editJob, {
                state: {
                    jobId: notification.object_id
                }
            });

        } else if( objectType === objectTypes.invoice.type ){
            
            let editInvoiceRoute = constants.routes.editInvoice;
            if( client != null ){
                editInvoiceRoute = constants.routes.editInvoiceUsers;
            }
            navigate(editInvoiceRoute, {
                state: {
                    invoiceId: notification.object_id
                }
            });

        }
    }

    return (

        <PrincipalLayout isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Notifications: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={infoMessage} setMessage={setInfoMessage} />
                        </Grid>
                        
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Grid>
                        
                        {
                            notificationList.map( (notification, index) => (

                                <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>

                                    <Card>
                                        <CardHeader
                                            avatar={
                                                ( notification.status === status.notification.active.status ?
                                                    (
                                                        <Avatar sx={{ bgcolor: red[600] }} >
                                                            <Warning sx={constants.styles.avatarIconSize} />
                                                        </Avatar>
                                                    )
                                                    :
                                                    (
                                                        <Avatar sx={{ bgcolor: yellow[600] }} >
                                                            <Warning sx={constants.styles.avatarIconSize} />
                                                        </Avatar>
                                                    )
                                                )
                                            }
                                            title={ notification.message }
                                            subheader={ convertDateTimeStrToString( notification.event_date_time ) }
                                            titleTypographyProps={constants.styles.textSubtitle}
                                            subheaderTypographyProps={constants.styles.textSmall}
                                        />
                                        <CardContent>
                                            { notification.object_title && 
                                                    (
                                                        <>
                                                        <Typography sx={constants.styles.textNormal}>
                                                            { getObjectTypeName(notification.object_type) + ': '}
                                                            <Link
                                                                component="button"
                                                                onClick={() => handleClickOpenObject(notification)}
                                                            >
                                                                {notification.object_title}
                                                            </Link>
                                                        </Typography>
                                                         <Typography sx={constants.styles.textNormal}>
                                                            Status: { getStatusNameByType(notification.object_type, notification.object_status) }
                                                         </Typography>
                                                         </>
                                                    )
                                            }
                                            
                                            { notification.details && 
                                                (
                                                    <Typography variant="body2" sx={constants.styles.textNormal}>
                                                        {notification.details}
                                                    </Typography>
                                                )
                                            }
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            { notification.status === status.notification.active.status &&
                                                (
                                                    <IconButton
                                                        onClick={ () => handleMarkNotificationAsReaded(notification) }
                                                    >
                                                        <MarkEmailRead sx={constants.styles.iconSize} />
                                                    </IconButton>
                                                )
                                            }   
                                            <IconButton
                                                onClick={ () => handleMarkNotificationAsDeleted(notification) }
                                            >
                                                <Delete sx={constants.styles.iconSize} />
                                            </IconButton>
                                        </CardActions>
                                    </Card>

                                </Grid>

                            ))

                        }

                            
                        

                        

                        

                       
                        

                        
                        
                    </Grid>
                    
                </Paper>
                
        </PrincipalLayout>


    );

}

export default ViewNotifications;