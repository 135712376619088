//Service Helper
import constants from "../../components/jobs-manager/util/Constants";

export const googleMapsBaseURL = 'https://www.google.com/maps/search/?api=1&query='; 

export const getDataRespose = (response) => {
    if( response != null && 
        response.data != null &&
        response.data.result === constants.api.successMessage){
        return response.data.data;
    }
    return null;
}

export const buildCompleteClientAddress = (clientAddress) => {
    if( clientAddress ){
        return clientAddress.number + ' ' + clientAddress.street + ', ' + clientAddress.city + ', ' + clientAddress.locality;
    }
    return '';
}

export const buildClientAddressLinkToMaps = (clientAddress) => {
    if( clientAddress ){
        return googleMapsBaseURL + clientAddress.latitude + ',' + clientAddress.longitude;
    }
    return '';
}


