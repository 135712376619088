//Job Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { getUserData } from "./SecurityService";
import {status} from "./StatusService";


export const searchJobsByAnyAttribute = async(anyInfo, clientId, projectId, tenanId) => {
    var response = await axios.get( constants.api.jobApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getJobById = async(jobId, tenanId) => {

    var response = await axios.get( constants.api.jobApiUrl + '/' + tenanId + '/' + jobId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateJob = async(job, tenanId) => {

    const user = getUserData();
    job.user_id = user.uuid;

    console.debug('[JobService][updateJob] job: ', job);

    var response = await axios.put( constants.api.jobApiUrl + '/' + tenanId + '/', job, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteJob = async(job, tenanId) => {

    const user = getUserData();
    job.user_id = user.uuid;
    job.status = status.jobStatus.cancelled.status;

    console.debug('[JobService][logicalDeleteJob] job: ', job);

    var response = await axios.delete( constants.api.jobApiUrl + '/' + tenanId + '/logical_delete/' + job.id, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createJob = async(job, tenanId) => {

    const user = getUserData();
    job.user_id = user.uuid;

    console.debug('[JobService][createJob] job: ', job);

    var response = await axios.post( constants.api.jobApiUrl + '/' + tenanId + '/', job, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const setBillingTypes = (billingTypes) => {
    console.debug('[JobService][setBillingTypes] billingTypes: ', billingTypes);
    if( billingTypes != null  ){
        localStorage.setItem(constants.storage.billingTypes, JSON.stringify(billingTypes));
    }
}

export const getBillingTypes = () => {
    const billingTypesStr = localStorage.getItem(constants.storage.billingTypes);
    if( billingTypesStr != null ){
        const billingTypes = JSON.parse(billingTypesStr);
        console.debug('[JobService][getBillingTypes] billingTypes: ', billingTypes);
        return billingTypes;
    }
    return null;
}


export const getAllBillingTypes = async () => {
    const billingTypes = getBillingTypes();

    if( billingTypes != null ){
        return billingTypes;
    } else {
        var response = await axios.get( constants.api.jobApiUrl + '/billing_types', {
            headers: constants.api.defaultHeaders 
        } )
        .catch( error => {
            console.error('[ERROR] error: ', error);
        });
        const billingTypesArray = getDataRespose(response);
        setBillingTypes(billingTypesArray);
        return billingTypesArray;
    }

}


export const setScheduleTypes = (scheduleTypes) => {
    console.debug('[JobService][setScheduleTypes] scheduleTypes: ', scheduleTypes);
    if( scheduleTypes != null  ){
        localStorage.setItem(constants.storage.scheduleTypes, JSON.stringify(scheduleTypes));
    }
}

export const getScheduleTypes = () => {
    const scheduleTypesStr = localStorage.getItem(constants.storage.scheduleTypes);
    if( scheduleTypesStr != null ){
        const scheduleTypes = JSON.parse(scheduleTypesStr);
        console.debug('[JobService][getScheduleTypes] scheduleTypes: ', scheduleTypes);
        return scheduleTypes;
    }
    return null;
}


export const getAllScheduleTypes = async () => {
    const scheduleTypes = getScheduleTypes();

    if( scheduleTypes != null ){
        return scheduleTypes;
    } else {
        var response = await axios.get( constants.api.jobApiUrl + '/schedule_types', {
            headers: constants.api.defaultHeaders 
        } )
        .catch( error => {
            console.error('[ERROR] error: ', error);
        });
        const scheduleTypesArray = getDataRespose(response);
        setScheduleTypes(scheduleTypesArray);
        return scheduleTypesArray;
    }

}

