//Client Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { getUserData } from "./SecurityService";
import {status} from "./StatusService";

export const taxRates = {
    texas: {
        houston: 0.0825
    }
}

export const getTaxRateByClient = () => {
    return taxRates.texas.houston;
}

export const searchClientsByAnyAttribute = async(anyInfo, tenanId) => {
    var response = await axios.get( constants.api.clientApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getAllActiveClients = async( tenanId) => {

    var response = await axios.get( constants.api.clientApiUrl + '/' + tenanId + '/shorten/filter', { 
        params: {
            status: status.clientStatus.active
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getClientById = async(clientId, tenanId) => {

    var response = await axios.get( constants.api.clientApiUrl + '/' + tenanId + '/' + clientId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getShortenClientById = async(clientId, tenanId) => {

    var response = await axios.get( constants.api.clientApiUrl + '/' + tenanId + '/shorten_address/' + clientId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateClient = async(client, tenanId) => {

    const user = getUserData();
    client.user_id = user.uuid;

    client.request_list = null;
    client.quote_list = null;
    client.job_list = null;
    client.work_order_list = null;

    console.debug('[ClientService][updateClient] client: ', client);

    var response = await axios.put( constants.api.clientApiUrl + '/' + tenanId + '/', client, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteClient = async(client, tenanId) => {

    const user = getUserData();
    client.user_id = user.uuid;
    client.status = status.clientStatus.inactive;

    console.debug('[ClientService][logicalDeleteClient] client: ', client);

    var response = await axios.delete( constants.api.clientApiUrl + '/' + tenanId + '/logical_delete/' + client.id, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createClient = async(client, tenanId) => {

    const user = getUserData();
    client.user_id = user.uuid;

    console.debug('[ClientService][createClient] client: ', client);

    var response = await axios.post( constants.api.clientApiUrl + '/' + tenanId + '/', client, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}



