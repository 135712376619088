import React from "react";
import constants from "./Constants";
import { Chip } from "@mui/material";

function ErrorMessageChip(props) {

    return (
        <>
            { props.message !== null && props.message !== '' ?
                <Chip label={props.message} 
                    sx={constants.styles.textNormalError} 
                    
                />
                :
                <></>
            }
        </>
    );

}

export default ErrorMessageChip;