import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Link, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorMessageChip from "./ErrorMessageChip";
import SuccessMessageChip from "./SuccessMessageChip";
import constants from "./Constants";
import { CloudUploadOutlined, Delete } from "@mui/icons-material";
import { buildFilePath, deleteFileInBucket, getSignedUrlToFilePath, getSingleFileName, uploadFilePathToBucket } from "../../../service/tenant/StorageService";
import { createEmptyItemImage } from "../../../service/tenant/ValidationService";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});



function UploaderComponent(props){


    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
    const [fileUploadSuccessMessage, setFileUploadSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [imageItemToDelete, setImageItemToDelete] = useState(null);
    const [indexImageItemToDelete, setIndexImageItemToDelete] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);


    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;

    useEffect(() => {

        

        if( props.object && props.object[props.imageListAttributeName] ){
            let objectAux = {...props.object};
            let objectImageList = objectAux[props.imageListAttributeName];
            objectImageList.forEach(itemImage => {
                console.debug('[UploaderComponent][useEffect] itemImage: ', itemImage);

                setIsLoading(true);
                getSignedUrlToFilePath(itemImage.name).then( newSignedUrlResponse => {
                    if( newSignedUrlResponse != null ){
                        itemImage.url = newSignedUrlResponse;
                    }
                    setIsLoading(false);
                });

            });

            props.setObject(objectAux);

        }
        

    },[ props.object ? props.object[props.imageListAttributeName] : {} ])


    const handleUploadFile = ( fileList ) => {
        setFileUploadErrorMessage('');
        setFileUploadSuccessMessage('');
        if( fileList ){
            const file = fileList[0];
            console.debug('[UploaderComponent][handleUploadFile] file: ', file);

            if( file && constants.allowedUploadFileTypes.includes( file.type ) ){
                console.debug('[UploaderComponent][handleUploadFile] uploading... ');

                setIsLoading(true);
                const filePath = buildFilePath(tenantId, props.object.id, props.prefix, props.folder, file.name);

                uploadFilePathToBucket(filePath, file).then( uploadResponse => {

                    if( uploadResponse != null ){

                        getSignedUrlToFilePath(filePath).then( signedUrlResponse => {

                            if( signedUrlResponse != null ){

                                addItemImage(filePath, signedUrlResponse);

                                if( props.saveObject ){
                                    console.debug('[UploaderComponent][handleUploadFile] save object... ');
                                    props.saveObject();
                                }
                            } else {
                                setFileUploadErrorMessage(constants.errors.fileUploadError);
                            }

                        })

                        
                        setFileUploadSuccessMessage(constants.errors.fileUploadedSuccessfully);
                    } else {
                        setFileUploadErrorMessage(constants.errors.fileUploadError);
                    }
                    setIsLoading(false);

                });

                
            } else {
                setFileUploadErrorMessage(constants.errors.fileUploadNotSupportedTypeError);
            }
        }
    }

    const addItemImage = (name, url) => {
        let objectAux = {...props.object};
        
        let objectImageList = objectAux[props.imageListAttributeName];
        if( !objectImageList ){
            objectImageList = [];
        }
        let itemImage = createEmptyItemImage();
        itemImage.name = name;
        itemImage.url = url;
        objectImageList.push(itemImage);

        props.setObject(objectAux);
    }

    const handleDeleteItemImage = (index) => {
        let objectImageList = props.object[props.imageListAttributeName];
        const itemImage = objectImageList[index];
        setImageItemToDelete(itemImage);
        setIndexImageItemToDelete(index);
        setOpenConfirmDialog(true);
    }

    const handleConfimrDeleteItemImage = () => {

        setImageItemToDelete(null);
        setIndexImageItemToDelete(null);
        setOpenConfirmDialog(false);

        let objectAux = {...props.object};
        let objectImageList = objectAux[props.imageListAttributeName];
        const itemImage = objectImageList[indexImageItemToDelete];
        

        setIsLoading(true);

        deleteFileInBucket(itemImage.name).then( deleteImageResponse => {
            if( deleteImageResponse != null ){

                objectImageList.splice(indexImageItemToDelete, 1);
                props.setObject(objectAux);

                if( props.saveObject ){
                    console.debug('[UploaderComponent][handleDeleteItemImage] save object... ');
                    props.saveObject();    
                }

                setFileUploadSuccessMessage(constants.errors.fileDeletedSuccessfully);
            } else {
                setFileUploadErrorMessage(constants.errors.fileDeletedError);
            }

            setIsLoading(false);
        })


    }

    const handleCloseDeleteDialog = () => {
        setImageItemToDelete(null);
        setIndexImageItemToDelete(null);
        setOpenConfirmDialog(false);
    }

    const handleCloseBackfrop = () => {
        setIsLoading(false);
    }

    return (
        <>
        { props.object != null && props.object.id && 
        (
            <>
                <div style={{"position": "relative"}}>
                    <Backdrop
                        key={'uploader_component'}
                        sx={{color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1, "position": "absolute"}}
                        open={isLoading}
                        onClick={() => handleCloseBackfrop()}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <Grid container direction="row" spacing={0}
                                            justifyContent={'left'}
                                            alignContent={'center'}
                                            alignItems={'center'}
                                        >
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            <Typography sx={constants.styles.textNormal} >
                               {props.title ? props.title + ": " : "Attached files: " } 
                            </Typography>
                        </Grid>

                        { props.object && props.object[props.imageListAttributeName] &&
                        
                            props.object[props.imageListAttributeName].map( (itemImage, index) => (
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={10} md={10}>
                                        { itemImage.name && itemImage.name.indexOf(".pdf") > 0  &&
                                            (
                                                <Link 
                                                    key={'item_image_link_'+index}
                                                    href={itemImage.url} 
                                                    target="_blank"
                                                >
                                                    <Typography sx={constants.styles.textNormal} >
                                                        { getSingleFileName(props.prefix, itemImage.name) }
                                                    </Typography>
                                                </Link>
                                            )
                                        }
                                        { itemImage.name && itemImage.name.indexOf(".pdf") <= 0  &&
                                            (
                                                <Link 
                                                    key={'item_image_link_'+index}
                                                    href={itemImage.url} 
                                                    target="_blank"
                                                >
                                                    <div 
                                                        style={{
                                                                display: 'block', 
                                                                backgroundSize: 'contain', 
                                                                backgroundRepeat: 'no-repeat', 
                                                                backgroundPosition: 'left center', 
                                                                minHeight: '100px', 
                                                                backgroundImage: 'url(' + itemImage.url +')'
                                                        }}
                                                    >
                                                    </div>
                                                </Link>
                                            )
                                        }   
                                    </Grid>
                                    { !props.disabled ?
                                        <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                            <IconButton 
                                                sx={{ p: 0 }} 
                                                title="Delete item"
                                                onClick={() => handleDeleteItemImage(index) }
                                            >
                                                <Delete sx={constants.styles.iconSize}/>
                                            </IconButton>
                                        </Grid>
                                        :
                                        <></>
                                    }
                                </>
                            ))
                        }

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        { !props.disabled ?
                        <>
                            <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                <ErrorMessageChip message={fileUploadErrorMessage} />
                            </Grid>

                            <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                <SuccessMessageChip message={fileUploadSuccessMessage} setMessage={setFileUploadSuccessMessage} />
                            </Grid>

                            <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>

                                <FormControl variant="standard">
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadOutlined />}
                                        sx={constants.styles.buttonNormal}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={(event) => handleUploadFile(event.target.files)}
                                            
                                        />
                                    </Button>
                                </FormControl>
                            </Grid>
                        </>
                        :
                        <></>
                        }
                        
                        <Dialog
                            open={openConfirmDialog}
                            onClose={handleCloseDeleteDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                sx={constants.styles.textNormal}
                                id="alert-dialog-title"
                            >
                                <strong>Do you confirm to delete this image?</strong>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                    sx={constants.styles.textNormal}
                                    id="alert-dialog-description"
                                >
                                    Image: <strong>{ imageItemToDelete ? getSingleFileName(props.prefix, imageItemToDelete.name) : ''}</strong>
                                    <br/>If you delete this image will no longer be available anymore.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined" 
                                    size="small"
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleCloseDeleteDialog}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    size="small"
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleConfimrDeleteItemImage} 
                                    autoFocus
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>
                </div>
            </>
            )
        }
        </>
    );

}

export default UploaderComponent;