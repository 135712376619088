import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote } from "@mui/icons-material";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createServiceType, getAllActiveServiceTypes, getServiceTypeById, logicalDeleteServiceType, updateServiceType } from "../../../service/tenant/ProjectService";
import InputComponent from "../util/InputComponent";
import { createEmptyServiceType, validateServiceType } from "../../../service/tenant/ValidationService";



function ServiceTypes(props){

    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageDialog, setErrorMessageDialog] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openServiceTypeDetails, setOpenServiceTypeDetails] = useState(false);
    const [activeServiceTypes, setActiveServiceTypes] = useState([]);
    const [serviceType, setServiceType] = useState([]);
    

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {

        setIsLoading(true);
        getAllActiveServiceTypes( tenantId ).then( activeServiceTypesResponse => {
            if( activeServiceTypesResponse !== null ){
                setActiveServiceTypes(activeServiceTypesResponse);
            }
            setIsLoading(false);
        });



    }, []);


    const handleEditServiceType = (serviceTypeAux) => {
        console.debug('[ServiceTypes][handleEditServiceType] serviceType: ', serviceTypeAux);

        setIsLoading(true);

        getServiceTypeById( tenantId, serviceTypeAux.id ).then( serviceTypeResponse => {

            if( serviceTypeResponse != null ){
                setServiceType(serviceTypeResponse);
                setOpenServiceTypeDetails(true);
                setIsLoading(false);
            }

        });
        
        
    }

    const handleNewServiceType = () => {
        console.debug('[ServiceTypes][handleNewServiceType] ');
        const serviceTypeAux = createEmptyServiceType();
        setServiceType(serviceTypeAux);
        setOpenServiceTypeDetails(true);
    }

    const handleDeleteServiceType = (serviceTypeAux) => {
        console.debug('[ServiceTypes][handleDeleteServiceType] project: ', serviceTypeAux);
        setOpenDeleteAlert(true);
        setServiceType(serviceTypeAux);
    }

    const handleConfirmDeleteServiceType = () => {
        console.debug('[ServiceTypes][handleConfirmDeleteServiceType] ');
        
        const tenantId = tenant.id;

        setSuccessMessage('');
        setErrorMessage('');

        setIsLoading(true);

        logicalDeleteServiceType(serviceType, tenantId).then( serviceTypeDeleteResponse => {

            if( serviceTypeDeleteResponse !== null ){

                getAllActiveServiceTypes( tenantId ).then( activeServiceTypesResponse => {
                    if( activeServiceTypesResponse !== null ){
                        setActiveServiceTypes(activeServiceTypesResponse);
                    }
                    setIsLoading(false);
                });

                setSuccessMessage(constants.errors.createServiceTypeSuccess);
            } else {
                setErrorMessage(constants.errors.createServiceTypeError);
                setIsLoading(false);
            }

        });

        setOpenDeleteAlert(false);
        
    }

    const handleCloseDeleteDialog = () => {
        setServiceType(null);
        setOpenDeleteAlert(false);
    }

    const handleCloseServiceTypeDetails = () => {
        setServiceType(null);
        setOpenServiceTypeDetails(false);
    }

    const handleSaveServiceType = () => {
        
        console.debug('[ServiceTypes][handleSaveServiceType] serviceType: ', serviceType);

        setSuccessMessage('');
        setErrorMessage('');
        setErrorMessageDialog('');

        let isValidServiceType = validateServiceType(serviceType);

        console.debug('[ServiceTypes][handleSaveServiceType] isValidServiceType: ', isValidServiceType);

        if( isValidServiceType && serviceType.id && serviceType.id !== '' ){
            handleUpdateServiceType();
            setOpenServiceTypeDetails(false);
            setServiceType(null);
        } else if( isValidServiceType && !serviceType.id ){
            handleCreateServiceType();
            setOpenServiceTypeDetails(false);
            setServiceType(null);
        } else {
            setErrorMessageDialog(constants.errors.validationErrorRequiredFields);
        }

        
    }


    const handleUpdateServiceType = () => {

        setIsLoading(true);

        updateServiceType(serviceType, tenantId).then( serviceTypeResponse => {

            if( serviceTypeResponse !== null ){
                setSuccessMessage(constants.errors.editServiceTypeSuccess);

                getAllActiveServiceTypes( tenantId ).then( activeServiceTypesResponse => {
                    if( activeServiceTypesResponse !== null ){
                        setActiveServiceTypes(activeServiceTypesResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.editServiceTypeError);
                setIsLoading(false);
            }
            
        });
    }


    const handleCreateServiceType = () => {

        setIsLoading(true);

        createServiceType(serviceType, tenantId).then( serviceTypeResponse => {

            if( serviceTypeResponse !== null ){
                setSuccessMessage(constants.errors.createServiceTypeSuccess);

                getAllActiveServiceTypes( tenantId ).then( activeServiceTypesResponse => {
                    if( activeServiceTypesResponse !== null ){
                        setActiveServiceTypes(activeServiceTypesResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.createServiceTypeError);
                setIsLoading(false);
            }
            
        });
    }


    const handleChangeServiceTypeAttribute = (attributeName, attributeValue) => {
        if( serviceType ){
            const serviceTypeAux = { ...serviceType };
            serviceTypeAux[attributeName] = attributeValue;
            setServiceType(serviceTypeAux);
        }
    }

    

    return (
        <PrincipalLayout section={constants.menu.admin_admin_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Service Types &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Type</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {activeServiceTypes.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.type}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit service type"
                                                    onClick={() => handleEditServiceType(row)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete service type"
                                                    onClick={() => handleDeleteServiceType(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewServiceType}
                                > 
                                        New ServiceType 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    key="deleteServiceTypeDialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this serviceType?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            ServiceType name: <strong>{ serviceType ? serviceType.name : ''}</strong>
                            <br/>If you delete this serviceType will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteServiceType} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openServiceTypeDetails}
                    onClose={handleCloseServiceTypeDetails}
                    key="serviceTypeDetails"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                    
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>ServiceType: {serviceType ? serviceType.name : ''}</strong>
                    </DialogTitle>
                    <DialogContent>
                            <Grid container >
                                <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                                    <Grid container direction="row" spacing={0}
                                        justifyContent={'left'}
                                    >
                                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                            <ErrorMessageChip message={errorMessageDialog} />
                                        </Grid>

                                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                            &nbsp;
                                        </Grid>

                                        <InputComponent 
                                            fieldName={"Name"} 
                                            fieldValue={ serviceType ? serviceType.name : '' } 
                                            fieldId={'input-serviceType-name'} 
                                            attributeName={'name'} 
                                            handleChangeField={handleChangeServiceTypeAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />
                                        <InputComponent 
                                            fieldName={"Type"} 
                                            fieldValue={ serviceType ? serviceType.type : '' } 
                                            fieldId={'input-serviceType-type'} 
                                            attributeName={'type'} 
                                            handleChangeField={handleChangeServiceTypeAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />
                                        


                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseServiceTypeDetails}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleSaveServiceType} 
                            autoFocus
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default ServiceTypes;