import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, Search } from "@mui/icons-material";
import { logicalDeleteProject, searchProjectsByAnyAttribute } from "../../../service/tenant/ProjectService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { getAllActiveClients } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getProjectStatusName } from "../../../service/tenant/StatusService";



function ViewProjects(props){
    

    const [tableRows, setTableRows] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [client, setClient] = useState(null);
    const [activeClients, setActiveClients] = useState([]);

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {

        setIsLoading(true);
        getAllActiveClients( tenantId ).then( activeClientsResponse => {
            if( activeClientsResponse != null ){
                setActiveClients(activeClientsResponse);
            }
            setIsLoading(false);
        });

    }, []);


    const handleSearchProjects = () => {
        const tenantId = tenant.id;
        
        if( (!searchFilter || searchFilter === '') && client == null ){
            setErrorMessage(constants.errors.searchFilterEmpty);
            return;
        }

        setErrorMessage('');
        setTableRows([]);

        setIsLoading(true);

        let clientId = '';
        if( client ){
            clientId = client.id;
        }

        searchProjectsByAnyAttribute(searchFilter, clientId, tenantId).then( projectList => {

            if( projectList !== null ){
                console.debug('[ViewProjects][handleSearchProjects] projectList: ', projectList);

                if( projectList.length > 0 ){
                    setTableRows(projectList);
                } else {
                    setErrorMessage(constants.errors.emptyResult);
                }
            } else {
                setErrorMessage(constants.errors.searchProjectError);
            }

            setIsLoading(false);
            
        });
    }

    const handleEditProject = (projectId) => {
        console.debug('[ViewProjects][handleEditProject] projectId: ', projectId);
        navigate(constants.routes.editProject, {
            state: {
                projectId: projectId
            }
        });
    }

    const handleEditClient = (clientId) => {
        if( clientId != null ) { 
            console.debug('[ViewClients][handleEditClient] clientId: ', clientId);
            navigate(constants.routes.editClient, {
                state: {
                    clientId: clientId
                }
            });
        }
    }

    const handleNewProject = () => {
        navigate(constants.routes.createProject, {
            state: {
                projectId: ''
            }
        });
    }

    const handleDeleteProject = (project) => {
        console.debug('[ViewProjects][handleDeleteProject] project: ', project);
        setProjectToDelete(project);
        setOpenDeleteAlert(true);
    }

    const handleConfirmDeleteProject = () => {
        console.debug('[ViewProjects][handleConfirmDeleteProject] ');

        const tenantId = tenant.id;

        setOpenDeleteAlert(false);
        setIsLoading(true);

        logicalDeleteProject(projectToDelete, tenantId).then( (deleteResponse) => {
            console.debug('[ViewProjects][handleConfirmDeleteProject] deleteResponse: ', deleteResponse);

            if( deleteResponse != null ){
                setSuccessMessage(constants.errors.deleteProjectSuccess);

                handleSearchProjects();

            } else {
                setErrorMessage(constants.errors.deleteProjectError);
            }

            setIsLoading(false);
            setProjectToDelete(null);
        })

        
    }

    const handleCloseDeleteDialog = () => {
        setProjectToDelete(null);
        setOpenDeleteAlert(false);
    }

    const handleChangeClient = (attrinuteName, newClient) => {
        console.debug('[ViewProjects][handleChangeClient] newClient: ', newClient)
        setClient(newClient);
    }


    return (
        <PrincipalLayout section={constants.menu.admin_clients_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Projects &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                            <Typography sx={constants.styles.textNormal}>
                                Search for project:
                            </Typography>
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={9} md={9}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-user-search" sx={constants.styles.textNormal}>
                                    Type project information
                                </InputLabel>
                                <Input
                                    id="input-user-search"
                                    value={searchFilter}
                                    sx={constants.styles.inputSearchNormal}
                                    onChange={ (e) => setSearchFilter(e.target.value) }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={handleSearchProjects}
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    
                                />
                            </FormControl>
                        </Grid>

                        <AutocompleteComponent
                            fieldId={'input-project-client'}
                            fieldName={'Client'} 
                            fieldValue={client}
                            attributeName={'client'}
                            optionList={activeClients}
                            isClient={true}
                            handleChangeField={handleChangeClient}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}


                        />
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Client</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Project type</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Status</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {tableRows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditProject(row.id)}
                                                >
                                                    {row.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditClient( row.client ? row.client.id : null)}
                                                >
                                                    {row.client && row.client.user ? row.client.user.first_name + ' ' + row.client.user.last_name : '' }
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{row.project_type ? row.project_type.name : ''}</TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ getProjectStatusName(row.status) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit project"
                                                    onClick={() => handleEditProject(row.id)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete project"
                                                    onClick={() => handleDeleteProject(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewProject}
                                > 
                                        New Project 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this project?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            Project name: <strong>{ projectToDelete ? projectToDelete.name : ''}</strong>
                            <br/>If you delete this project will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteProject} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default ViewProjects;