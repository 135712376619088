import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote } from "@mui/icons-material";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { createUser, getAllActiveStaffRoles, getAllActiveStaffUsers, getAllActiveTeams, getAllUserTitles, getUserById, logicalDeleteUser, updateUser } from "../../../service/tenant/SecurityService";
import InputComponent from "../util/InputComponent";
import SelectComponent from "../util/SelectComponent";
import { createEmptyUser, validateUser } from "../../../service/tenant/ValidationService";



function Users(props){

    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageDialog, setErrorMessageDialog] = useState('');
    const [userTitles, setUserTitles] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [activeUsers, setActiveUsers] = useState([]);
    const [user, setUser] = useState([]);
    const [activeTeams, setActiveTeams] = useState([]);
    const [activeRoles, setActiveRoles] = useState([]);
    

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {

        setIsLoading(true);
        getAllActiveStaffUsers( tenantId ).then( activeUsersResponse => {
            if( activeUsersResponse !== null ){
                setActiveUsers(activeUsersResponse);
            }
            setIsLoading(false);
        });

        setIsLoading(true);
        getAllUserTitles().then( allUserTitles =>{
            if( allUserTitles !== null ){
                setUserTitles(allUserTitles);
            }
            setIsLoading(false);
        });

        setIsLoading(true);
        getAllActiveTeams( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse !== null ){
                setActiveTeams(activeItemsResponse);
            }
            setIsLoading(false);
        });

        setIsLoading(true);
        getAllActiveStaffRoles( tenantId ).then( activeStaffRolesResponse => {
            if( activeStaffRolesResponse !== null ){
                setActiveRoles(activeStaffRolesResponse);
            }
            setIsLoading(false);
        });

    }, []);


    const handleEditUser = (userAux) => {
        console.debug('[Users][handleEditUser] user: ', userAux);

        setIsLoading(true);

        getUserById( tenantId, userAux.id ).then( userResponse => {

            if( userResponse != null ){
                setUser(userResponse);
                setOpenUserDetails(true);
                setIsLoading(false);
            }

        });
        
        
    }

    const handleNewUser = () => {
        console.debug('[Users][handleNewUser] ');
        const userAux = createEmptyUser();
        setUser(userAux);
        setOpenUserDetails(true);
    }

    const handleDeleteUser = (userAux) => {
        console.debug('[Users][handleDeleteUser] project: ', userAux);
        setOpenDeleteAlert(true);
        setUser(userAux);
    }

    const handleConfirmDeleteUser = () => {
        console.debug('[Users][handleConfirmDeleteUser] ');
        
        const tenantId = tenant.id;

        setSuccessMessage('');
        setErrorMessage('');

        setIsLoading(true);

        logicalDeleteUser(user, tenantId).then( userDeleteResponse => {

            if( userDeleteResponse !== null ){

                getAllActiveStaffUsers( tenantId ).then( activeUsersResponse => {
                    if( activeUsersResponse !== null ){
                        setActiveUsers(activeUsersResponse);
                    }
                    setIsLoading(false);
                });

                setSuccessMessage(constants.errors.createUserSuccess);
            } else {
                setErrorMessage(constants.errors.createUserError);
                setIsLoading(false);
            }

        });

        setOpenDeleteAlert(false);
        
    }

    const handleCloseDeleteDialog = () => {
        setUser(null);
        setOpenDeleteAlert(false);
    }

    const handleCloseUserDetails = () => {
        setUser(null);
        setOpenUserDetails(false);
    }

    const handleSaveUser = () => {
        
        console.debug('[Users][handleSaveUser] user: ', user);

        setSuccessMessage('');
        setErrorMessage('');
        setErrorMessageDialog('');

        let isValidUser = validateUser(user);

        console.debug('[Users][handleSaveUser] isValidUser: ', isValidUser);

        if( !user.team_list || user.team_list.length === 0 || user.team_list[0] === null ||
            !user.role_list || user.role_list.length === 0 || user.role_list[0] === null
        ){
            setErrorMessageDialog(constants.errors.userValidationErrorNoTeam);
        } else if( isValidUser && user.id && user.id !== '' ){
            handleUpdateUser();
            setOpenUserDetails(false);
            setUser(null);
        } else if( isValidUser && !user.id ){
            handleCreateUser();
            setOpenUserDetails(false);
            setUser(null);
        } else {
            setErrorMessageDialog(constants.errors.validationErrorRequiredFields);
        }

        
    }


    const handleUpdateUser = () => {

        setIsLoading(true);

        updateUser(user, tenantId).then( userResponse => {

            if( userResponse !== null ){
                setSuccessMessage(constants.errors.editUserSuccess);

                getAllActiveStaffUsers( tenantId ).then( activeUsersResponse => {
                    if( activeUsersResponse !== null ){
                        setActiveUsers(activeUsersResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.editUserError);
                setIsLoading(false);
            }
            
        });
    }


    const handleCreateUser = () => {

        setIsLoading(true);

        createUser(user, tenantId).then( userResponse => {

            if( userResponse !== null ){
                setSuccessMessage(constants.errors.createUserSuccess);

                getAllActiveStaffUsers( tenantId ).then( activeUsersResponse => {
                    if( activeUsersResponse !== null ){
                        setActiveUsers(activeUsersResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.createUserError);
                setIsLoading(false);
            }
            
        });
    }


    const handleChangeUserAttribute = (attributeName, attributeValue) => {
        if( user ){
            const userAux = { ...user };
            userAux[attributeName] = attributeValue;
            setUser(userAux);
        }
    }

    const handleChangeUserTeamAttribute = (attributeName, attributeValue) => {
        if( user ){

            console.debug('[Users][handleChangeUserTeamAttribute] attributeValue: ', attributeValue);

            let userAux = { ...user };
            let teamListAux = userAux.team_list;
            console.debug('[Users][handleChangeUserTeamAttribute] teamListAux (prev): ', teamListAux);

            if( !teamListAux || teamListAux.length === 0){
                teamListAux.push(attributeValue);
                console.debug('[Users][handleChangeUserTeamAttribute] teamListAux: ', teamListAux);
            } else {
                teamListAux[0] = attributeValue;
            }

            setUser(userAux);

            console.debug('[Users][handleChangeUserTeamAttribute] user: ', user);
        }
    }

    const handleChangeUserRoleAttribute = (attributeName, attributeValue) => {
        if( user ){

            console.debug('[Users][handleChangeUserRoleAttribute] attributeValue: ', attributeValue);

            let userAux = { ...user };
            let roleListAux = userAux.role_list;

            if( !roleListAux || roleListAux.length == 0){
                roleListAux.push(attributeValue);
            } else {
                roleListAux[0] = attributeValue;
            }

            setUser(userAux);

            console.debug('[Users][handleChangeUserRoleAttribute] user: ', user);
        }
    }


    return (
        <PrincipalLayout section={constants.menu.admin_admin_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Users &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >First name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Last name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Email</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {activeUsers.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.first_name}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.last_name}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.email}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit project"
                                                    onClick={() => handleEditUser(row)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete project"
                                                    onClick={() => handleDeleteUser(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewUser}
                                > 
                                        New User 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    key="deleteUserDialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this user?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            User name: <strong>{ user ? user.first_name + ' ' + user.last_name : ''}</strong>
                            <br/>If you delete this user will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteUser} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openUserDetails}
                    onClose={handleCloseUserDetails}
                    key="userDetails"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                    
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>User: {user ? user.first_name + ' ' + user.last_name : ''}</strong>
                    </DialogTitle>
                    <DialogContent>
                            <Grid container >
                                <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                                    <Grid container direction="row" spacing={0}
                                        justifyContent={'left'}
                                    >
                                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                            <ErrorMessageChip message={errorMessageDialog} />
                                        </Grid>

                                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                            &nbsp;
                                        </Grid>

                                        <SelectComponent
                                            fieldName={"Title"} 
                                            fieldValue={ user ? user.title: '' } 
                                            fieldId={'input-user-title'} 
                                            attributeName={'title'} 
                                            optionList={userTitles}
                                            optionAttributeName={'title'}
                                            handleChangeField={handleChangeUserAttribute}
                                            sx={constants.styles.selectTextNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}
                                        />

                                        <InputComponent 
                                            fieldName={"First name"} 
                                            fieldValue={ user ? user.first_name : '' } 
                                            fieldId={'input-user-first_name'} 
                                            attributeName={'first_name'} 
                                            handleChangeField={handleChangeUserAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />
                                        <InputComponent 
                                            fieldName={"Last name"} 
                                            fieldValue={ user ? user.last_name : '' } 
                                            fieldId={'input-user-last_name'} 
                                            attributeName={'last_name'} 
                                            handleChangeField={handleChangeUserAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />
                                        
                                        <InputComponent 
                                            fieldName={"User id"} 
                                            fieldValue={ user ? user.uuid : '' } 
                                            fieldId={'input-user-uuid'} 
                                            attributeName={'uuid'} 
                                            handleChangeField={handleChangeUserAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}


                                        />
                                        <InputComponent 
                                            fieldName={"Password"} 
                                            fieldValue={ user ? user.password_hash : '' } 
                                            fieldId={'input-user-password'} 
                                            attributeName={'password_hash'} 
                                            handleChangeField={handleChangeUserAttribute}
                                            required={true} 
                                            passwordValidation={true}
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />

                                        <InputComponent 
                                            fieldName={"Email"} 
                                            fieldValue={ user ? user.email : '' } 
                                            fieldId={'input-user-email'} 
                                            attributeName={'email'} 
                                            handleChangeField={handleChangeUserAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />

                                        <InputComponent 
                                            fieldName={"Phone"} 
                                            fieldValue={ user ? user.phone: '' } 
                                            fieldId={'input-user-phone'} 
                                            attributeName={'phone'} 
                                            handleChangeField={handleChangeUserAttribute} 
                                            required={true}
                                            phoneValidation={true}

                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}
                                        />

                                        <AutocompleteComponent
                                            fieldId={'input-user-team'}
                                            fieldName={'Team'} 
                                            fieldValue={user && user.team_list && (user.team_list.length > 0) ? user.team_list[0] : null}
                                            attributeName={'team'}
                                            optionList={activeTeams}
                                            optionAttributeLabel={'name'}
                                            isClient={false}
                                            handleChangeField={handleChangeUserTeamAttribute}

                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}
                                        />

                                        <AutocompleteComponent
                                            fieldId={'input-user-role'}
                                            fieldName={'Role'} 
                                            fieldValue={user && user.role_list && (user.role_list.length > 0) ? user.role_list[0] : null}
                                            attributeName={'role'}
                                            optionList={activeRoles}
                                            optionAttributeLabel={'name'}
                                            isClient={false}
                                            handleChangeField={handleChangeUserRoleAttribute}

                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}
                                        />
                                    


                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseUserDetails}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleSaveUser} 
                            autoFocus
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default Users;