//Quote Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { getUserData } from "./SecurityService";
import {status} from "./StatusService";
import { parseToFloatAmount } from "./ValidationService";
import { getTaxRateByClient } from "./ClientService";


export const discountTypesCatalog = {
    percentageDiscount: {
        shortName: '%',
        id: 1
    },
    clientDiscount: {
        shortName: '$',
        id: 2
    }
}

export const searchQuotesByAnyAttribute = async(anyInfo, clientId, projectId, tenanId) => {
    var response = await axios.get( constants.api.quoteApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const searchQuotesByAnyAttributeAndStatus = async(anyInfo, clientId, projectId, status, tenanId) => {
    var response = await axios.get( constants.api.quoteApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            client_id: clientId,
            project_id: projectId,
            status: status,
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getQuoteById = async(quoteId, tenanId) => {

    var response = await axios.get( constants.api.quoteApiUrl + '/' + tenanId + '/' + quoteId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateQuote = async(quote, tenanId) => {

    const user = getUserData();
    quote.user_id = user.uuid;

    console.debug('[QuoteService][updateQuote] quote: ', quote);

    var response = await axios.put( constants.api.quoteApiUrl + '/' + tenanId + '/', quote, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteQuote = async(quote, tenanId) => {

    const user = getUserData();
    quote.user_id = user.uuid;
    quote.status = status.quoteStatus.cancelled.status;

    console.debug('[QuoteService][logicalDeleteQuote] quote: ', quote);

    var response = await axios.delete( constants.api.quoteApiUrl + '/' + tenanId + '/logical_delete/' + quote.id , {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createQuote = async(quote, tenanId) => {

    const user = getUserData();
    quote.user_id = user.uuid;

    console.debug('[QuoteService][createQuote] quote: ', quote);

    var response = await axios.post( constants.api.quoteApiUrl + '/' + tenanId + '/', quote, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const sendQuoteEmailPost = async(pdfFile, quoteId, tenanId) => {

    console.debug('[QuoteService][sendQuoteEmail] quoteId: ', quoteId);

    const formData = new FormData();
    formData.append('pdfFile', pdfFile);

    var response = await axios.post( constants.api.quoteApiUrl + '/' + tenanId + '/email/' + quoteId, formData, {
        headers: constants.api.defaultMultipartHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const calculateSubTotal = ( quoteItem ) => {
    if( quoteItem && quoteItem.unit_price && quoteItem.quantity ){
        const subTotal = quoteItem.unit_price * quoteItem.quantity;
        return parseToFloatAmount(subTotal);
    }
    return 0;
}

export const calculatTotal = (quote) => {
    let total = 0;
    if( quote && quote.quote_item_list ){
        quote.quote_item_list.forEach( quoteItem => {
            const subTotal = parseFloat(calculateSubTotal(quoteItem));
            total = total + subTotal;
        });
    }
    return parseToFloatAmount(total);
}


export const calculatDiscount = (quote) => {
    let totalDiscount = 0;
    const total = parseFloat(calculatTotal(quote));
    if( quote && quote.discount ){
        if( discountTypesCatalog.percentageDiscount.id === quote.discount_type ){
            totalDiscount = total * (quote.discount/100);
        } else {
            totalDiscount = quote.discount;
        }
    }
    return parseToFloatAmount(totalDiscount);
}


export const calculatTaxes = (quote) => {
    const taxRate = getTaxRateByClient();
    let taxes = 0.0;
    if( quote && quote.include_taxes === true ){
        taxes = calculatTotal(quote) * taxRate;
    }
    return parseToFloatAmount(taxes);
}

export const calculatGrandTotal = (quote) => {
    const taxRate = getTaxRateByClient();
    const total = parseFloat(calculatTotal(quote));
    let taxes = 0.0;
    if( quote && quote.include_taxes === true ){
        taxes = total * taxRate;
    }

    const totalDiscount = calculatDiscount(quote);
    const grandTotal = total + taxes - totalDiscount;

    

    return parseToFloatAmount(grandTotal);
}



export const setDiscountTypes = (discountTypes) => {
    console.debug('[WorkOrderService][setDiscountTypes] discountTypes: ', discountTypes);
    if( discountTypes != null  ){
        localStorage.setItem(constants.storage.discountTypes, JSON.stringify(discountTypes));
    }
}

export const getDiscountTypes = () => {
    const discountTypesStr = localStorage.getItem(constants.storage.discountTypes);
    if( discountTypesStr != null ){
        const discountTypes = JSON.parse(discountTypesStr);
        console.debug('[WorkOrderService][getDiscountTypes] discountTypes: ', discountTypes);
        return discountTypes;
    }
    return null;
}


export const getAllDiscountTypes = async () => {
    const discountTypes = getDiscountTypes();

    if( discountTypes != null ){
        return discountTypes;
    } else {
        var response = await axios.get( constants.api.quoteApiUrl + '/discount_types', {
            headers: constants.api.defaultHeaders 
        } )
        .catch( error => {
            console.error('[ERROR] error: ', error);
        });
        const discountTypesArray = getDataRespose(response);
        setDiscountTypes(discountTypesArray);
        return discountTypesArray;
    }

}