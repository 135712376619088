import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote } from "@mui/icons-material";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createTeam, getAllActiveTeams, getTeamById, logicalDeleteTeam, updateTeam } from "../../../service/tenant/SecurityService";
import InputComponent from "../util/InputComponent";
import { createEmptyTeam, validateTeam } from "../../../service/tenant/ValidationService";



function Teams(props){

    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageDialog, setErrorMessageDialog] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openTeamDetails, setOpenTeamDetails] = useState(false);
    const [activeTeams, setActiveTeams] = useState([]);
    const [team, setTeam] = useState([]);
    

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {

        setIsLoading(true);
        getAllActiveTeams( tenantId ).then( activeTeamsResponse => {
            if( activeTeamsResponse !== null ){
                setActiveTeams(activeTeamsResponse);
            }
            setIsLoading(false);
        });



    }, []);


    const handleEditTeam = (teamAux) => {
        console.debug('[Teams][handleEditTeam] team: ', teamAux);

        setIsLoading(true);

        getTeamById( tenantId, teamAux.id ).then( teamResponse => {

            if( teamResponse != null ){
                setTeam(teamResponse);
                setOpenTeamDetails(true);
                setIsLoading(false);
            }

        });
        
        
    }

    const handleNewTeam = () => {
        console.debug('[Teams][handleNewTeam] ');
        const teamAux = createEmptyTeam();
        setTeam(teamAux);
        setOpenTeamDetails(true);
    }

    const handleDeleteTeam = (teamAux) => {
        console.debug('[Teams][handleDeleteTeam] project: ', teamAux);
        setOpenDeleteAlert(true);
        setTeam(teamAux);
    }

    const handleConfirmDeleteTeam = () => {
        console.debug('[Teams][handleConfirmDeleteTeam] ');
        
        const tenantId = tenant.id;

        setSuccessMessage('');
        setErrorMessage('');

        setIsLoading(true);

        logicalDeleteTeam(team, tenantId).then( teamDeleteResponse => {

            if( teamDeleteResponse !== null ){

                getAllActiveTeams( tenantId ).then( activeTeamsResponse => {
                    if( activeTeamsResponse !== null ){
                        setActiveTeams(activeTeamsResponse);
                    }
                    setIsLoading(false);
                });

                setSuccessMessage(constants.errors.createTeamSuccess);
            } else {
                setErrorMessage(constants.errors.createTeamError);
                setIsLoading(false);
            }

        });

        setOpenDeleteAlert(false);
        
    }

    const handleCloseDeleteDialog = () => {
        setTeam(null);
        setOpenDeleteAlert(false);
    }

    const handleCloseTeamDetails = () => {
        setTeam(null);
        setOpenTeamDetails(false);
    }

    const handleSaveTeam = () => {
        
        console.debug('[Teams][handleSaveTeam] team: ', team);

        setSuccessMessage('');
        setErrorMessage('');
        setErrorMessageDialog('');

        let isValidTeam = validateTeam(team);

        console.debug('[Teams][handleSaveTeam] isValidTeam: ', isValidTeam);

        if( isValidTeam && team.id && team.id !== '' ){
            handleUpdateTeam();
            setOpenTeamDetails(false);
            setTeam(null);
        } else if( isValidTeam && !team.id ){
            handleCreateTeam();
            setOpenTeamDetails(false);
            setTeam(null);
        } else {
            setErrorMessageDialog(constants.errors.validationErrorRequiredFields);
        }

        
    }


    const handleUpdateTeam = () => {

        setIsLoading(true);

        updateTeam(team, tenantId).then( teamResponse => {

            if( teamResponse !== null ){
                setSuccessMessage(constants.errors.editTeamSuccess);

                getAllActiveTeams( tenantId ).then( activeTeamsResponse => {
                    if( activeTeamsResponse !== null ){
                        setActiveTeams(activeTeamsResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.editTeamError);
                setIsLoading(false);
            }
            
        });
    }


    const handleCreateTeam = () => {

        setIsLoading(true);

        createTeam(team, tenantId).then( teamResponse => {

            if( teamResponse !== null ){
                setSuccessMessage(constants.errors.createTeamSuccess);

                getAllActiveTeams( tenantId ).then( activeTeamsResponse => {
                    if( activeTeamsResponse !== null ){
                        setActiveTeams(activeTeamsResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.createTeamError);
                setIsLoading(false);
            }
            
        });
    }


    const handleChangeTeamAttribute = (attributeName, attributeValue) => {
        if( team ){
            const teamAux = { ...team };
            teamAux[attributeName] = attributeValue;
            setTeam(teamAux);
        }
    }

    

    return (
        <PrincipalLayout section={constants.menu.admin_admin_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Teams &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Short name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Users</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Color</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {activeTeams.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.short_name}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                { row.user_list && row.user_list.map( user => (<>{user.first_name + ' ' + user.last_name}<br/></>) ) }
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                <TextField 
                                                    key={'color_' + row.id}
                                                    value={row.color}
                                                    type="color"
                                                    disabled={true}
                                                    size="small"
                                                    sx={constants.styles.textFieldSmall}
                                                />
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit project"
                                                    onClick={() => handleEditTeam(row)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete project"
                                                    onClick={() => handleDeleteTeam(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewTeam}
                                > 
                                        New Team 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    key="deleteTeamDialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this team?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            Team name: <strong>{ team ? team.name : ''}</strong>
                            <br/>If you delete this team will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteTeam} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openTeamDetails}
                    onClose={handleCloseTeamDetails}
                    key="teamDetails"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                    
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Team: {team ? team.name : ''}</strong>
                    </DialogTitle>
                    <DialogContent>
                            <Grid container >
                                <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                                    <Grid container direction="row" spacing={0}
                                        justifyContent={'left'}
                                    >
                                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                            <ErrorMessageChip message={errorMessageDialog} />
                                        </Grid>

                                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                            &nbsp;
                                        </Grid>

                                        <InputComponent 
                                            fieldName={"Name"} 
                                            fieldValue={ team ? team.name : '' } 
                                            fieldId={'input-team-name'} 
                                            attributeName={'name'} 
                                            handleChangeField={handleChangeTeamAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />
                                        <InputComponent 
                                            fieldName={"Short name"} 
                                            fieldValue={ team ? team.short_name : '' } 
                                            fieldId={'input-team-short_name'} 
                                            attributeName={'short_name'} 
                                            handleChangeField={handleChangeTeamAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />
                                        
                                        <InputComponent 
                                            fieldName={"Color"} 
                                            fieldValue={ team ? team.color : '' } 
                                            fieldId={'input-team-color'} 
                                            attributeName={'color'} 
                                            handleChangeField={handleChangeTeamAttribute}
                                            required={true} 
                                            colorValidation={true}
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}


                                        />


                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseTeamDetails}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleSaveTeam} 
                            autoFocus
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default Teams;