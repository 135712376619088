import { type } from "@testing-library/user-event/dist/type";
import {discountTypes, objectTypes, status} from "./StatusService";
import { depositTypes } from "./InvoiceService";

const propertyTypes = {
    string_type: "String",
    number_type: "Number",
    object_type: "Object",
    array_type: "Array",
    date_type: "Date",
    timestamp_type: "Timestamp",
    boolean_type: "Boolean",
}

const statusPropertyName = "status";
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const serviceValidations = {

    client: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.clientStatus.active,
        },
        birth_date: {
            required: false,
            type: propertyTypes.date_type
        },
        company_name: {
            required: false,
            type: propertyTypes.string_type
        },
        job_notifications_on: {
            required: false,
            type: propertyTypes.boolean_type
        },
        quote_notifications_on: {
            required: false,
            type: propertyTypes.boolean_type
        },
        invoice_notifications_on: {
            required: false,
            type: propertyTypes.boolean_type
        },
        client_address: {
            required: true,
            type: propertyTypes.object_type,
            properties: {
                street: {
                    required: true,
                    type: propertyTypes.string_type
                },
                number: {
                    required: true,
                    type: propertyTypes.string_type
                },
                interior: {
                    required: false,
                    type: propertyTypes.string_type
                },
                locality: {
                    required: true,
                    type: propertyTypes.string_type
                },
                city: {
                    required: true,
                    type: propertyTypes.string_type
                },
                country: {
                    required: true,
                    type: propertyTypes.string_type
                },
                postal_code: {
                    required: true,
                    type: propertyTypes.number_type
                },
                is_bill_address: {
                    required: false,
                    type: propertyTypes.boolean_type
                },
                latitude: {
                    required: false,
                    type: propertyTypes.number_type
                },
                longitude: {
                    required: false,
                    type: propertyTypes.number_type
                },
            }
        },
        user: {
            required: true,
            type: propertyTypes.object_type,
            properties: {
                status: {
                    required: true,
                    type: propertyTypes.number_type,
                    defaultValue: status.userStatus.active,
                },
                uuid: {
                    required: true,
                    type: propertyTypes.string_type
                },
                title: {
                    required: true,
                    type: propertyTypes.string_type
                },
                first_name: {
                    required: true,
                    type: propertyTypes.string_type
                },
                last_name: {
                    required: true,
                    type: propertyTypes.string_type
                },
                phone: {
                    required: true,
                    type: propertyTypes.string_type
                },
                email: {
                    required: true,
                    type: propertyTypes.string_type
                },
                password_hash: {
                    required: true,
                    type: propertyTypes.string_type
                },
            }
        },
        client_attribute_list: {
            required: true,
            type: propertyTypes.array_type,
            properties: {
                vale_str: {
                    required: true,
                    type: propertyTypes.string_type
                },
            }
        },
        client_image_list:{
            required: false,
            type: propertyTypes.array_type,
        },
    },

    project: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.projectStatus.active,
        },
        name: {
            required: true,
            type: propertyTypes.string_type,
        },
        start_date: {
            required: false,
            type: propertyTypes.date_type,
        },
        property_details: {
            required: true,
            type: propertyTypes.string_type,
        },
        project_address: {
            required: true,
            type: propertyTypes.object_type,
            properties: {
                street: {
                    required: true,
                    type: propertyTypes.string_type
                },
                number: {
                    required: true,
                    type: propertyTypes.string_type
                },
                interior: {
                    required: false,
                    type: propertyTypes.string_type
                },
                locality: {
                    required: true,
                    type: propertyTypes.string_type
                },
                city: {
                    required: true,
                    type: propertyTypes.string_type
                },
                country: {
                    required: true,
                    type: propertyTypes.string_type
                },
                postal_code: {
                    required: true,
                    type: propertyTypes.number_type
                },
                is_bill_address: {
                    required: false,
                    type: propertyTypes.boolean_type
                },
                latitude: {
                    required: false,
                    type: propertyTypes.number_type
                },
                longitude: {
                    required: false,
                    type: propertyTypes.number_type
                },
            }
        },
    },

    request: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.requestStatus.draft.status,
        },
        title: {
            required: true,
            type: propertyTypes.string_type,
        },
        details: {
            required: true,
            type: propertyTypes.string_type,
        },
        assess_notes: {
            required: false,
            type: propertyTypes.string_type,
        },
        due_date: {
            required: false,
            type: propertyTypes.date_type,
        },
        start_datetime_assess: {
            required: false,
            type: propertyTypes.timestamp_type,
        },
        end_datetime_assess: {
            required: false,
            type: propertyTypes.timestamp_type,
        },
        requires_assess: {
            required: false,
            type: propertyTypes.boolean_type
        },
    },

    quote: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.quoteStatus.draft.status,
        },
        title: {
            required: true,
            type: propertyTypes.string_type,
        },
        include_taxes:  {
            required: false,
            type: propertyTypes.boolean_type,
            defaultValue: true,
        },
        notes: {
            required: true,
            type: propertyTypes.string_type,
        },
        predefined_message: {
            required: false,
            type: propertyTypes.boolean_type,
        },
        discount: {
            required: false,
            type: propertyTypes.number_type,
            defaultValue: 0.0,
        },
        discount_type: {
            required: false,
            type: propertyTypes.string_type,
            defaultValue: discountTypes.client_discount.type,
        },
        rate: {
            required: false,
            type: propertyTypes.number_type,
            defaultValue: 0,
        },
        quote_item_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        quote_image_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        quote_staff_image_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
    },

    itemImage: {
        url: {
            required: true,
            type: propertyTypes.string_type,
        },
        name: {
            required: true,
            type: propertyTypes.string_type,
        }
    },

    quoteItem: {
        quantity: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: 0,
        },
        unit_price: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: 0.0,
        },
        name: {
            required: false,
            type: propertyTypes.string_type,
        },
        details: {
            required: false,
            type: propertyTypes.string_type,
        },
    },

    job: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.jobStatus.new.status,
        },
        title: {
            required: true,
            type: propertyTypes.string_type,
        },
        instructions: {
            required: true,
            type: propertyTypes.string_type,
        },
        start_date: {
            required: false,
            type: propertyTypes.date_type,
        },
        end_date: {
            required: false,
            type: propertyTypes.date_type,
        },
        schedule_type: {
            required: false,
            type: propertyTypes.number_type,
        },
        billing_type: {
            required: false,
            type: propertyTypes.number_type,
        },
        line_item_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        job_expense_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        }
    },
    lineItem: {
        quantity: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: 0,
        },
        unit_price: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: 0.0,
        },
        name: {
            required: false,
            type: propertyTypes.string_type,
        },
        details: {
            required: false,
            type: propertyTypes.string_type,
        },
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.lineItemStatus.pendingApproval.status,
        },
    },
    workOrder: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.workOrderStatus.new.status,
        },
        title: {
            required: true,
            type: propertyTypes.string_type,
        },
        start_date: {
            required: false,
            type: propertyTypes.date_type,
        },
        end_date: {
            required: false,
            type: propertyTypes.date_type,
        },
        reminders_client: {
            required: true,
            type: propertyTypes.boolean_type,
        },
        reminder_type: {
            required: false,
            type: propertyTypes.number_type,
        },
        reminder: {
            required: false,
            type: propertyTypes.string_type,
        },
        client_notes: {
            required: false,
            type: propertyTypes.string_type,
        },
        staff_notes: {
            required: false,
            type: propertyTypes.string_type,
        },
        discount: {
            required: false,
            type: propertyTypes.number_type,
            defaultValue: 0.0,
        },
        discount_type: {
            required: false,
            type: propertyTypes.string_type,
            defaultValue: discountTypes.client_discount.type,
        },
		include_taxes:  {
            required: false,
            type: propertyTypes.boolean_type,
            defaultValue: true,
        },
        work_order_item_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        work_order_image_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        work_order_staff_image_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
    },
    workOrderItem: {
        start_datetime: {
            required: false,
            type: propertyTypes.timestamp_type,
        },
        end_datetime: {
            required: true,
            type: propertyTypes.timestamp_type,
        },
    },


    invoice: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.invoiceStatus.draft.status,
        },
        invoice_number: {
            required: false,
            type: propertyTypes.string_type,
        },
        due_date: {
            required: true,
            type: propertyTypes.date_type,
        },
        issue_date: {
            required: true,
            type: propertyTypes.date_type,
        },
        date_sent: {
            required: true,
            type: propertyTypes.boolean_type,
        },
        reminders_client: {
            required: true,
            type: propertyTypes.boolean_type,
        },
        reminder_type: {
            required: false,
            type: propertyTypes.number_type,
        },
        use_project_address: {
            required: false,
            type: propertyTypes.boolean_type,
        },
        include_taxes:  {
            required: false,
            type: propertyTypes.boolean_type,
            defaultValue: true,
        },
        
        notes: {
            required: false,
            type: propertyTypes.string_type,
        },
        predefined_message: {
            required: true,
            type: propertyTypes.boolean_type,
        },
        discount: {
            required: false,
            type: propertyTypes.number_type,
        },
        discount_type: {
            required: false,
            type: propertyTypes.number_type,
            defaultValue: discountTypes.client_discount.type,
        },
        payment_method: {
            required: false,
            type: propertyTypes.number_type,
        },
        invoice_image_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        invoice_deposit_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        invoice_item_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        }
    },
    invoiceItem: {
        quantity: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: 0,
        },
        unit_price: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: 0.0,
        },
        name: {
            required: false,
            type: propertyTypes.string_type,
        },
        details: {
            required: false,
            type: propertyTypes.string_type,
        },
    },

    invoiceDeposit: {
        deposit_date: {
            required: true,
            type: propertyTypes.date_type,
        },
        deposit_type: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: depositTypes.deposit,
        },
        amount: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: 0.0,
        },
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.invoiceDeposit.pendingApproval.status,
        },
    },

    user: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.userStatus.active,
        },
        uuid: {
            required: true,
            type: propertyTypes.string_type
        },
        title: {
            required: true,
            type: propertyTypes.string_type
        },
        first_name: {
            required: true,
            type: propertyTypes.string_type
        },
        last_name: {
            required: true,
            type: propertyTypes.string_type
        },
        phone: {
            required: true,
            type: propertyTypes.string_type
        },
        email: {
            required: true,
            type: propertyTypes.string_type
        },
        password_hash: {
            required: true,
            type: propertyTypes.string_type
        },
        team_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        role_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        }
    },
    team: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.teamStatus.active,
        },
        name: {
            required: true,
            type: propertyTypes.string_type
        },
        short_name: {
            required: true,
            type: propertyTypes.string_type
        },
        color: {
            required: true,
            type: propertyTypes.string_type
        },
    },
    serviceType: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.serviceTypeStatus.active,
        },
        name: {
            required: true,
            type: propertyTypes.string_type
        },
        type: {
            required: true,
            type: propertyTypes.string_type
        },
    },
    item: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.itemStatus.active.status,
        },
        name: {
            required: true,
            type: propertyTypes.string_type
        },
        sku: {
            required: true,
            type: propertyTypes.string_type
        },
        description: {
            required: true,
            type: propertyTypes.string_type
        },
        barcode: {
            required: false,
            type: propertyTypes.string_type
        },
        price_unit_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        category_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        image_list: {
            required: false,
            type: propertyTypes.array_type,
            defaultValue: []
        },
        
    },
    price_unit: {
        price: {
            required: true,
            type: propertyTypes.number_type,
        },
        unit: {
            required: true,
            type: propertyTypes.object_type,
            properties: {
                id: {
                    required: true,
                    type: propertyTypes.number_type,
                    defaultValue: 2,
                },
                name: {
                    required: true,
                    type: propertyTypes.string_type,
                    defaultValue: 'UNIT',
                },
                description: {
                    required: true,
                    type: propertyTypes.string_type,
                    defaultValue: 'Piece',
                },
            }
        },
    },

    notification: {
        status: {
            required: true,
            type: propertyTypes.number_type,
            defaultValue: status.notification.active.status,
        },
        event_type: {
            required: true,
            type: propertyTypes.number_type
        },
        event_date_time: {
            required: true,
            type: propertyTypes.timestamp_type
        },
        message: {
            required: true,
            type: propertyTypes.string_type
        },
        details: {
            required: false,
            type: propertyTypes.string_type
        },
        object_id: {
            required: false,
            type: propertyTypes.number_type
        },
        object_type: {
            required: false,
            type: propertyTypes.number_type
        },
        object_status: {
            required: false,
            type: propertyTypes.number_type
        },
        object_title: {
            required: false,
            type: propertyTypes.string_type
        },
        is_broadcast: {
            required: true,
            type: propertyTypes.boolean_type
        },
        role: {
            required: false,
            type: propertyTypes.object_type,
            properties: {
                name: {
                    required: false,
                    type: propertyTypes.string_type
                },
                code: {
                    required: true,
                    type: propertyTypes.string_type
                },
            }
        },
        from_user: {
            required: false,
            type: propertyTypes.object_type,
            properties: {
                id: {
                    required: true,
                    type: propertyTypes.number_type,
                    defaultValue: null,
                },
                uuid: {
                    required: true,
                    type: propertyTypes.string_type
                },
            }
        },
        to_user: {
            required: false,
            type: propertyTypes.object_type,
            properties: {
                id: {
                    required: true,
                    type: propertyTypes.number_type,
                    defaultValue: null,
                },
                uuid: {
                    required: true,
                    type: propertyTypes.string_type
                },
            }
        },
    }
}


export const validateNotification = (notification) => {
    console.debug('[ValidationService][validateNotification] ...');
    const objectProperties = serviceValidations.notification;
    let isValid = validateEntity(objectProperties, notification);
    console.debug('[ValidationService][validateNotification] isValid: ', isValid);
    return isValid;

}

export const createEmptyNotification = (userId) => {
    console.debug('[ValidationService][createEmptyNotification] ...');
    const objectProperties = serviceValidations.notification;
    let newNotification = createEmptyObject(userId, objectProperties);
    newNotification.event_date_time = new Date();
    newNotification.is_broadcast = false;
    newNotification.from_user.id = userId;
    return newNotification;
}

export const createEmptyNotificationForRequest = (userId, request) => {
    console.debug('[ValidationService][createEmptyNotificationForRequest] ...');
    let newNotification = createEmptyNotification(userId);
    newNotification.object_id = request.id;
    newNotification.object_type = objectTypes.request.type;
    newNotification.object_status = request.status;
    newNotification.object_title = request.title;
    return newNotification;
}

export const createEmptyNotificationForQuote = (userId, quote) => {
    console.debug('[ValidationService][createEmptyNotificationForQuote] ...');
    let newNotification = createEmptyNotification(userId);
    newNotification.object_id = quote.id;
    newNotification.object_type = objectTypes.quote.type;
    newNotification.object_status = quote.status;
    newNotification.object_title = quote.title;
    return newNotification;
}

export const createEmptyNotificationForWorkOrder = (userId, workOrder) => {
    console.debug('[ValidationService][createEmptyNotificationForWorkOrder] ...');
    let newNotification = createEmptyNotification(userId);
    newNotification.object_id = workOrder.id;
    newNotification.object_type = objectTypes.work_order.type;
    newNotification.object_status = workOrder.status;
    newNotification.object_title = workOrder.title;
    return newNotification;
}

export const createEmptyNotificationForInvoice = (userId, invoice) => {
    console.debug('[ValidationService][createEmptyNotificationForInvoice] ...');
    let newNotification = createEmptyNotification(userId);
    newNotification.object_id = invoice.id;
    newNotification.object_type = objectTypes.invoice.type;
    newNotification.object_status = invoice.status;
    newNotification.object_title = invoice.invoice_number;
    return newNotification;
}



export const validateInvoice = (invoice) => {
    console.debug('[ValidationService][validateInvoice] ...');
    const objectProperties = serviceValidations.invoice;
    let isValid = validateEntity(objectProperties, invoice);

    if( invoice.invoice_item_list ){
        invoice.invoice_item_list.forEach( invoiceItem => {
            if( !validateInvoiceItem(invoiceItem) ){
                isValid = false;
            }
        });
    }

    console.debug('[ValidationService][validateInvoice] isValid: ', isValid);
    return isValid;

}

export const createEmptyInvoice = (userId) => {
    console.debug('[ValidationService][createEmptyInvoice] ...');
    const objectProperties = serviceValidations.invoice;
    let newInvoice = createEmptyObject(userId, objectProperties);
    return newInvoice;
}

export const validateInvoiceItem = (invoiceItem) => {
    console.debug('[ValidationService][validateInvoiceItem] ...');
    const objectProperties = serviceValidations.invoiceItem;
    let isValid = validateEntity(objectProperties, invoiceItem);
    console.debug('[ValidationService][validateInvoiceItem] isValid: ', isValid);
    return isValid;

}

export const createEmptyInvoiceItem = (userId) => {
    console.debug('[ValidationService][createEmptyInvoiceItem] ...');
    const objectProperties = serviceValidations.invoiceItem;
    let newInvoiceItem = createEmptyObject(userId, objectProperties);
    return newInvoiceItem;
}


export const validateInvoiceDeposit = (invoiceDeposit) => {
    console.debug('[ValidationService][validateInvoiceDeposit] ...');
    const objectProperties = serviceValidations.invoiceDeposit;
    let isValid = validateEntity(objectProperties, invoiceDeposit);
    console.debug('[ValidationService][validateInvoiceDeposit] isValid: ', isValid);
    return isValid;
}

export const createEmptyInvoiceDeposit = (userId) => {
    console.debug('[ValidationService][createEmptyInvoiceDeposit] ...');
    const objectProperties = serviceValidations.invoiceDeposit;
    let newInvoiceDeposit = createEmptyObject(userId, objectProperties);
    return newInvoiceDeposit;
}


export const validatePriceUnit = (price_unit) => {
    console.debug('[ValidationService][validatePriceUnit] ...');
    const objectProperties = serviceValidations.price_unit;
    let isValid = validateEntity(objectProperties, price_unit);
    console.debug('[ValidationService][validatePriceUnit] isValid: ', isValid);
    return isValid;

}


export const createEmptyPriceUnit = (userId) => {
    console.debug('[ValidationService][createEmptyPriceUnit] ...');
    const objectProperties = serviceValidations.price_unit;
    const newPriceUnit = createEmptyObject(userId, objectProperties);
    return newPriceUnit;
}


export const validateItem = (item) => {
    console.debug('[ValidationService][validateItem] ...');
    const objectProperties = serviceValidations.item;
    let isValid = validateEntity(objectProperties, item);
    console.debug('[ValidationService][validateItem] isValid: ', isValid);
    return isValid;

}


export const createEmptyItem = (userId) => {
    console.debug('[ValidationService][createEmptyItem] ...');
    const objectProperties = serviceValidations.item;
    let newItem = createEmptyObject(userId, objectProperties);
    const priceUnit = createEmptyPriceUnit(userId);
    newItem.price_unit_list.push(priceUnit);
    return newItem;
}


export const validateServiceType = (serviceType) => {
    console.debug('[ValidationService][validateServiceType] ...');

    const objectProperties = serviceValidations.serviceType;
    let isValid = validateEntity(objectProperties, serviceType);

    console.debug('[ValidationService][validateServiceType] isValid: ', isValid);
    return isValid;

}


export const createEmptyServiceType = (userId) => {
    console.debug('[ValidationService][createEmptyServiceType] ...');

    const objectProperties = serviceValidations.serviceType;
    
    const newServiceType = createEmptyObject(userId, objectProperties);
    
    return newServiceType;
}


export const validateTeam = (team) => {
    console.debug('[ValidationService][validateTeam] ...');

    const objectProperties = serviceValidations.team;
    let isValid = validateEntity(objectProperties, team);

    console.debug('[ValidationService][validateTeam] isValid: ', isValid);
    return isValid;

}


export const createEmptyTeam = (userId) => {
    console.debug('[ValidationService][createEmptyTeam] ...');

    const objectProperties = serviceValidations.team;
    
    const newTeam = createEmptyObject(userId, objectProperties);
    
    return newTeam;
}


export const validateUser = (user) => {
    console.debug('[ValidationService][validateUser] ...');

    const objectProperties = serviceValidations.user;
    let isValid = validateEntity(objectProperties, user);

    console.debug('[ValidationService][validateUser] isValid: ', isValid);
    return isValid;

}


export const createEmptyUser = (userId) => {
    console.debug('[ValidationService][createEmptyUser] ...');

    const objectProperties = serviceValidations.user;
    
    const newUser = createEmptyObject(userId, objectProperties);
    
    return newUser;
}


export const validateItemImage = (itemImage) => {
    console.debug('[ValidationService][validateItemImage] ...');

    const objectProperties = serviceValidations.itemImage;
    let isValid = validateEntity(objectProperties, itemImage);

    console.debug('[ValidationService][validateItemImage] isValid: ', isValid);
    return isValid;

}


export const createEmptyItemImage = (userId) => {
    console.debug('[ValidationService][createEmptyItemImage] ...');

    const objectProperties = serviceValidations.itemImage;
    
    const newItemImage = createEmptyObject(userId, objectProperties);
    
    return newItemImage;
}

export const validateLineItem = (lineItem) => {
    console.debug('[ValidationService][validateLineItem] ...');

    const objectProperties = serviceValidations.lineItem;
    let isValid = validateEntity(objectProperties, lineItem);

    console.debug('[ValidationService][validateLineItem] isValid: ', isValid);
    return isValid;

}


export const createEmptyLineItem = (userId) => {
    console.debug('[ValidationService][createEmptyLineItem] ...');

    const objectProperties = serviceValidations.lineItem;
    
    const newLineItem = createEmptyObject(userId, objectProperties);
    
    return newLineItem;
}


export const createEmptyWorkOrderItem = (userId) => {
    console.debug('[ValidationService][createEmptyWorkOrderItem] ...');

    const objectProperties = serviceValidations.workOrderItem;
    
    const newWorkOrderItem = createEmptyObject(userId, objectProperties);
    
    return newWorkOrderItem;
}


export const validateQuoteItem = (quoteItem) => {
    console.debug('[ValidationService][validateQuoteItem] ...');

    const objectProperties = serviceValidations.quoteItem;
    let isValid = validateEntity(objectProperties, quoteItem);

    console.debug('[ValidationService][validateQuoteItem] isValid: ', isValid);
    return isValid;

}


export const createEmptyQuoteItem = (userId) => {
    console.debug('[ValidationService][createEmptyQuoteItem] ...');

    const objectProperties = serviceValidations.quoteItem;
    
    const newQuote = createEmptyObject(userId, objectProperties);
    
    return newQuote;
}

export const validateClient = (client) => {
    console.debug('[ValidationService][validateClient] ...');

    const objectProperties = serviceValidations.client;
    let isValid = validateEntity(objectProperties, client);

    console.debug('[ValidationService][validateClient] isValid: ', isValid);
    return isValid;

}


export const createEmptyClient = (userId) => {
    console.debug('[ValidationService][createEmptyClient] ...');

    const objectProperties = serviceValidations.client;
    
    const newClient = createEmptyObject(userId, objectProperties);
    
    return newClient;
}

export const validateProject = (project) => {
    console.debug('[ValidationService][validateProject] ...');

    const objectProperties = serviceValidations.project;
    let isValid = validateEntity(objectProperties, project);

    console.debug('[ValidationService][validateProject] isValid: ', isValid);
    return isValid;

}


export const createEmptyProject = (userId) => {
    console.debug('[ValidationService][createEmptyProject] ...');

    const objectProperties = serviceValidations.project;
    
    const newProject = createEmptyObject(userId, objectProperties);
    
    return newProject;
}


export const validateRequest = (request) => {
    console.debug('[ValidationService][validateRequest] ...');

    const objectProperties = serviceValidations.request;
    let isValid = validateEntity(objectProperties, request);

    console.debug('[ValidationService][validateRequest] isValid: ', isValid);
    return isValid;

}


export const createEmptyRequest = (userId) => {
    console.debug('[ValidationService][createEmptyRequest] ...');

    const objectProperties = serviceValidations.request;
    
    const newRequest = createEmptyObject(userId, objectProperties);
    
    return newRequest;
}

export const validateQuote = (quote) => {
    console.debug('[ValidationService][validateQuote] ...');

    const objectProperties = serviceValidations.quote;
    let isValid = validateEntity(objectProperties, quote);

    if( quote.quote_item_list ){
        quote.quote_item_list.forEach( quoteItem => {
            if( !validateQuoteItem(quoteItem) ){
                isValid = false;
            }
        });
    }

    console.debug('[ValidationService][validateQuote] isValid: ', isValid);
    return isValid;

}


export const createEmptyQuote = (userId) => {
    console.debug('[ValidationService][createEmptyQuote] ...');

    const objectProperties = serviceValidations.quote;
    
    const newQuote = createEmptyObject(userId, objectProperties);
    
    return newQuote;
}


export const validateJob = (job) => {
    console.debug('[ValidationService][validateJob] ...');

    const objectProperties = serviceValidations.job;
    let isValid = validateEntity(objectProperties, job);

    if( job.line_item_list ){
        job.line_item_list.forEach( lineItem => {
            if( !validateLineItem(lineItem) ){
                isValid = false;
            }
        });
    }

    console.debug('[ValidationService][validateJob] isValid: ', isValid);
    return isValid;

}


export const createEmptyJob = (userId) => {
    console.debug('[ValidationService][createEmptyJob] ...');
    const objectProperties = serviceValidations.job;
    
    const newJob = createEmptyObject(userId, objectProperties);

    return newJob;
}


export const validateWorkOrder = (workOrder) => {
    console.debug('[ValidationService][validateWorkOrder] ...');

    const objectProperties = serviceValidations.workOrder;
    let isValid = validateEntity(objectProperties, workOrder);

    console.debug('[ValidationService][validateWorkOrder] isValid: ', isValid);
    return isValid;

}


export const createEmptyWorkOrder = (userId) => {
    console.debug('[ValidationService][createEmptyWorkOrder] ...');
    const objectProperties = serviceValidations.workOrder;
    
    const newWorkOrder = createEmptyObject(userId, objectProperties);

    return newWorkOrder;
}



export const validateEmail = (email) => {
    return (  email != null && email.match(emailRegex) );
}


const createEmptyObject = (userId, objectProperties) => {

    let newObject = {};
    const propertiesDefinition = Object.getOwnPropertyNames(objectProperties);

    propertiesDefinition.forEach( (propertyName) => {

        createEmptyProperty(objectProperties, propertyName, newObject);

        if( objectProperties[propertyName].type === propertyTypes.object_type ){

            const childrenProperties = objectProperties[propertyName].properties;
            const childrenPropertiesDefinition = Object.getOwnPropertyNames(childrenProperties);

            let newChildObject = {};

            childrenPropertiesDefinition.forEach( (childPropertyName) => {
                createEmptyProperty(childrenProperties, childPropertyName, newChildObject);
            });

            setAuditValues( userId, newChildObject);
            newObject[propertyName] = newChildObject;

        } else if( objectProperties[propertyName].type === propertyTypes.array_type ){
            newObject[propertyName] = [];
        }

    });

    setAuditValues( userId, newObject);

    return newObject;

}

const validateEntity = (objectProperties, object) => {
    let isValid = true;

    const propertiesDefinition = Object.getOwnPropertyNames(objectProperties);

    propertiesDefinition.forEach( (property) => {

        if( objectProperties[property].required ){
            

            if( !validatePropertyValue( objectProperties, property, object )){
                isValid = false;
            }


            if( objectProperties[property].type === propertyTypes.object_type ){

                const childrenProperties = objectProperties[property].properties;
                const childrenPropertiesDefinition = Object.getOwnPropertyNames(childrenProperties);

                childrenPropertiesDefinition.forEach( (childProperty) => {

                    if( childrenProperties[childProperty].required ){

                        if( !validatePropertyValue( childrenProperties, childProperty, object[property] )){
                            isValid = false;
                        }

                    }

                });

            }

            if( objectProperties[property].type === propertyTypes.array_type ){

                const childrenProperties = objectProperties[property].properties;
                const childrenPropertiesDefinition = Object.getOwnPropertyNames(childrenProperties);

                childrenPropertiesDefinition.forEach( (childProperty) => {

                    if( childrenProperties[childProperty].required ){

                        const childrenObjectArray = object[property];

                        childrenObjectArray.forEach( (childrenObject) => {

                            if( !validatePropertyValue( childrenProperties, childProperty, childrenObject )){
                                isValid = false;
                            }
                        });
                    }

                });

            }

        }
    });

    return isValid;
}

const validatePropertyValue = ( objectProperties, propertyName, object ) => {

    let isValid = true;
    const propertyValue = object[propertyName];

    if( objectProperties[propertyName].type === propertyTypes.string_type ){
        if( !propertyValue || propertyValue === '' ){
            isValid = false;
        }
    } else if( objectProperties[propertyName].type === propertyTypes.number_type ){
        if( propertyValue === 0 ){
            isValid = true;
        } else if( !propertyValue || propertyValue == null ){
            isValid = false;
        }
    }

    
    /**
    console.debug('[ValidationService][validatePropertyValue] propertyName: ', propertyName);
    console.debug('[ValidationService][validatePropertyValue] propertyValue: ', propertyValue);
    console.debug('[ValidationService][validatePropertyValue] isValid: ', isValid);

    if( !isValid ){
        console.debug('###################################');
    }
     */
    

    return isValid;

}

const createEmptyProperty = ( objectProperties, propertyName, object ) => {

    if( objectProperties[propertyName].defaultValue ){
        object[propertyName] = objectProperties[propertyName].defaultValue;
    } else if( objectProperties[propertyName].type === propertyTypes.string_type ){
        object[propertyName] = '';
    } else if( objectProperties[propertyName].type === propertyTypes.number_type ){
        object[propertyName] = 0;
    } else if( objectProperties[propertyName].type === propertyTypes.boolean_type ){
        object[propertyName] = false;
    } else if( objectProperties[propertyName].type === propertyTypes.date_type ){
        object[propertyName] = getDateISOString();
    } else if( objectProperties[propertyName].type === propertyTypes.timestamp_type ){
        object[propertyName] = getDatetimeISOString();
    }
}

const setAuditValues = ( userId, object ) => {
    object['user_id'] = userId;
    object['creation_date'] = getDatetimeISOString();
}

export const getDatetimeISOString = () => {
    const nowDate = new Date();
    return nowDate.toISOString();
}

export const getDateLocalString = () => {
    const nowDate = new Date();
    return nowDate.toLocaleDateString();
}

export const getDateISOString = () => {
    const nowDate = new Date();
    return nowDate.toISOString().split('T')[0];
}

export const convertDateISOString = (nowDate) => {
    if( nowDate ){
        return nowDate.toISOString().split('T')[0];
    } 

    return '';
}

export const convertDateJsToLocalString = (newDateValue) => {
    const standarDate = new Date(newDateValue.toISOString());
    const newDateValueISOStr = "" + standarDate.getFullYear()
                        + "-" + ("0" + (standarDate.getMonth()+1)).slice(-2)
                        + "-" + ("0" + standarDate.getDate()).slice(-2)
                        + "T" + ("0" + standarDate.getHours()).slice(-2) 
                        + ":" + ("0" + standarDate.getMinutes()).slice(-2)
                        + ":" + ("0" + standarDate.getSeconds()).slice(-2);
    return newDateValueISOStr;
}

export const convertDateStrISOString = (dateStr) => {
    if( dateStr ){
        return dateStr.split('T')[0];
    } 

    return '';
}


export const convertDateStrToString = (dateStr) => {
    if( dateStr ){
        let myDate = new Date(dateStr);
        return myDate.toLocaleDateString();
    } 

    return '';
}

export const convertDateTimeStrToString = (dateStr) => {
    if( dateStr ){
        let myDate = new Date(dateStr);
        return myDate.toLocaleDateString() + '  ' + myDate.toLocaleTimeString();
    } 

    return '';
}

export const parseToFloatAmount = ( amount ) =>{
    if( amount && !isNaN(amount)){
        return parseFloat(amount).toFixed(2);
    }

    return parseFloat(0).toFixed(2);
} 

export const parseToFloat = ( amount ) =>{
    if( amount && !isNaN(amount)){
        return parseFloat(amount);
    }

    return parseFloat(0);
} 
