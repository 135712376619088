import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, PictureAsPdf } from "@mui/icons-material";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { createItem, getAllActiveItems, getItemById, getPublicItemUrl, logicalDeleteItem, updateItem } from "../../../service/tenant/ItemService";
import InputComponent from "../util/InputComponent";
import SelectComponent from "../util/SelectComponent";
import { createEmptyItem, createEmptyPriceUnit, createEmptyProject, validateItem } from "../../../service/tenant/ValidationService";
import { getCategoriesFirstLevel } from "../../../service/tenant/CategoryService";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import UploaderComponent from "../util/UploaderComponent";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ItemsPDF from "./ItemsPDF";
import ItemQRCode from "../item/ItemQRCode";


function Items(props){

    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageDialog, setErrorMessageDialog] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openItemDetails, setOpenItemDetails] = useState(false);
    const [activeItems, setActiveItems] = useState([]);
    const [qrURIs, setQrURIs] = useState([]);
    const [item, setItem] = useState([]);
    const [activeCategories, setActiveCategories] = useState([]);
    
    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {

        setIsLoading(true);
        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse !== null ){

                var qrURIsAux = [];

                activeItemsResponse.forEach( item => {
                    qrURIsAux.push(' ');
                });

                setQrURIs(qrURIsAux);
                setActiveItems(activeItemsResponse);
            }
            setIsLoading(false);
        });

        setIsLoading(true);
        getCategoriesFirstLevel( tenantId ).then( activeCategoriesResponse => {
            if( activeCategoriesResponse !== null ){
                setActiveCategories(activeCategoriesResponse);
            }
            setIsLoading(false);
        });

    }, []);


    const handleEditItem = (itemAux) => {
        console.debug('[Items][handleEditItem] item: ', itemAux);
        setErrorMessageDialog('');
        setIsLoading(true);

        getItemById( tenantId, itemAux.id ).then( itemResponse => {

            if( itemResponse != null ){
                setItem(itemResponse);
                setOpenItemDetails(true);
                setIsLoading(false);
            }

        });
        
        
    }

    const handleNewItem = () => {
        console.debug('[Items][handleNewItem] ');
        const itemAux = createEmptyItem();
        setErrorMessageDialog('');
        setItem(itemAux);
        setOpenItemDetails(true);
    }

    const handleDeleteItem = (itemAux) => {
        console.debug('[Items][handleDeleteItem] project: ', itemAux);
        setOpenDeleteAlert(true);
        setItem(itemAux);
    }

    const handleConfirmDeleteItem = () => {
        console.debug('[Items][handleConfirmDeleteItem] ');
        
        const tenantId = tenant.id;

        setSuccessMessage('');
        setErrorMessage('');

        setIsLoading(true);

        logicalDeleteItem(item, tenantId).then( itemDeleteResponse => {

            if( itemDeleteResponse !== null ){

                getAllActiveItems( tenantId ).then( activeItemsResponse => {
                    if( activeItemsResponse !== null ){
                        setActiveItems(activeItemsResponse);
                    }
                    setIsLoading(false);
                });

                setSuccessMessage(constants.errors.createItemSuccess);
            } else {
                setErrorMessage(constants.errors.createItemError);
                setIsLoading(false);
            }

        });

        setOpenDeleteAlert(false);
        
    }

    const handleCloseDeleteDialog = () => {
        setItem(null);
        setOpenDeleteAlert(false);
    }

    const handleCloseItemDetails = () => {
        setItem(null);
        setOpenItemDetails(false);
    }

    const handleSaveItemNoClose = () => {
        handleSaveItemDefault(false);
    }

    const handleSaveItem = () => {
        handleSaveItemDefault(true);
    }

    const handleSaveItemDefault = (closeDialog) => {
        
        console.debug('[Items][handleSaveItem] item: ', item);

        setSuccessMessage('');
        setErrorMessage('');
        setErrorMessageDialog('');

        let isValidItem = validateItem(item);

        console.debug('[Items][handleSaveItem] isValidItem: ', isValidItem);

        if( !item.price_unit_list || item.price_unit_list.length === 0 || item.price_unit_list[0] === null || 
            !item.price_unit_list[0].price || item.price_unit_list[0].price === null || 
            !item.category_list || item.category_list.length === 0 || item.category_list[0] === null
        ){
            setErrorMessageDialog(constants.errors.itemValidationErrorNoCategory);
        } else if( isValidItem && item.id && item.id !== '' ){
            handleUpdateItem();
            if( closeDialog ){
                setOpenItemDetails(false);
                setItem(null);
            }
        } else if( isValidItem && !item.id ){
            handleCreateItem();
            if( closeDialog ){
                setOpenItemDetails(false);
                setItem(null);
            }
        } else {
            setErrorMessageDialog(constants.errors.validationErrorRequiredFields);
        }

        
    }


    const handleUpdateItem = () => {

        setIsLoading(true);

        updateItem(item, tenantId).then( itemResponse => {

            if( itemResponse !== null ){
                setSuccessMessage(constants.errors.editItemSuccess);

                getAllActiveItems( tenantId ).then( activeItemsResponse => {
                    if( activeItemsResponse !== null ){
                        setActiveItems(activeItemsResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.editItemError);
                setIsLoading(false);
            }
            
        });
    }


    const handleCreateItem = () => {

        setIsLoading(true);

        createItem(item, tenantId).then( itemResponse => {

            if( itemResponse !== null ){
                setSuccessMessage(constants.errors.createItemSuccess);

                getAllActiveItems( tenantId ).then( activeItemsResponse => {
                    if( activeItemsResponse !== null ){
                        setActiveItems(activeItemsResponse);
                    }
                    setIsLoading(false);
                });

            } else {
                setErrorMessage(constants.errors.createItemError);
                setIsLoading(false);
            }
            
        });
    }


    const handleChangeItemAttribute = (attributeName, attributeValue) => {
        if( item ){
            const itemAux = { ...item };
            itemAux[attributeName] = attributeValue;
            setItem(itemAux);
        }
    }

    const handleChangeItemPrice = (attributeName, attributeValue) => {
        if( item ){
            let itemAux = { ...item };
            if( !itemAux.price_unit_list || itemAux.price_unit_list.length === 0 ){
                const priceUnitAux = createEmptyPriceUnit(itemAux.user_id);
                itemAux.price_unit_list.push(priceUnitAux);
            }
            itemAux.price_unit_list[0].price = attributeValue;
            setItem(itemAux);
        }
    }


    const handleChangeItemCategoryAttribute = (attributeName, attributeValue) => {
        if( item ){

            console.debug('[Items][handleChangeItemCategoryAttribute] attributeValue: ', attributeValue);

            let itemAux = { ...item };
            let categoryListAux = itemAux.category_list;

            if( !categoryListAux || categoryListAux.length == 0){
                categoryListAux.push(attributeValue);
            } else {
                categoryListAux[0] = attributeValue;
            }

            setItem(itemAux);

            console.debug('[Items][handleChangeItemCategoryAttribute] item: ', item);
        }
    }


    return (
        <PrincipalLayout section={constants.menu.admin_admin_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={11} md={11}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Items &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid item xs={1} md={1}>
                            <Grid container 
                                spacing={0}
                                alignItems={'flex-end'}
                                alignContent={'flex-end'}
                                flexDirection={'column'}
                            >
                                <Grid item xs={12} md={12}>
                                    { activeItems && activeItems.length > 0 &&
                                        (
                                            <PDFDownloadLink
                                                document={
                                                    <ItemsPDF 
                                                        itemList={activeItems} 
                                                        qrURIs={qrURIs}
                                                    />
                                                }
                                                fileName={"Item_Catalog.pdf"}
                                                
                                                >
                                                <IconButton 
                                                    variant="outlined" 
                                                    size="small" 
                                                    sx={constants.styles.buttonNormal}
                                                >
                                                    <PictureAsPdf 
                                                        sx={constants.styles.iconSize}
                                                    />
                                                </IconButton>
                                            </PDFDownloadLink>
                                        )
                                    }

                                </Grid>

                            
                            </Grid>
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >SKU</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Price</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">QR Code</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {activeItems.map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.sku}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="right">
                                                ${row.price_unit_list && (row.price_unit_list.length > 0) ? row.price_unit_list[0].price : ''}
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                        <ItemQRCode
                                                            key={index}
                                                            item={row}
                                                            index={index}
                                                            qrURIs={qrURIs}
                                                            setQrURIs={setQrURIs}
                                                        />
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit item"
                                                    onClick={() => handleEditItem(row)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete item"
                                                    onClick={() => handleDeleteItem(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewItem}
                                > 
                                        New Item 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    key="deleteItemDialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Do you confirm to delete this item?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            sx={constants.styles.textNormal}
                            id="alert-dialog-description"
                        >
                            Item name: <strong>{ item ? item.name : ''}</strong>
                            <br/>If you delete this item will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteItem} 
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openItemDetails}
                    onClose={handleCloseItemDetails}
                    key="itemDetails"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                    
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Item: {item ? item.name : ''}</strong>
                    </DialogTitle>
                    <DialogContent>
                            <Grid container >
                                <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                                    <Grid container direction="row" spacing={0}
                                        justifyContent={'left'}
                                    >
                                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                            <ErrorMessageChip message={errorMessageDialog} />
                                        </Grid>

                                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                            &nbsp;
                                        </Grid>

                                        <AutocompleteComponent
                                            fieldId={'input-item-category'}
                                            fieldName={'Category'} 
                                            fieldValue={item && item.category_list && (item.category_list.length > 0) ? item.category_list[0] : null}
                                            attributeName={'category'}
                                            optionList={activeCategories}
                                            optionAttributeLabel={'name'}
                                            isClient={false}
                                            handleChangeField={handleChangeItemCategoryAttribute}

                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}
                                        />

                                        <InputComponent 
                                            fieldName={"Name"} 
                                            fieldValue={ item ? item.name : '' } 
                                            fieldId={'input-item-name'} 
                                            attributeName={'name'} 
                                            handleChangeField={handleChangeItemAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />
                                        <InputComponent 
                                            fieldName={"SKU"} 
                                            fieldValue={ item ? item.sku : '' } 
                                            fieldId={'input-item-sku'} 
                                            attributeName={'sku'} 
                                            handleChangeField={handleChangeItemAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />

                                        <InputComponent 
                                            fieldName={"Price"} 
                                            fieldValue={ item && item.price_unit_list && (item.price_unit_list.length > 0) ? item.price_unit_list[0].price : '' } 
                                            fieldId={'input-item-price'} 
                                            attributeName={'price'} 
                                            handleChangeField={handleChangeItemPrice}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                    min: 0,
                                                }
                                            }}
                                            numberValidation={true}

                                        />
                                        

                                        <InputComponent 
                                            fieldName={"Description"} 
                                            fieldValue={ item ? item.description : '' } 
                                            fieldId={'input-item-description'} 
                                            attributeName={'description'} 
                                            handleChangeField={handleChangeItemAttribute}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                            multiline={true}
                                            rows={4}

                                        />
                                        
                                        { item && item.id &&
                                            (
                                                <Grid container direction="row" spacing={0}
                                                    justifyContent={'center'}
                                                    alignContent={'center'}
                                                >
                                                    <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                                                        <UploaderComponent
                                                            object={item}
                                                            prefix={'item'}
                                                            folder={'items'}
                                                            imageListAttributeName={'image_list'}
                                                            setObject={setItem}
                                                            saveObject={handleSaveItemNoClose}
                                                            
                                                        />
                                                    </Grid>
                                                    <Grid item 
                                                        sx={constants.styles.sectionQR} 
                                                        xs={12} 
                                                        md={12} 
                                                    >
                                                        <QRCodeSVG 
                                                            value={ getPublicItemUrl(item.id, tenantId) } 
                                                            size={constants.styles.qrCodeDefaultSize}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                        
                                    


                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseItemDetails}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleSaveItem} 
                            autoFocus
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default Items;