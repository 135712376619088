//Security Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { status } from "./StatusService";


export const security_constants = {
    privileges: {
        admin_clients_all_access: {
            name: 'admin_clients_all_access',
        },
        admin_projects_all_access: {
            name: 'admin_projects_all_access',
        },
        admin_requests_all_access: {
            name: 'admin_requests_all_access',
        },
        admin_quotes_all_access: {
            name: 'admin_quotes_all_access',
        },
        admin_jobs_all_access: {
            name: 'admin_jobs_all_access',
        },
        admin_workorders_all_access: {
            name: 'admin_workorders_all_access',
        },
        admin_invoices_all_access: {
            name: 'admin_invoices_all_access',
        },
        admin_calendar_all_access: {
            name: 'admin_calendar_all_access',
        },
        user_clients_all_access: {
            name: 'user_clients_all_access',
        },
        user_projects_all_access: {
            name: 'user_projects_all_access',
        },
        user_requests_all_access: {
            name: 'user_requests_all_access',
        },
        user_quotes_all_access: {
            name: 'user_quotes_all_access',
        },
        user_jobs_all_access: {
            name: 'user_jobs_all_access',
        },
        user_workorders_all_access:{
            name: 'user_workorders_all_access',
        },
        user_invoices_all_access: {
            name: 'user_invoices_all_access',
        },
        user_calendar_all_access: {
            name: 'user_calendar_all_access'
        },

    }
}

export const validateUserPrivileges = (privileges) => {

    const userData = getUserData();
    let validatePrivileges = false;

    if( userData && privileges ){
        privileges.forEach( (privilege) => {
            const userRoles = userData.role_list;
            if( userRoles ){
                userRoles.forEach( (role) => {
                    
                    const userPrivileges = role.privilege_list;
                    
                    userPrivileges.forEach((userPrivilege) => {
                        if(  privilege.name === userPrivilege.name ){
                            validatePrivileges = true;
                        }
                    });

                });
            }
        });
    }

    return validatePrivileges;

}

export const authenticateUser = async(uuid, password) => {

    const passwordHash = password;

    var response = await axios.get( constants.api.userApiUrl + '/validate_pass', { 
        params: {
            uuid: uuid,
            password_hash: passwordHash
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);

}

export const getAllUserTitles = async () => {

    const userTitles = getUserTitles();

    if( userTitles != null ){
        return userTitles
    } else {

        var response = await axios.get( constants.api.userApiUrl + '/user_titles', {
            headers: constants.api.defaultHeaders 
        } )
        .catch( error => {
            console.error('[ERROR] error: ', error);
        });

        const userTitlesArray = getDataRespose(response);

        setUserTitles(userTitlesArray);

        return userTitlesArray;
    }

}


export const clearUserData = () => {
    localStorage.setItem(constants.storage.userData, null);
    localStorage.setItem(constants.storage.tenant, null);
    localStorage.setItem(constants.storage.client, null);
    localStorage.setItem(constants.storage.userTitles, null);
    localStorage.setItem(constants.storage.reminderTypes, null);
    localStorage.setItem(constants.storage.billingTypes, null);
    localStorage.setItem(constants.storage.scheduleTypes, null);
    localStorage.setItem(constants.storage.paymentMethods, null);
    localStorage.setItem(constants.storage.discountTypes, null);
}

export const setUserData = (userData) => {
    console.log('[SecurityServices][setUserData] userData: ', userData);
    localStorage.setItem(constants.storage.userData, JSON.stringify(userData));
    if( userData != null && userData.tenant != null ){
        localStorage.setItem(constants.storage.tenant, JSON.stringify(userData.tenant));
    }
    if( userData != null && userData.client != null && userData.client.id ){
        localStorage.setItem(constants.storage.client, JSON.stringify(userData.client));
    }
}

export const getUserData = () => {
    const userDataStr = localStorage.getItem(constants.storage.userData);
    if( userDataStr != null ){
        const userData = JSON.parse(userDataStr);
        //console.log('[SecurityServices][getUserData] setUserData: ', userData);
        return userData;
    }
    return null;
}

export const getClientLocalStorage = () => {
    const clientDataStr = localStorage.getItem(constants.storage.client);
    if( clientDataStr != null ){
        const clientData = JSON.parse(clientDataStr);
        console.log('[SecurityServices][getClientData] clientData: ', clientData);
        return clientData;
    }
    return null;
}


export const setUserTitles = (userTitles) => {
    //console.log('[SecurityServices][setUserTitles] userTitles: ', userTitles);
    if( userTitles != null  ){
        localStorage.setItem(constants.storage.userTitles, JSON.stringify(userTitles));
    }
}

export const getUserTitles = () => {
    const userTitlesStr = localStorage.getItem(constants.storage.userTitles);
    if( userTitlesStr != null ){
        const userTitles = JSON.parse(userTitlesStr);
        //console.log('[SecurityServices][getUserTitles] userTitles: ', userTitles);
        return userTitles;
    }
    return null;
}

export const getAllActiveTeams = async( tenanId ) => {

    var response = await axios.get( constants.api.teamApiUrl + '/' + tenanId + '/' , { 
        
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getAllActiveStaffUsers = async( tenanId ) => {

    var response = await axios.get( constants.api.userApiUrl + '/' + tenanId + '/shorten/staff/filter' , { 
        
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getAllActiveStaffRoles = async( tenanId ) => {

    var response = await axios.get( constants.api.roleApiUrl + '/' + tenanId + '/shorten/staff/filter' , { 
        
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getUserById = async( tenanId, userId ) => {

    var response = await axios.get( constants.api.userApiUrl + '/' + tenanId + '/' + userId , { 
        
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateUser = async(user, tenanId) => {

    const userData = getUserData();
    user.user_id = userData.uuid;

    console.debug('[SecurityServices][updateUser] user: ', user);

    var response = await axios.put( constants.api.userApiUrl + '/' + tenanId + '/', user, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createUser = async(user, tenanId) => {

    const userData = getUserData();
    user.user_id = userData.uuid;

    console.debug('[SecurityServices][createUser] user: ', user);

    var response = await axios.post( constants.api.userApiUrl + '/' + tenanId + '/', user, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}



export const logicalDeleteUser = async(user, tenanId) => {

    const userData = getUserData();
    user.user_id = userData.uuid;
    user.status = status.userStatus.inactive;

    console.debug('[SecurityService][logicalDeleteUser] user: ', user);

    var response = await axios.put( constants.api.userApiUrl + '/' + tenanId + '/', user, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}




export const getTeamById = async( tenanId, teamId ) => {

    var response = await axios.get( constants.api.teamApiUrl + '/' + tenanId + '/' + teamId , { 
        
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateTeam = async(team, tenanId) => {

    const userData = getUserData();
    team.user_id = userData.uuid;

    console.debug('[SecurityServices][updateTeam] team: ', team);

    var response = await axios.put( constants.api.teamApiUrl + '/' + tenanId + '/', team, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createTeam = async(team, tenanId) => {

    const userData = getUserData();
    team.user_id = userData.uuid;

    console.debug('[SecurityServices][createUser] team: ', team);

    var response = await axios.post( constants.api.teamApiUrl + '/' + tenanId + '/', team, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}



export const logicalDeleteTeam = async(team, tenanId) => {

    const userData = getUserData();
    team.user_id = userData.uuid;
    team.status = status.userStatus.inactive;

    console.debug('[SecurityService][logicalDeleteTeam] team: ', team);

    var response = await axios.put( constants.api.teamApiUrl + '/' + tenanId + '/', team, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}