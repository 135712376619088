import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { searchActiveWorkOrdersByAnyAttribute } from "../../../service/tenant/WorkOrderService";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import { useNavigate } from "react-router";
import constants from "../util/Constants";
import { getClientLocalStorage } from "../../../service/tenant/SecurityService";
import { getWorkOrderStatusName } from "../../../service/tenant/StatusService";

const localizer = dayjsLocalizer(dayjs);

function WorkOrdersCalendar(props){

    const tenant = getTenantLocalStorage();
    const tenantId = tenant.id;
    const client = getClientLocalStorage();
    const workTitleMaxSize = 15;

    const [eventList, setEventList] = useState([]);

    const navigate = useNavigate();

    const navigateTo = (route) => {
        console.debug('[WorkOrdersCalendar][navigateTo] route: ', route);
        navigate(route);
    } 

    useEffect(() => {

        let clientId = '';

        if( client && client.id ){
            clientId = client.id;
        }

        searchActiveWorkOrdersByAnyAttribute('', clientId, '', '', tenantId).then( workOrderList => {
            if( workOrderList !== null ){

                let eventArray = [];

                workOrderList.forEach(workOrder => {
                    
                    let workOrderTitle = workOrder.title;

                    if( workOrderTitle.length > workTitleMaxSize ){
                        workOrderTitle = workOrderTitle.substring(0, workTitleMaxSize) + '...';
                    }

                    let eventTitle = workOrderTitle + ' (' + getWorkOrderStatusName(workOrder.status) + ')';
                    let teamColor = constants.colors.calendarUnnasigned;
                    let clientName = '';

                    if( workOrder.team ){
                        eventTitle += ' - ' + workOrder.team.name;
                        teamColor = workOrder.team.color;
                    }

                    if( workOrder.client && workOrder.client.user ){
                        clientName = workOrder.client.user.first_name + ' ' + workOrder.client.user.last_name;
                        eventTitle += ' (' + clientName + ')';
                    }

                    eventArray.push(
                        {
                            id: workOrder.id,
                            title: eventTitle,
                            start: new Date(workOrder.start_date+' 09:00:00'),
                            end: new Date(workOrder.end_date+' 19:00:00'),
                            color: teamColor,
                            actionUrl: client && client.id ? constants.routes.editWorkOrderUsers : constants.routes.editWorkOrder,
                        }
                    );

                });

                setEventList(eventArray);
                
            }
        });

    }, []);


    return (
        <div style={{height:'400px', marginTop: '10px'}} className="bigCalendar-container">
            <Calendar
                views={["day", "agenda", "work_week", "month"]}
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={eventList}
                startAccessor="start"
                endAccessor="end"
                style={{height: "90vh"}}
                onSelectEvent={(event) => navigate(event.actionUrl, {
                    state: {
                        workOrderId: event.id
                    }
                })}
                eventPropGetter={(event) => {
                    const backgroundColor = event.color && event.color !== '' ? 
                                            event.color : constants.colors.calendarUnnasigned;
                    return { style: { backgroundColor } }
                }}
            />

        </div>
    );

}

export default WorkOrdersCalendar;