import { AccountBalanceWalletOutlined, AdminPanelSettingsOutlined, AllInboxOutlined, AssignmentIndOutlined, CalendarMonthOutlined, ContentPasteSearch, ExpandLess, ExpandMore, PeopleOutline, Settings } from "@mui/icons-material";
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import constants from "../util/Constants";
import ValidatePrivileges from "../security/ValidatePrivileges";
import { security_constants } from "../../../service/tenant/SecurityService";


function MenuDrawer(props) {
    const [openClientMenu, setOpenClientMenu] = useState(constants.menu.admin_clients_section === props.section);
    const [openRequestMenu, setOpenRequestMenu] = useState(constants.menu.admin_requests_section === props.section);
    const [openQuotesMenu, setOpenQuotesMenu] = useState(constants.menu.admin_quotes_section === props.section);
    const [openWorkOrdersMenu, setOpenWorkOrderstMenu] = useState(constants.menu.admin_workorders_section === props.section);
    const [openInvoiceMenu, setOpenInvoiceMenu] = useState(constants.menu.admin_invoices_section === props.section);
    const [openAdminMenu, setOpenAdminMenu] = useState(constants.menu.admin_admin_section === props.section);
    const [openAdminCatalogMenu, setOpenAdminCatalogMenu] = useState(constants.menu.admin_admin_catalogs_section === props.section);

    const navigate = useNavigate();

    const navigateTo = (route) => {
        console.debug('[MenuDrawer][navigateTo] route: ', route);
        navigate(route);
    } 
    
    return (
    <div>
        <Toolbar
            color="inherit"
            sx={{flexDirection: 'column', alignContent:'center'}}
        >
            <Box
                component='img'
                sx={{ height: 70 }}
                alt='Job Tube'
                src='/images/logo_job_tube_2.png'
            />
        </Toolbar>
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >

            <ValidatePrivileges privileges={[security_constants.privileges.admin_clients_all_access]}>
                <ListItemButton onClick={() => setOpenClientMenu(!openClientMenu)}>
                    <ListItemIcon>
                        <PeopleOutline/>
                    </ListItemIcon>
                    <ListItemText primary='Clients' />
                    {openClientMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openClientMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewClients) }>
                            <ListItemText primary="View clients" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createClient, {
                                                                            state: {
                                                                                clientId: ''
                                                                            }
                                                                        }) }
                        >
                            <ListItemText primary="Create client" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewProjects) }>
                            <ListItemText primary="View projects" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createProject, {
                                                                            state: {
                                                                                projectId: ''
                                                                            }
                                                                        }) }
                        >
                            <ListItemText primary="Create project" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>

            <ValidatePrivileges privileges={[security_constants.privileges.admin_requests_all_access]}>
                <ListItemButton onClick={() => setOpenRequestMenu(!openRequestMenu)}>
                    <ListItemIcon>
                        <AllInboxOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Requests' />
                    {openRequestMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openRequestMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewRequests) }>
                            <ListItemText primary="View requests" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createRequest, {
                                                                            state: {
                                                                                requestId: ''
                                                                            }
                                                                        }) }>
                            <ListItemText primary="Create request" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>


            <ValidatePrivileges privileges={[security_constants.privileges.user_requests_all_access]}>
                <ListItemButton onClick={() => setOpenRequestMenu(!openRequestMenu)}>
                    <ListItemIcon>
                        <AllInboxOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Requests' />
                    {openRequestMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openRequestMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewRequestsUsers) }>
                            <ListItemText primary="View requests" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createRequestUsers, {
                                                                            state: {
                                                                                requestId: ''
                                                                            }
                                                                        }) }>
                            <ListItemText primary="Create request" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>


            <ValidatePrivileges privileges={[security_constants.privileges.admin_quotes_all_access]}>
                <ListItemButton onClick={() => setOpenQuotesMenu(!openQuotesMenu)}>
                    <ListItemIcon>
                        <ContentPasteSearch />
                    </ListItemIcon>
                    <ListItemText primary='Quotes' />
                    {openQuotesMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openQuotesMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewQuotes) }>
                            <ListItemText primary="View quotes" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createQuote, {
                                                                            state: {
                                                                                quoteId: ''
                                                                            }
                                                                        }) }>
                            <ListItemText primary="Create quote" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>

            <ValidatePrivileges privileges={[security_constants.privileges.user_quotes_all_access]}>
                <ListItemButton onClick={() => setOpenQuotesMenu(!openQuotesMenu)}>
                    <ListItemIcon>
                        <ContentPasteSearch />
                    </ListItemIcon>
                    <ListItemText primary='Quotes' />
                    {openQuotesMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openQuotesMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewQuotesUsers) }>
                            <ListItemText primary="View quotes" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createQuoteUsers, {
                                                                            state: {
                                                                                quoteId: ''
                                                                            }
                                                                        }) }>
                            <ListItemText primary="Create quote" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>

            <ValidatePrivileges privileges={[security_constants.privileges.admin_jobs_all_access,
                                            security_constants.privileges.admin_workorders_all_access, ]}>
                <ListItemButton onClick={() => setOpenWorkOrderstMenu(!openWorkOrdersMenu)}>
                    <ListItemIcon>
                        <AssignmentIndOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Work orders' />
                    {openWorkOrdersMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openWorkOrdersMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ValidatePrivileges privileges={[security_constants.privileges.admin_jobs_all_access]}>
                            <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewJobs) }>
                                <ListItemText primary="View jobs" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createJob, {
                                                                            state: {
                                                                                jobId: ''
                                                                            }
                                                                        }) }>
                                <ListItemText primary="Create job" />
                            </ListItemButton>
                        </ValidatePrivileges>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewWorkOrders) }>
                            <ListItemText primary="View work orders" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigate(constants.routes.createWorkOrder, {
                                                                            state: {
                                                                                workOrderId: '',
                                                                                lineItems: null,
                                                                                client: null,
                                                                                project: null,
                                                                                job: null,
                                                                                quote: null,
                                                                            }
                                                                        }) }>
                            <ListItemText primary="Create work order" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>


            <ValidatePrivileges privileges={[security_constants.privileges.user_jobs_all_access ]}>
                <ListItemButton onClick={() => setOpenWorkOrderstMenu(!openWorkOrdersMenu)}>
                    <ListItemIcon>
                        <AssignmentIndOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Work orders' />
                    {openWorkOrdersMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openWorkOrdersMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewWorkOrdersUsers) }>
                            <ListItemText primary="View work orders" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>
        


            <ValidatePrivileges privileges={[security_constants.privileges.admin_invoices_all_access]}>
                <ListItemButton onClick={() => setOpenInvoiceMenu(!openInvoiceMenu)}>
                    <ListItemIcon>
                        <AccountBalanceWalletOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Invoices' />
                    {openInvoiceMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openInvoiceMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewInvoices) }>
                            <ListItemText primary="View invoices" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary="Create invoice" onClick={ () => navigate(constants.routes.createInvoice, {
                                                                            state: {
                                                                                invoiceId: ''
                                                                            }
                                                                        }) }/>
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>


            <ValidatePrivileges privileges={[security_constants.privileges.user_invoices_all_access]}>
                <ListItemButton onClick={() => setOpenInvoiceMenu(!openInvoiceMenu)}>
                    <ListItemIcon>
                        <AccountBalanceWalletOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Invoices' />
                    {openInvoiceMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openInvoiceMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.viewInvoicesUsers) }>
                            <ListItemText primary="View invoices" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ValidatePrivileges>

            <ValidatePrivileges privileges={[security_constants.privileges.admin_clients_all_access]}>
                <ListItemButton onClick={() => setOpenAdminMenu(!openAdminMenu)}>
                    <ListItemIcon>
                        <AdminPanelSettingsOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Admin' />
                    {openAdminMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openAdminMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.adminUsers) }>
                            <ListItemText primary="Users" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={ () => navigateTo(constants.routes.adminTeams) }>
                            <ListItemText primary="Teams" />
                        </ListItemButton>
                        
                        <ListItemButton onClick={() => setOpenAdminCatalogMenu(!openAdminCatalogMenu)}>
                            <ListItemIcon sx={{ pl: 2 }}>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary='Catalogs' />
                            {openAdminCatalogMenu ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openAdminCatalogMenu} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 8 }} >
                                    <ListItemText primary="Items" onClick={ () => navigateTo(constants.routes.adminItems) }/>
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 8 }} >
                                    <ListItemText primary="Service types" onClick={ () => navigateTo(constants.routes.adminServiceTypes) }/>
                                </ListItemButton>
                            </List>
                        </Collapse>

                    </List>
                </Collapse>
            </ValidatePrivileges>

            <ValidatePrivileges privileges={[security_constants.privileges.admin_calendar_all_access, security_constants.privileges.user_calendar_all_access]}>
                <ListItemButton onClick={ () => navigateTo(constants.routes.home) }>
                    <ListItemIcon>
                        <CalendarMonthOutlined />
                    </ListItemIcon>
                    <ListItemText primary='Calendar' />
                </ListItemButton>
            </ValidatePrivileges>

        </List>
        
    </div>
    );
}

export default MenuDrawer;