import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, Search } from "@mui/icons-material";
import { logicalDeleteInvoice, searchInvoicesByAnyAttribute, searchInvoicesByAnyAttributeForClient } from "../../../service/tenant/InvoiceService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { getAllActiveClients } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import { getInvoiceStatusName } from "../../../service/tenant/StatusService";
import { convertDateStrISOString, convertDateStrToString } from "../../../service/tenant/ValidationService";
import { searchJobsByAnyAttribute } from "../../../service/tenant/JobService";
import { searchWorkOrdersByAnyAttribute, searchWorkOrdersByAnyAttributeForClient } from "../../../service/tenant/WorkOrderService";
import { getClientLocalStorage } from "../../../service/tenant/SecurityService";



function ViewInvoicesUser(props){
    

    const [tableRows, setTableRows] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [invoiceToDelete, setInvoiceToDelete] = useState(null);
    const [project, setProject] = useState(null);
    const [activeProjects, setActiveProjects] = useState([]);
    const [job, setJob] = useState(null);
    const [activeJobs, setActiveJobs] = useState([]);
    const [workOrder, setWorkOrder] = useState(null);
    const [activeWorkOrders, setActiveWorkOrders] = useState([]);
    

    const tenant = getTenantLocalStorage();
    const client = getClientLocalStorage();
    const tenantId = tenant.id;
    const navigate = useNavigate();


    useEffect( () => {
        console.log('[ViewInvoices][useEffect] client: ', client);
        
        if( client ){
            const clientId = client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

            setIsLoading(true);
            searchJobsByAnyAttribute('', clientId, '', tenantId).then( activeJobsResponse => {
                if( activeJobsResponse != null ){
                    setActiveJobs(activeJobsResponse);
                }
                setIsLoading(false);
            });

            setIsLoading(true);
            searchWorkOrdersByAnyAttributeForClient('', clientId, '', '', tenantId).then( activeWorkOrdersResponse => {
                if( activeWorkOrdersResponse != null ){
                    setActiveWorkOrders(activeWorkOrdersResponse);
                }
                setIsLoading(false);
            });

        }
    }, []);

    const handleSearchInvoices = () => {
        const tenantId = tenant.id;
        
        if( (!searchFilter || searchFilter === '') && client == null ){
            setErrorMessage(constants.errors.searchFilterEmpty);
            return;
        }

        setErrorMessage('');
        setTableRows([]);

        setIsLoading(true);

        let clientId = '';
        let projectId = '';
        let jobId = '';
        let workOrderId = '';

        if( client ){
            clientId = client.id;
            if( project ){
                projectId = project.id;
            }
            if( job ){
                jobId = job.id;
            }
            if( workOrder ){
                workOrderId = workOrder.id;
            }
        }

        searchInvoicesByAnyAttributeForClient(searchFilter, clientId, projectId, jobId, workOrderId, tenantId).then( invoiceList => {

            if( invoiceList !== null ){
                console.debug('[ViewInvoices][handleSearchInvoices] invoiceList: ', invoiceList);

                if( invoiceList.length > 0 ){
                    setTableRows(invoiceList);
                } else {
                    setErrorMessage(constants.errors.emptyResult);
                }
            } else {
                setErrorMessage(constants.errors.searchInvoiceError);
            }

            setIsLoading(false);
            
        });
    }

    const handleEditInvoice = (invoiceId) => {
        console.debug('[ViewInvoices][handleEditInvoice] invoiceId: ', invoiceId);
        navigate(constants.routes.editInvoiceUsers, {
            state: {
                invoiceId: invoiceId
            }
        });
    }


    const handleEditWorkOrder = (workOrderId) => {
        if( workOrderId != null ) { 
            console.debug('[ViewClients][handleEditWorkOrder] workOrderId: ', workOrderId);
            navigate(constants.routes.editWorkOrderUsers, {
                state: {
                    workOrderId: workOrderId
                }
            });
        }
    }



    const handleChangeProject = (attributeName, newProject) => {
        console.debug('[ViewInvoices][handleChangeClient] newProject: ', newProject)
        setProject(newProject);
    }

    const handleChangeJob = (attributeName, newJob) => {
        console.debug('[ViewInvoices][handleChangeJob] newJob: ', newJob)
        setJob(newJob);
    }

    const handleChangeWorkOrder = (attributeName, newWorkOrder) => {
        console.debug('[ViewInvoices][handleChangeWorkOrder] newWorkOrder: ', newWorkOrder)
        setWorkOrder(newWorkOrder);
    }


    return (
        <PrincipalLayout section={constants.menu.admin_invoices_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Invoices &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                            <Typography sx={constants.styles.textNormal}>
                                Search for invoice:
                            </Typography>
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={9} md={9}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-user-search" sx={constants.styles.textNormal}>
                                    Type invoice number
                                </InputLabel>
                                <Input
                                    id="input-user-search"
                                    value={searchFilter}
                                    sx={constants.styles.inputSearchNormal}
                                    onChange={ (e) => setSearchFilter(e.target.value) }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={handleSearchInvoices}
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    
                                />
                            </FormControl>
                        </Grid>


                        <AutocompleteComponent
                            fieldId={'input-invoice-project'}
                            fieldName={'Project'} 
                            fieldValue={project}
                            attributeName={'project'}
                            optionList={activeProjects}
                            optionAttributeLabel={'name'}
                            isClient={false}
                            handleChangeField={handleChangeProject}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />

                        <AutocompleteComponent
                            fieldId={'input-invoice-job'}
                            fieldName={'Job'} 
                            fieldValue={job}
                            attributeName={'job'}
                            optionList={activeJobs}
                            optionAttributeLabel={'title'}
                            isClient={false}
                            handleChangeField={handleChangeJob}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />

                        <AutocompleteComponent
                            fieldId={'input-invoice-work_order'}
                            fieldName={'Work order'} 
                            fieldValue={workOrder}
                            attributeName={'work_order'}
                            optionList={activeWorkOrders}
                            optionAttributeLabel={'title'}
                            isClient={false}
                            handleChangeField={handleChangeWorkOrder}

                            labelSx={2}
                            labelMd={2}

                            inputSx={9}
                            inputMd={9}
                        />
                        
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>

                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Invoice number</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Job</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Work order</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Issued</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Due date</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >Status</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {tableRows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditInvoice(row.id)}
                                                >
                                                    {row.invoice_number}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                {row.job  ? row.job.title : '' }
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditWorkOrder( row.work_order ? row.work_order.id : null)}
                                                >
                                                    {row.work_order  ? row.work_order.title : '' }
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ convertDateStrToString( row.issue_date ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ convertDateStrToString( row.due_date ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{ getInvoiceStatusName( row.status ) }</TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit invoice"
                                                    onClick={() => handleEditInvoice(row.id)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>


                    </Grid>
                    
                </Paper>
                
        </PrincipalLayout>
    );
}

export default ViewInvoicesUser;