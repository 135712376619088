import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuDrawer from "../menu/MenuDrawer";
import PrincipalLayout from "../menu/PrincipalLayout";
import WorkOrdersCalendar from "../calendar/WorkOrdersCalendar";
import constants from "../util/Constants";

const drawerWidth = 240;

function Home(props){
    
    const tenant = getTenantLocalStorage();


    return (
        <PrincipalLayout 
            notificationNumber={props.notificationNumber} 
            setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />
                
                <Paper sx={{marginTop: '10px', width:'80vw', height: "90vh", marginLeft: '0px'}}>
                    <WorkOrdersCalendar />
                </Paper>
                
        </PrincipalLayout>
    );
}

export default Home;