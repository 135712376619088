import constants from "./Constants";


const pdfConstants = {
    pdfStyles :{
        page: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "left",
            backgroundColor: "white"
        },
        sectionCenter: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            padding: 10
        },
        mainHeader: {
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20
        },
        quoteRecipient: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "left",
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
        },
        logo: {
            width: 84,
            height: 70
        },
        qrCodeImage: {
            width: 30,
            height: 30,
        },
        sectionLeft: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "left",
            backgroundColor: "white",
            padding: 10
        },
        textTitle: {
            marginLeft: '10px', 
            fontSize: '18px', 
        },
        textNormal: {
            fontSize: '10px', 
            marginRight: '10px'
        },
        textNormalBold: {
            fontSize: '10px', 
            marginRight: '10px',
            fontWeight: 'bold'
        },

        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 24,
            marginLeft: 20,
            marginRight: 20,
            borderWidth: 1,
            borderColor: constants.colors.tableBorder,
        },
        quoteFooterContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 24,
            marginLeft: 20,
            marginRight: 20,
        },
        quoteFooterTotals: {
            flexDirection: "row",
            width: "40%",
            flexWrap: "wrap",
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
        },
        container: {
            flexDirection: "row",
            borderBottomColor: constants.colors.tableCellHeaderBorder,
            backgroundColor: constants.colors.tableHeaderBackground,
            color: constants.colors.tableHeaderColor,
            borderBottomWidth: 1,
            alignItems: "center",
            height: 24,
            textAlign: "center",
            flexGrow: 1
        },
        row: {
            flexDirection: "row",
            borderBottomColor: constants.colors.tableCellBorder,
            borderBottomWidth: 1,
            alignItems: "center",
            height: 60,
            fontStyle: "bold"
        },
        row30: {
            flexDirection: "row",
            borderBottomColor: constants.colors.tableCellBorder,
            borderBottomWidth: 1,
            alignItems: "center",
            height: 30,
            fontStyle: "bold"
        },
        row30NoBorder: {
            flexDirection: "row",
            alignItems: "center",
            height: 30,
            fontStyle: "bold"
        },
        row20NoBorder: {
            flexDirection: "row",
            alignItems: "center",
            height: 20,
            fontStyle: "bold"
        },
        row15NoBorder: {
            flexDirection: "row",
            alignItems: "center",
            height: 15,
            fontStyle: "bold"
        },
        row10NoBorder: {
            flexDirection: "row",
            alignItems: "center",
            height: 12,
            fontStyle: "bold"
        },
        row60NoBorder: {
            flexDirection: "row",
            alignItems: "center",
            height: 60,
            fontStyle: "bold"
        },
        row60NoBorderLeft: {
            flexDirection: "row",
            alignItems: "left",
            height: 60,
            fontStyle: "bold"
        },
        row120: {
            flexDirection: "row",
            borderBottomColor: constants.colors.tableCellBorder,
            borderBottomWidth: 1,
            alignItems: "center",
            height: 120,
            fontStyle: "bold"
        },
        row120NoBorder: {
            flexDirection: "row",
            alignItems: "center",
            height: 120,
            fontStyle: "bold"
        },
        mediumColumnHeader: {
            width: "20%",
            borderRightColor: constants.colors.tableCellHeaderBorder,
            borderRightWidth: 1,
            fontSize: '12px',
        },
        smallColumnHeader: {
            width: "10%",
            borderRightColor: constants.colors.tableCellHeaderBorder,
            borderRightWidth: 1,
            fontSize: '12px',
        },
        bigColumnHeader: {
            width: "35%",
            borderRightColor: constants.colors.tableCellHeaderBorder,
            borderRightWidth: 1,
            fontSize: '12px',
        },
        bigColumnHeader50: {
            width: "50%",
            borderRightColor: constants.colors.tableCellHeaderBorder,
            borderRightWidth: 1,
            fontSize: '12px',
        },
        endColumnHeader: {
            width: "15%",
            fontSize: '12px',
        },
        mediumColumn: {
            width: "20%",
            borderRightColor: constants.colors.tableCellBorder,
            fontSize: '10px',
            borderWidth: 0,
            paddingLeft: 8,
            paddingRight: 8,
        },
        mediumColumn30: {
            width: "30%",
            borderRightColor: constants.colors.tableCellBorder,
            fontSize: '10px',
            borderWidth: 0,
            paddingLeft: 8,
            paddingRight: 8,
        },
        mediumColumn30Right: {
            width: "30%",
            borderRightColor: constants.colors.tableCellBorder,
            fontSize: '10px',
            borderWidth: 0,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'right',
        },
        mediumColumnRight: {
            width: "20%",
            borderRightColor: constants.colors.tableCellBorder,
            fontSize: '10px',
            borderWidth: 0,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'right',
        },
        mediumColumnRightNoBorder: {
            width: "20%",
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'right',
        },
        smallColumn: {
            width: "10%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
        },
        smallColumnRight: {
            width: "10%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'right',
        },
        smallColumnCenter: {
            width: "10%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
        },
        smallColumnCenterNoBorder: {
            width: "10%",
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
        },
        bigColumn: {
            width: "35%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
        },
        bigColumn50: {
            width: "50%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
        },
        bigColumn60: {
            width: "60%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
        },
        bigColumn80Client: {
            width: "80%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '15px',
            paddingLeft: 8,
            paddingRight: 8,
        },
        bigColumn80: {
            width: "80%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
        },
        bigColumn50NoBorder: {
            width: "50%",
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
        },
        bigColumn50Right: {
            width: "50%",
            borderRightColor: constants.colors.tableCellBorder,
            borderRightWidth: 0,
            fontSize: '10px',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'right',
        },
        endColumn: {
            width: "15%",
            paddingLeft: 8,
            paddingRight: 8,
        },
        endColumnCenter: {
            width: "15%",
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
        }

    },
    default_logo_image: '/images/logo_crists_lights.jpeg.jpg',
}

export default pdfConstants;