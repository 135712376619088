import { AdvancedMarker, APIProvider, ControlPosition, Map, MapControl, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import PlaceAutocomplete from "./PlaceAutocomplete";
import MapHandler from "./MapHandlerProps";

function MapPlaceAutocomplete(props) {

    const [markerRef, marker] = useAdvancedMarkerRef();

    useEffect(() => {
        if (!marker) {
            return;
        }
    }, [marker]);

    const handlePlaceSelect = (place) => {
        props.setSelectedPlace(place);
        props.handleMapPlaceSelect(place);
    }

    return (
        <APIProvider apiKey={'AIzaSyA3Ye_T_PbU1oQgfEy0YRHbw-qbbj-vyzk'}>
                                                
            <Map
                mapId={"bf51a910020fa25a"}
                style={{width: '38vh', height: '35vh'}}
                defaultCenter={props.mapPosition}
                gestureHandling={"greedy"}
                defaultZoom={15}
                disableDefaultUI={true}
            >
                <AdvancedMarker ref={markerRef} position={props.mapPosition} />
            </Map>

            <MapControl position={ControlPosition.TOP}>
                <div className="autocomplete-control">
                    <PlaceAutocomplete onPlaceSelect={handlePlaceSelect} />
                </div>
            </MapControl>
            <MapHandler place={props.selectedPlace} marker={marker} />

        </APIProvider>
    )

}

export default MapPlaceAutocomplete;