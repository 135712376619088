import { FormControl, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import constants from "./Constants";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { convertDateISOString, convertDateJsToLocalString } from "../../../service/tenant/ValidationService";


function DateComponent(props){

    const [validationError, setValidationError] = useState(false);

    useEffect(() => {
        if( props.required && (props.fieldValue === null || props.fieldValue === '') ){
            setValidationError(true);
        } else {
            setValidationError(false);
        }
    }, [props.fieldValue])


    const handleChangeValue = (newDateValue) => {

        let newDateValueISOStr = null;

        if( props.isDateTime ){

            newDateValueISOStr = convertDateJsToLocalString(newDateValue);

        } else {
            newDateValueISOStr = convertDateISOString(newDateValue);
        }

        console.debug('[DateComponent][handleChangeValue] newDateValueISOStr: ', newDateValueISOStr);
        props.handleChangeField( props.attributeName, newDateValueISOStr );
    }


    return (
        <>
            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                <Typography sx={constants.styles.textNormal} >
                    {props.fieldName} :
                </Typography>
            </Grid>
            <Grid item sx={constants.styles.sectionInput} xs={8} md={8}>

                <FormControl variant="standard">
                    {   props.isDateTime ?

                        <DateTimePicker
                            id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                            value={ dayjs(props.fieldValue)}
                            sx={ props.sx != null ? props.sx : constants.styles.textFieldNormal }
                            helperText={ validationError ? constants.errors.formValidationError : ''}
                            error={validationError}
                            size="small"
                            onChange={(newDateValue) => handleChangeValue(newDateValue)}
                            disabled={props.disabled}
                        />

                        :
                        <DatePicker
                            id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                            value={ dayjs(props.fieldValue)}
                            sx={ props.sx != null ? props.sx : constants.styles.textFieldNormal }
                            helperText={ validationError ? constants.errors.formValidationError : ''}
                            error={validationError}
                            size="small"
                            onChange={(newDateValue) => handleChangeValue(newDateValue)}
                            disabled={props.disabled}
                        />

                        
                    }
                </FormControl>
            </Grid>
        </>
    );


}


export default DateComponent;