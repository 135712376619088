import { Image, Text, View } from "@react-pdf/renderer";
import pdfConstants from "../util/PDFConstants";

function PDFHeader(props) {
    return (
        <View style={pdfConstants.pdfStyles.mainHeader}>
            <Image
                style={pdfConstants.pdfStyles.logo}
                src={pdfConstants.default_logo_image}
            />
            <Text style={pdfConstants.pdfStyles.textTitle}>
                {props.title}
            </Text>
        </View>
    )
}

export default PDFHeader;