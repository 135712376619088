//Tenant Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";

export const getCategoriesFirstLevel = async(tenanId) => {

    var response = await axios.get( constants.api.categoryApiUrl + '/' + tenanId + '/shorten/filter?level=0', { headers: constants.api.defaultHeaders } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    if( response != null && 
        response.data != null &&
        response.data.result === constants.api.successMessage){
        return response.data.data;
    }
    return null;

}

export const getCategoriesByParentId = async(tenanId, parentId) => {

    var response = await axios.get( constants.api.categoryApiUrl + '/' + tenanId + '/shorten/filter?parent_id=' + parentId, { headers: constants.api.defaultHeaders } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    if( response != null && 
        response.data != null &&
        response.data.result === constants.api.successMessage){
        return response.data.data;
    }
    return null;

}


