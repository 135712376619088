import { Document, Page, Text, View } from "@react-pdf/renderer";
import pdfConstants from "../util/PDFConstants";
import PDFHeader from "../util/PDFHeader";
import PDFInvoiceHeader from "./PDFInvoiceHeader";
import { calculatDiscountInvoice, calculateSubTotalInvoice, calculatGrandTotalInvoice, calculatTaxesInvoice, calculatTotalInvoice } from "../../../service/tenant/InvoiceService";


function InvoicePDF(props) {


    return (
        <Document>
            <Page size="A4" style={pdfConstants.pdfStyles.page}>

                <PDFHeader
                    title={"Invoice: " + 
                        (props.invoice && props.invoice.id ? ' #' + props.invoice.id : '')
                    }
                />

                <PDFInvoiceHeader
                    invoice={props.invoice}
                    client={props.client}
                    clientAddress={props.clientAddress}
                />
                
                <View style={pdfConstants.pdfStyles.tableContainer}>

                    <View style={pdfConstants.pdfStyles.container}>
                        <Text style={pdfConstants.pdfStyles.mediumColumnHeader}>Product/Service</Text>
                        <Text style={pdfConstants.pdfStyles.bigColumnHeader50}>Description</Text>
                        <Text style={pdfConstants.pdfStyles.smallColumnHeader}>Qty.</Text>
                        <Text style={pdfConstants.pdfStyles.smallColumnHeader}>Unit price</Text>
                        <Text style={pdfConstants.pdfStyles.smallColumnHeader}>Total</Text>
                        
                    </View>

                    {  props.invoice && props.invoice.invoice_item_list && props.invoice.invoice_item_list.map( (item, index) => (
                        
                            <View style={pdfConstants.pdfStyles.row30} key={index}>
                                <Text style={pdfConstants.pdfStyles.mediumColumn}>
                                    {item.name}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.bigColumn50}>
                                    {item.details}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.smallColumnCenter}>
                                    {item.quantity}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.smallColumnRight}>
                                    ${item.unit_price}
                                </Text>
                                <Text style={pdfConstants.pdfStyles.smallColumnRight}>
                                    ${ calculateSubTotalInvoice(item) }
                                </Text>
                               
                            </View>

                        ))
                    }

                </View>


                {  props.invoice && 
                    (
                        <View style={pdfConstants.pdfStyles.quoteFooterContainer}>

                            <View style={pdfConstants.pdfStyles.row120NoBorder}>
                                <Text style={pdfConstants.pdfStyles.bigColumn80}>
                                    {props.invoice.notes}
                                </Text>
                                <View style={pdfConstants.pdfStyles.quoteFooterTotals}>

                                    <View style={pdfConstants.pdfStyles.row30NoBorder}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Subtotal
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatTotalInvoice(props.invoice) }
                                        </Text>
                                    </View>
                                    <View style={pdfConstants.pdfStyles.row30NoBorder}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Discount
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatDiscountInvoice(props.invoice) }
                                        </Text>
                                    </View>
                                    <View style={pdfConstants.pdfStyles.row30NoBorder}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Taxes
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatTaxesInvoice(props.invoice) }
                                        </Text>
                                    </View>
                                    <View style={pdfConstants.pdfStyles.row30}>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            Total
                                        </Text>
                                        <Text style={pdfConstants.pdfStyles.bigColumn50Right}>
                                            ${ calculatGrandTotalInvoice(props.invoice) }
                                        </Text>
                                    </View>

                                </View>
                                
                                
                            </View>

                        </View>
                    )
                }

            </Page>
        </Document>

    );

}

export default InvoicePDF;