import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import constants from "./Constants";

function ConfirmDialog(props){

    const handleCloseConfirmDialog = () => {
        props.setOpenConfirmDialog(false);
    }

    const handleConfirmAction = () => {
        props.setOpenConfirmDialog(false);
        console.debug('[ConfirmDialog][handleConfirmAction] props.itemObject: ', props.itemObject)
        if( props.itemObject != null ){
            props.handleConfirmAction(props.itemObject);    
        } else {
            props.handleConfirmAction();
        }
    }

    return (
        <Dialog
            open={props.openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={constants.styles.textNormal}
                id="alert-dialog-title"
            >
                <strong>{props.title}</strong>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={constants.styles.textNormal}
                    id="alert-dialog-description"
                >
                    { props.message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined" 
                    size="small"
                    sx={constants.styles.buttonNormal}
                    onClick={handleCloseConfirmDialog}
                >
                    Cancel
                </Button>
                <Button 
                    variant="outlined" 
                    size="small"
                    sx={constants.styles.buttonNormal}
                    onClick={handleConfirmAction} 
                    autoFocus
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default ConfirmDialog;