import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import constants from "./Constants";


function SelectComponent(props){

    return (
        <>
            { !props.hideLabel &&  
                (
                    <Grid item sx={constants.styles.sectionInput} xs={ props.labelSx ? props.labelSx : 3 } md={ props.labelMd ? props.labelMd : 3 }>
                        <Typography sx={constants.styles.textNormal}>
                            {props.fieldName} :
                        </Typography>
                    </Grid>
                )
            }
            <Grid item sx={constants.styles.sectionInput} xs={ props.inputSx ? props.inputSx : 8 } md={ props.inputMd ? props.inputMd : 8 }>
                <FormControl variant="standard">
                    <Select
                        id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                        value={props.fieldValue}
                        
                        size="small"
                        variant="outlined"
                        sx={ props.sx != null ? 
                            props.sx : constants.styles.selectTextNormal 
                        }
                        onChange={(e) => props.handleChangeField( props.attributeName, e.target.value)}
                        disabled={props.disabled}
                    >
                        {
                            props.optionList.map( (option, index) => (
                                <MenuItem 
                                    key={index} 
                                    value={option.id}
                                    sx={ constants.styles.textSubmenu }
                                >
                                    {option[props.optionAttributeName]}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </>
    );

}

export default SelectComponent;