import { Text, View } from "@react-pdf/renderer";
import pdfConstants from "../util/PDFConstants";
import { buildCompleteClientAddress } from "../../../service/tenant/ServiceHelper";
import { convertDateStrToString, getDateISOString, getDateLocalString } from "../../../service/tenant/ValidationService";

function PDFInvoiceHeader(props) {
    return (
        <>
            <View style={pdfConstants.pdfStyles.quoteRecipient}>
                <View style={pdfConstants.pdfStyles.row30NoBorder}>
                    <Text style={pdfConstants.pdfStyles.bigColumn80Client}>
                        { props.client ? props.client.user.first_name + ' ' + props.client.user.last_name : '' }
                    </Text>
                    <Text style={pdfConstants.pdfStyles.smallColumn}>
                        &nbsp;
                    </Text>
                </View>
            </View>
            <View style={pdfConstants.pdfStyles.quoteRecipient}>
                <View style={pdfConstants.pdfStyles.row30NoBorder}>
                    <Text style={pdfConstants.pdfStyles.bigColumn60}>
                        { buildCompleteClientAddress(props.clientAddress) }
                    </Text>
                    <Text style={pdfConstants.pdfStyles.smallColumn}>
                        &nbsp;
                    </Text>
                    <Text style={pdfConstants.pdfStyles.mediumColumn30Right}>
                        Issued: { convertDateStrToString( props.invoice.issue_date ) }
                    </Text>
                </View>
            </View>
            <View style={pdfConstants.pdfStyles.quoteRecipient}>
                <View style={pdfConstants.pdfStyles.row10NoBorder}>
                    <Text style={pdfConstants.pdfStyles.bigColumn60}>
                        &nbsp;
                    </Text>
                    <Text style={pdfConstants.pdfStyles.smallColumn}>
                        &nbsp;
                    </Text>
                    <Text style={pdfConstants.pdfStyles.mediumColumn30Right}>
                        Due: { convertDateStrToString( props.invoice.due_date ) }
                    </Text>
                </View>
            </View>
        </>
    )
}

export default PDFInvoiceHeader;