import { AssignmentTurnedIn, PauseCircle, PlayArrow, StopCircle } from "@mui/icons-material";
import constants from "../../components/jobs-manager/util/Constants";

export const status = {
    clientStatus: {
        active: 1,
        inactive: 2,
        suspended: 3
    },
    projectStatus: {
        active: 1,
        inactive: 2,
        completed: 3,
        suspended: 4
    },
    userStatus: {
        active: 1,
        inactive: 0,
        suspended: 2
    },
    teamStatus: {
        active: 1,
        inactive: 0,
        suspended: 2
    },
    serviceTypeStatus: {
        active: 1,
        inactive: 2,
    },
    requestStatus: {
        draft: {
            status: 1,
            name: 'Draft'
        },
        assessPending: {
            status: 2,
            name: 'Assess pending'
        },
        assessCompleted: {
            status: 3,
            name: 'Assess completed'
        },
        cancelled: {
            status: 4,
            name: 'Cancelled'
        },
    },
    quoteStatus: {
        draft: {
            status: 1,
            name: 'Draft',
            visible: true,
        },
        pendingApproval: {
            status: 2,
            name: 'Pending approval',
            visible: true,
        },
        changesRequested: {
            status: 3,
            name: 'Changes requested',
            visible: false,
        },
        approved: {
            status: 4,
            name: 'Approved',
            visible: true,
        },
        cancelled: {
            status: 5,
            name: 'Cancelled',
            visible: false,
        },
        convertedToWorkOrder: {
            status: 5,
            name: 'Converted Work Order',
            visible: false,
        },
    },
    jobStatus: {
        new: {
            status: 1,
            name: 'New',
            visible: true,
        },
        onGoing: {
            status: 2,
            name: 'On going',
            visible: true,
        },
        completed: {
            status: 3,
            name: 'Completed',
            visible: true,
        },
        cancelled: {
            status: 4,
            name: 'Cancelled',
            visible: false,
        },
    },
    lineItemStatus: {
        pendingApproval: {
            status: 1,
            name: 'Pending approval'
        },
        pending: {
            status: 2,
            name: 'Pending'
        },
        onGoing: {
            status: 3,
            name: 'On going'
        },
        overdue: {
            status: 4,
            name: 'Overdue'
        },
        completed: {
            status: 5,
            name: 'Completed'
        },
        cancelled: {
            status: 6,
            name: 'Cancelled'
        },
    },
    workOrderStatus: {
        new: {
            status: 0,
            name: 'New',
            visible: true,
        },
        pendingApproveDates: {
            status: 1,
            name: 'Pending approve dates',
            visible: true,
        },
        scheduled: {
            status: 2,
            name: 'Scheduled',
            visible: false,
        },
        onGoing: {
            status: 3,
            name: 'On going',
            visible: true,
        },
        overdue: {
            status: 4,
            name: 'Overdue',
            visible: false,
        },
        completed: {
            status: 5,
            name: 'Completed',
            visible: true,
        },
        cancelled: {
            status: 6,
            name: 'Cancelled',
            visible: false,
        },
    },
    itemStatus: {
        active: {
            status: 1,
            name: 'Active'
        },
        inactive: {
            status: 2,
            name: 'Inactive'
        },
        retired: {
            status: 3,
            name: 'Retired'
        },
    },
    invoiceStatus: {
        draft: {
            status: 0,
            name: 'Draft',
            visible: true,
        },
        pendingApproval: {
            status: 1,
            name: 'Pending approval',
            visible: true,
        },
        pendingPayment: {
            status: 2,
            name: 'Pending payment',
            visible: true,
        },
        paid: {
            status: 3,
            name: 'Paid',
            visible: true,
        },
        cancelled: {
            status: 4,
            name: 'Cancelled',
            visible: true,
        },
        deleted: {
            status: 5,
            name: 'Deleted',
            visible: false,
        },
    },
    invoiceDeposit: {
        pendingApproval: {
            status: 1,
            name: 'Pending approval'
        },
        approved: {
            status: 2,
            name: 'Approved'
        },
        rejected: {
            status: 3,
            name: 'Rejected'
        },
        deleted: {
            status: 4,
            name: 'Rejected'
        },
    },
    notification: {
        active: {
            status: 1,
            name: 'Active',
        },
        read: {
            status: 2,
            name: 'Read',
        },
        deleted: {
            status: 3,
            name: 'Deleted',
        },
    },
}

export const discountTypes = {
    percentage_discount: {
        name: 'Percentage discount',
        type: 1
    },
    client_discount: {
        name: 'Client',
        type: 2
    },
    volume_discount: {
        name: 'Volume discount',
        type: 3
    },
    seasonal_discount: {
        name: 'Seasonal discount',
        type: 4
    },
   
} 


export const notificationTypes = {
    pending_review_client: {
        name: 'Pending review client',
        type: 1
    },
    pending_review_role: {
        name: 'Pending review role',
        type: 2
    },
    approved_by_client_role: {
        name: 'Approved by client for role',
        type: 3
    },
    pending_approve_client: {
        name: 'Pending approve client',
        type: 4
    },
    broadcast_event: {
        name: 'Broadcast event',
        type: 5
    },
    reminder: {
        name: 'Reminder',
        type: 5
    },
} 


export const objectTypes = {
    request: {
        name: 'Request',
        type: 1
    },
    quote: {
        name: 'Quote',
        type: 2
    },
    job: {
        name: 'Job',
        type: 3
    },
    work_order: {
        name: 'Work order',
        type: 4
    },
    invoice: {
        name: 'Invoice',
        type: 5
    },
    broadcast: {
        name: 'Broadcast',
        type: 6
    },
} 

export const roles = {
    administrator:{
        name: 'administrator',
        code: 'root',
    },
    staff:{
        name: 'staff',
        code: 'staff',
    },
    user:{
        name: 'user',
        code: 'user',
    },
}


export const getProjectStatusName = ( statusId ) => {
    return getStatusNameSingleObject( statusId, status.projectStatus);
} 

export const getObjectTypeName = ( objectType ) => {
    return getTypeName( objectType, objectTypes);
} 

export const getStatusNameByType = ( objectType, statusId ) => {

    if( objectType === objectTypes.request.type ){
        return getRequestStatusName(statusId);
    } else if( objectType === objectTypes.quote.type ){
        return getQuoteStatusName(statusId);
    } else if( objectType === objectTypes.work_order.type ){
        return getWorkOrderStatusName(statusId);
    } else if( objectType === objectTypes.job.type ){
        return getJobStatusName(statusId);
    } else if( objectType === objectTypes.invoice.type ){
        return getInvoiceStatusName(statusId);
    } else {
        return '';
    }

}

export const getRequestStatusName = ( statusId ) => {
    return getStatusName( statusId, status.requestStatus);
} 

export const getRequestStatusValues = () => {
    return getStatusVisibleValues( status.requestStatus);
} 

export const getQuoteStatusName = ( statusId ) => {
    return getStatusName( statusId, status.quoteStatus);
} 

export const getQuoteStatusValues = () => {
    return getStatusVisibleValues( status.quoteStatus);
} 

export const getJobStatusName = ( statusId ) => {
    return getStatusName( statusId, status.jobStatus);
} 

export const getWorkOrderStatusName = ( statusId ) => {
    return getStatusName( statusId, status.workOrderStatus);
}

export const getWorkOrderStatusValues = () => {
    return getStatusVisibleValues( status.workOrderStatus);
}

export const getInvoiceStatusName = ( statusId ) => {
    return getStatusName( statusId, status.invoiceStatus);
}

export const getInvoiceStatusValues = () => {
    return getStatusVisibleValues( status.invoiceStatus);
}

export const getLineItemStatusIcon = ( statusId ) => {

    if( status.lineItemStatus.pendingApproval.status == statusId ){
        return ( <StopCircle sx={constants.styles.iconSize} color="error" titleAccess={status.lineItemStatus.pendingApproval.name} /> )
    } else if( status.lineItemStatus.pending.status == statusId  ){
        return ( <PauseCircle sx={constants.styles.iconSize} color="info" titleAccess={status.lineItemStatus.pending.name} /> )
    } else if( status.lineItemStatus.onGoing.status == statusId ){
        return ( <PlayArrow sx={constants.styles.iconSize} color="success" titleAccess={status.lineItemStatus.onGoing.name} /> )
    } else if( status.lineItemStatus.overdue.status == statusId ){
        return ( <PlayArrow sx={constants.styles.iconSize} color="error" titleAccess={status.lineItemStatus.overdue.name} /> )
    } else if( status.lineItemStatus.completed.status == statusId ){
        return ( <AssignmentTurnedIn sx={constants.styles.iconSize} color="success" titleAccess={status.lineItemStatus.completed.name} /> )
    }
}


export const isRequestDraft =  ( requestStatusId ) => {
    return (status.requestStatus.draft.status == requestStatusId);
}

export const isRequestAssessPending =  ( requestStatusId ) => {
    return (status.requestStatus.assessPending.status == requestStatusId);
}

export const isRequestAssessCompleted =  ( requestStatusId ) => {
    return (status.requestStatus.assessCompleted.status == requestStatusId);
}


export const isQuoteInDraft = (quoteStatusId) => {
    return ( status.quoteStatus.draft.status == quoteStatusId );
}

export const isQuotePendingApproval = (quoteStatusId) => {
    return ( status.quoteStatus.pendingApproval.status == quoteStatusId );
}

export const isQuoteApproved = (quoteStatusId) => {
    return ( status.quoteStatus.approved.status == quoteStatusId );
}

export const isQuoteEditable =  ( quoteStatusId ) => {
    return ( status.quoteStatus.draft.status == quoteStatusId || 
        status.quoteStatus.pendingApproval.status == quoteStatusId );
}

export const isJobEditable = (jobStatusId) => {
    return ( status.jobStatus.new.status == jobStatusId ||
        status.jobStatus.onGoing.status == jobStatusId
     );
}

export const isJobOnGoing = (jobStatusId) => {
    return ( status.jobStatus.onGoing.status == jobStatusId );
}


export const isWorkOrderEditable =  ( workorderStatusId ) => {
    return (status.workOrderStatus.new.status == workorderStatusId || 
        status.workOrderStatus.pendingApproveDates.status == workorderStatusId
    );
}

export const isWorkOrderPendingApproveDate =  ( workorderStatusId ) => {
    return (status.workOrderStatus.pendingApproveDates.status == workorderStatusId);
}

export const isWorkOrderNew =  ( workorderStatusId ) => {
    return (status.workOrderStatus.new.status == workorderStatusId);
}


export const isWorkOrderCompleted =  ( workorderStatusId ) => {
    return (status.workOrderStatus.completed.status == workorderStatusId);
}



export const isInvoiceEditable =  ( invoiceStatusId ) => {
    return (status.invoiceStatus.draft.status == invoiceStatusId || 
        status.invoiceStatus.pendingApproval.status == invoiceStatusId
    );
}

export const isInvoicePendingApproval =  ( invoiceStatusId ) => {
    return (status.invoiceStatus.pendingApproval.status == invoiceStatusId);
}

export const isInvoiceNew =  ( invoiceStatusId ) => {
    return (status.invoiceStatus.draft.status == invoiceStatusId);
}

export const isInvoicePaid =  ( invoiceStatusId ) => {
    return (status.invoiceStatus.paid.status == invoiceStatusId);
}


export const isLineItemEditable = ( lineItemStatusId ) => {
    return (status.lineItemStatus.pendingApproval.status == lineItemStatusId);
}

export const isLineItemApprovable = ( lineItemStatusId ) => {
    return ( status.lineItemStatus.pendingApproval.status == lineItemStatusId );
}

export const isLineItemOnGoing = ( lineItemStatusId ) => {
    return ( status.lineItemStatus.onGoing.status == lineItemStatusId );
}

export const isInvoiceDepositApproved = ( invoiceDepositStatusId ) => {
    return ( status.invoiceDeposit.approved.status == invoiceDepositStatusId );
}

export const isInvoiceDepositPendingApproval = ( invoiceDepositStatusId ) => {
    return ( status.invoiceDeposit.pendingApproval.status == invoiceDepositStatusId );
}



const getStatusName = ( statusId, allStatus ) => {
    for( const statusProperty in allStatus ){
        const statusObject = allStatus[statusProperty];
        if( statusObject.status == statusId ){
            return statusObject.name;
        }
    }
    return statusId;
} 

const getStatusVisibleValues = ( allStatus ) => {
    let statusValuesList = [];

    for( const statusProperty in allStatus ){
        const statusObject = allStatus[statusProperty];
        if( statusObject.visible === true ){
            statusValuesList.push({
                id: statusObject.status,
                name: statusObject.name,
            });
        }
    }
    return statusValuesList;
} 

const getTypeName = ( typeId, allTypes ) => {
    for( const typeProperty in allTypes ){
        const typeObject = allTypes[typeProperty];
        if( typeObject.type == typeId ){
            return typeObject.name;
        }
    }
    return typeId;
} 

const getStatusNameSingleObject = ( statusId, allStatus ) => {
    for( const statusProperty in allStatus ){
        const statusIdAux = allStatus[statusProperty];
        if( statusIdAux == statusId ){
            const statusName = statusProperty[0].toUpperCase() + statusProperty.slice(1);;
            return statusName;
        }
    }
    return statusId;
} 



