import React, { useEffect, useState } from "react";
import { Button, Divider, FormControl, Grid, IconButton, InputAdornment, Link, Paper, Switch} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { calculatDiscount, calculatGrandTotal, calculatTaxes, calculatTotal, createQuote, discountTypesCatalog, getAllDiscountTypes, getQuoteById, updateQuote } from "../../../service/tenant/QuoteService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation, useNavigate } from "react-router";
import InputComponent from "../util/InputComponent";
import { getAllActiveStaffUsers, getUserData } from "../../../service/tenant/SecurityService";
import SwitchComponent from "../util/SwitchComponent";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createEmptyQuote, createEmptyQuoteItem, parseToFloat, parseToFloatAmount, validateQuote } from "../../../service/tenant/ValidationService";
import { getAllActiveClients, getShortenClientById, getTaxRateByClient } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import RatingComponent from "../util/RatingComponent";
import { getAllActiveItems } from "../../../service/tenant/ItemService";
import { CloudUploadOutlined, Delete, EditNote, LocationOn, PictureAsPdf } from "@mui/icons-material";
import QuoteItemDetails from "./QuoteItemDetails";
import UploaderComponent from "../util/UploaderComponent";
import ConfirmDialog from "../util/ConfirmDialog";
import { getQuoteStatusName, isQuoteApproved, isQuoteEditable, isQuoteInDraft, isQuotePendingApproval, status } from "../../../service/tenant/StatusService";
import { sendQuoteEmail } from "../../../service/tenant/EmailService";
import { buildClientAddressLinkToMaps } from "../../../service/tenant/ServiceHelper";
import SelectComponent from "../util/SelectComponent";
import DiscountDialog from "../util/DiscountDialog";
import { useStompClient } from "react-stomp-hooks";
import { sendUserQuoteSentNotification } from "../../../service/tenant/NotificationsService";
import ReactPDF, { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import QuotePDF from "./QuotePDF";


function EditQuote( props ){
    
    const location = useLocation();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    const userId = userData.id;
    var quoteId = location.state.quoteId;
    const tenantId = tenant.id;
    const navigate = useNavigate();

    const [quote, setQuote] = useState(null);
    const [clientAddress, setClientAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeClients, setActiveClients] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [activeTeamUsers, setactiveTeamUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeItems, setActiveItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openDiscountDialog, setOpenDiscountDialog] = useState(false);

    const [saveEvent, setSaveEvent] = useState(false);

    const stompClient = useStompClient();
    

    useEffect(() => {
        
        console.debug('[EditQuote] quoteId: ', quoteId);

        setIsLoading(true);

        if( quoteId != null && quoteId != '' ){
            getQuoteById(quoteId, tenantId).then( quote => {

                if( quote != null ){
                    console.debug('[EditQuote][useEffect] quote: ', quote);
                    setQuote(quote);
                }
                setIsLoading(false);
            });
        } else {

            const newQuote = createEmptyQuote(userData.uuid);
            console.debug('[EditQuote][useEffect] newQuote: ', newQuote);
            setQuote(newQuote);
            setIsLoading(false);
        }

        setIsLoading(true);

        getAllActiveClients( tenantId ).then( activeClientsResponse => {
            if( activeClientsResponse != null ){
                setActiveClients(activeClientsResponse);
            }
        });

        getAllActiveStaffUsers( tenantId ).then( activeStaffUsersResponse => {
            if( activeStaffUsersResponse != null ){
                setactiveTeamUsers(activeStaffUsersResponse);
            }
        });

        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse ){
                setActiveItems(activeItemsResponse);
            }

            setIsLoading(false);
        });

        


    }, [location.state.quoteId]);
    

    useEffect( () => {
        if( quote && quote.client ){

            console.log('[EditQuotes][useEffect] quote.client: ', quote.client);
            const clientId = quote.client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

            getShortenClientById(clientId, tenantId).then( clientResponse => {
                if( clientResponse != null ){
                    setClientAddress(clientResponse.client_address);
                }
            });

        }
    }, [quote ? quote.client: {}]);

    useEffect( () => {
        if( quote ){

            console.log('[EditQuote][useEffect] saveEvent: ');
            
            handleClickSave();

        }
    }, [saveEvent]);



    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        let isValidQuote = validateQuote(quote);
        isValidQuote = isValidQuote && ( quote.client != null );

        console.debug('[EditQuote][handleClickSave] quote: ', quote);
        console.debug('[EditQuote][handleClickSave] quoteId: ', quoteId);

        if( isValidQuote && quote.id != null && quote.id != ''  ){
            handleUpdateQuote();
        } else if( isValidQuote && ( quote.id == null || quote.id == '' ) ){
            handleCreateQuote();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateQuote = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateQuote(quote, tenantId).then( quoteResponse => {

            if( quoteResponse != null ){
                console.debug('[EditQuote][handleUpdateQuote] quoteResponse: ', quoteResponse);
                setQuote(quoteResponse);
                setSuccessMessage(constants.errors.editQuoteSuccess);
            } else {
                setErrorMessage(constants.errors.editQuoteError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateQuote = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createQuote(quote, tenantId).then( quoteResponse => {

            if( quoteResponse != null ){
                console.debug('[EditQuote][handleCreateQuote] quoteResponse: ', quoteResponse);
                setQuote(quoteResponse);
                quoteId = quoteResponse.id;
                console.debug('[EditQuote][handleCreateQuote] quoteId: ', quoteId);
                setSuccessMessage(constants.errors.createQuoteSuccess);
            } else {
                setErrorMessage(constants.errors.createQuoteError)
            }

            setIsLoading(false);
        });

    }


    const handleChangeQuoteAttribute = (attributeName, attributeValue) => {
        if( quote ){
            let quoteAux = { ...quote };
            quoteAux[attributeName] = attributeValue;

            if( attributeName === 'client' ){
                quoteAux['project'] = null; 

                setActiveProjects([]);
            }else if(  attributeName === 'predefined_message'){

                if( attributeValue == true ){
                    
                    quoteAux.notes = constants.messages.defaultQuoteMessage;
                } else {
                    quoteAux.notes = '';
                }
                
            }

            console.debug('[EditQuote][handleChangeQuoteAttribute] quoteAux: ', quoteAux);
            setQuote(quoteAux);
        }
    }

    const handleChangeQuoteDiscountAttribute = (attributeName, attributeValue) => {
        if( quote ){
            let quoteAux = { ...quote };

            const discount = parseToFloat(attributeValue);
            quoteAux[attributeName] = discount;
            console.debug('[EditQuote][handleChangeQuoteDiscountAttribute] quoteAux: ', quoteAux);
            setQuote(quoteAux);
        }
    }


    const handleChangeQuoteItemAttribute = (index, attributeName, attributeValue) => {
        if( quote && quote.quote_item_list ){
            let quoteAux = { ...quote };
            console.debug('[EditQuote][handleChangeQuoteItemAttribute] attributeName: ', attributeName);

            let quoteItemListAux = quoteAux[attributeName];

            quoteItemListAux[index].item = attributeValue;
            if( attributeValue !== null ){
                quoteItemListAux[index].unit_price = getQuoteItemPrice(attributeValue);
                quoteItemListAux[index].details = attributeValue.description;
                quoteItemListAux[index].name = attributeValue.name;
            } else {
                quoteItemListAux[index].unit_price = 0.0;
                quoteItemListAux[index].details = '';
                quoteItemListAux[index].name = '';
            }
            
            console.debug('[EditQuote][handleChangeQuoteItemAttribute] quoteItemListAux: ', quoteItemListAux);

            setQuote(quoteAux);
        }
    }


    

    const getQuoteItemPrice = ( item ) => {
        if(  item && item.price_unit_list && item.price_unit_list.length > 0 ){
            return parseToFloatAmount(item.price_unit_list[0].price);
        }
        return 0;
    }

    const handleChangeQuoteItemQuantity = (index, attributeName, attributeValue) => {
        if( quote && quote.quote_item_list ){
            let quoteAux = { ...quote };

            let quoteItemListAux = quoteAux[attributeName];

            quoteItemListAux[index].quantity = attributeValue;
            setQuote(quoteAux);
        }
    }

    const handleChangeQuoteItemUnitPrice = (index, attributeName, attributeValue) => {
        if( quote && quote.quote_item_list ){
            let quoteAux = { ...quote };

            let quoteItemListAux = quoteAux[attributeName];
            const unitPrice = parseToFloatAmount(attributeValue);
            quoteItemListAux[index].unit_price = unitPrice;
            setQuote(quoteAux);
        }
    }

    const handleAddQuoteItem = () => {
        const quoteItem = createEmptyQuoteItem();
        let quoteAux = { ...quote };
        quoteAux.quote_item_list.push(quoteItem);
        setQuote(quoteAux);
    }

    const handleDeleteQuoteItem = (index) => {
        let quoteAux = { ...quote };
        quoteAux.quote_item_list.splice(index, 1);
        setQuote(quoteAux);
    }

    const calculateSubTotal = ( quoteItem ) => {
        if( quoteItem && quoteItem.unit_price && quoteItem.quantity ){
            const subTotal = quoteItem.unit_price * quoteItem.quantity;
            return parseToFloatAmount(subTotal);
        }
        return 0;
    }


    const buildCompleteClientAddress = () => {
        if( clientAddress ){
            return clientAddress.number + ' ' + clientAddress.street + ', ' + clientAddress.city + ', ' + clientAddress.locality;
        }
        return '';
    }


    const handleShowItemDetails = (item) => {
        console.debug('[EditQuote][handleShowItemDetails] item: ', item);
        setSelectedItem(item);
        setOpenDetailsDialog(true);
    }

    const handleCloseDetailsDialog = () => {
        setSelectedItem(null);
        setOpenDetailsDialog(false);
    }

    const handleApproveQuote = () => {
        setOpenConfirmDialog(true);
    }

    const handleConfirmApproveQuote = () => {
        let quoteAux = {...quote};
        quoteAux.status = status.quoteStatus.approved.status;
        setQuote(quoteAux);
        setSaveEvent(!saveEvent);
    }
    
    const handleClickSaveAndSend = (url, blob) => {
        let quoteAux = {...quote};
        quoteAux.status = status.quoteStatus.pendingApproval.status;
        setQuote(quoteAux);
        sendQuoteEmail(quoteAux, blob);

        sendUserQuoteSentNotification(userId, quoteAux, tenantId, stompClient);

        setSaveEvent(!saveEvent);
    }



    const handleCreateWorkOrder = () => {

        var lineItems = [];

        quote.quote_item_list.forEach( quoteItem => {
            let quoteItemAux = {...quoteItem};
            quoteItemAux.id = null;
            lineItems.push(quoteItemAux);
        });

        console.log('[EditQuote][handleCreateWorkOrder] lineItems: ', lineItems);

        navigate(constants.routes.createWorkOrder, {
            state: {
                workOrderId: '',
                lineItems: lineItems,
                quote: quote,
                client: quote.client,
                project: quote.project,
                job: null,
            }
        });

    }

    return (
        
        <PrincipalLayout section={constants.menu.admin_quotes_section} isLoading={isLoading}
            notificationNumber={props.notificationNumber} 
            setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={11} md={11}>
                            
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Quote: {quote && quote.id ? ' #' + quote.id + ' - ' : ''} {quote ? quote.title : ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>

                            
                            
                        </Grid>

                        <Grid item xs={1} md={1}>
                            <Grid container 
                                spacing={0}
                                alignItems={'flex-end'}
                                alignContent={'flex-end'}
                                flexDirection={'column'}
                            >
                                <Grid item xs={12} md={12}>
                                    {  quote && quote.id &&
                                        (
                                            <PDFDownloadLink
                                                document={
                                                    <QuotePDF
                                                        quote={quote}
                                                        client={quote.client}
                                                        clientAddress={clientAddress}
                                                    />
                                                }
                                                fileName={"Quote_" + quote.id + ".pdf"}
                                                
                                                >
                                                <IconButton 
                                                    variant="outlined" 
                                                    size="small" 
                                                    sx={constants.styles.buttonNormal}
                                                >
                                                    <PictureAsPdf 
                                                        sx={constants.styles.iconSize}
                                                    />
                                                </IconButton>
                                            </PDFDownloadLink>
                                        )
                                    }

                                </Grid>

                            
                            </Grid>
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Quote title"} 
                                    fieldValue={ quote ? quote.title: '' } 
                                    fieldId={'input-quote-title'} 
                                    attributeName={'title'} 
                                    handleChangeField={handleChangeQuoteAttribute}
                                    required={true} 
                                    disabled={!isQuoteEditable(quote ? quote.status : '')}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-quote-client'}
                                    fieldName={'Client'} 
                                    fieldValue={quote ? quote.client : null}
                                    attributeName={'client'}
                                    optionList={activeClients}
                                    isClient={true}
                                    handleChangeField={handleChangeQuoteAttribute}
                                    disabled={!isQuoteInDraft(quote ? quote.status : '')}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-quote-project'}
                                    fieldName={'Project'} 
                                    fieldValue={quote ? quote.project : null}
                                    attributeName={'project'}
                                    optionList={activeProjects}
                                    optionAttributeLabel={'name'}
                                    isClient={false}
                                    handleChangeField={handleChangeQuoteAttribute}
                                    disabled={!isQuoteEditable(quote ? quote.status : '')}
                                />


                               
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                 
                                 <InputComponent 
                                    fieldName={"Status"} 
                                    fieldValue={ quote ? getQuoteStatusName( quote.status ): '' } 
                                    fieldId={'input-quote-status'} 
                                    attributeName={'status'} 
                                    required={false}
                                    disabled={true} 
                                />

                                <RatingComponent
                                    fieldName={"Rate"} 
                                    fieldValue={ quote ? quote.rate : 0 } 
                                    fieldId={'input-quote-rate'} 
                                    attributeName={'rate'} 
                                    handleChangeField={handleChangeQuoteAttribute}
                                />

                                <InputComponent 
                                    fieldName={"Address"} 
                                    fieldIcon={
                                        <IconButton 
                                            sx={{ p: 0 }} 
                                            title="Open map"
                                            href={buildClientAddressLinkToMaps(clientAddress)}
                                            target="_blank"
                                        >
                                            <LocationOn sx={constants.styles.iconSize} color="error"/>
                                        </IconButton>
                                    }
                                    fieldValue={ quote && quote.client ? buildCompleteClientAddress() : '' } 
                                    fieldId={'input-quote-client-address'} 
                                    attributeName={'address'} 
                                    handleChangeField={handleChangeQuoteAttribute}
                                    required={false} 
                                    disabled={true}
                                    multiline={true}
                                    rows={2}

                                />

                                <AutocompleteComponent
                                        fieldId={'input-quote-user'}
                                        fieldName={'Sales person'} 
                                        fieldValue={quote ? quote.user : null}
                                        attributeName={'user'}
                                        optionList={activeTeamUsers}
                                        optionAttributeLabel={'first_name'}
                                        isUser={true}
                                        handleChangeField={handleChangeQuoteAttribute}
                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                />


                            </Grid>

                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                        
                            <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                            {  quote && quote.quote_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Product/Service
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Qty.
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Unit price
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {  quote && quote.quote_item_list &&

                                quote.quote_item_list.map( (quoteItem, index) => (
                                    <>
                                    
                                    <AutocompleteComponent
                                        fieldId={'input-quote_item_list-'+index}
                                        fieldName={'Item'} 
                                        fieldValue={quoteItem.item}
                                        fieldIndex={index}
                                        attributeName={'quote_item_list'}
                                        optionList={activeItems}
                                        optionAttributeLabel={'name'}
                                        hideLabel={true}
                                        handleChangeField={handleChangeQuoteItemAttribute}
                                        sx={ constants.styles.textFieldLineItem }
                                        inputSx={4}
                                        inputMd={4}
                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                    />

                                    <InputComponent 
                                        fieldName={'Quantity'} 
                                        fieldValue={quoteItem.quantity} 
                                        fieldIndex={index}
                                        fieldId={'input-quote_item_qty-'+index} 
                                        attributeName={'quote_item_list'} 
                                        handleChangeField={handleChangeQuoteItemQuantity}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldSmall }
                                        inputSx={1}
                                        inputMd={1}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                    />

                                    <InputComponent 
                                        fieldName={'Unit price'} 
                                        fieldValue={quoteItem.unit_price} 
                                        fieldIndex={index}
                                        fieldId={'input-quote_item_price-'+index} 
                                        attributeName={'quote_item_list'} 
                                        handleChangeField={handleChangeQuoteItemUnitPrice}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                    />

                                    <InputComponent 
                                        fieldName={'Total'} 
                                        fieldValue={quoteItem.unit_price ? calculateSubTotal(quoteItem) : 0} 
                                        fieldIndex={index}
                                        fieldId={'input-quote_item_total-'+index} 
                                        attributeName={'quote_item_list'} 
                                        handleChangeField={handleChangeQuoteItemUnitPrice}
                                        required={false} 
                                        hideLabel={true}
                                        disabled={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            }
                                        }}
                                    />

                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <IconButton 
                                            sx={{ p: 0 }} 
                                            title="Edit item"
                                            onClick={()=> handleShowItemDetails(quoteItem)}
                                        >
                                            <EditNote sx={constants.styles.iconSize}/>
                                        </IconButton>
                                        { quote && isQuoteEditable( quote.status ) &&
                                        <>
                                            <IconButton 
                                                sx={{ p: 0 }} 
                                                title="Delete item"
                                                onClick={() => handleDeleteQuoteItem(index) }
                                            >
                                                <Delete sx={constants.styles.iconSize}/>
                                            </IconButton>
                                        </>
                                        }
                                    </Grid>
                                    
                                    </>
                                ))

                            }

                            {  quote && quote.quote_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={5} md={4}>
                                        <Grid container direction="row" spacing={0}
                                            justifyContent={'left'}
                                            alignContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Subtotal:
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatTotal(quote) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <Link
                                                        component="button"
                                                        onClick={() => setOpenDiscountDialog(true)}
                                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                                    >
                                                        Discount:
                                                    </Link>
                                                </Typography>
                                                {quote && quote.discount_type && quote.discount_type === discountTypesCatalog.percentageDiscount.id &&
                                                    (
                                                        <Typography sx={constants.styles.textSmall} align="right">
                                                            ({quote ? quote.discount : 0.0}%)
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatDiscount(quote) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <Switch 
                                                        id={ 'input-quote-include_taxes' }
                                                        checked={ quote ? quote.include_taxes : false }
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        size="small"
                                                        onChange={ (e) => handleChangeQuoteAttribute('include_taxes', e.target.checked) }
                                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                                    />
                                                    Taxes:
                                                </Typography>
                                                
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatTaxes(quote) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={7} md={8}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong> Total:  </strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong>${ calculatGrandTotal(quote) }</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            } 

                            </Grid>

                            
                        </Grid>

                        { quote && isQuoteEditable( quote.status ) &&
                            <>
                                <Grid item  
                                        xs={12} md={12} 
                                        sx={constants.styles.sectionButtons}
                                    >
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        sx={constants.styles.buttonNormal}
                                        onClick={handleAddQuoteItem}
                                        > 
                                            Add item 
                                    </Button>
                                </Grid>
                            </>
                        }


                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <InputComponent 
                                    fieldName={"Quote notes"} 
                                    fieldValue={ quote ? quote.notes: '' } 
                                    fieldId={'input-quote-notes'} 
                                    attributeName={'notes'} 
                                    handleChangeField={handleChangeQuoteAttribute}
                                    required={true} 
                                    multiline={true}
                                    rows={6}

                                    labelSx={12}
                                    labelMd={12}

                                    inputSx={12}
                                    inputMd={12}

                                    sx={constants.styles.textFieldLarge}

                                    disabled={!isQuoteEditable(quote ? quote.status : '')}
                                />

                                <SwitchComponent 
                                    fieldName={"Predefined message"} 
                                    fieldValue={ quote ? quote.predefined_message : false } 
                                    fieldId={'input-quote-predefined_message'} 
                                    attributeName={'predefined_message'} 
                                    handleChangeField={handleChangeQuoteAttribute} 
                                    disabled={!isQuoteEditable(quote ? quote.status : '')}
                                />

                            </Grid>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={4}>

                            <UploaderComponent
                                object={quote}
                                prefix={'quote'}
                                folder={'quotes'}
                                imageListAttributeName={'quote_image_list'}
                                setObject={setQuote}
                                saveObject={handleClickSave}
                                disabled={!isQuoteEditable(quote ? quote.status : '')}
                            />
                            

                        </Grid>
                        
                        { quote && quote.id && !isQuoteInDraft( quote.status ) &&
                        <>
                            <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>

                                <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                                    <InputComponent 
                                        fieldName={"Assessment"} 
                                        fieldValue={ quote ? quote.assess_notes: '' } 
                                        fieldId={'input-quote-assess_notes'} 
                                        attributeName={'assess_notes'} 
                                        handleChangeField={handleChangeQuoteAttribute}
                                        required={true} 
                                        multiline={true}
                                        rows={6}

                                        labelSx={12}
                                        labelMd={12}

                                        inputSx={12}
                                        inputMd={12}

                                        fullWidth={true}
                                        disabled={!isQuoteEditable(quote ? quote.status : '')}
                                    />

                                </Grid>
                            </Grid>
                        </>
                        }
                        
                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                { quote && quote.id && isQuoteInDraft( quote.status ) &&
                                    <>
                                        <BlobProvider 
                                            document={
                                                <QuotePDF
                                                    quote={quote}
                                                    client={quote.client}
                                                    clientAddress={clientAddress}
                                                />
                                            }
                                        >
                                            { ({ url, blob }) => (
                                                <Button 
                                                    variant="outlined" 
                                                    size="small" 
                                                    sx={constants.styles.buttonNormal}
                                                    onClick={() => handleClickSaveAndSend(url, blob)}
                                                > 
                                                    Save and send to client
                                                </Button>
                                            )}
                                        </BlobProvider>
                                    </>
                                }
                                { quote && isQuotePendingApproval( quote.status ) &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleApproveQuote}
                                        > 
                                        Approve quote
                                        </Button>
                                    </>
                                }
                                { quote && isQuotePendingApproval( quote.status ) &&
                                    <>
                                        <BlobProvider 
                                            document={
                                                <QuotePDF
                                                    quote={quote}
                                                    client={quote.client}
                                                    clientAddress={clientAddress}
                                                />
                                            }
                                        >
                                            { ({ url, blob }) => (
                                                <Button 
                                                    variant="outlined" 
                                                    size="small" 
                                                    sx={constants.styles.buttonNormal}
                                                    onClick={() => handleClickSaveAndSend(url, blob)}
                                                > 
                                                    Save and re-send to client
                                                </Button>
                                            )}
                                        </BlobProvider>
                                    </>
                                }
                                { quote && isQuoteApproved( quote.status ) &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleCreateWorkOrder}
                                        > 
                                        Convert to work order
                                        </Button>
                                    </>
                                }
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
                <QuoteItemDetails
                    openDetailsDialog={openDetailsDialog}
                    handleCloseDetailsDialog={handleCloseDetailsDialog}
                    item={selectedItem}
                />

                <ConfirmDialog 
                    key={'confirm_approve_quote'}
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                    handleConfirmAction={handleConfirmApproveQuote}
                    title={'Do you approve this quote?'}
                    message={quote ? 'Quote assessment: ' + quote.assess_notes : ''}
                />

                <DiscountDialog 
                    key={'discount_quote_dialog'}
                    openDiscountDialog={openDiscountDialog}
                    setOpenDiscountDialog={setOpenDiscountDialog}
                    title={'Quote discount'}
                    
                    fieldDiscountTypeName={'Discount'}
                    fieldDiscountTypeValue={ quote ? quote.discount_type : null }
                    fieldDiscountTypeId={'input-quote-discount_type'}
                    fieldDiscountTypeAttributeName={'discount_type'}
                    handleChangeDiscountTypeField={handleChangeQuoteAttribute}
                    
                    fieldDiscountName={'Discount'}
                    fieldDiscountValue={ quote ? quote.discount : null }
                    fieldDiscountId={'input-quote-discount'}
                    discountAttributeName={'discount'}
                    discountRequired={true}

                    handleChangeDiscountField={handleChangeQuoteDiscountAttribute}

                />

        </PrincipalLayout>

        
    );
}

export default EditQuote;