import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Divider, Grid, Paper, Tab, Tabs, TextField} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { createProject, getAllProjectTypes, getProjectById, updateProject } from "../../../service/tenant/ProjectService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation } from "react-router";
import InputComponent from "../util/InputComponent";
import { getAllUserTitles, getUserData } from "../../../service/tenant/SecurityService";
import SelectComponent from "../util/SelectComponent";
import ProjectsTable from "./ProjectsTable";
import SwitchComponent from "../util/SwitchComponent";
import AttributeInputComponent from "../util/AttributeInputComponent";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createEmptyProject, validateProject } from "../../../service/tenant/ValidationService";
import { getAllActiveClients } from "../../../service/tenant/ClientService";
import { AdvancedMarker, APIProvider, ControlPosition, Map, MapControl, Marker, useAdvancedMarkerRef, useMarkerRef } from "@vis.gl/react-google-maps";
import PlaceAutocomplete from "../map/PlaceAutocomplete";
import MapHandler from "../map/MapHandlerProps";
import { map_constants, mergeAddress } from "../map/MapUtils";
import DateComponent from "../util/DateComponent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MapPlaceAutocomplete from "../map/MapPlaceAutocomplete";
import AutocompleteComponent from "../util/AutocompleteComponent";



function EditProject( props ){
    
    const location = useLocation();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    var projectId = location.state.projectId;
    const tenantId = tenant.id;

    const [project, setProject] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [projectTypes, setProjectTypes] = useState([]);
    const [activeClients, setActiveClients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [mapPosition, setMapPosition] = useState({
        lat: map_constants.defaultGeometry.latitude,
        lng: map_constants.defaultGeometry.longitude
    });

    useEffect(() => {
        
        console.debug('[EditProject] projectId: ', projectId);

        setIsLoading(true);

        if( projectId != null && projectId != '' ){
            getProjectById(projectId, tenantId).then( project => {

                if( project != null ){
                    console.debug('[EditProject][useEffect] project: ', project);
                    setProject(project);
                    setMapPosition({
                        lat: project.project_address.latitude,
                        lng: project.project_address.longitude
                    });
                }
                setIsLoading(false);
            });
        } else {

            const newProject = createEmptyProject(userData.uuid);
            console.debug('[EditProject][useEffect] newProject: ', newProject);
            setProject(newProject);
            setIsLoading(false);
        }


        getAllProjectTypes( tenantId ).then( projectTypesResponse =>{
            if( projectTypesResponse != null ){
                setProjectTypes(projectTypesResponse);
            }
        });

        getAllActiveClients( tenantId ).then( activeClientsResponse => {
            if( activeClientsResponse != null ){
                setActiveClients(activeClientsResponse);
            }
        });


    }, [location.state.projectId]);
    


    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        let isValidProject = validateProject(project);
        isValidProject = isValidProject && ( project.client != null );

        console.debug('[EditProject][handleClickSave] project: ', project);
        console.debug('[EditProject][handleClickSave] projectId: ', projectId);

        if( isValidProject && project.id != null && project.id != ''  ){
            handleUpdateProject();
        } else if( isValidProject && ( project.id == null || project.id == '' ) ){
            handleCreateProject();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateProject = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateProject(project, tenantId).then( projectResponse => {

            if( projectResponse != null ){
                console.debug('[EditProject][handleUpdateProject] projectResponse: ', projectResponse);
                setProject(projectResponse);
                setSuccessMessage(constants.errors.editProjectSuccess);
            } else {
                setErrorMessage(constants.errors.editProjectError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateProject = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createProject(project, tenantId).then( projectResponse => {

            if( projectResponse != null ){
                console.debug('[EditProject][handleCreateProject] projectResponse: ', projectResponse);
                setProject(projectResponse);
                projectId = projectResponse.id;
                console.debug('[EditProject][handleCreateProject] projectId: ', projectId);
                setSuccessMessage(constants.errors.createProjectSuccess);
            } else {
                setErrorMessage(constants.errors.createProjectError)
            }

            setIsLoading(false);
        });

    }


    const handleChangeAddressAttribute = (attributeName, attributeValue) => {
        if( project ){
            
            let projectAddressAux = { ...project.project_address };
            projectAddressAux[attributeName] = attributeValue;
            const projectAux = { ...project, project_address: projectAddressAux };
            setProject(projectAux);
            
        }
    }

    const handleChangeProjectAttribute = (attributeName, attributeValue) => {
        if( project ){
            const projectAux = { ...project };
            projectAux[attributeName] = attributeValue;
            setProject(projectAux);
            
        }
    }

    const handleChangeProjectType = (attributeName, projectTypeId) => {
        if( project ){
            let projectAux = { ...project };

            console.debug('[EditProject][handleChangeProjectType] projectTypeId: ', projectTypeId);

            const projectTypeSelected = projectTypes.find( (projectType) => projectType.id == projectTypeId );

            projectAux.project_type = projectTypeSelected;

            setProject(projectAux);
        }
    }

    const handleMapPlaceSelect = (place) => {
        const newAddress = mergeAddress(place, project.project_address);
        console.debug('[EditProject][handlePlaceSelect]newAddress: ', newAddress);

        const projectAux = { ...project, project_address: newAddress };
        setProject(projectAux);

    }
    

    return (
        
        <PrincipalLayout section={constants.menu.admin_clients_section} isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Project: {project ? project.name : ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Name"} 
                                    fieldValue={ project ? project.name: '' } 
                                    fieldId={'input-project-name'} 
                                    attributeName={'name'} 
                                    handleChangeField={handleChangeProjectAttribute}
                                    required={true} 
                                />

                                <AutocompleteComponent
                                    fieldId={'input-project-client'}
                                    fieldName={'Client'} 
                                    fieldValue={project ? project.client : null}
                                    attributeName={'client'}
                                    optionList={activeClients}
                                    isClient={true}
                                    handleChangeField={handleChangeProjectAttribute}

                                />

                                <SelectComponent
                                    fieldName={"Project type"} 
                                    fieldValue={ project && project.project_type ? project.project_type.id: '' } 
                                    fieldId={'input-project-project_type'} 
                                    attributeName={'project_type'} 
                                    optionList={projectTypes}
                                    optionAttributeName={'name'}
                                    handleChangeField={handleChangeProjectType}
                                />

                                <DateComponent
                                    fieldName={"Planned start"} 
                                    fieldValue={ project ? project.start_date: '' } 
                                    fieldId={'input-project-start_date'} 
                                    attributeName={'start_date'} 
                                    handleChangeField={handleChangeProjectAttribute}
                                    required={true} 
                                />

                                

                                <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                    <Divider/>
                                </Grid>

                                <InputComponent 
                                    fieldName={"Property details"} 
                                    fieldValue={ project ? project.property_details : '' } 
                                    fieldId={'input-project-property_details'} 
                                    attributeName={'property_details'} 
                                    handleChangeField={handleChangeProjectAttribute}
                                    required={true} 
                                    multiline={true}
                                    rows={4}
                                />

                               
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <InputComponent 
                                    fieldName={"Street"} 
                                    fieldValue={ project ? project.project_address.street : '' } 
                                    fieldId={'input-project-street'} 
                                    attributeName={'street'} 
                                    handleChangeField={handleChangeAddressAttribute} 
                                    required={true}
                                />
                                <InputComponent 
                                    fieldName={"Number"} 
                                    fieldValue={ project ? project.project_address.number : '' } 
                                    fieldId={'input-project-number'} 
                                    attributeName={'number'} 
                                    handleChangeField={handleChangeAddressAttribute} 
                                    required={true}
                                />
                                <InputComponent 
                                    fieldName={"City"} 
                                    fieldValue={ project ? project.project_address.city : '' } 
                                    fieldId={'input-project-city'} 
                                    attributeName={'city'} 
                                    handleChangeField={handleChangeAddressAttribute} 
                                    required={true}
                                />
                                <InputComponent 
                                    fieldName={"State"} 
                                    fieldValue={ project ? project.project_address.locality : '' } 
                                    fieldId={'input-project-locality'} 
                                    attributeName={'locality'} 
                                    handleChangeField={handleChangeAddressAttribute} 
                                    required={true}
                                />
                                <InputComponent 
                                    fieldName={"Country"} 
                                    fieldValue={ project ? project.project_address.country : '' } 
                                    fieldId={'input-project-country'} 
                                    attributeName={'country'} 
                                    handleChangeField={handleChangeAddressAttribute} 
                                    required={true}
                                />
                                <InputComponent 
                                    fieldName={"Postal code"} 
                                    fieldValue={ project ? project.project_address.postal_code : '' } 
                                    fieldId={'input-project-postal_code'} 
                                    attributeName={'postal_code'} 
                                    handleChangeField={handleChangeAddressAttribute} 
                                    required={true}
                                />

                                <SwitchComponent 
                                    fieldName={"Is bill address"} 
                                    fieldValue={ project ? project.project_address.is_bill_address : false } 
                                    fieldId={'input-project-is_bill_address'} 
                                    attributeName={'is_bill_address'} 
                                    handleChangeField={handleChangeAddressAttribute} 
                                />

                                <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                    <Typography sx={constants.styles.textNormal} >
                                        Map :
                                    </Typography>
                                </Grid>

                                <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                    { project != null && project.project_address != null && ( 

                                            <MapPlaceAutocomplete 
                                                mapPosition={mapPosition}
                                                handleMapPlaceSelect={handleMapPlaceSelect}
                                                selectedPlace={selectedPlace}
                                                setSelectedPlace={setSelectedPlace}
                                            />

                                        )
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                        
                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
        </PrincipalLayout>

        
    );
}

export default EditProject;