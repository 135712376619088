//Notification Service
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { notificationTypes, roles, status } from "./StatusService";
import { convertDateStrToString, createEmptyNotificationForInvoice, createEmptyNotificationForQuote, createEmptyNotificationForRequest, createEmptyNotificationForWorkOrder } from "./ValidationService";
import { getUserData } from "./SecurityService";


export const searchNotificationsByAnyAttribute = async(anyInfo, userId, roleCode, tenanId) => {
    var response = await axios.get( constants.api.notificationApiUrl + '/' + tenanId + '/shorten/filter_any', { 
        params: {
            any_attribute: anyInfo,
            user_id: userId,
            role_code: roleCode
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const updateNotification = async(notifications, tenanId) => {

    const user = getUserData();
    notifications.user_id = user.uuid;

    console.debug('[NotificationService][updateNotification] notifications: ', notifications);

    var response = await axios.put( constants.api.notificationApiUrl + '/' + tenanId + '/', notifications, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteNotification = async(notifications, tenanId) => {

    const user = getUserData();
    notifications.user_id = user.uuid;
    notifications.status = status.notification.deleted.status;

    console.debug('[NotificationService][logicalDeleteNotification] notifications: ', notifications);

    var response = await axios.put( constants.api.notificationApiUrl + '/' + tenanId + '/', notifications, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}




export const sendBroadcastNotification = (notification, tenantId , stompClient) => {
    if( stompClient ){
        
        notification.event_type = notificationTypes.broadcast_event;
        notification.is_broadcast = true;

        console.debug('[NotificationsService][sendBroadcastNotification]', notification);

        stompClient.publish({
                destination: constants.notifications.broadcastTopic + tenantId,
                body: JSON.stringify( notification ),
        });
    }
}

//---------------------------------- Invoice notifications ----------------------------------


export const sendBroadcastRoleAdministratorInvoiceDepositSentNotification = (userId, invoice, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorInvoiceNotification(
                                                    userId, 
                                                    invoice, 
                                                    constants.notifications.invoiceDepositSent, 
                                                    notificationTypes.approved_by_client_role.type, 
                                                    tenantId , 
                                                    stompClient);
}

export const sendBroadcastRoleAdministratorInvoiceApprovedNotification = (userId, invoice, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorInvoiceNotification(
                                                    userId, 
                                                    invoice, 
                                                    constants.notifications.invoiceApproved, 
                                                    notificationTypes.approved_by_client_role.type, 
                                                    tenantId , 
                                                    stompClient);
}


export const sendBroadcastRoleAdministratorInvoiceNotification = (userId, invoice, message, eventType, tenantId , stompClient) => {

    let notification = createEmptyNotificationForInvoice(userId, invoice);
    notification.message = message;
    notification.event_type = eventType;

    sendBroadcastRoleAdministratorNotification(notification, tenantId , stompClient);
}


//---------------------------------- Work order notifications ----------------------------------

export const sendBroadcastRoleAdministratorWorkOrderApprovedNotification = (userId, workOrder, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorWorkOrderNotification(
                                                    userId, 
                                                    workOrder, 
                                                    constants.notifications.workOrderApproved, 
                                                    notificationTypes.approved_by_client_role.type, 
                                                    tenantId , 
                                                    stompClient);
}


export const sendBroadcastRoleAdministratorLineItemApprovedNotification = (userId, workOrder, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorWorkOrderNotification(
                                                    userId, 
                                                    workOrder, 
                                                    constants.notifications.workOrderLineItemApproved, 
                                                    notificationTypes.approved_by_client_role.type, 
                                                    tenantId , 
                                                    stompClient);
}

export const sendBroadcastRoleAdministratorWorkOrderCompletedNotification = (userId, workOrder, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorWorkOrderNotification(
                                                    userId, 
                                                    workOrder, 
                                                    constants.notifications.workOrderCompleted, 
                                                    notificationTypes.pending_review_role.type, 
                                                    tenantId , 
                                                    stompClient);
}

export const sendBroadcastRoleAdministratorLineItemCompletedNotification = (userId, workOrder, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorWorkOrderNotification(
                                                    userId, 
                                                    workOrder, 
                                                    constants.notifications.workOrderLineItemCompleted, 
                                                    notificationTypes.pending_review_role.type, 
                                                    tenantId , 
                                                    stompClient);
}


export const sendBroadcastRoleAdministratorWorkOrderOngoingNotification = (userId, workOrder, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorWorkOrderNotification(
                                                    userId, 
                                                    workOrder, 
                                                    constants.notifications.workOrderOnGoing, 
                                                    notificationTypes.approved_by_client_role.type, 
                                                    tenantId , 
                                                    stompClient);
}

export const sendBroadcastRoleStaffWorkOrderOngoingNotification = (userId, workOrder, tenantId , stompClient) => {
    sendBroadcastRoleStaffWorkOrderNotification(
                                                    userId, 
                                                    workOrder, 
                                                    constants.notifications.workOrderOnGoing, 
                                                    notificationTypes.approved_by_client_role.type, 
                                                    tenantId , 
                                                    stompClient);
}

export const sendBroadcastRoleAdministratorWorkOrderNotification = (userId, workOrder, message, eventType, tenantId , stompClient) => {

    let notification = createEmptyNotificationForWorkOrder(userId, workOrder);
    notification.message = message;
    notification.event_type = eventType;

    sendBroadcastRoleAdministratorNotification(notification, tenantId , stompClient);
}


export const sendBroadcastRoleStaffWorkOrderNotification = (userId, workOrder, message, eventType, tenantId , stompClient) => {

    let notification = createEmptyNotificationForWorkOrder(userId, workOrder);
    notification.message = message;
    notification.event_type = eventType;

    sendBroadcastRoleStaffNotification(notification, tenantId , stompClient);
}

//---------------------------------- Quote notifications ----------------------------------

export const sendBroadcastRoleAdministratorApprovedQuoteNotification = (userId, quote, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorQuoteNotification(
                                                    userId, 
                                                    quote, 
                                                    constants.notifications.quoteApproved, 
                                                    notificationTypes.approved_by_client_role.type, 
                                                    tenantId , 
                                                    stompClient);
}

export const sendBroadcastRoleAdministratorAssessmentQuoteNotification = (userId, quote, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorQuoteNotification(
                                                    userId, 
                                                    quote, 
                                                    constants.notifications.quoteRequiresAssessment, 
                                                    notificationTypes.pending_review_role.type, 
                                                    tenantId , 
                                                    stompClient);
}

export const sendBroadcastRoleAdministratorQuoteNotification = (userId, quote, message, eventType, tenantId , stompClient) => {

    let notification = createEmptyNotificationForQuote(userId, quote);
    notification.message = message;
    notification.event_type = eventType;

    sendBroadcastRoleAdministratorNotification(notification, tenantId , stompClient);
}


//---------------------------------- Request notifications ----------------------------------


export const sendBroadcastRoleAdministratorAssessmentRequestNotification = (userId, request, tenantId , stompClient) => {
    sendBroadcastRoleAdministratorRequestNotification(
                                                    userId, 
                                                    request, 
                                                    constants.notifications.requestRequiresAssessment, 
                                                    notificationTypes.pending_review_role.type, 
                                                    tenantId , 
                                                    stompClient);
}


export const sendBroadcastRoleAdministratorRequestNotification = (userId, request, message, eventType, tenantId , stompClient) => {

    let notification = createEmptyNotificationForRequest(userId, request);
    notification.message = message;
    notification.event_type = eventType;

    sendBroadcastRoleAdministratorNotification(notification, tenantId , stompClient);
}


//---------------------------------- Notifications by role ----------------------------------

export const sendBroadcastRoleUserNotification = (notification, tenantId , stompClient) => {
    if( stompClient ){
        console.debug('[NotificationsService][sendBroadcastRoleUserNotification] ...');
        notification.role.code = roles.user.code;
        sendBroadcastRoleNotification(roles.user.code, notification, tenantId, stompClient);
    }
}

export const sendBroadcastRoleAdministratorNotification = (notification, tenantId , stompClient) => {
    if( stompClient ){
        console.debug('[NotificationsService][sendBroadcastRoleAdministratorNotification] ...');
        notification.role.code = roles.administrator.code;
        sendBroadcastRoleNotification(roles.administrator.code, notification, tenantId, stompClient);
    }
}

export const sendBroadcastRoleStaffNotification = (notification, tenantId , stompClient) => {
    if( stompClient ){
        console.debug('[NotificationsService][sendBroadcastRoleStaffNotification] ...');
        notification.role.code = roles.staff.code;
        sendBroadcastRoleNotification(roles.staff.code, notification, tenantId, stompClient);
    }
}

export const sendBroadcastRoleNotification = (roleCode, notification, tenantId , stompClient) => {
    if( stompClient ){
        console.debug('[NotificationsService][sendBroadcastRoleNotification] notification: ', notification);
        stompClient.publish({
                destination: constants.notifications.broadcastRoleTopic + roleCode + '|' + tenantId,
                body: JSON.stringify( notification ),
        });
    }
}


//---------------------------------- Invoice user notifications ----------------------------------

export const sendUserInvoiceDepositRejectedNotification = (userId, invoice, tenantId, stompClient) => {

    sendUserInvoiceNotification(userId,  
                                invoice, 
                                constants.notifications.invoiceDepositRejected, 
                                notificationTypes.pending_review_client.type,
                                tenantId, 
                                stompClient);

}

export const sendUserInvoiceDepositApprovedNotification = (userId, invoice, tenantId, stompClient) => {

    sendUserInvoiceNotification(userId,  
                                invoice, 
                                constants.notifications.invoiceDepositApproved, 
                                notificationTypes.pending_review_client.type,
                                tenantId, 
                                stompClient);

}

export const sendUserInvoiceSentNotification = (userId, invoice, tenantId, stompClient) => {

    sendUserInvoiceNotification(userId,  
                                invoice, 
                                constants.notifications.invoiceSentToClient, 
                                notificationTypes.pending_approve_client.type,
                                tenantId, 
                                stompClient);

}

export const sendUserInvoiceNotification = (userId, invoice, message, eventType, tenantId, stompClient) => {

    if( invoice.client && invoice.client.user ){

        const user = invoice.client.user;
        const userClientId = user.id;
        let notification = createEmptyNotificationForInvoice(userId, invoice);
        notification.message = message;
        notification.event_type = eventType;

        notification.to_user.id = userClientId;

        sendUserNotification(userClientId, notification, tenantId, stompClient);
    }

}

//---------------------------------- Work order user notifications ----------------------------------

export const sendUserWorkOrderSentNotification = (userId, workOrder, tenantId, stompClient) => {

    const notificationMessage = constants.notifications.workOrderSentToClient + 
                                "\nStart date: " + convertDateStrToString( workOrder.start_date ) +
                                "\nEnd date: " + convertDateStrToString( workOrder.end_date );

    sendUserWorkOrderNotification(userId,  
                                workOrder, 
                                notificationMessage ,
                                notificationTypes.pending_approve_client.type,
                                tenantId, 
                                stompClient);

}


export const sendUserWorkOrderNotification = (userId, workOrder, message, eventType, tenantId, stompClient) => {

    if( workOrder.client && workOrder.client.user ){

        const user = workOrder.client.user;
        const userClientId = user.id;
        let notification = createEmptyNotificationForWorkOrder(userId, workOrder);
        notification.message = message;
        notification.event_type = eventType;

        notification.to_user.id = userClientId;

        sendUserNotification(userClientId, notification, tenantId, stompClient);
    }

}

//---------------------------------- Quote user notifications ----------------------------------

export const sendUserQuoteSentNotification = (userId, quote, tenantId, stompClient) => {

    sendUserQuoteNotification(userId,  
                                quote, 
                                constants.notifications.quoteSentToClient, 
                                notificationTypes.pending_approve_client.type,
                                tenantId, 
                                stompClient);

}

export const sendUserQuoteNotification = (userId, quote, message, eventType, tenantId, stompClient) => {

    if( quote.client && quote.client.user ){

        const user = quote.client.user;
        const userClientId = user.id;
        let notification = createEmptyNotificationForQuote(userId, quote);
        notification.message = message;
        notification.event_type = eventType;

        notification.to_user.id = userClientId;

        sendUserNotification(userClientId, notification, tenantId, stompClient);
    }

}

//---------------------------------- Request user notifications ----------------------------------

export const sendUserRequestAssessmentCompletedNotification = (userId, request, tenantId, stompClient) => {

    sendUserRequestNotification(userId,  
                                request, 
                                constants.notifications.requestAssessmentCompleted, 
                                notificationTypes.pending_review_client.type,
                                tenantId, 
                                stompClient);

}

export const sendUserRequestNotification = (userId, request, message, eventType, tenantId, stompClient) => {

    if( request.client && request.client.user ){

        const user = request.client.user;
        const userClientId = user.id;
        let notification = createEmptyNotificationForRequest(userId, request);
        notification.message = message;
        notification.event_type = eventType;

        notification.to_user.id = userClientId;
    

        sendUserNotification(userClientId, notification, tenantId, stompClient);
    }

}

//---------------------------------- User notifications ----------------------------------

export const sendUserNotification = (userId, notification, tenantId, stompClient) => {
    if( stompClient ){
        console.debug('[NotificationsService][sendUserNotification] notification: ', notification);
        console.debug('[NotificationsService][sendUserNotification] userId: ', userId);
        stompClient.publish({
                destination: constants.notifications.userMessageTopicPrefix + userId + '|' + tenantId,
                body: JSON.stringify( notification ),
        });
    }
}




export const sendNotification = (message) => {

    console.debug('[NotificationService][sendNotification] ...');


    if( Notification.permission === "granted" ) {
        const notification = new Notification('Job Tube notification', {
            icon: '/images/logo_job_tube_2.png',
            body: message
        });

        notification.onclick = () => {
            console.debug('[NotificationService][sendNotification] focus on actual window');
            window.focus();
        }
    }
    else {
        console.debug('[NotificationService][sendNotification] permissions not granteed');
    }

}


export const checkPermissionsNotifications = () => {

    console.debug('[NotificationService][checkPermissionsNotification] ...');


    if( !("Notification" in window) ) {

        console.debug("[NotificationService][checkPermissionsNotification] This browser does not support system notifications!");

    } else if( Notification.permission === "granted" ) {

        console.debug("[NotificationService][checkPermissionsNotification] Permissions granted");

    } else {

        console.debug("[NotificationService][checkPermissionsNotification] Ask for permissions");

        Notification.requestPermission( ( permission ) => {
            if (permission === "granted") {
                console.debug("[NotificationService][checkPermissionsNotification] Permissions agreed");

            }
        })

    } 
}





