import React, { useState } from "react";
import { Navigate, redirect, redirectDocument, useLocation, useNavigate } from "react-router-dom";
import { authenticateUser, setUserData } from "../../../service/tenant/SecurityService";
import constants from "../util/Constants";
import { Backdrop, Box, Button, Chip, CircularProgress, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Paper, Toolbar } from "@mui/material";
import { AccountCircle, KeySharp, Password, PasswordRounded, PasswordSharp, Visibility, VisibilityOff } from "@mui/icons-material";
import ErrorMessageChip from "../util/ErrorMessageChip";


const Login = () => {

    const [userId, setUserId] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showChip, setShowChip] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    const navigate = useNavigate();

    const handleLogin = () => {
       
        setIsLoading(true);
        console.debug('[Login][handleLogin] authenticateUser...');
        authenticateUser(userId, userPassword).then( userData => {
            
            if( userData != null){
                console.debug('[Login][handleLogin] userData: ', userData);
                setUserData(userData);
                clearState();
                navigate(constants.routes.home);
            } else {
                setErrorMessage(constants.errors.authenticationError);
                setShowChip(true);
            }

            setIsLoading(false);

        });

        
    }

    const clearState = () => {
        setUserId('');
        setUserPassword('');
        setErrorMessage('');
    }

    

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleChipDelete = (event) => {
        setShowChip(false);
    }


    return(
        <>
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={1} md={1}>
        </Grid>
        <Grid item xs={10} md={10}>
            <Paper sx={{marginTop:'300px'}} >
                <Toolbar sx={constants.styles.textHeader}>
                    Jobs Manager
                </Toolbar>
                <Grid container direction="column" spacing={2}
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                    
                    >
                    <Grid item xs={12} md={12}>
                        <ErrorMessageChip message={errorMessage} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-user-id" >
                                Type your user id
                            </InputLabel>
                            <Input
                                id="input-user-id"
                                value={ userId }
                                type={ 'text' }
                                sx={constants.styles.inputTextNormal}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                }
                                onChange={ (e) => setUserId(e.target.value) }
                            />
                        </FormControl>
                        <br></br>
                        <br></br>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-user-password" >
                                Type your password
                            </InputLabel>
                            <Input
                                id="input-user-password"
                                value={ userPassword }
                                type={showPassword ? 'text' : 'password'}
                                sx={constants.styles.inputTextNormal}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <KeySharp />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                            edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={ (e) => setUserPassword(e.target.value) }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{marginBottom:'10px'}}>
                        <Button variant="outlined" size="small" sx={constants.styles.textNormal} onClick={handleLogin}> Login </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={1} md={1}>
        </Grid>
        </>
    );
}

export default Login;