import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Paper, Tab, Tabs} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { createClient, getClientById, updateClient } from "../../../service/tenant/ClientService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation, useNavigate } from "react-router";
import InputComponent from "../util/InputComponent";
import { getAllUserTitles, getUserData } from "../../../service/tenant/SecurityService";
import SelectComponent from "../util/SelectComponent";
import ProjectsTable from "../projects/ProjectsTable";
import SwitchComponent from "../util/SwitchComponent";
import AttributeInputComponent from "../util/AttributeInputComponent";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createEmptyClient, validateClient } from "../../../service/tenant/ValidationService";
import MapPlaceAutocomplete from "../map/MapPlaceAutocomplete";
import { map_constants, mergeAddress } from "../map/MapUtils";
import { status } from "../../../service/tenant/StatusService";
import UploaderComponent from "../util/UploaderComponent";
import { searchWorkOrdersByAnyAttributeForClient } from "../../../service/tenant/WorkOrderService";
import WorkOrdersTable from "../workorders/WorkOrdersTable";



function EditClient( props ){
    
    const location = useLocation();
    const navigate = useNavigate();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    var clientId = location.state.clientId;
    const tenantId = tenant.id;

    const [client, setClient] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userTitles, setUserTitles] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [workOrderList, setWorkOrderList] = useState([]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [mapPosition, setMapPosition] = useState({
        lat: map_constants.defaultGeometry.latitude,
        lng: map_constants.defaultGeometry.longitude
    });


    useEffect(() => {
        
        console.debug('[EditClient] clientId: ', clientId);

        setIsLoading(true);

        if( clientId != null && clientId != '' ){
            getClientById(clientId, tenantId).then( client => {

                if( client !== null ){
                    console.debug('[EditClient][useEffect] client: ', client);
                    setClient(client);

                    if( client.project_list ){
                        const activeProjectList = client.project_list.filter( (project) =>
                            project.status == status.projectStatus.active
                        );

                        setProjectList(activeProjectList);
                    }

                    setMapPosition({
                        lat: client.client_address.latitude,
                        lng: client.client_address.longitude
                    });

                    searchWorkOrdersByAnyAttributeForClient('', clientId, '', '', tenantId).then( workOrderListResponse => {

                        if( workOrderListResponse !== null ){
                            console.debug('[EditClient][handleSearchWorkOrders] workOrderListResponse: ', workOrderListResponse);
            
                            if( workOrderListResponse.length > 0 ){
                                setWorkOrderList(workOrderListResponse);
                            } 
                        } else {
                            setErrorMessage(constants.errors.searchWorkOrderError);
                        }
            
                        setIsLoading(false);
                        
                    });

                } else {
                    setIsLoading(false);
                }
                
            });
        } else {

            const newClient = createEmptyClient(userData.uuid);
            console.debug('[EditClient][useEffect] newClient: ', newClient);
            setClient(newClient);
            setProjectList([]);

            setIsLoading(false);
        }


        getAllUserTitles().then( allUserTitles =>{
            if( allUserTitles !== null ){
                setUserTitles(allUserTitles);
            }
        });

    }, [location.state.clientId]);
    


    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        const isValidUser = validateClient(client);

        console.debug('[EditClient][handleClickSave] client: ', client);
        console.debug('[EditClient][handleClickSave] clientId: ', clientId);

        if( isValidUser && client.id != null && client.id != ''  ){
            handleUpdateClient();
        } else if( isValidUser && ( client.id == null || client.id == '' ) ){
            handleCreateClient();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateClient = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateClient(client, tenantId).then( clientResponse => {

            if( clientResponse != null ){
                console.debug('[EditClient][handleUpdateClient] clientResponse: ', clientResponse);
                setClient(clientResponse);
                setSuccessMessage(constants.errors.editClientSuccess);
            } else {
                setErrorMessage(constants.errors.editClientError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateClient = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createClient(client, tenantId).then( clientResponse => {

            if( clientResponse != null ){
                console.debug('[EditClient][handleCreateClient] clientResponse: ', clientResponse);
                setClient(clientResponse);
                clientId = clientResponse.id;
                console.debug('[EditClient][handleCreateClient] clientId: ', clientId);
                setSuccessMessage(constants.errors.createClientSuccess);
            } else {
                setErrorMessage(constants.errors.createClientError)
            }

            setIsLoading(false);
        });

    }



    const handleChangeUserAttribute = (attributeName, attributeValue) => {
        if( client ){
            
            const userAux = { ...client.user };
            userAux[attributeName] = attributeValue;
            const clientAux = { ...client, user: userAux };
            setClient(clientAux);
            
        }
    }

    const handleChangeAddressAttribute = (attributeName, attributeValue) => {
        if( client ){
            
            let clientAddressAux = { ...client.client_address };
            clientAddressAux[attributeName] = attributeValue;
            const clientAux = { ...client, client_address: clientAddressAux };
            setClient(clientAux);
            
        }
    }

    const handleChangeClientAttribute = (attributeName, attributeValue) => {
        if( client ){
            const clientAux = { ...client };
            clientAux[attributeName] = attributeValue;
            setClient(clientAux);
            
        }
    }

    const handleMapPlaceSelect = (place) => {
        const newAddress = mergeAddress(place, client.client_address);
        console.debug('[EditClient][handleMapPlaceSelect]newAddress: ', newAddress);

        const clientAux = { ...client, client_address: newAddress };
        setClient(clientAux);

    }

    const handleChangeClientCustomAttribute = (attributeName, index, attributeValue) => {
        if( client && client.client_attribute_list ){
           
            const clientAttributeListAux = { ...client.client_attribute_list };

            let clientAttributeArrayAux = Object.entries(clientAttributeListAux).map(
                (clientCustomAttribute) => clientCustomAttribute[1]
            ); 

            clientAttributeArrayAux[index][attributeName] = attributeValue;
            let clientAux = { ...client };
            clientAux['client_attribute_list'] = clientAttributeArrayAux;

            setClient(clientAux);
        }
    }

    const handleAddProject = () => {
        console.debug('[EditClient][handleAddProject] ');
        navigate(constants.routes.editProject, {
            state: {
                projectId: ''
            }
        });
    }

    const handleEditProject = (projectId) => {
        console.debug('[EditClient][handleEditProject] projectId: ', projectId);
        navigate(constants.routes.editProject, {
            state: {
                projectId: projectId
            }
        });
    }

    const handleDeleteProject = (projectId) => {
        console.debug('[EditClient][handleDeleteProject] projectId: ', projectId);
    }

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    

    return (
        <PrincipalLayout section={constants.menu.admin_clients_section} isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Client: {client ? client.user.first_name + ' ' + client.user.last_name: ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={5}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <SelectComponent
                                    fieldName={"Title"} 
                                    fieldValue={ client ? client.user.title: '' } 
                                    fieldId={'input-client-title'} 
                                    attributeName={'title'} 
                                    optionList={userTitles}
                                    optionAttributeName={'title'}
                                    handleChangeField={handleChangeUserAttribute}

                                />

                                <InputComponent 
                                    fieldName={"First name"} 
                                    fieldValue={ client ? client.user.first_name: '' } 
                                    fieldId={'input-client-first_name'} 
                                    attributeName={'first_name'} 
                                    handleChangeField={handleChangeUserAttribute}
                                    required={true} 
                                />

                                <InputComponent 
                                    fieldName={"Last name"} 
                                    fieldValue={ client ? client.user.last_name: '' } 
                                    fieldId={'input-client-last_name'} 
                                    attributeName={'last_name'} 
                                    handleChangeField={handleChangeUserAttribute} 
                                    required={true}
                                />

                                <InputComponent 
                                    fieldName={"Company"} 
                                    fieldValue={ client ? client.company_name: '' } 
                                    fieldId={'input-client-company_name'} 
                                    attributeName={'company_name'} 
                                    handleChangeField={handleChangeClientAttribute} 
                                    required={false}
                                />

                                <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                    <Divider/>
                                </Grid>

                                <InputComponent 
                                    fieldName={"User id"} 
                                    fieldValue={ client ? client.user.uuid: '' } 
                                    fieldId={'input-client-uuid'} 
                                    attributeName={'uuid'} 
                                    handleChangeField={handleChangeUserAttribute}
                                    required={true} 
                                />

                                <InputComponent 
                                    fieldName={"Password"} 
                                    fieldValue={ client ? client.user.password_hash: '' } 
                                    fieldId={'input-client-password_hash'} 
                                    attributeName={'password_hash'} 
                                    handleChangeField={handleChangeUserAttribute} 
                                    required={true}
                                    passwordValidation={true}
                                />

                                <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                    <Divider/>
                                </Grid>

                                <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                    <Tabs 
                                        value={currentTabIndex} 
                                        onChange={handleTabChange} 
                                        sx={constants.styles.sectionInput}>
                                        <Tab label='Contact' sx={constants.styles.tableHeader}  />
                                        <Tab label='Address' sx={constants.styles.tableHeader}  />
                                        <Tab label='Notifications' sx={constants.styles.tableHeader}  />
                                    </Tabs>
                                </Grid>
                                {
                                    currentTabIndex == 0 &&
                                    (
                                        <>
                                           
                                            <InputComponent 
                                                fieldName={"Email"} 
                                                fieldValue={ client ? client.user.email: '' } 
                                                fieldId={'input-client-email'} 
                                                attributeName={'email'} 
                                                handleChangeField={handleChangeUserAttribute} 
                                                required={true}
                                                emailValidation={true}
                                            />
                                            <InputComponent 
                                                fieldName={"Phone"} 
                                                fieldValue={ client ? client.user.phone: '' } 
                                                fieldId={'input-client-phone'} 
                                                attributeName={'phone'} 
                                                handleChangeField={handleChangeUserAttribute} 
                                                required={true}
                                                phoneValidation={true}
                                                />
                                        </>
                                    )
                                }

                                {
                                    currentTabIndex == 1 &&
                                    (
                                        <>
                                            <InputComponent 
                                                fieldName={"Street"} 
                                                fieldValue={ client ? client.client_address.street : '' } 
                                                fieldId={'input-client-street'} 
                                                attributeName={'street'} 
                                                handleChangeField={handleChangeAddressAttribute} 
                                                required={true}
                                            />
                                            <InputComponent 
                                                fieldName={"Number"} 
                                                fieldValue={ client ? client.client_address.number : '' } 
                                                fieldId={'input-client-number'} 
                                                attributeName={'number'} 
                                                handleChangeField={handleChangeAddressAttribute} 
                                                required={true}
                                            />
                                            <InputComponent 
                                                fieldName={"City"} 
                                                fieldValue={ client ? client.client_address.city : '' } 
                                                fieldId={'input-client-city'} 
                                                attributeName={'city'} 
                                                handleChangeField={handleChangeAddressAttribute} 
                                                required={true}
                                            />
                                            <InputComponent 
                                                fieldName={"State"} 
                                                fieldValue={ client ? client.client_address.locality : '' } 
                                                fieldId={'input-client-locality'} 
                                                attributeName={'locality'} 
                                                handleChangeField={handleChangeAddressAttribute} 
                                                required={true}
                                            />
                                            <InputComponent 
                                                fieldName={"Country"} 
                                                fieldValue={ client ? client.client_address.country : '' } 
                                                fieldId={'input-client-country'} 
                                                attributeName={'country'} 
                                                handleChangeField={handleChangeAddressAttribute} 
                                                required={true}
                                            />
                                            <InputComponent 
                                                fieldName={"Postal code"} 
                                                fieldValue={ client ? client.client_address.postal_code : '' } 
                                                fieldId={'input-client-postal_code'} 
                                                attributeName={'postal_code'} 
                                                handleChangeField={handleChangeAddressAttribute} 
                                                required={true}
                                            />

                                            <SwitchComponent 
                                                fieldName={"Is bill address"} 
                                                fieldValue={ client ? client.client_address.is_bill_address : false } 
                                                fieldId={'input-client-is_bill_address'} 
                                                attributeName={'is_bill_address'} 
                                                handleChangeField={handleChangeAddressAttribute} 
                                            />

                                            <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                                <Typography sx={constants.styles.textNormal} >
                                                    Map :
                                                </Typography>
                                            </Grid>

                                            <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                                { client != null && client.client_address != null && ( 

                                                        <MapPlaceAutocomplete 
                                                            mapPosition={mapPosition}
                                                            handleMapPlaceSelect={handleMapPlaceSelect}
                                                            selectedPlace={selectedPlace}
                                                            setSelectedPlace={setSelectedPlace}
                                                        />

                                                    )
                                                }
                                            </Grid>
                                        </>
                                    )
                                }

                                {
                                    currentTabIndex == 2 &&
                                    (
                                        <>
                                            <SwitchComponent 
                                                fieldName={"Quotes"} 
                                                fieldValue={ client ? client.quote_notifications_on : false } 
                                                fieldId={'input-client-quote_notifications_on'} 
                                                attributeName={'quote_notifications_on'} 
                                                handleChangeField={handleChangeClientAttribute} 
                                            />

                                            <SwitchComponent 
                                                fieldName={"Jobs"} 
                                                fieldValue={ client ? client.job_notifications_on : false } 
                                                fieldId={'input-client-job_notifications_on'} 
                                                attributeName={'job_notifications_on'} 
                                                handleChangeField={handleChangeClientAttribute} 
                                            />

                                            <SwitchComponent 
                                                fieldName={"Invoices"} 
                                                fieldValue={ client ? client.invoice_notifications_on : false } 
                                                fieldId={'input-client-invoice_notifications_on'} 
                                                attributeName={'invoice_notifications_on'} 
                                                handleChangeField={handleChangeClientAttribute} 
                                            />
                                            
                                        </>
                                    )
                                }

                                <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>

                                    <UploaderComponent
                                        object={client}
                                        prefix={'client'}
                                        folder={'clients'}
                                        imageListAttributeName={'client_image_list'}
                                        setObject={setClient}
                                        saveObject={handleClickSave}
                                    />

                                    {  client && client.client_attribute_list ?
                                        
                                        client.client_attribute_list.map( (customAttribute, index) => (
                                            
                                            <AttributeInputComponent
                                                key={index}
                                                index={index}
                                                fieldName={customAttribute.attribute.name} 
                                                fieldValue={ customAttribute.vale_str } 
                                                fieldId={'input-client-vale_str-' + index} 
                                                attributeName={'vale_str'} 
                                                handleChangeField={handleChangeClientCustomAttribute} 
                                                required={true}

                                            />

                                        ))
                                        :
                                        <></>
                                    }

                                </Grid>
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <ProjectsTable 
                                projectList={projectList} 
                                handleEditProject={handleEditProject} 
                                handleDeleteProject={handleDeleteProject}
                                handleAddProject={handleAddProject} 
                            />
                            
                            <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                <Divider/>
                                <br/>
                            </Grid>


                            <WorkOrdersTable 
                                showClient={false}
                                workOrderList={workOrderList}
                            />

                        </Grid>
                        
                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >

                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
        </PrincipalLayout>
    );
}

export default EditClient;