import React, { useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress, Input, InputAdornment } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuDrawer from "./MenuDrawer";
import { Search } from "@mui/icons-material";
import ToolbarMenuIcons from "./ToolbarMenuIcons";
import { useNavigate } from "react-router";
import constants from "../util/Constants";
import { getUserData } from "../../../service/tenant/SecurityService";
import { useSubscription } from "react-stomp-hooks";
import { searchNotificationsByAnyAttribute, sendNotification } from "../../../service/tenant/NotificationsService";
import { status } from "../../../service/tenant/StatusService";

const drawerWidth = 200;

function PrincipalLayout(props){
    
    const navigate = useNavigate();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    let userId = '';
    let tenantId = '';
    let roleCode = '';
    userId = userData.id;

    const roleList = userData.role_list;
    if( roleList && roleList.length > 0 ){
        const primaryRole = roleList[0];
        roleCode = primaryRole.code;
    }
    tenantId = tenant.id;

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [fetchNotifications, setFetchNotifications] = useState(false);
    
    
    useEffect(() => {

        searchNotificationsByAnyAttribute('', userId, roleCode, tenantId).then( notificationsResponse => {
            if( notificationsResponse != null ){
                const notificationsCount = notificationsResponse.length;
                const activeNotifications = notificationsResponse.filter( (notification) => notification.status === status.notification.active.status);
                props.setNotificationNumber(activeNotifications.length);
            }
        });

    }, [])

    useEffect(() => {

        searchNotificationsByAnyAttribute('', userId, roleCode, tenantId).then( notificationsResponse => {
            if( notificationsResponse != null ){
                const activeNotifications = notificationsResponse.filter( (notification) => notification.status === status.notification.active.status);
                props.setNotificationNumber(activeNotifications.length);
            }
        });

    }, [fetchNotifications])


    useSubscription(constants.notifications.broadcastReply + tenantId, (message) => {
        if( message && message.body ){

            setFetchNotifications(!fetchNotifications);

            const notification = JSON.parse(message.body);
            console.log('[PrincipalLayout] notification: ', notification);

            sendNotification(notification.message);
        }
    });

    

    useSubscription( constants.notifications.broadcastRoleReply + roleCode + '|' + tenantId , (messageForRole) => {
        if( messageForRole && messageForRole.body ){
           
            setFetchNotifications(!fetchNotifications);

            const notification = JSON.parse(messageForRole.body);
            console.log('[PrincipalLayout] notification: ', notification);

            sendNotification(notification.message);
        }
    });

    

    useSubscription( constants.notifications.userMessageQueueReplyPrefix + userId + '|' + tenantId , (messageForUser) => {
        if( messageForUser && messageForUser.body ){
            
            setFetchNotifications(!fetchNotifications);

            const notification = JSON.parse(messageForUser.body);
            console.log('[PrincipalLayout] notification: ', notification);

            sendNotification(notification.message);
        }
    });

    
    


    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const navigateToHome = () => {
        navigate(constants.routes.home);
    }

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;



    return (

    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
            <AppBar
            position="fixed"
            sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
            }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                    <MenuIcon />
                    </IconButton>


                    <Typography
                        noWrap component="div" 
                        sx={constants.styles.textHeader}  
                        onClick={navigateToHome}
                    >
                        Jobs Manager 
                        
                        <Typography 
                            noWrap
                            sx={constants.styles.textTitleUser}
                            >
                            { userData != null ? userData.first_name + ' ' + userData.last_name : '' } 
                        </Typography>
                        
                    </Typography>

                    

                    <Typography
                            noWrap component="div" 
                            sx={{  flex: 1 }}  
                            variant="h6" 
                    >
                    </Typography>

                    

                    {
                        /**
                         * TODO: Implement search
                         */
                    }

                    <Input
                        sx={constants.styles.ipnputSearch}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                >
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                        
                    />

                    <ToolbarMenuIcons
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
                    />

                </Toolbar>
            </AppBar>
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, marginRight: 0, marginLeft: 0 }}
            aria-label="mailbox folders"
            
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                <MenuDrawer />
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                <MenuDrawer section={props.section} />
            </Drawer>
        </Box>
        <Box
            component="main"
            sx={{
                    width: {
                        xs: 1, 
                        sm: 1, 
                        md: 1, 
                        lg: 1, 
                        xl: 1 
                    },
                    marginLeft: '5px',
                    marginRight: '5px',
                    marginTop: '5px'
                }}
        >

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={props.isLoading != null ? props.isLoading : false}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {
                props.children ?
                    props.children
                    :
                    <></>

            }
        </Box>
    </Box>
    );
}

PrincipalLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default PrincipalLayout;