import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, Paper} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { calculatDiscountInvoice, calculateSubTotalInvoice, calculatGrandTotalInvoice, calculatInvoiceBalance, calculatTaxesInvoice, calculatTotalInvoice, createInvoice, depositTypes, getAllPaymentMethods, getInvoiceById, updateInvoice } from "../../../service/tenant/InvoiceService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation, useNavigate } from "react-router";
import InputComponent from "../util/InputComponent";
import SwitchComponent from "../util/SwitchComponent";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { convertDateStrToString, createEmptyInvoiceDeposit, createEmptyInvoiceItem, parseToFloat, parseToFloatAmount, validateInvoice, validateInvoiceDeposit } from "../../../service/tenant/ValidationService";
import { getShortenClientById } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import { getAllActiveItems } from "../../../service/tenant/ItemService";
import UploaderComponent from "../util/UploaderComponent";
import ConfirmDialog from "../util/ConfirmDialog";
import { getInvoiceStatusName, isInvoiceDepositPendingApproval, isInvoiceEditable, isInvoicePaid, isInvoicePendingApproval, isLineItemApprovable, status } from "../../../service/tenant/StatusService";
import { sendInvoiceEmail } from "../../../service/tenant/EmailService";
import { getAllReminderTypes, searchWorkOrdersByAnyAttributeForClient } from "../../../service/tenant/WorkOrderService";
import { searchJobsByAnyAttribute } from "../../../service/tenant/JobService";
import DateComponent from "../util/DateComponent";
import SelectComponent from "../util/SelectComponent";
import QuoteItemDetails from "../quotes/QuoteItemDetails";
import { Delete, Done, EditNote, PauseCircle } from "@mui/icons-material";
import { getClientLocalStorage, getUserData } from "../../../service/tenant/SecurityService";
import { useStompClient } from "react-stomp-hooks";
import { sendBroadcastRoleAdministratorInvoiceApprovedNotification, sendBroadcastRoleAdministratorInvoiceDepositSentNotification } from "../../../service/tenant/NotificationsService";
import { discountTypesCatalog } from "../../../service/tenant/QuoteService";


function EditInvoiceUsers( props ){
    
    const location = useLocation();
    const tenant = getTenantLocalStorage();
    const client = getClientLocalStorage();
    const userData = getUserData();
    const userId = userData.id;
    var invoiceId = location.state.invoiceId;
    const tenantId = tenant.id;
    const navigate = useNavigate();

    const [invoice, setInvoice] = useState(null);
    const [clientAddress, setClientAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeProjects, setActiveProjects] = useState([]);
    const [activeJobs, setActiveJobs] = useState([]);
    const [activeWorkOrders, setActiveWorkOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeItems, setActiveItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [reminderTypes, setReminderTypes] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [openAddDeposit, setOpenAddDeposit] = useState(false);
    const [invoiceDeposit, setInvoiceDeposit] = useState(null);
    const [errorMessageDialog, setErrorMessageDialog] = useState('');

    const [saveEvent, setSaveEvent] = useState(false);
    const [isTotalPendingBalance, setIsTotalPendingBalance] = useState(false);
    
    const stompClient = useStompClient();

    useEffect(() => {
        
        console.debug('[EditInvoiceUsers] invoiceId: ', invoiceId);

        setIsLoading(true);


        if( invoiceId != null && invoiceId != '' ){
            getInvoiceById(invoiceId, tenantId).then( invoice => {

                if( invoice != null ){
                    console.debug('[EditInvoiceUsers][useEffect] invoice: ', invoice);
                    setInvoice(invoice);
                }
                setIsLoading(false);
            });
        } 


        setIsLoading(true);
        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse ){
                setActiveItems(activeItemsResponse);
            }

            setIsLoading(false);
        })

        setIsLoading(true);
        getAllReminderTypes().then( allReminderTypes =>{
            if( allReminderTypes !== null ){
                setReminderTypes(allReminderTypes);
            }
            setIsLoading(false);
        });


        setIsLoading(true);
        getAllPaymentMethods().then( allPaymentMethods =>{
            if( allPaymentMethods !== null ){
                setPaymentMethods(allPaymentMethods);
            }
            setIsLoading(false);
        });


        if( client && client.id ){

            console.log('[EditInvoiceUserss][useEffect] client: ', client);
            const clientId = client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

            setIsLoading(true);
            getShortenClientById(clientId, tenantId).then( clientResponse => {
                if( clientResponse != null ){
                    setClientAddress(clientResponse.client_address);
                }
                setIsLoading(false);
            });

            setIsLoading(true);
            searchJobsByAnyAttribute('', clientId, '', tenantId).then( activeJobsResponse => {
                if( activeJobsResponse != null ){
                    setActiveJobs(activeJobsResponse);
                }
                setIsLoading(false);
            });

            setIsLoading(true);
            searchWorkOrdersByAnyAttributeForClient('', clientId, '', '', tenantId).then( activeWorkOrdersResponse => {
                if( activeWorkOrdersResponse != null ){
                    setActiveWorkOrders(activeWorkOrdersResponse);
                }
                setIsLoading(false);
            });

        }


    }, [location.state.invoiceId]);
    

    useEffect( () => {
        if( invoice ){

            console.log('[EditInvoiceUsers][useEffect] saveEvent: ');
            
            handleClickSave();

        }
    }, [saveEvent]);



    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        let isValidInvoice = validateInvoice(invoice);
        isValidInvoice = isValidInvoice && ( invoice.client != null );

        console.debug('[EditInvoiceUsers][handleClickSave] invoice: ', invoice);
        console.debug('[EditInvoiceUsers][handleClickSave] invoiceId: ', invoiceId);

        if( isValidInvoice && invoice.id != null && invoice.id != ''  ){
            handleUpdateInvoice();
        } else if( isValidInvoice && ( invoice.id == null || invoice.id == '' ) ){
            handleCreateInvoice();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateInvoice = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateInvoice(invoice, tenantId).then( invoiceResponse => {

            if( invoiceResponse != null ){
                console.debug('[EditInvoiceUsers][handleUpdateInvoice] invoiceResponse: ', invoiceResponse);
                setInvoice(invoiceResponse);
                setSuccessMessage(constants.errors.editInvoiceSuccess);
            } else {
                setErrorMessage(constants.errors.editInvoiceError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateInvoice = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createInvoice(invoice, tenantId).then( invoiceResponse => {

            if( invoiceResponse != null ){
                console.debug('[EditInvoiceUsers][handleCreateInvoice] invoiceResponse: ', invoiceResponse);
                setInvoice(invoiceResponse);
                invoiceId = invoiceResponse.id;
                console.debug('[EditInvoiceUsers][handleCreateInvoice] invoiceId: ', invoiceId);
                setSuccessMessage(constants.errors.createInvoiceSuccess);
            } else {
                setErrorMessage(constants.errors.createInvoiceError)
            }

            setIsLoading(false);
        });

    }

    const handleChangeInvoiceDeposit = (attributeName, attributeValue) => {
        if( invoiceDeposit ){
            let invoiceDepositAux = {...invoiceDeposit};
            invoiceDepositAux[attributeName] = attributeValue;
            setInvoiceDeposit(invoiceDepositAux);
        }
    }

    const handleChangeInvoiceDepositAmount = (attributeName, attributeValue) => {
        if( invoiceDeposit ){
            let invoiceDepositAux = {...invoiceDeposit};
            const amount = parseToFloat(attributeValue);
            invoiceDepositAux.amount = amount;
            setInvoiceDeposit(invoiceDepositAux);
        }
    }

    const handleChangeDepositTotalBalance = (attributeName, attributeValue) => {
        if( invoiceDeposit ){
            setIsTotalPendingBalance(attributeValue);
            if( attributeValue == true ){
                let invoiceDepositAux = {...invoiceDeposit};
                invoiceDepositAux.amount = calculatInvoiceBalance(invoice);
                setInvoiceDeposit(invoiceDepositAux);
            }
        }
    }

    const handleChangeInvoiceAttribute = (attributeName, attributeValue) => {
        if( invoice ){
            let invoiceAux = { ...invoice };
            invoiceAux[attributeName] = attributeValue;

            if( attributeName === 'client' ){
                invoiceAux['project'] = null; 

                setActiveProjects([]);
                setActiveJobs([]);
                setActiveWorkOrders([]);
            }else if(  attributeName === 'predefined_message'){

                if( attributeValue == true ){
                    let clientGreeting = '';
                    if( invoiceAux.client && invoiceAux.client.user ){
                        clientGreeting = 'Dear ' + invoiceAux.client.user.first_name + ' ' +invoiceAux.client.user.last_name + ',  \n';
                    }
                    
                    invoiceAux.notes = clientGreeting + constants.messages.defaultInvoiceMessage;
                } else {
                    invoiceAux.notes = '';
                }
                
            }

            console.debug('[EditInvoiceUsers][handleChangeInvoiceAttribute] invoiceAux: ', invoiceAux);
            setInvoice(invoiceAux);
        }
    }


    const handleChangeInvoiceItemAttribute = (index, attributeName, attributeValue) => {

        if( invoice && invoice.invoice_item_list ){
            let invoiceAux = { ...invoice };
            console.debug('[EditInvoiceUsers][handleChangeInvoiceItemAttribute] attributeName: ', attributeName);

            let invoiceItemListAux = invoiceAux[attributeName];

            invoiceItemListAux[index].item = attributeValue;
            invoiceItemListAux[index].unit_price = getInvoiceItemPrice(attributeValue);
            invoiceItemListAux[index].details = attributeValue.description;
            invoiceItemListAux[index].name = attributeValue.name;
            console.debug('[EditInvoiceUsers][handleChangeInvoiceItemAttribute] invoiceItemListAux: ', invoiceItemListAux);

            setInvoice(invoiceAux);
        }

    }


    

    const getInvoiceItemPrice = ( item ) => {
        if(  item && item.price_unit_list && item.price_unit_list.length > 0 ){
            return parseToFloatAmount(item.price_unit_list[0].price);
        }
        return 0;
    }

    const handleChangeInvoiceItemQuantity = (index, attributeName, attributeValue) => {
        if( invoice && invoice.invoice_item_list ){
            let invoiceAux = { ...invoice };

            let invoiceItemListAux = invoiceAux[attributeName];

            invoiceItemListAux[index].quantity = attributeValue;
            setInvoice(invoiceAux);
        }
    }

    const handleChangeInvoiceItemUnitPrice = (index, attributeName, attributeValue) => {
        if( invoice && invoice.invoice_item_list ){
            let invoiceAux = { ...invoice };

            let invoiceItemListAux = invoiceAux[attributeName];
            const unitPrice = parseToFloatAmount(attributeValue);
            invoiceItemListAux[index].unit_price = unitPrice;
            setInvoice(invoiceAux);
        }
    }

    const handleChangeInvoiceItemDetails = (index, attributeName, attributeValue) => {
        if( invoice && invoice.invoice_item_list ){
            let invoiceAux = { ...invoice };
            let invoiceItemListAux = invoiceAux[attributeName];
            invoiceItemListAux[index].details = attributeValue;
            setInvoice(invoiceAux);
        }
    }


    const buildCompleteClientAddress = () => {
        if( clientAddress ){
            return clientAddress.number + ' ' + clientAddress.street + ', ' + clientAddress.city + ', ' + clientAddress.locality;
        }
        return '';
    }


    const handleShowItemDetails = (item) => {
        console.debug('[EditInvoiceUsers][handleShowItemDetails] item: ', item);
        setSelectedItem(item);
        setOpenDetailsDialog(true);
    }

    const handleCloseDetailsDialog = () => {
        setSelectedItem(null);
        setOpenDetailsDialog(false);
    }

    const handleApproveInvoice = () => {
        setOpenConfirmDialog(true);
    }

    const handleConfirmApproveInvoice = () => {
        let invoiceAux = {...invoice};
        invoiceAux.status = status.invoiceStatus.pendingPayment.status;
        setInvoice(invoiceAux);

        sendBroadcastRoleAdministratorInvoiceApprovedNotification(userId, invoiceAux, tenantId, stompClient);

        setSaveEvent(!saveEvent);
    }
    

    const handleCloseAddDeposit = () => {
        setOpenAddDeposit(false);
        setInvoiceDeposit(null);
    }

    const handleConfirmAddDeposit = () => {

        const isValid = validateInvoiceDeposit(invoiceDeposit);

        console.debug('[EditInvoiceUsers][handleConfirmAddDeposit] invoiceDeposit: ', invoiceDeposit);

        setErrorMessageDialog('');
        if( isValid ){
            let invoiceAux = {...invoice};

            invoiceAux.invoice_deposit_list.push(invoiceDeposit);
            setInvoice(invoiceAux);
            setOpenAddDeposit(false);
            setInvoiceDeposit(null);

            sendBroadcastRoleAdministratorInvoiceDepositSentNotification(userId, invoiceAux, tenantId, stompClient);

            setSaveEvent(!saveEvent);

        } else {
            setErrorMessageDialog(constants.errors.validationErrorRequiredFields);
        }
        
    }

    const handleAddDeposit = () => {

        const invoiceDepositAux = createEmptyInvoiceDeposit();

        setOpenAddDeposit(true);
        setInvoiceDeposit(invoiceDepositAux);
    }




    return (
        
        <PrincipalLayout section={constants.menu.admin_invoices_section} isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Invoice: {invoice ? invoice.invoice_number : ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Invoice number"} 
                                    fieldValue={ invoice ? invoice.invoice_number: '' } 
                                    fieldId={'input-invoice-invoice_number'} 
                                    attributeName={'invoice_number'} 
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    required={true} 
                                    disabled={true}
                                />


                                <AutocompleteComponent
                                    fieldId={'input-invoice-project'}
                                    fieldName={'Project'} 
                                    fieldValue={invoice ? invoice.project : null}
                                    attributeName={'project'}
                                    optionList={activeProjects}
                                    optionAttributeLabel={'name'}
                                    isClient={false}
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    disabled={true}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-invoice-job'}
                                    fieldName={'Job'} 
                                    fieldValue={invoice ? invoice.job : null}
                                    attributeName={'job'}
                                    optionList={activeJobs}
                                    optionAttributeLabel={'title'}
                                    isClient={false}
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    disabled={true}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-invoice-work_order'}
                                    fieldName={'Work order'} 
                                    fieldValue={invoice ? invoice.work_order : null}
                                    attributeName={'work_order'}
                                    optionList={activeWorkOrders}
                                    optionAttributeLabel={'title'}
                                    isClient={false}
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    disabled={true}
                                />


                               
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                 
                                 <InputComponent 
                                    fieldName={"Status"} 
                                    fieldValue={ invoice ? getInvoiceStatusName( invoice.status ): '' } 
                                    fieldId={'input-invoice-status'} 
                                    attributeName={'status'} 
                                    required={false}
                                    disabled={true} 
                                />


                                <InputComponent 
                                    fieldName={"Phone"} 
                                    fieldValue={ invoice && invoice.client && invoice.client.user ? invoice.client.user.phone : '' } 
                                    fieldId={'input-invoice-client-user-phone'} 
                                    attributeName={'phone'} 
                                    required={false} 
                                    disabled={true}

                                />

                                <InputComponent 
                                    fieldName={"Email"} 
                                    fieldValue={ invoice && invoice.client && invoice.client.user ? invoice.client.user.email : '' } 
                                    fieldId={'input-invoice-client-user-email'} 
                                    attributeName={'phone'} 
                                    required={false} 
                                    disabled={true}

                                />

                                <InputComponent 
                                    fieldName={"Address"} 
                                    fieldValue={ invoice && invoice.client ? buildCompleteClientAddress() : '' } 
                                    fieldId={'input-invoice-client-address'} 
                                    attributeName={'address'} 
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    required={false} 
                                    disabled={true}

                                />


                            </Grid>

                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>


                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                 
                                 

                                 <DateComponent
                                    fieldName={"Issued date"} 
                                    fieldValue={ invoice ? invoice.issue_date: '' } 
                                    fieldId={'input-request-issue_date'} 
                                    attributeName={'issue_date'} 
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    required={false} 
                                    disabled={true}
                                />

                                <SwitchComponent 
                                    fieldName={"Date sent"} 
                                    fieldValue={ invoice ? invoice.date_sent : false } 
                                    fieldId={'input-request-date_sent'} 
                                    attributeName={'date_sent'} 
                                    handleChangeField={handleChangeInvoiceAttribute} 
                                    disabled={true}
                                />

                                <DateComponent
                                    fieldName={"Due date"} 
                                    fieldValue={ invoice ? invoice.due_date: '' } 
                                    fieldId={'input-request-due_date'} 
                                    attributeName={'due_date'} 
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    required={false} 
                                    disabled={true}
                                />


                            </Grid>
                        </Grid>

                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <SwitchComponent 
                                    fieldName={"Reminders for client"} 
                                    fieldValue={ invoice ? invoice.reminders_client : false } 
                                    fieldId={'input-request-reminders_client'} 
                                    attributeName={'reminders_client'} 
                                    handleChangeField={handleChangeInvoiceAttribute} 
                                    disabled={true}
                                />

                                {  invoice && invoice.reminders_client &&
                                    (
                                        <SelectComponent
                                            fieldName={"Type"} 
                                            fieldValue={ invoice ? invoice.reminder_type: '' } 
                                            fieldId={'input-workOrder-reminder_type'} 
                                            attributeName={'reminder_type'} 
                                            optionList={reminderTypes}
                                            optionAttributeName={'name'}
                                            handleChangeField={handleChangeInvoiceAttribute}
                                            disabled={true}
                                        />
                                    )
                                }

                            </Grid>
                        </Grid>


                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                        
                            <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                            {  invoice && invoice.invoice_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Product/Service
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Qty.
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Unit price
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {  invoice && invoice.invoice_item_list &&

                                invoice.invoice_item_list.map( (invoiceItem, index) => (
                                    <>
                                    
                                    <AutocompleteComponent
                                        fieldId={'input-invoice_item_list-'+index}
                                        fieldName={'Item'} 
                                        fieldValue={invoiceItem.item}
                                        fieldIndex={index}
                                        attributeName={'invoice_item_list'}
                                        optionList={activeItems}
                                        optionAttributeLabel={'name'}
                                        hideLabel={true}
                                        handleChangeField={handleChangeInvoiceItemAttribute}
                                        sx={ constants.styles.textFieldLineItem }
                                        inputSx={4}
                                        inputMd={4}
                                        disabled={true}
                                    />

                                    <InputComponent 
                                        fieldName={'Quantity'} 
                                        fieldValue={invoiceItem.quantity} 
                                        fieldIndex={index}
                                        fieldId={'input-invoice_item_qty-'+index} 
                                        attributeName={'invoice_item_list'} 
                                        handleChangeField={handleChangeInvoiceItemQuantity}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldSmall }
                                        inputSx={1}
                                        inputMd={1}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                        disabled={true}
                                    />

                                    <InputComponent 
                                        fieldName={'Unit price'} 
                                        fieldValue={invoiceItem.unit_price} 
                                        fieldIndex={index}
                                        fieldId={'input-invoice_item_price-'+index} 
                                        attributeName={'invoice_item_list'} 
                                        handleChangeField={handleChangeInvoiceItemUnitPrice}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                        disabled={true}
                                    />

                                    <InputComponent 
                                        fieldName={'Total'} 
                                        fieldValue={invoiceItem.unit_price ? calculateSubTotalInvoice(invoiceItem) : 0} 
                                        fieldIndex={index}
                                        fieldId={'input-invoice_item_total-'+index} 
                                        attributeName={'invoice_item_list'} 
                                        handleChangeField={handleChangeInvoiceItemUnitPrice}
                                        required={false} 
                                        hideLabel={true}
                                        disabled={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            }
                                        }}
                                    />

                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <IconButton 
                                            sx={{ p: 0 }} 
                                            title="Edit item"
                                            onClick={()=> handleShowItemDetails(invoiceItem)}
                                        >
                                            <EditNote sx={constants.styles.iconSize}/>
                                        </IconButton>
                                    </Grid>

                                    <InputComponent 
                                        fieldName={'Details'} 
                                        fieldValue={invoiceItem.details ? invoiceItem.details : ''} 
                                        fieldIndex={index}
                                        fieldId={'input-invoice_item_details-'+index} 
                                        attributeName={'invoice_item_list'} 
                                        handleChangeField={handleChangeInvoiceItemDetails}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldLineItem }
                                        inputSx={4}
                                        inputMd={4}
                                        multiline={true}
                                        rows={4}
                                        disabled={true}
                                    />

                                    <Grid item sx={constants.styles.sectionInput} xs={8} md={8}>
                                        &nbsp;
                                    </Grid>
                                    
                                    </>
                                ))

                            }

                            </Grid>

                            
                        </Grid>


                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <InputComponent 
                                    fieldName={"Message"} 
                                    fieldValue={ invoice ? invoice.notes: '' } 
                                    fieldId={'input-invoice-notes'} 
                                    attributeName={'notes'} 
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    required={true} 
                                    multiline={true}
                                    rows={6}
                                    disabled={true}
                                />


                                <SelectComponent
                                    fieldName={"Payment method"} 
                                    fieldValue={ invoice ? invoice.payment_method: '' } 
                                    fieldId={'input-invoice-payment_method'} 
                                    attributeName={'payment_method'} 
                                    optionList={paymentMethods}
                                    optionAttributeName={'name'}
                                    handleChangeField={handleChangeInvoiceAttribute}
                                    disabled={isInvoicePaid(invoice ? invoice.status : '')}
                                />

                                { invoice && invoice.id &&
                                    (
                                        <UploaderComponent
                                            object={invoice}
                                            prefix={'invoice'}
                                            folder={'invoices'}
                                            imageListAttributeName={'invoice_image_list'}
                                            setObject={setInvoice}
                                            saveObject={handleClickSave}
                                            disabled={isInvoicePaid(invoice ? invoice.status : '')}
                                        />
                                    )
                                }

                            </Grid>

                            

                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                            {  invoice && invoice.invoice_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={8} md={7}>
                                        <Grid container direction="row" spacing={0}
                                            justifyContent={'left'}
                                            alignContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Grid item sx={constants.styles.sectionInput} xs={6} md={7}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Subtotal:
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatTotalInvoice(invoice) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={6} md={7}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Discount:
                                                </Typography>
                                                {invoice && invoice.discount_type && invoice.discount_type === discountTypesCatalog.percentageDiscount.id &&
                                                    (
                                                        <Typography sx={constants.styles.textSmall} align="right">
                                                            ({invoice ? invoice.discount : 0.0}%)
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatDiscountInvoice(invoice) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={6} md={7}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    Taxes:
                                                </Typography>
                                                
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    ${ calculatTaxesInvoice(invoice) }
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={6} md={7}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong> Total:  </strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={constants.styles.sectionInput} xs={3} md={3}>
                                                <Typography sx={constants.styles.textNormal} align="right">
                                                    <strong>${ calculatGrandTotalInvoice(invoice) }</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            } 

                               

                                { !isInvoiceEditable(invoice ? invoice.status : '') &&
                                ( 
                                    <>
                                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                            <Typography sx={constants.styles.textNormal} align="left">
                                                Deposits:
                                            </Typography>
                                        </Grid>
                                        {  invoice && invoice.invoice_deposit_list &&
                                            invoice.invoice_deposit_list.map( (invoiceDeposit, index) => (
                                                    <>
                                                    { invoiceDeposit.deposit_type == depositTypes.deposit &&
                                                    (
                                                        <>
                                                            <Grid item sx={constants.styles.sectionInput} xs={8} md={8}>
                                                                <Typography sx={constants.styles.textNormal} align="right">
                                                                    { convertDateStrToString(invoiceDeposit.deposit_date) } 
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                                                <Typography sx={constants.styles.textNormal} align="right">
                                                                    ${ parseToFloatAmount( invoiceDeposit.amount ) } 
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                                                { isInvoiceDepositPendingApproval(invoiceDeposit.status) &&
                                                                    <>
                                                                        <IconButton 
                                                                            sx={{ p: 0 }} 
                                                                            title="Pending approval"
                                                                        >
                                                                            <PauseCircle sx={constants.styles.iconSize}/>
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </>
                                                    )
                                                }
                                                </>
                                            ))
                                        }
                                        <Grid 
                                            container
                                            alignItems={'flex-end'}
                                            alignContent={'flex-end'}
                                            flexDirection={'column'}
                                        >
                                            <Grid item  
                                                xs={12} md={12} 
                                                sx={constants.styles.sectionButtons}
                                            >
                                                { invoice && !isInvoicePaid( invoice.status ) &&
                                                    <>
                                                        <Button 
                                                            variant="outlined" 
                                                            size="small" 
                                                            sx={constants.styles.buttonNormal}
                                                            onClick={handleAddDeposit}
                                                            
                                                            > 
                                                                Add deposit 
                                                        </Button>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item sx={constants.styles.sectionDivider}  xs={8} md={8}>
                                            <Typography sx={constants.styles.textNormal} align="right">
                                                <strong>Invoice balance:</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                            <Typography sx={constants.styles.textNormal} align="right">
                                                <strong>${calculatInvoiceBalance(invoice)}</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                            <Typography sx={constants.styles.textNormal} >
                                                &nbsp;
                                            </Typography>
                                        </Grid>
                                    </>
                                )
                                }

                            </Grid>
                        
                        </Grid>
                        
                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                { invoice && isInvoicePendingApproval( invoice.status ) &&
                                    <>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleApproveInvoice}
                                        > 
                                        Approve invoice
                                        </Button>
                                    </>
                                }
                               
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
                <QuoteItemDetails
                    openDetailsDialog={openDetailsDialog}
                    handleCloseDetailsDialog={handleCloseDetailsDialog}
                    item={selectedItem}
                />

                <ConfirmDialog 
                    key={'confirm_approve_invoice'}
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                    handleConfirmAction={handleConfirmApproveInvoice}
                    title={'Do you approve this invoice?'}
                    message={invoice ? 'Invoice number: ' + invoice.invoice_number + ', total: $' + calculatGrandTotalInvoice(invoice) : ''}
                />

                <Dialog
                    open={openAddDeposit}
                    onClose={handleCloseAddDeposit}
                    key="serviceTypeDetails"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                    
                >
                    <DialogTitle
                        sx={constants.styles.textNormal}
                        id="alert-dialog-title"
                    >
                        <strong>Add deposit</strong>
                    </DialogTitle>
                    <DialogContent>
                            <Grid container >
                                <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                                    <Grid container direction="row" spacing={0}
                                        justifyContent={'left'}
                                    >

                                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                            &nbsp;
                                        </Grid>

                                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                                            <ErrorMessageChip message={errorMessageDialog} />
                                        </Grid>

                                        <DateComponent
                                            fieldName={"Date"} 
                                            fieldValue={ invoiceDeposit ? invoiceDeposit.deposit_date: '' } 
                                            fieldId={'input-invoice-deposit-deposit_date'} 
                                            attributeName={'deposit_date'} 
                                            handleChangeField={handleChangeInvoiceDeposit}
                                            required={true} 

                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                        />

                                        <InputComponent 
                                            fieldName={"Amount"} 
                                            fieldValue={ invoiceDeposit ? invoiceDeposit.amount : '' } 
                                            fieldId={'input-invoice-deposit-amount'} 
                                            attributeName={'amount'} 
                                            handleChangeField={handleChangeInvoiceDepositAmount}
                                            required={true} 
                                            sx={constants.styles.textFieldNormalDialog}
                                            labelSx={3}
                                            labelMd={3}

                                            inputSx={8}
                                            inputMd={8}

                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                    min: 0,
                                                }
                                            }}
                                            numberValidation={true}
                                            disabled={isTotalPendingBalance}
                                        />

                                        <SwitchComponent 
                                            fieldName={"Total pending balance: $" + (invoice ? calculatInvoiceBalance(invoice) : '0.0')  } 
                                            fieldValue={ isTotalPendingBalance } 
                                            fieldId={'input-invoide-deposit_pending_balance'} 
                                            attributeName={'amount'} 
                                            handleChangeField={handleChangeDepositTotalBalance} 

                                            labelSx={10}
                                            labelMd={10}

                                            inputSx={1}
                                            inputMd={1}
                                        />


                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseAddDeposit}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmAddDeposit} 
                            autoFocus
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

        </PrincipalLayout>

        
    );
}

export default EditInvoiceUsers;