import React, { useEffect, useState } from "react";
import { Button, Chip, Divider, Grid, IconButton, InputAdornment, Paper} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { createJob, getAllBillingTypes, getAllScheduleTypes, getJobById, updateJob } from "../../../service/tenant/JobService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation, useNavigate } from "react-router";
import InputComponent from "../util/InputComponent";
import { getUserData } from "../../../service/tenant/SecurityService";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createEmptyJob, createEmptyLineItem, parseToFloatAmount, validateJob } from "../../../service/tenant/ValidationService";
import { getAllActiveClients, getShortenClientById, getTaxRateByClient } from "../../../service/tenant/ClientService";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId } from "../../../service/tenant/ProjectService";
import { getAllActiveItems } from "../../../service/tenant/ItemService";
import { Delete, Done, EditNote, PlayArrow } from "@mui/icons-material";
import QuoteItemDetails from "../quotes/QuoteItemDetails";
import DateComponent from "../util/DateComponent";
import SelectComponent from "../util/SelectComponent";
import { getLineItemStatusIcon, isJobEditable, isJobOnGoing, isLineItemEditable, status } from "../../../service/tenant/StatusService";
import { searchWorkOrdersByAnyAttribute } from "../../../service/tenant/WorkOrderService";
import WorkOrdersTable from "../workorders/WorkOrdersTable";



function EditJob( props ){
    
    const location = useLocation();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    var jobId = location.state.jobId;
    const tenantId = tenant.id;
    const navigate = useNavigate();

    const [job, setJob] = useState(null);
    const [clientAddress, setClientAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeClients, setActiveClients] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeItems, setActiveItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [scheduleTypes, setScheduleTypes] = useState([]);
    const [billingTypes, setBillingTypes] = useState([]);
    const [activeWorkOrders, setActiveWorkOrders] = useState([]);
    const [saveEvent, setSaveEvent] = useState(false);
    

    useEffect(() => {
        
        console.debug('[EditJob] jobId: ', jobId);

        setIsLoading(true);

        if( jobId != null && jobId != '' ){
            getJobById(jobId, tenantId).then( job => {

                if( job != null ){
                    console.debug('[EditJob][useEffect] job: ', job);
                    setJob(job);

                    setIsLoading(true);
                    searchWorkOrdersByAnyAttribute('', '', '', jobId, tenantId).then( activeWorkOrdersResponse => {
                        if( activeWorkOrdersResponse != null ){
                            setActiveWorkOrders(activeWorkOrdersResponse);
                        }
                        setIsLoading(false);
                    });

                } else {
                    setIsLoading(false);
                }

                
            });

            
        } else {

            const newJob = createEmptyJob(userData.uuid);
            console.debug('[EditJob][useEffect] newJob: ', newJob);
            setJob(newJob);
            setIsLoading(false);
        }

        setIsLoading(true);


        getAllScheduleTypes().then( allScheduleTypes =>{
            if( allScheduleTypes !== null ){
                setScheduleTypes(allScheduleTypes);
            }
        });

        getAllBillingTypes().then( allBillingTypes =>{
            if( allBillingTypes !== null ){
                setBillingTypes(allBillingTypes);
            }
        });


        getAllActiveClients( tenantId ).then( activeClientsResponse => {
            if( activeClientsResponse != null ){
                setActiveClients(activeClientsResponse);
            }
        });

        getAllActiveItems( tenantId ).then( activeItemsResponse => {
            if( activeItemsResponse ){
                setActiveItems(activeItemsResponse);
            }

            setIsLoading(false);
        })


    }, [location.state.jobId]);
    

    useEffect( () => {
        if( job && job.client ){

            console.log('[EditJobs][useEffect] job.client: ', job.client);
            const clientId = job.client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

            getShortenClientById(clientId, tenantId).then( clientResponse => {
                if( clientResponse != null ){
                    setClientAddress(clientResponse.client_address);
                }
            });

        }
    }, [job ? job.client: {}]);


    useEffect( () => {
        if( job ){

            console.log('[EditWorkOrder][useEffect] saveEvent: ');
            
            handleClickSave();

        }
    }, [saveEvent]);


    const loadActiveWorkOrders = () => {
        if( job && job.id ){
            setIsLoading(true);
            searchWorkOrdersByAnyAttribute('', '', '', job.id, tenantId).then( activeWorkOrdersResponse => {
                if( activeWorkOrdersResponse != null ){
                    setActiveWorkOrders(activeWorkOrdersResponse);
                }
                setIsLoading(false);
            });
        }
    }

    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        let isValidJob = validateJob(job);
        isValidJob = isValidJob && ( job.client != null );

        console.debug('[EditJob][handleClickSave] job: ', job);
        console.debug('[EditJob][handleClickSave] jobId: ', jobId);

        if( isValidJob && job.id != null && job.id != ''  ){
            handleUpdateJob();
        } else if( isValidJob && ( job.id == null || job.id == '' ) ){
            handleCreateJob();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateJob = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateJob(job, tenantId).then( jobResponse => {

            if( jobResponse != null ){
                console.debug('[EditJob][handleUpdateJob] jobResponse: ', jobResponse);
                setJob(jobResponse);

                loadActiveWorkOrders();

                setSuccessMessage(constants.errors.editJobSuccess);
            } else {
                setErrorMessage(constants.errors.editJobError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateJob = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createJob(job, tenantId).then( jobResponse => {

            if( jobResponse != null ){
                console.debug('[EditJob][handleCreateJob] jobResponse: ', jobResponse);
                setJob(jobResponse);
                jobId = jobResponse.id;
                console.debug('[EditJob][handleCreateJob] jobId: ', jobId);

                loadActiveWorkOrders();

                setSuccessMessage(constants.errors.createJobSuccess);
            } else {
                setErrorMessage(constants.errors.createJobError)
            }

            setIsLoading(false);
        });

    }


    const handleChangeJobAttribute = (attributeName, attributeValue) => {
        if( job ){
            let jobAux = { ...job };
            jobAux[attributeName] = attributeValue;

            if( attributeName === 'client' ){
                jobAux['project'] = null; 

                setActiveProjects([]);
            }

            setJob(jobAux);
        }
    }


    const handleChangeLineItemAttribute = (index, attributeName, attributeValue) => {
        if( job && job.line_item_list ){
            let jobAux = { ...job };
            console.debug('[EditJob][handleChangeLineItemAttribute] attributeName: ', attributeName);

            let lineItemListAux = jobAux[attributeName];

            lineItemListAux[index].item = attributeValue;
            lineItemListAux[index].unit_price = getLineItemPrice(attributeValue);
            lineItemListAux[index].details = attributeValue.description;
            lineItemListAux[index].name = attributeValue.name;
            console.debug('[EditJob][handleChangeLineItemAttribute] lineItemListAux: ', lineItemListAux);

            setJob(jobAux);
        }
    }


    

    const getLineItemPrice = ( item ) => {
        if(  item && item.price_unit_list && item.price_unit_list.length > 0 ){
            return parseToFloatAmount(item.price_unit_list[0].price);
        }
        return 0;
    }

    const handleChangeLineItemQuantity = (index, attributeName, attributeValue) => {
        if( job && job.line_item_list ){
            let jobAux = { ...job };

            let lineItemListAux = jobAux[attributeName];

            lineItemListAux[index].quantity = attributeValue;
            setJob(jobAux);
        }
    }

    const handleChangeLineItemUnitPrice = (index, attributeName, attributeValue) => {
        if( job && job.line_item_list ){
            let jobAux = { ...job };

            let lineItemListAux = jobAux[attributeName];
            const unitPrice = parseToFloatAmount(attributeValue);
            lineItemListAux[index].unit_price = unitPrice;
            setJob(jobAux);
        }
    }

    const handleAddLineItem = () => {
        const lineItem = createEmptyLineItem();
        let jobAux = { ...job };
        jobAux.line_item_list.push(lineItem);
        setJob(jobAux);
    }

    const handleDeleteLineItem = (index) => {
        let jobAux = { ...job };
        jobAux.line_item_list.splice(index, 1);
        setJob(jobAux);
    }

    const calculateSubTotal = ( lineItem ) => {
        if( lineItem && lineItem.unit_price && lineItem.quantity ){
            const subTotal = lineItem.unit_price * lineItem.quantity;
            return parseToFloatAmount(subTotal);
        }
        return 0;
    }

    const calculatTotal = () => {
        let total = 0;
        if( job && job.line_item_list ){
            job.line_item_list.forEach( lineItem => {
                const subTotal = parseFloat(calculateSubTotal(lineItem));
                total = total + subTotal;
            });
        }
        return parseToFloatAmount(total);
    }

    const calculatTaxes = () => {
        const taxRate = getTaxRateByClient();
        const taxes = calculatTotal() * taxRate;
        return parseToFloatAmount(taxes);
    }

    const calculatGrandTotal = () => {
        const taxRate = getTaxRateByClient();
        const total = parseFloat(calculatTotal());
        const taxes = total * taxRate;
        const grandTotal = total + taxes;
        return parseToFloatAmount(grandTotal);
    }


    const buildCompleteClientAddress = () => {
        if( clientAddress ){
            return clientAddress.number + ' ' + clientAddress.street + ', ' + clientAddress.city + ', ' + clientAddress.locality;
        }
        return '';
    }


    const handleShowItemDetails = (item) => {
        console.debug('[EditJob][handleShowItemDetails] item: ', item);
        setSelectedItem(item);
        setOpenDetailsDialog(true);
    }

    const handleCloseDetailsDialog = () => {
        setSelectedItem(null);
        setOpenDetailsDialog(false);
    }

    const handleApproveItem = (index) => {
        console.debug('[EditWorkOrder][handleApproveItem] index: ', index);
        if( job && job.line_item_list ){
            let jobAux = { ...job };
            let lineItemListAux = jobAux.line_item_list;
            lineItemListAux[index].status = status.lineItemStatus.pending.status;
            setJob(jobAux);
        }
        
    }

    const handleCreateWorkOrder = () => {

        var lineItems = [];

        job.line_item_list.forEach( lineItem => {
            let lineItemAux = {...lineItem};
            lineItems.push(lineItemAux);
        });

        navigate(constants.routes.createWorkOrder, {
            state: {
                workOrderId: '',
                lineItems: lineItems,
                client: job.client,
                project: job.project,
                quote: null,
                job: job,
            }
        });

    }

    const handleClickCreate = () => {
        let jobAux = {...job};
        jobAux.status = status.jobStatus.onGoing.status;
        setJob(jobAux);

        setSaveEvent(!saveEvent);
    }

    const handleClickComplete = () => {
        let jobAux = {...job};
        jobAux.status = status.jobStatus.completed.status;
        setJob(jobAux);

        setSaveEvent(!saveEvent);
    }

    return (
        
        <PrincipalLayout section={constants.menu.admin_workorders_section} isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Job: {job && job.id ? ' #' + job.id + ' - ' : ''} {job ? job.title : ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Title"} 
                                    fieldValue={ job ? job.title: '' } 
                                    fieldId={'input-job-title'} 
                                    attributeName={'title'} 
                                    handleChangeField={handleChangeJobAttribute}
                                    required={true} 
                                />

                                <AutocompleteComponent
                                    fieldId={'input-job-client'}
                                    fieldName={'Client'} 
                                    fieldValue={job ? job.client : null}
                                    attributeName={'client'}
                                    optionList={activeClients}
                                    isClient={true}
                                    handleChangeField={handleChangeJobAttribute}
                                    disabled={( job ? isJobOnGoing(job.status) : false )}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-job-project'}
                                    fieldName={'Project'} 
                                    fieldValue={job ? job.project : null}
                                    attributeName={'project'}
                                    optionList={activeProjects}
                                    optionAttributeLabel={'name'}
                                    isClient={false}
                                    handleChangeField={handleChangeJobAttribute}
                                    disabled={( job ? !isJobEditable(job.status) : false )}
                                />


                               
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >


                                <InputComponent 
                                    fieldName={"Phone"} 
                                    fieldValue={ job && job.client && job.client.user ? job.client.user.phone : '' } 
                                    fieldId={'input-job-client-user-phone'} 
                                    attributeName={'phone'} 
                                    handleChangeField={handleChangeJobAttribute}
                                    required={false} 
                                    disabled={true}

                                />

                                <InputComponent 
                                    fieldName={"Email"} 
                                    fieldValue={ job && job.client && job.client.user ? job.client.user.email : '' } 
                                    fieldId={'input-job-client-user-email'} 
                                    attributeName={'phone'} 
                                    handleChangeField={handleChangeJobAttribute}
                                    required={false} 
                                    disabled={true}

                                />
                                

                                <InputComponent 
                                    fieldName={"Address"} 
                                    fieldValue={ job && job.client ? buildCompleteClientAddress() : '' } 
                                    fieldId={'input-job-client-address'} 
                                    attributeName={'address'} 
                                    handleChangeField={handleChangeJobAttribute}
                                    required={false} 
                                    disabled={true}

                                />


                            </Grid>

                        </Grid>
                        

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Typography sx={constants.styles.textNormal} >
                                        <strong> Job details </strong>
                            </Typography>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >


                                <DateComponent
                                    fieldName={"Start date"} 
                                    fieldValue={ job ? job.start_date: '' } 
                                    fieldId={'input-request-start_date'} 
                                    attributeName={'start_date'} 
                                    handleChangeField={handleChangeJobAttribute}
                                    required={false} 
                                />

                                <DateComponent
                                    fieldName={"End date"} 
                                    fieldValue={ job ? job.end_date: '' } 
                                    fieldId={'input-request-end_date'} 
                                    attributeName={'end_date'} 
                                    handleChangeField={handleChangeJobAttribute}
                                    required={false} 
                                />


                            </Grid>
                        </Grid>


                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >


                                <SelectComponent
                                    fieldName={"Schedule"} 
                                    fieldValue={ job ? job.schedule_type: '' } 
                                    fieldId={'input-job-schedule_type'} 
                                    attributeName={'schedule_type'} 
                                    optionList={scheduleTypes}
                                    optionAttributeName={'name'}
                                    handleChangeField={handleChangeJobAttribute}

                                />

                                <SelectComponent
                                    fieldName={"Billing"} 
                                    fieldValue={ job ? job.billing_type: '' } 
                                    fieldId={'input-job-billing_type'} 
                                    attributeName={'billing_type'} 
                                    optionList={billingTypes}
                                    optionAttributeName={'name'}
                                    handleChangeField={handleChangeJobAttribute}

                                />


                            </Grid>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <InputComponent 
                                    fieldName={"Job instructions"} 
                                    fieldValue={ job ? job.instructions: '' } 
                                    fieldId={'input-job-instructions'} 
                                    attributeName={'instructions'} 
                                    handleChangeField={handleChangeJobAttribute}
                                    required={true} 
                                    multiline={true}
                                    rows={4}

                                    labelSx={12}
                                    labelMd={12}

                                    inputSx={12}
                                    inputMd={12}

                                    fullWidth={true}
                                />

                            </Grid>
                        </Grid>

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Typography sx={constants.styles.textNormal} >
                                        &nbsp;
                            </Typography>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                        
                            <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                            {  job && job.line_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            Product/Service
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Qty.
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unit
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {  job && job.line_item_list &&

                                job.line_item_list.map( (lineItem, index) => (
                                    <>
                                    
                                    

                                    <AutocompleteComponent
                                        fieldId={'input-line_item_list-'+index}
                                        fieldName={'Item'} 
                                        fieldValue={lineItem.item}
                                        fieldIndex={index}
                                        attributeName={'line_item_list'}
                                        optionList={activeItems}
                                        optionAttributeLabel={'name'}
                                        hideLabel={true}
                                        handleChangeField={handleChangeLineItemAttribute}
                                        sx={ constants.styles.textFieldLineItem }
                                        inputSx={4}
                                        inputMd={4}
                                        disabled={!isLineItemEditable(lineItem.status)}
                                    />
                                    
                                    
                                    { getLineItemStatusIcon(lineItem.status) }
                                    
                                    
                                    <InputComponent 
                                        fieldName={'Quantity'} 
                                        fieldValue={lineItem.quantity} 
                                        fieldIndex={index}
                                        fieldId={'input-job_item_qty-'+index} 
                                        attributeName={'line_item_list'} 
                                        handleChangeField={handleChangeLineItemQuantity}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldSmall }
                                        inputSx={1}
                                        inputMd={1}
                                        InputProps={{
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                        disabled={!isLineItemEditable(lineItem.status)}
                                    />

                                    <InputComponent 
                                        fieldName={'Unit price'} 
                                        fieldValue={lineItem.unit_price} 
                                        fieldIndex={index}
                                        fieldId={'input-job_item_price-'+index} 
                                        attributeName={'line_item_list'} 
                                        handleChangeField={handleChangeLineItemUnitPrice}
                                        required={true} 
                                        hideLabel={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                                min: 0,
                                            }
                                        }}
                                        numberValidation={true}
                                        validationErrorMessage={''}
                                        disabled={!isLineItemEditable(lineItem.status)}
                                    />

                                    <InputComponent 
                                        fieldName={'Total'} 
                                        fieldValue={lineItem.unit_price ? calculateSubTotal(lineItem) : 0} 
                                        fieldIndex={index}
                                        fieldId={'input-job_item_total-'+index} 
                                        attributeName={'line_item_list'} 
                                        handleChangeField={handleChangeLineItemUnitPrice}
                                        required={false} 
                                        hideLabel={true}
                                        disabled={true}
                                        sx={ constants.styles.textFieldMedium }
                                        inputSx={2}
                                        inputMd={2}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            }
                                        }}
                                    />

                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <IconButton 
                                            sx={{ p: 0 }} 
                                            title="Edit item"
                                            onClick={()=> handleShowItemDetails(lineItem)}
                                        >
                                            <EditNote sx={constants.styles.iconSize}/>
                                        </IconButton>
                                        { isLineItemEditable(lineItem.status) &&
                                            <>
                                                <IconButton 
                                                    sx={{ p: 0 }} 
                                                    title="Delete item"
                                                    onClick={() => handleDeleteLineItem(index) }
                                                >
                                                    <Delete sx={constants.styles.iconSize}/>
                                                </IconButton>
                                            </>
                                        }
                                    </Grid>
                                    
                                    </>
                                ))

                            }

                            {  job && job.line_item_list && 
                                <>
                                    <Grid item sx={constants.styles.sectionInput} xs={4} md={4}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={2}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={3} md={2}>
                                        <Typography sx={constants.styles.textNormal} align="right">
                                            Subtotal:  ${ calculatTotal() } <br/>
                                            Taxes:  ${ calculatTaxes() } <br/>
                                            <strong> Total:  ${ calculatGrandTotal() } </strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={constants.styles.sectionInput} xs={1} md={1}>
                                        <Typography sx={constants.styles.textNormal} >
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </>
                            } 

                            </Grid>

                            
                        </Grid>


                        <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                            <Button 
                                variant="outlined" 
                                size="small" 
                                sx={constants.styles.buttonNormal}
                                onClick={handleAddLineItem}
                                > 
                                    Add item 
                            </Button>
                        </Grid>

                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>
                        
                        <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >
                            
                            { 
                                <WorkOrdersTable
                                    showClient={false}
                                    workOrderList={activeWorkOrders}
                                />
                            }

                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >
                            
                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                { job && job.id &&
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        sx={constants.styles.buttonNormal}
                                        onClick={handleCreateWorkOrder}
                                        > 
                                            Create work order 
                                    </Button>
                                }
                                { job && isJobOnGoing(job.status) &&
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        sx={constants.styles.buttonNormal}
                                        onClick={handleClickComplete}
                                        > 
                                            Mark as completed 
                                    </Button>
                                }
                                { job && job.id &&
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        sx={constants.styles.buttonNormal}
                                        onClick={handleClickSave}
                                        > 
                                            Save 
                                    </Button>
                                }
                                { job && job.id == null &&
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        sx={constants.styles.buttonNormal}
                                        onClick={handleClickCreate}
                                        > 
                                            Save 
                                    </Button>
                                }
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
                <QuoteItemDetails
                    openDetailsDialog={openDetailsDialog}
                    handleCloseDetailsDialog={handleCloseDetailsDialog}
                    item={selectedItem}
                />

        </PrincipalLayout>

        
    );
}

export default EditJob;