//Item Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";
import { status } from "./StatusService";
import { getUserData } from "./SecurityService";

export const getItemsByCategory = async(tenanId, categoryId) => {

    var response = await axios.get( constants.api.itemApiUrl + '/' + tenanId + '/shorten/filter?category_id=' + categoryId, { headers: constants.api.defaultHeaders } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    if( response != null && 
        response.data != null &&
        response.data.result === constants.api.successMessage){
        return response.data.data;
    }
    return null;

}


export const getAllActiveItems = async( tenanId ) => {

    var response = await axios.get( constants.api.itemApiUrl + '/' + tenanId + '/shorten/filter', { 
        params: {
            status: status.itemStatus.active.status
        },
        headers: constants.api.defaultHeaders 
    } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const getItemById = async(tenanId, itemId) => {

    var response = await axios.get( constants.api.itemApiUrl + '/' + tenanId + '/' + itemId, {
        headers: constants.api.defaultHeaders 
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const updateItem = async(item, tenanId) => {

    const user = getUserData();
    item.user_id = user.uuid;

    console.debug('[ItemService][updateItem] item: ', item);

    var response = await axios.put( constants.api.itemApiUrl + '/' + tenanId + '/', item, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const logicalDeleteItem = async(item, tenanId) => {

    const user = getUserData();
    item.user_id = user.uuid;
    item.status = status.itemStatus.inactive.status;

    console.debug('[ItemService][logicalDeleteItem] item: ', item);

    var response = await axios.put( constants.api.itemApiUrl + '/' + tenanId + '/', item, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}


export const createItem = async(item, tenanId) => {

    const user = getUserData();
    item.user_id = user.uuid;

    console.debug('[ItemService][createItem] item: ', item);

    var response = await axios.post( constants.api.itemApiUrl + '/' + tenanId + '/', item, {
        headers: constants.api.defaultHeaders
    })
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);
}

export const getPublicItemUrl = ( itemId, tenantId ) => {
    return constants.api.baseUrlUI + 'item?item_id=' + itemId + '&tenant_id=' + tenantId
}




