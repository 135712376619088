import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { Delete, EditNote, FormatListBulleted, Search } from "@mui/icons-material";
import { logicalDeleteClient, searchClientsByAnyAttribute } from "../../../service/tenant/ClientService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useNavigate } from "react-router";
import SuccessMessageChip from "../util/SuccessMessageChip";



function ViewClients(props){
    

    const [tableRows, setTableRows] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);

    const tenant = getTenantLocalStorage();

    const navigate = useNavigate();


    const createRow = (shortenClient) => {
        return {
            id: shortenClient.id,
            first_name: shortenClient.user.first_name,
            last_name: shortenClient.user.last_name,
            email: shortenClient.user.email
        };
    }


    const handleSearchClients = () => {
        const tenantId = tenant.id;
        
        if( !searchFilter || searchFilter === '' ){
            setErrorMessage(constants.errors.searchFilterEmpty);
            return;
        }

        setErrorMessage('');
        setTableRows([]);

        setIsLoading(true);

        searchClientsByAnyAttribute(searchFilter, tenantId).then( shortenClientList => {

            if( shortenClientList !== null ){
                console.debug('[ViewClients][handleSearchClients] shortenClientList: ', shortenClientList);

                if( shortenClientList.length > 0 ){
                    var tableRowsTemp = [];
                    
                    shortenClientList.forEach(shortenClient => {
                        const clientRow = createRow(shortenClient);
                        tableRowsTemp.push(clientRow);
                    });

                    console.debug('[ViewClients][handleSearchClients] tableRowsTemp: ', tableRowsTemp);

                    setTableRows(tableRowsTemp);
                } else {
                    setErrorMessage(constants.errors.emptyResult);
                }
            } else {
                setErrorMessage(constants.errors.searchClientError);
            }

            setIsLoading(false);
            
        });
    }

    const handleEditClient = (clientId) => {
        console.debug('[ViewClients][handleEditClient] clientId: ', clientId);
        navigate(constants.routes.editClient, {
            state: {
                clientId: clientId
            }
        });
    }

    const handleNewClient = () => {
        navigate(constants.routes.createClient, {
            state: {
                clientId: ''
            }
        });
    }

    const handleAddProject = (clientId) => {
        console.debug('[ViewClients][handleAddProject] clientId: ', clientId);
        navigate(constants.routes.createProject, {
            state: {
                projectId: ''
            }
        });
    }

    const handleDeleteClient = (client) => {
        console.debug('[ViewClients][handleDeleteClient] client: ', client);
        setClientToDelete(client);
        setOpenDeleteAlert(true);
    }

    const handleConfirmDeleteClient = () => {
        console.debug('[ViewClients][handleConfirmDeleteClient] ');

        const tenantId = tenant.id;

        setOpenDeleteAlert(false);
        setIsLoading(true);

        logicalDeleteClient(clientToDelete, tenantId).then( (deleteResponse) => {
            console.debug('[ViewClients][handleConfirmDeleteClient] deleteResponse: ', deleteResponse);

            if( deleteResponse != null ){
                setSuccessMessage(constants.errors.deleteClientSuccess);

                handleSearchClients();

            } else {
                setErrorMessage(constants.errors.deleteClientError);
            }

            setIsLoading(false);
            setClientToDelete(null);
        })

        
    }

    const handleCloseDeleteDialog = () => {
        setClientToDelete(null);
        setOpenDeleteAlert(false);
    }


    return (
        <PrincipalLayout section={constants.menu.admin_clients_section} isLoading={isLoading} 
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>
                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Clients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionInput} xs={2} md={2}>
                            <Typography sx={constants.styles.textNormal}>
                                Search for client:
                            </Typography>
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={9} md={9}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-user-search" sx={constants.styles.textNormal}>
                                    Type client information
                                </InputLabel>
                                <Input
                                    id="input-user-search"
                                    value={searchFilter}
                                    sx={constants.styles.inputSearchNormal}
                                    onChange={ (e) => setSearchFilter(e.target.value) }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={handleSearchClients}
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>
                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={12} md={12} sx={constants.styles.sectionTable}>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={constants.styles.tableHeader} >Name</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} >E-mail</TableCell>
                                            <TableCell sx={constants.styles.tableHeader} align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {tableRows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={constants.styles.tableCell} component="th" scope="row">
                                                <Link 
                                                    component="button"
                                                    onClick={() => handleEditClient(row.id)}
                                                >
                                                    {row.first_name + ' ' + row.last_name }
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={constants.styles.tableCell}>{row.email}</TableCell>
                                            <TableCell sx={constants.styles.tableCell} align="center">
                                                <IconButton 
                                                    size="small" 
                                                    title="Edit client"
                                                    onClick={() => handleEditClient(row.id)}
                                                >
                                                    <EditNote/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Add project"
                                                    onClick={() => handleAddProject(row.id)}
                                                >
                                                    <FormatListBulleted/>
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    title="Delete client"
                                                    onClick={() => handleDeleteClient(row)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleNewClient}
                                > 
                                        New Client 
                                </Button>

                            </Grid>

                        </Grid>

                    </Grid>
                    
                </Paper>

                <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle 
                        id="alert-dialog-title"
                        sx={constants.styles.textNormal}
                    >
                        <strong>Do you confirm to delete this client?</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText 
                            id="alert-dialog-description"
                            sx={constants.styles.textNormal}
                        >
                            Client name: <strong>{ clientToDelete ? clientToDelete.first_name + ' ' + clientToDelete.last_name : ''}</strong>
                            <br/>If you delete this client will no longer be available anymore.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="outlined" 
                            size="small"
                            sx={constants.styles.buttonNormal}
                            onClick={handleConfirmDeleteClient} 
                            autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                
        </PrincipalLayout>
    );
}

export default ViewClients;