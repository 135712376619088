import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Paper, Tab, Tabs} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation, useNavigate } from "react-router";
import InputComponent from "../util/InputComponent";
import { createUser, getAllUserTitles, getUserById, getUserData, updateUser } from "../../../service/tenant/SecurityService";
import SelectComponent from "../util/SelectComponent";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { validateUser } from "../../../service/tenant/ValidationService";




function UserProfile( props ){
    
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    var userId = userData.id;
    const tenantId = tenant.id;

    const [user, setUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userTitles, setUserTitles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        
        console.debug('[UserProfile] userId: ', userId);

        setIsLoading(true);

        if( userId != null && userId != '' ){
            getUserById(tenantId, userId).then( userResponse => {

                if( userResponse !== null ){
                    console.debug('[UserProfile][useEffect] userResponse: ', userResponse);
                    setUser(userResponse);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
                
            });
        } 


        getAllUserTitles().then( allUserTitles =>{
            if( allUserTitles !== null ){
                setUserTitles(allUserTitles);
            }
        });

    }, []);
    


    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        const isValidUser = validateUser(user);

        console.debug('[EditUser][handleClickSave] user: ', user);
        console.debug('[EditUser][handleClickSave] userId: ', userId);

        if( isValidUser && user.id != null && user.id != ''  ){
            handleUpdateUser();
        } else if( isValidUser && ( user.id == null || user.id == '' ) ){
            handleCreateUser();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateUser = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateUser(user, tenantId).then( userResponse => {

            if( userResponse != null ){
                console.debug('[EditUser][handleUpdateUser] userResponse: ', userResponse);
                setUser(userResponse);
                setSuccessMessage(constants.errors.editUserSuccess);
            } else {
                setErrorMessage(constants.errors.editUserError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateUser = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createUser(user, tenantId).then( userResponse => {

            if( userResponse != null ){
                console.debug('[EditUser][handleCreateUser] userResponse: ', userResponse);
                setUser(userResponse);
                userId = userResponse.id;
                console.debug('[EditUser][handleCreateUser] userId: ', userId);
                setSuccessMessage(constants.errors.createUserSuccess);
            } else {
                setErrorMessage(constants.errors.createUserError)
            }

            setIsLoading(false);
        });

    }

    const handleChangeUserAttribute = (attributeName, attributeValue) => {
        if( user ){
            const userAux = { ...user };
            userAux[attributeName] = attributeValue;
            setUser(userAux);
            
        }
    }

    

    return (
        <PrincipalLayout isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                User: {user ? user.first_name + ' ' + user.last_name: ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={5}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <SelectComponent
                                    fieldName={"Title"} 
                                    fieldValue={ user ? user.title: '' } 
                                    fieldId={'input-user-title'} 
                                    attributeName={'title'} 
                                    optionList={userTitles}
                                    optionAttributeName={'title'}
                                    handleChangeField={handleChangeUserAttribute}

                                />

                                <InputComponent 
                                    fieldName={"First name"} 
                                    fieldValue={ user ? user.first_name: '' } 
                                    fieldId={'input-user-first_name'} 
                                    attributeName={'first_name'} 
                                    handleChangeField={handleChangeUserAttribute}
                                    required={true} 
                                />

                                <InputComponent 
                                    fieldName={"Last name"} 
                                    fieldValue={ user ? user.last_name: '' } 
                                    fieldId={'input-user-last_name'} 
                                    attributeName={'last_name'} 
                                    handleChangeField={handleChangeUserAttribute} 
                                    required={true}
                                />

                                <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                    <Divider/>
                                </Grid>

                                <InputComponent 
                                    fieldName={"User id"} 
                                    fieldValue={ user ? user.uuid: '' } 
                                    fieldId={'input-user-uuid'} 
                                    attributeName={'uuid'} 
                                    handleChangeField={handleChangeUserAttribute}
                                    required={true} 
                                />

                                <InputComponent 
                                    fieldName={"Password"} 
                                    fieldValue={ user ? user.password_hash: '' } 
                                    fieldId={'input-user-password_hash'} 
                                    attributeName={'password_hash'} 
                                    handleChangeField={handleChangeUserAttribute} 
                                    required={true}
                                    passwordValidation={true}
                                />

                                <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                    <Divider/>
                                </Grid>

                                <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                    <InputComponent 
                                        fieldName={"Email"} 
                                        fieldValue={ user ? user.email: '' } 
                                        fieldId={'input-user-email'} 
                                        attributeName={'email'} 
                                        handleChangeField={handleChangeUserAttribute} 
                                        required={true}
                                        emailValidation={true}
                                    />
                                    <InputComponent 
                                        fieldName={"Phone"} 
                                        fieldValue={ user ? user.phone: '' } 
                                        fieldId={'input-user-phone'} 
                                        attributeName={'phone'} 
                                        handleChangeField={handleChangeUserAttribute} 
                                        required={true}
                                        phoneValidation={true}
                                    />
                                </Grid>
                               
                                <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                    <Divider/>
                                </Grid>
                                

                            </Grid>
                        
                        </Grid>

                        
                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >

                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
        </PrincipalLayout>
    );
}

export default UserProfile;