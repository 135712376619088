import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Paper} from "@mui/material";
import { getTenantLocalStorage } from "../../../service/tenant/TenantService";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PrincipalLayout from "../menu/PrincipalLayout";
import { createRequest, getRequestById, updateRequest } from "../../../service/tenant/RequestService";
import ErrorMessageChip from "../util/ErrorMessageChip";
import constants from "../util/Constants";
import { useLocation } from "react-router";
import InputComponent from "../util/InputComponent";
import { getAllActiveTeams, getUserData } from "../../../service/tenant/SecurityService";
import SelectComponent from "../util/SelectComponent";
import SwitchComponent from "../util/SwitchComponent";
import SuccessMessageChip from "../util/SuccessMessageChip";
import { createEmptyRequest, validateRequest } from "../../../service/tenant/ValidationService";
import { getAllActiveClients } from "../../../service/tenant/ClientService";
import DateComponent from "../util/DateComponent";
import AutocompleteComponent from "../util/AutocompleteComponent";
import { getAllActiveProjectsByClientId, getAllProjectTypes } from "../../../service/tenant/ProjectService";
import { isRequestAssessCompleted, isRequestAssessPending, isRequestDraft, status } from "../../../service/tenant/StatusService";
import { sendUserRequestAssessmentCompletedNotification } from "../../../service/tenant/NotificationsService";
import { useStompClient } from "react-stomp-hooks";



function EditRequest( props ){
    
    const location = useLocation();
    const tenant = getTenantLocalStorage();
    const userData = getUserData();
    const userId = userData.id;
    var requestId = location.state.requestId;
    const tenantId = tenant.id;

    const [request, setRequest] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [projectTypes, setProjectTypes] = useState([]);
    const [activeClients, setActiveClients] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [activeTeams, setActiveTeams] = useState([]);
    const [activeTeamUsers, setactiveTeamUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const [saveEvent, setSaveEvent] = useState(false);

    const stompClient = useStompClient();

    useEffect(() => {
        
        console.debug('[EditRequest] requestId: ', requestId);

        setIsLoading(true);

        if( requestId != null && requestId != '' ){
            getRequestById(requestId, tenantId).then( request => {

                if( request != null ){
                    console.debug('[EditRequest][useEffect] request: ', request);
                    setRequest(request);
                }
                setIsLoading(false);
            });
        } else {

            const newRequest = createEmptyRequest(userData.uuid);
            console.debug('[EditRequest][useEffect] newRequest: ', newRequest);
            setRequest(newRequest);
            setIsLoading(false);
        }


        getAllProjectTypes( tenantId ).then( projectTypesResponse =>{
            if( projectTypesResponse != null ){
                setProjectTypes(projectTypesResponse);
            }
        });

        getAllActiveClients( tenantId ).then( activeClientsResponse => {
            if( activeClientsResponse != null ){
                setActiveClients(activeClientsResponse);
            }
        });

        getAllActiveTeams( tenantId ).then( activeTeamsResponse => {
            if( activeTeamsResponse != null ){
                setActiveTeams(activeTeamsResponse);
            }
        });


    }, [location.state.requestId]);
    

    useEffect( () => {
        if( request && request.client ){

            console.log('[EditRequests][useEffect] request.client: ', request.client);
            const clientId = request.client.id;

            setIsLoading(true);
            getAllActiveProjectsByClientId(clientId, tenantId).then( activeProjectsResponse => {
                if( activeProjectsResponse != null ){
                    setActiveProjects(activeProjectsResponse);
                }
                setIsLoading(false);
            });

        }
    }, [request ? request.client: {}]);


    useEffect( () => {
        
        if( request && request.team ){

            console.log('[EditRequests][useEffect] request.team: ', request.team);
            
            setactiveTeamUsers( request.team.user_list );

        }
    }, [request ? request.team: {}]);


    useEffect( () => {
        if( request ){

            console.log('[EditRequest][useEffect] saveEvent: ');
            
            handleClickSave();

        }
    }, [saveEvent]);


    const handleClickSave = () => {
            
        setSuccessMessage('');
        setErrorMessage('');

        let isValidRequest = validateRequest(request);
        isValidRequest = isValidRequest && ( request.client != null );

        console.debug('[EditRequest][handleClickSave] request: ', request);
        console.debug('[EditRequest][handleClickSave] requestId: ', requestId);

        if( isValidRequest && request.id != null && request.id != ''  ){
            handleUpdateRequest();
        } else if( isValidRequest && ( request.id == null || request.id == '' ) ){
            handleCreateRequest();
        } else {
            setErrorMessage(constants.errors.validationErrorRequiredFields);
        }
    }


    const handleUpdateRequest = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        updateRequest(request, tenantId).then( requestResponse => {

            if( requestResponse != null ){
                console.debug('[EditRequest][handleUpdateRequest] requestResponse: ', requestResponse);
                setRequest(requestResponse);
                setSuccessMessage(constants.errors.editRequestSuccess);
            } else {
                setErrorMessage(constants.errors.editRequestError)
            }

            setIsLoading(false);
        });

    }

    const handleCreateRequest = () => {
        const tenantId = tenant.id;

        setIsLoading(true);
        createRequest(request, tenantId).then( requestResponse => {

            if( requestResponse != null ){
                console.debug('[EditRequest][handleCreateRequest] requestResponse: ', requestResponse);
                setRequest(requestResponse);
                requestId = requestResponse.id;
                console.debug('[EditRequest][handleCreateRequest] requestId: ', requestId);
                setSuccessMessage(constants.errors.createRequestSuccess);
            } else {
                setErrorMessage(constants.errors.createRequestError)
            }

            setIsLoading(false);
        });

    }


    const handleChangeRequestAttribute = (attributeName, attributeValue) => {
        if( request ){
            let requestAux = { ...request };
            requestAux[attributeName] = attributeValue;

            if( attributeName === 'client' ){
                requestAux['project'] = null; 
                setActiveProjects([]);
            } else if( attributeName === 'team' ){
                requestAux['user'] = null;
            }

            //console.debug('[EditRequest][handleChangeRequestAttribute] requestAux: ', requestAux);
            setRequest(requestAux);
        }
    }

    const handleChangeProjectType = (attributeName, projectTypeId) => {
        if( request ){
            let requestAux = { ...request };

            //console.debug('[EditRequest][handleChangeProjectType] projectTypeId: ', projectTypeId);

            const requestTypeSelected = projectTypes.find( (requestType) => requestType.id == projectTypeId );

            requestAux.project_type = requestTypeSelected;

            setRequest(requestAux);
        }
    }


    const handleSendToAssessment = () => {
        if( request ){
            let requestAux = {...request};
            requestAux.status = status.requestStatus.assessPending.status;
            setRequest(requestAux);
            setSaveEvent(!saveEvent);
        }
    }

    const handleAssessmentCompleted = () => {
        if( request ){
            let requestAux = {...request};
            requestAux.status = status.requestStatus.assessCompleted.status;
            setRequest(requestAux);

            sendUserRequestAssessmentCompletedNotification(userId, requestAux, tenantId, stompClient);

            setSaveEvent(!saveEvent);
        }
    }


    return (
        
        <PrincipalLayout section={constants.menu.admin_requests_section} isLoading={isLoading}
                        notificationNumber={props.notificationNumber} 
                        setNotificationNumber={props.setNotificationNumber}
        >

                <Toolbar />

                <Paper sx={constants.styles.paperContainer}>

                    <Grid container direction="row" spacing={0}
                        justifyContent={'left'}
                    >
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            <Typography 
                                variant="h6"
                                sx={constants.styles.textTitle}
                            >
                                Request: {request ? request.title : ''} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                            
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <ErrorMessageChip message={errorMessage} />
                        </Grid>

                        <Grid sx={constants.styles.sectionError} item xs={12} md={12}>
                            <SuccessMessageChip message={successMessage} setMessage={setSuccessMessage} />
                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >
                                
                                <InputComponent 
                                    fieldName={"Name"} 
                                    fieldValue={ request ? request.title: '' } 
                                    fieldId={'input-request-title'} 
                                    attributeName={'title'} 
                                    handleChangeField={handleChangeRequestAttribute}
                                    required={true} 
                                    disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-request-client'}
                                    fieldName={'Client'} 
                                    fieldValue={request ? request.client : null}
                                    attributeName={'client'}
                                    optionList={activeClients}
                                    isClient={true}
                                    handleChangeField={handleChangeRequestAttribute}
                                    disabled={!isRequestDraft( request ? request.status : '' )}
                                />

                                <AutocompleteComponent
                                    fieldId={'input-request-project'}
                                    fieldName={'Project'} 
                                    fieldValue={request ? request.project : null}
                                    attributeName={'project'}
                                    optionList={activeProjects}
                                    optionAttributeLabel={'name'}
                                    isClient={false}
                                    handleChangeField={handleChangeRequestAttribute}
                                    disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                />


                               
                            </Grid>
                        
                        </Grid>

                        
                        <Grid item 
                            sx={constants.styles.sectionTitle} xs={12} md={6} 
                        >

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <DateComponent
                                    fieldName={"Estimated due date"} 
                                    fieldValue={ request ? request.due_date: '' } 
                                    fieldId={'input-request-due_date'} 
                                    attributeName={'due_date'} 
                                    handleChangeField={handleChangeRequestAttribute}
                                    required={false} 
                                    disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                />

                            </Grid>

                        </Grid>
                        
                        <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            
                                <InputComponent 
                                    fieldName={"Request details"} 
                                    fieldValue={ request ? request.details: '' } 
                                    fieldId={'input-request-details'} 
                                    attributeName={'details'} 
                                    handleChangeField={handleChangeRequestAttribute}
                                    required={true} 
                                    multiline={true}
                                    rows={4}

                                    labelSx={12}
                                    labelMd={12}

                                    inputSx={12}
                                    inputMd={12}

                                    fullWidth={true}
                                    disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                />

                            
                        </Grid>

                        <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                            <Grid container direction="row" spacing={0}
                                justifyContent={'left'}
                                alignContent={'center'}
                                alignItems={'center'}
                            >

                                <SelectComponent
                                    fieldName={"Service type"} 
                                    fieldValue={ request && request.project_type ? request.project_type.id: '' } 
                                    fieldId={'input-request-project_type'} 
                                    attributeName={'project_type'} 
                                    optionList={projectTypes}
                                    optionAttributeName={'name'}
                                    handleChangeField={handleChangeProjectType}
                                    disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                />

                                <SwitchComponent 
                                    fieldName={"Requires assessment"} 
                                    fieldValue={ request ? request.requires_assess : false } 
                                    fieldId={'input-request-requires_assess'} 
                                    attributeName={'requires_assess'} 
                                    handleChangeField={handleChangeRequestAttribute} 
                                    disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                />

                            </Grid>
                        </Grid>

                        {  request && request.requires_assess && (
                        
                        <>
                            <Grid item sx={constants.styles.sectionDivider}  xs={12} md={12}>
                                <Divider/>
                            </Grid>

                            <Grid item sx={constants.styles.sectionInput} xs={12} md={12}>
                                <Typography sx={constants.styles.textNormal} >
                                    Assessment: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>
                            </Grid>

                            <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                                <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                                    <DateComponent
                                        fieldName={"Start date"} 
                                        fieldValue={ request ? request.start_datetime_assess: '' } 
                                        fieldId={'input-request-start_datetime_assess'} 
                                        attributeName={'start_datetime_assess'} 
                                        handleChangeField={handleChangeRequestAttribute}
                                        required={false} 
                                        isDateTime={true}
                                        disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                    />

                                    <DateComponent
                                        fieldName={"End date"} 
                                        fieldValue={ request ? request.end_datetime_assess: '' } 
                                        fieldId={'input-request-end_datetime_assess'} 
                                        attributeName={'end_datetime_assess'} 
                                        handleChangeField={handleChangeRequestAttribute}
                                        required={false} 
                                        isDateTime={true}
                                        disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                    />

                                </Grid>
                            </Grid>

                            <Grid item sx={constants.styles.sectionTitle} xs={12} md={6}>

                                <Grid container direction="row" spacing={0}
                                    justifyContent={'left'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >

                                    <AutocompleteComponent
                                        fieldId={'input-request-team'}
                                        fieldName={'Team'} 
                                        fieldValue={request ? request.team : null}
                                        attributeName={'team'}
                                        optionList={activeTeams}
                                        optionAttributeLabel={'name'}
                                        isClient={false}
                                        handleChangeField={handleChangeRequestAttribute}
                                        disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                    />

                                    <AutocompleteComponent
                                        fieldId={'input-request-user'}
                                        fieldName={'Team member'} 
                                        fieldValue={request ? request.user : null}
                                        attributeName={'user'}
                                        optionList={activeTeamUsers}
                                        optionAttributeLabel={'first_name'}
                                        isUser={true}
                                        handleChangeField={handleChangeRequestAttribute}
                                        disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                    />

                                </Grid>
                            </Grid>

                        </>

                        )}

                        { !isRequestDraft( request ? request.status : '' ) &&
                            (
                                <Grid item sx={constants.styles.sectionTitle} xs={12} md={12}>
                            
                                    <InputComponent 
                                        fieldName={"Assessment"} 
                                        fieldValue={ request ? request.assess_notes: '' } 
                                        fieldId={'input-request-assess_notes'} 
                                        attributeName={'assess_notes'} 
                                        handleChangeField={handleChangeRequestAttribute}
                                        required={true} 
                                        multiline={true}
                                        rows={4}

                                        labelSx={12}
                                        labelMd={12}

                                        inputSx={12}
                                        inputMd={12}

                                        fullWidth={true}
                                        disabled={isRequestAssessCompleted( request ? request.status : '' )}
                                    />

                                
                                </Grid>
                            )
                        }
                        

                        <Grid 
                            container
                            alignItems={'flex-end'}
                            alignContent={'flex-end'}
                            flexDirection={'column'}
                        >

                            <Grid item  
                                xs={12} md={12} 
                                sx={constants.styles.sectionButtons}
                            >
                                { isRequestDraft( request ? request.status : '' ) &&
                                    (
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleSendToAssessment}
                                            > 
                                                Send to assessment 
                                        </Button>
                                    )
                                }
                                { isRequestAssessPending( request ? request.status : '' ) &&
                                    (
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            sx={constants.styles.buttonNormal}
                                            onClick={handleAssessmentCompleted}
                                            > 
                                                Assessment completed 
                                        </Button>
                                    )
                                }
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    sx={constants.styles.buttonNormal}
                                    onClick={handleClickSave}
                                    > 
                                        Save 
                                </Button>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                    
                </Paper>
                
        </PrincipalLayout>

        
    );
}

export default EditRequest;