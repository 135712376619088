import { FormControl, Grid, Rating, TextField, Typography } from "@mui/material";
import constants from "./Constants";


function RatingComponent(props){

    const handleChangeValue = (e, newValue) => {
        props.handleChangeField( props.attributeName, newValue );
    }


    return (
        <>
            <Grid item sx={constants.styles.sectionInput} xs={ props.labelSx ? props.labelSx : 3} md={ props.labelMd ? props.labelMd : 3 }>
                <Typography sx={constants.styles.textNormal} >
                    {props.fieldName} :
                </Typography>
            </Grid>
            <Grid item sx={constants.styles.sectionInput} xs={ props.inputSx ? props.inputSx : 8 } md={ props.inputMd ? props.inputMd : 8 }>                
                <FormControl variant="standard">

                    <Rating
                        id={ props.fieldId ? props.fieldId : 'id_' + props.fieldName }
                        value={props.fieldValue}
                        onChange={ handleChangeValue }
                        sx={ props.fullWidth ? constants.styles.textFieldNormalFullWidth : constants.styles.textFieldNormal }
                        size="small"

                        fullWidth={props.fullWidth}
                        disabled={props.disabled}
                    />
                        
                </FormControl>
            </Grid>
        </>
    );


}


export default RatingComponent;