//Tenant Service Delegate
import axios from "axios";
import constants from "../../components/jobs-manager/util/Constants";
import { getDataRespose } from "./ServiceHelper";

export const getTenantById = async(tenanId) => {

    var response = await axios.get( constants.api.tenantApiUrl + '/' + tenanId, { headers: constants.api.defaultHeaders } )
    .catch( error => {
        console.error('[ERROR] error: ', error);
    });

    return getDataRespose(response);

}

export const getTenantLocalStorage = () => {
    const tenantStorage = localStorage.getItem(constants.storage.tenant);
    if( tenantStorage != null ){
        const tenant = JSON.parse(tenantStorage);
        return tenant;
    }
    return null;
}