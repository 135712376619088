import React from "react";
import { useNavigate } from "react-router";
import { Badge, IconButton } from "@mui/material";
import { CircleNotifications } from "@mui/icons-material";
import constants from "../util/Constants";
import { checkPermissionsNotifications, sendNotification } from "../../../service/tenant/NotificationsService";

function NotificationIcon(props) {

    const navigate = useNavigate();

    const handleNotificationsClick = () => {
        checkPermissionsNotifications();
        navigate(constants.routes.viewNotifications);
    }

    return (
        <IconButton
                size="medium"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"

                onClick={handleNotificationsClick}
        >
            { props.notificationNumber > 0 ?
                (
                    <Badge badgeContent={props.notificationNumber} color="error">
                        <CircleNotifications />
                    </Badge>
                )
                :
                (
                    <CircleNotifications />
                )
            }
            
        </IconButton>
    );

}

export default NotificationIcon;